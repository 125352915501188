const style = (theme) => {
  const output = {
    page: {
      paddingTop: (props) => `${props.headerHeight}px`,
      height: 'var(--vh)',
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },
    questionsWrapper: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
    },
    counter: {
      position: 'absolute',
      zIndex: 1,
      top: 120,
      fontSize: 12,
      color: theme.colors[3],
      width: 50,
      left: 0,
      right: 0,
      margin: '0 auto',
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -2,
        left: 25,
        height: 18,
        width: 1,
        background: theme.colors[3],
      },
      '& strong': {
        width: 25,
        display: 'inline-block',
        paddingRight: 10,
        fontFamily: theme.fonts[1],
        textAlign: 'right',
      },
      '& span': {
        paddingLeft: 10,
        width: 25,
        display: 'inline-block',
        textAlign: 'left',
      },
    },
    questions: {
      position: 'relative',
      left: '50%',
      marginLeft: -221, // 382 (card width) + 60 (margin) = 442 => diviso 2 = 221px
      display: 'flex',
      paddingBottom: 150,
      transition: `margin 1s ${theme.easings['power3.out']}`,
      height: '100%',
      padding: [60, 0, 100],
    },
    card: {
      flex: '0 0 auto',
      margin: '0 30px',
    },
    controls: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 1,
      bottom: 20,
      left: 0,
      width: '100%',
    },
    arrow: {
      position: 'relative',
      top: 0,
      zIndex: 10,
      clear: 'both',
      width: 54,
      height: 54,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      border: `1px solid ${theme.colors[3]}`,
      borderRadius: '100%',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: 10,
      padding: '0',
      transition: 'opacity 0.3s ease-out, background 0.3s ease-out',
      margin: 8,
      '& svg': {
        width: 'auto',
        height: '10px',
        stroke: 'none',
        fill: theme.colors[3],
        transition: 'fill .3s ease-out',
      },
      '@media (hover: hover)': {
        '&:hover': {
          background: theme.colors[3],
          '& svg': {
            fill: theme.colors[1],
          },
        },
      },
      '&.carousel__prev': {
        '& svg': {
          transform: 'translateX(-1px)',
        },
      },
      '&.carousel__next': {
        '& svg': {
          transform: 'translateX(1px)',
        },
      },
    },
    buttonNext: {
      position: 'relative',
      zIndex: 1,
      margin: 8,
      '&.disabled': {
        cursor: 'not-allowed',
        '@media (hover: hover)': {
          '&:hover': {
            '& $hint': {
              opacity: 1,
              transform: 'translate(-50%, 0px)',
            },
          },
        },
        '& button': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
      },
    },
    hint: {
      position: 'absolute',
      zIndex: 2,
      background: theme.colors[1],
      borderRadius: 10,
      color: theme.colors[3],
      padding: 10,
      fontSize: 12,
      bottom: 57,
      width: 170,
      textAlign: 'center',
      left: '50%',
      transform: 'translate(-50%, -5px)',
      boxShadow: '0 10px -5px 10px rgba(0, 0, 0, .2)',
      border: `1px solid ${theme.colors[3]}`,
      opacity: '0',
      fontFamily: theme.fonts[1],
      pointerEvents: 'none',
      transition: 'all .3s ease-out',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        bottom: -5,
        left: '50%',
        transform: 'translateX(-50%) rotate(-135deg)',
        width: 10,
        height: 10,
        background: theme.colors[1],
        borderTop: `1px solid ${theme.colors[3]}`,
        borderLeft: `1px solid ${theme.colors[3]}`,
      },
    },
  }

  /*------------------------------
  Max Height > 640
  Min Width > 775
  ------------------------------*/
  output['@media (max-height: 780px) and (min-width: 720px)'] = {
    counter: {
      top: 75,
      fontSize: 11,
    },
    questions: {
      paddingTop: 5,
    },
    controls: {
      bottom: 5,
    },
    card: {
      height: 'calc(var(--vh) - 250px)',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    questions: {
      paddingTop: 15,
      marginLeft: -165, // 300 (card width) + 30 (margin) = 330 => diviso 2 = 165px
      paddingBottom: 150,
    },
    counter: {
      top: 75,
      fontSize: 11,
    },
    card: {
      margin: '0 15px',
      height: 'calc(var(--vh) - 250px)',
    },
    controls: {
      bottom: 5,
    },
    arrow: {
      margin: [8, 1],
      transform: 'scale(.9)',
    },
    buttonNext: {
      whiteSpace: 'nowrap',
      '& button': {
        minWidth: 0,
      },
    },
    hint: {
      whiteSpace: 'normal',
    },
  }

  return output
}

export default style
