import { useEffect, useContext } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { array } from 'prop-types'
import { Context as LoadingContext } from '@/context/loading'

const useGLBPreload = ({
  models,
}) => {
  const { isGLBReady, setGLBReady } = useContext(LoadingContext)
  const loader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('/frontend/draco/')
  loader.setDRACOLoader(dracoLoader)

  useEffect(() => {
    if (!isGLBReady && models.length > 0) {
      loader
        .load(models, () => {
          setGLBReady(true)
        })
    }
  }, [])
}

useGLBPreload.defaultProps = {
  models: [],
}

useGLBPreload.propTypes = {
  models: array.isRequired,
}

export default useGLBPreload
