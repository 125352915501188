import { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import classNames from 'classnames'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import Form from '@/components/Form'
import { useResize } from '@/components/Handlers'
import usePrevious from '@/hooks/usePrevious'
import { Context as LoadingContext } from '@/context/loading'
import * as layerActions from '@/actions/layer'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import style from './style'
import React from 'react'

import { API } from '@/constants'

const useStyles = createUseStyles(style)

const EmailModal = () => {
  const { isLoaderExited } = useContext(LoadingContext)
  const $root = useRef()
  const rootBounds = useRef()
  const [entered, setEntered] = useState(false)
  const [modalHeight, setModalHeight] = useState(0)
  const classes = useStyles({ modalHeight })
  const [ready, setReady] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { form, page, isLayerOpen, location, user, strings } = useSelector((state) => ({
    page: state.content['save-your-place'] || {},
    location: state.fakerouter.location,
    strings: state.options.strings,
    form: state.form['email-modal'],
    user: state.user,
    isLayerOpen: state.layer.layers.some((layer) => layer.id === 'email' && layer.isOpen),
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'email' })), [dispatch])
  const moveNext = useCallback(() => dispatch(routerActions.changeLocation('timer')), [dispatch])
  const savePartialExperience = useCallback((data) => dispatch(experienceActions.savePartialExperience(data)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'saveYourPlace')), [dispatch])

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent('save-your-place')
  }, [])

  /*------------------------------
  Set Ready
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && isLoaderExited) setReady(true)
  }, [page, isLoaderExited])

  /*------------------------------
  Get Header Height
  ------------------------------*/
  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setModalHeight($root.current.getBoundingClientRect().height)
    }
  })
  useEffect(() => {
    if (ready) {
      setTimeout(() => {
        rootBounds.current = $root.current.getBoundingClientRect()
        setModalHeight($root.current.getBoundingClientRect().height)
      }, 1000)
    }
  }, [ready])

  /*------------------------------
  Close Modal on change location
  ------------------------------*/
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if ((prevLocation !== location) && isLayerOpen) {
      closeLayer()
    }
  }, [location, isLayerOpen])

  /*------------------------------
  Handle Keydown
  ------------------------------*/
  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isLayerOpen) closeLayer()
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isLayerOpen])

  /*------------------------------
  Open Modal Animation
  ------------------------------*/
  const openModal = () => {
    gsap.killTweensOf([$root.current])
    const tl = gsap.timeline({ ease: 'power3.inOut', onComplete: () => setEntered(true) })
    tl
      .to($root.current, {
        duration: 0.5,
        y: 0,
        autoAlpha: 1,
      })
  }

  /*------------------------------
  Close Modal Animation
  ------------------------------*/
  const closeModal = () => {
    setEntered(false)
    gsap.killTweensOf([$root.current])
    gsap.to($root.current, {
      duration: 0.5,
      y: 50,
      autoAlpha: 0,
    })
  }

  /*------------------------------
  Close Layer when isLayerOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isLayerOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isLayerOpen) openModal()
    if (prevIsOpen !== undefined && !isLayerOpen) closeModal()
  }, [isLayerOpen])

  const handleOAuthLogin = () => {
    localStorage.setItem('expOAuthLogin', true);
    //moveNext();
    window.location.replace(API.OAUTH_URL);
  }

  const handleWithoutLogin = () => {
    savePartialExperience()
    moveNext()
  }

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <div className={classes.box}>
        <RevealText
          tag="h1"
          type="lines"
          value={page.heading}
          visible={entered}
          lineHeight={1.4}
          delay={0}
          className={classes.title}
        />
        <RevealText
          tag="div"
          type="lines"
          value={ user.isLoggedIn ? 'Please press continue and come back in 20 minutes to complete your experience' : convertRichText(page.bodyCopy) }
          visible={entered}
          lineHeight={1.333}
          delay={0.5}
          className={classes.text}
        />
        <RevealText
          tag="div"
          type="lines"
          value={`<p>${page.subHeading}<p/>`}
          visible={entered}
          lineHeight={1.2}
          delay={0.5}
          className={classes.disclaimer}
        />

        <RevealFadeIn
          delay={0.5}
          inview={entered}
        >
          <Form
            className={classes.form}
            fields={form.fields}
            submitLabel={page.continueButton || "Continue"}
            onSuccessSend={() => moveNext()}
            initialValues={user.isLoggedIn ? { email: user.profile.email } : {}}
            sendContactForm={savePartialExperience}
            disabled={user.isLoggedIn}
            emailRequired={true}
          />
        </RevealFadeIn>
        { !user.isLoggedIn && 
          
            <RevealFadeIn
              delay={0.5}
              inview={entered}
            >

              <button
                onClick={handleOAuthLogin}
                className={classes.btn}
              >
                {strings['form.login']} / {strings['form.signup']}
              </button>
              
              <br></br><br></br>

              <button
                onClick={handleWithoutLogin}
                className={classNames({
                  [classes.btn]: true,
                  white: true,
                })}
              >
                Skip                      {/* to test updated content, change with below line, and change contentful value */ } 
                {/*page.skipLabel*/}        
              </button>
              
            </RevealFadeIn>
        }
      </div>
    </div>
  )
}

export default EmailModal
