import axios from 'axios';
import {
  SET_PROGRAM_DURATION,
  GET_PROGRAM_DURATION,
  SAVE_PROGRAM,
} from '@/actions/types'
import { API } from '@/constants'

const getProgramDuration = () => ({
    type: GET_PROGRAM_DURATION,
})

const setProgramDuration = (value) => ({
  type: SET_PROGRAM_DURATION,
  payload: value,
})

const saveProgramAction = (value) => ({
  type: SAVE_PROGRAM,
  payload: value,
})

const setDuration = (value) => (dispatch, getState) => {
  dispatch(setProgramDuration(value))
}

const getDuration = () => (dispatch, getState) => {
  dispatch(getProgramDuration())
}

const saveProgram = (value) => (dispatch, getState) => {
  dispatch(saveProgramAction(value))
}

const getUserToken = () => {
  let token = ''
  let state = JSON.parse(localStorage.getItem('loginState'))
  if(state && state.token) {
    token = state.token
  }

  return token;
}

const saveUpliftProgram = (val = {}) => async (dispatch, getState) => {
  const defaultValue = {
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    duration: '',
    q5: '',
    q6: '',
    q7: '',
    q8: '',
    completed: '',
  };

  let token = getUserToken()

  const response = await axios.put(`${API.SAVE_PROGRAM}`, {...defaultValue, ...val}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  console.log('RESPONSE', response);
  if (response) {
    dispatch(saveProgram(response));
  }
}

export {
  setDuration,
  getDuration,
  saveUpliftProgram,
}
