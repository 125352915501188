import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealFadeIn from '@/components/RevealFadeIn'
import RevealText from '@/components/RevealText'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as contentActions from '@/actions/content'
import * as experienceActions from '@/actions/experience'
import useStore from '@/base/zustand'
import { convertTime } from '@/utils/date'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const Timer = () => {
  const { selectedSport } = useStore()
  const { setPageAnimationReady, pageAnimationReady, headerHeight, setHeaderInverse } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const [elapsedTime, setElapsedTime] = useState(convertTime(0))

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, sports, fakeLocation, strings, startTime, experience } = useSelector((state) => ({
    page: state.content['timer-page'] || {},
    sports: state.sports.items,
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    startTime: state.experience.startTime,
    experience: state.experience
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'timerPage')), [dispatch])
  const setStartTime = useCallback((time) => dispatch(experienceActions.setStartTime(time)), [dispatch])
  const moveNext = useCallback(() => dispatch(routerActions.changeLocation('confirm-timer')), [dispatch])

  /*------------------------------
  Set fakerouter
  ------------------------------*/
  useEffect(() => {
    localStorage.setItem('isTimerVisited', true)
  }, [])

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent('timer-page')
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && sports.length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Header Inverse
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true)
    return () => setHeaderInverse(false)
  }, [])

  /*------------------------------
  Interval Timer
  ------------------------------*/
  useEffect(() => {
    if(startTime>0){
      localStorage.setItem('expState', JSON.stringify({ experience: experience}));   // to set start time to exp
      //setExperience(JSON.parse(localStorage.getItem('expState')))

      const interval = setInterval(() => {
        const now = Date.now()
        setElapsedTime(convertTime(now - startTime))
      }, 1000)
  
      return () => {
        clearInterval(interval)
      }
    }
  }, [startTime])

  /*------------------------------
  Render Sport
  ------------------------------*/
  const renderSport = () => {
    const sportName = sports.filter((s) => s.id === experience.sport_id)[0].name
    const sportImage = sports.find((s) => s.id === experience.sport_id)?.image?.file?.url
    return (
      <div className={classes.sport}>
        {sportImage && <img src={sportImage} alt={sportName} />}
        <span>{sportName}</span>
      </div>
    )
  }

  const handeleStartExercise = () => {
    setStartTime(Date.now())
  }

  const handeleEndExercise = () => {
    moveNext()
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <div className={classes.page}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <RevealFadeIn delay={0} inview={pageAnimationReady} >
                {renderSport()}
              </RevealFadeIn>
             
              <RevealFadeIn delay={1} inview={pageAnimationReady} >
                <div className={classes.timer} dangerouslySetInnerHTML={{ __html: elapsedTime }} />
              </RevealFadeIn>
             
              <div className={classes.buttons}>
                <RevealFadeIn delay={2} inview={pageAnimationReady} >
                  <button
                    onClick={() => {
                      (startTime==0)?handeleStartExercise():handeleEndExercise()
                    }}
                    className={classNames({
                      [classes.btn]: true,
                      white: true,
                      noStroke: true,
                    })}
                  >
                    <span>{(startTime==0)?page.startTimerButton:page.endTimerButton}</span>
                  </button>
                </RevealFadeIn>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageTimer ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default Timer
