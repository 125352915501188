const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      width: '45vw',
      left: 'calc(50% - 22.5vw)',
      top: (props) => `calc(50% - ${props.modalHeight / 2}px)`,
      background: theme.colors[1],
      color: theme.colors[3],
      zIndex: theme.zindex.modal,
      padding: 50,
      borderRadius: 22,
      opacity: 0,
      transform: 'translateY(50px)',
      visibility: 'hidden',
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 36,
      marginBottom: 30,
      textAlign: 'center',
    },
    text: {
      textAlign: 'center',
      fontSize: 14,
      marginBottom: 30,
    },
    btn: {
      extend: theme.button,
      display: 'block !important',
      marginTop: 20,
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: .5,
      },
    },
    programmes: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      transition: 'all 350ms',
      marginTop: 50,

      '& > div': {
        cursor: 'pointer',
        opacity: 0.5,
      },

      '& div:nth-child(2)': {
        margin: [0, 55, 0, 55],
      },

      '& span': {
        textAlign: 'center',
        display: 'block',
        fontSize: 24,
        marginTop: 33,
      }
    },
    selectedProgramme: {
      opacity: '1!important',

      '& img': {
        '-webkit-filter': 'drop-shadow(0px 0px 18px rgba(165, 165, 165, .5))',
        filter: 'url(#drop-shadow)',
      },
      
      '& span': {
        fontWeight: 'bold',
      }
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '90vw',
      left: 'calc(50% - 45vw)',
      padding: [30, 20],
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 24,
    },
    programmes: {
      '& span': {
        marginTop: 13,
        fontSize: 16,
      },
      '& > div:nth-child(2)': {
        margin: [0, 10]
      }
    }
  }

  return output
}

export default style