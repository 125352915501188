import { createContext, useState, useEffect } from 'react'
import useSound from 'use-sound'
import ambientAudio from '@/assets/audio/audio_ambient.mp3'

const Context = createContext({})
const { Provider, Consumer } = Context

const AudioContext = ({
  children,
}) => {
  const [playAmbientAudio, {
    pause: pauseAmbientAudio,
    sound: soundAmbient,
  }] = useSound(ambientAudio, { loop: true, volume: .55 })
  const [isAudioActive, setAudioActive] = useState(false)
  const [isAudioControlsVisible, setAudioControlsVisible] = useState(false)
  const [isFirstTimeAudio, setFirstTimeAudio] = useState(true)
  const [isAudioReady, setAudioReady] = useState(false)

  useEffect(() => {
    if (isAudioActive) playAmbientAudio()
    if (!isAudioActive) pauseAmbientAudio()
  }, [isAudioActive])

  const pauseOnBlur = () => {
    if (isAudioActive) pauseAmbientAudio()
  }

  const playOnFocus = () => {
    if (isAudioActive) playAmbientAudio()
  }

  useEffect(() => {
    window.addEventListener('blur', pauseOnBlur)
    window.addEventListener('focus', playOnFocus)
    return () => {
      window.removeEventListener('blur', pauseOnBlur)
      window.removeEventListener('focus', playOnFocus)
    }
  }, [isAudioActive])

  useEffect(() => {
    if (soundAmbient) {
      soundAmbient.once('load', () => {
        setAudioReady(true)
      })
    }
  }, [soundAmbient])

  return (
    <Provider
      value={{
        isAudioActive,
        setAudioActive,
        isAudioControlsVisible,
        setAudioControlsVisible,
        isFirstTimeAudio,
        setFirstTimeAudio,
        isAudioReady,
      }}
    >
      {children}
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default AudioContext
