const style = (theme) => {
  const time = '.7s'
  const easing = theme.easings['power3.inOut']

  const output = {
    root: {
      background: theme.colors[1],
      color: theme.colors[3],
      width: 382,
      height: 525,
      borderRadius: 40,
      margin: [0, 'auto'],
      zIndex: 1,
      border: `1px solid ${theme.colors[3]}`,
      boxShadow: '0 6px 10px rgba(0, 0, 0, .1)',
      transition: `background .1s ease-out .1s, color .1s ease-out .1s, transform 1s ${theme.easings['power3.out']}`,
      transformOrigin: '50% bottom',
      userSelect: 'none',
    },
    active: {
      background: theme.colors[3],
      color: theme.colors[1],
      '& $title': {
        top: 60,
        transform: 'translateY(0%)',
      },
      '& $buttons': {
        opacity: 1,
        pointerEvents: 'all',
        transition: `opacity .4s ${easing} .4s`,
      },
    },
    title: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      width: '100%',
      padding: [0, 30],
      textAlign: 'center',
      fontSize: 29,
      lineHeight: 1.2,
      fontFamily: theme.fonts[1],
      transition: `all ${time} ${easing}`,
    },
    hint: {
      position: 'absolute',
      zIndex: 10,
      top: '100%',
      transform: 'translateY(-50%)',
      left: 0,
      width: '100%',
      padding: [0, 30],
      margin: [50,0],
      textAlign: 'center',
      fontSize: 12,
      lineHeight: 0.1,
      fontFamily: theme.fonts[1],
      color: theme.colors[3],
      transition: `all ${time} ${easing}`,
    },
    buttonsWrap: {},
    buttons: {
      height: 286,
      position: 'absolute',
      zIndex: 3,
      bottom: 30,
      left: 0,
      width: '100%',
      opacity: 0,
      pointerEvents: 'none',
      transition: `opacity .2s ${easing}`,
    },
    button: {
      fontSize: 12,
      position: 'absolute',
      zIndex: -1,
      left: '50%',
      top: '50%',
      width: 30,
      height: 30,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transformOrigin: '0px 0px',
      cursor: 'pointer',
      color: theme.colors[1],
      border: 'none',
      opacity: 0.7,
      '&.active': {
        opacity: 1,
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: -10,
        zIndex: -1,
        background: 'transparent',
        width: '200%',
        height: '300%',
        borderBottomRightRadius: 40,
        borderBottomLeftRadius: 40,
      },
    },
    spinner: {
      position: 'absolute',
      zIndex: 1,
      width: 'calc(100% - 80px)',
      height: 'calc(100% - 80px)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fill: 'none',
      stroke: theme.colors[1],
      strokeWidth: 1,
      opacity: 0.3,
      pointerEvents: 'none',
    },
    spinner2: {
      opacity: 1,
      strokeDasharray: '0 151',
      transform: 'translate(-50%, -50%) rotate(-90deg)',
      transformOrigin: 'center',
      pointerEvents: 'none',
    },
    ball: {
      position: 'absolute',
      zIndex: 1,
      width: 'calc(100% - 80px)',
      height: 'calc(100% - 80px)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transformOrigin: 'center center',
      pointerEvents: 'none',
      '&:before': {
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        zIndex: 1,
        width: 14,
        height: 14,
        borderRadius: 10,
        background: theme.colors[1],
        top: -3,
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    num: {
      position: 'absolute',
      zIndex: 1,
      width: 80,
      height: 80,
      borderRadius: '50%',
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 50,
      textAlign: 'center',
      fontFamily: theme.fonts[1],
      paddingTop: 10,
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        boxShadow: `inset 0 0 20px ${theme.colors[3]}`,
      },
    },
    flag: {
      position: 'absolute',
      zIndex: 2,
      top: 20,
      right: 20,
      background: theme.colors[8],
      width: 30,
      height: 30,
      borderRadius: '50%',
      border: `1px solid ${theme.colors[1]}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      fontFamily: theme.fonts[1],
      color: theme.colors[1],
      paddingTop: 1,
      opacity: 0,
      transition: 'opacity .2s ease-out .2s',
      '& svg': {
        fill: theme.colors[1],
        width: '70%',
      },
    },
    visibleFlag: {
      opacity: 1,
    },
  }

  /*------------------------------
  Max Height > 740
  Min Width > 720
  ------------------------------*/
  output['@media (max-height: 740px) and (min-width: 720px)'] = {
    root: {
      height: 'calc(var(--vh) - 270px)',
      borderRadius: 15,
    },
    buttons: {
      bottom: 10,
    },
    active: {
      '& $title': {
        top: 30,
      },
    },
    title: {
      fontSize: 24,
    },
    flag: {
      top: 8,
      right: 8,
      width: 20,
      height: 20,
    },
  }

  /*------------------------------
  Max Height > 640
  Min Width > 720
  ------------------------------*/
  output['@media (max-height: 640px) and (min-width: 720px)'] = {
    root: {
      height: 'calc(var(--vh) - 250px)',
      borderRadius: 15,
    },
    title: {
      fontSize: 20,
    },
    buttons: {
      bottom: 0,
      height: 220,
    },
    flag: {
      top: 8,
      right: 8,
      width: 20,
      height: 20,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: 300,
      borderRadius: 15,
      height: 'calc(var(--vh) - 250px)',
      maxHeight: 450,
    },
    active: {
      '& $title': {
        top: '10%',
        fontSize: 24,
      },
    },
    title: {
      padding: [0, 30],
      fontSize: 22,
      lineHeight: 1.3,
    },
    flag: {
      top: 8,
      right: 8,
      width: 20,
      height: 20,
    },
    buttons: {
      bottom: '5%',
    },
  }

  /*------------------------------
  Max Width < 720 & Max Height < 600
  ------------------------------*/
  output['@media (max-width: 720px) and (max-height: 600px)'] = {
    active: {
      '& $title': {
        top: 10,
        fontSize: 22,
      },
    },
    buttons: {
      bottom: 0,
    },
  }

  return output
}

export default style
