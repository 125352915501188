export const REQUEST_OPTIONS = 'REQUEST_OPTIONS'
export const RECEIVE_OPTIONS = 'RECEIVE_OPTIONS'
export const REQUEST_STRING = 'REQUEST_STRING'

export const REQUEST_LOCALES = 'REQUEST_LOCALES'
export const RECEIVE_LOCALES = 'RECEIVE_LOCALES'
export const SET_LANGUAGE = 'SET_LANGUAGE'

export const REQUEST_CONTENT = 'REQUEST_CONTENT'
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT'
export const REQUEST_CONTENT_IMAGE = 'REQUEST_CONTENT_IMAGE'
export const REQUEST_STEPS = 'REQUEST_STEPS'
export const RECEIVE_STEPS = 'RECEIVE_STEPS'
export const REQUEST_EMOTIONS = 'REQUEST_EMOTIONS'
export const RECEIVE_EMOTIONS = 'RECEIVE_EMOTIONS'
export const REQUEST_QUESTIONS = 'REQUEST_QUESTIONS'
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'

export const REQUEST_NAV = 'REQUEST_NAV'
export const RECEIVE_NAV = 'RECEIVE_NAV'
export const REQUEST_NAV_ITEMS = 'REQUEST_NAV_ITEMS'
export const RECEIVE_NAV_ITEMS = 'RECEIVE_NAV_ITEMS'

export const OPEN_LAYER = 'OPEN_LAYER'
export const CLOSE_LAYER = 'CLOSE_LAYER'
export const CLOSE_ALL_LAYER = 'CLOSE_ALL_LAYER'

export const FAKEROUTER_CHANGE_LOCATION = 'FAKEROUTER_CHANGE_LOCATION'
export const FAKEROUTER_MOVE_FORWARD = 'FAKEROUTER_MOVE_FORWARD'
export const FAKEROUTER_MOVE_BACKWARD = 'FAKEROUTER_MOVE_BACKWARD'

export const REQUEST_EVENTS = 'REQUEST_EVENTS'
export const REQUEST_TAGS = 'REQUEST_TAGS'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'
export const RECEIVE_DAILY_EVENTS = 'RECEIVE_DAILY_EVENTS'
export const RECEIVE_TOTAL_EVENTS = 'RECEIVE_TOTAL_EVENTS'
export const RECEIVE_WORLD_MAP_EVENTS = 'RECEIVE_WORLD_MAP_EVENTS'
export const REQUEST_EVENT_IMAGE = 'REQUEST_EVENT_IMAGE'
export const RECEIVE_EVENT_IMAGE = 'RECEIVE_EVENT_IMAGE'
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
export const SET_CURRENT_TAG = 'SET_CURRENT_TAG'

export const REQUEST_SPORTS = 'REQUEST_SPORTS'
export const RECEIVE_SPORTS = 'RECEIVE_SPORTS'

export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const SET_SINGLE_EMOTION = 'SET_SINGLE_EMOTION'
export const SET_SPORT = 'SET_SPORT'
export const SET_UPLIFTER_PROGRAM_ID = 'SET_UPLIFTER_PROGRAM_ID'
export const SET_STEP = 'SET_STEP'
export const SET_TIME = 'SET_TIME'
export const SET_START_TIME = 'SET_START_TIME'
export const SET_END_TIME = 'SET_END_TIME'
export const SET_AGE = 'SET_AGE'
export const SET_GENDER = 'SET_GENDER'
export const SET_EXERTION = 'SET_EXERTION'
export const SET_LOTTERY = 'SET_LOTTERY'
export const SET_PRIZE_MAIL_BODY = 'SET_PRIZE_MAIL_BODY'
export const SET_EVENT_LOCATION = 'SET_EVENT_LOCATION'
export const SET_UUID = 'SET_UUID'
export const SET_IMAGE = 'SET_IMAGE'
export const SET_IMAGE_DOWNLOAD = 'SET_IMAGE_DOWNLOAD'
export const SET_IMAGE_THUMB = 'SET_IMAGE_THUMB'
export const SET_STARTING_EXPERIENCE = 'SET_STARTING_EXPERIENCE'
export const SET_ENDED_EXPERIENCE = 'SET_ENDED_EXPERIENCE'
export const SET_EXPERIENCE = 'SET_EXPERIENCE'
export const SET_TOTAL_UPLIFT = 'SET_TOTAL_UPLIFT'
export const SET_IMAGE_GIF_1 = 'SET_IMAGE_GIF_1'
export const SET_IMAGE_GIF_2 = 'SET_IMAGE_GIF_2'
export const SET_IMAGE_GIF_3 = 'SET_IMAGE_GIF_3'
export const SET_IMAGE_GIF_4 = 'SET_IMAGE_GIF_4'
export const REQUEST_GIF = 'REQUEST_GIF'
export const REQUEST_GEOCODING = 'REQUEST_GEOCODING'
export const RECEIVE_GEOCODING = 'RECEIVE_GEOCODING'

export const RESET_EXPERIENCE = 'RESET_EXPERIENCE'
export const REQUEST_EXPERIENCE = 'REQUEST_EXPERIENCE'
export const RECEIVE_EXPERIENCE = 'RECEIVE_EXPERIENCE'
export const REQUEST_SAVE_EXPERIENCE = 'REQUEST_SAVE_EXPERIENCE'
export const RECEIVE_SAVE_EXPERIENCE = 'RECEIVE_SAVE_EXPERIENCE'
export const RECEIVE_MINDRACE_STATUS = 'RECEIVE_MINDRACE_STATUS'
export const SET_PHASE = 'SET_PHASE'
export const DENIED_CAMERA = 'DENIED_CAMERA'
export const SET_USER_IMAGE = 'SET_USER_IMAGE'

export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER'
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT'
export const RECEIVE_IS_NOT_LOGGED_IN = 'RECEIVE_IS_NOT_LOGGED_IN'
export const RECEIVE_EMAIL_RESET = 'RECEIVE_EMAIL_RESET'
export const RECEIVE_PASSWORD_RESET = 'RECEIVE_PASSWORD_RESET'
export const RECEIVE_PREFERENCES = 'RECEIVE_PREFERENCES'
export const SET_POSITION = 'SET_POSITION'

export const REQUEST_MAP = 'REQUEST_MAP'
export const RECEIVE_MAP = 'RECEIVE_MAP'
export const SET_MAP_LEVEL = 'SET_MAP_LEVEL'
export const SET_MAP_EMOTION = 'SET_MAP_EMOTION'
export const SET_MAP_SPORT = 'SET_MAP_SPORT'
export const SET_MAP_EVENT = 'SET_MAP_EVENT'
export const SET_MAP_ZONE = 'SET_MAP_ZONE'
export const SET_MAP_ZONE_NAME = 'SET_MAP_ZONE_NAME'
export const SET_MAP_CURRENT_COUNTRY = 'SET_MAP_CURRENT_COUNTRY'
export const RESET_MAP_MARKERS = 'RESET_MAP_MARKERS'
export const REQUEST_MAP_SPORT_LIST = 'REQUEST_MAP_SPORT_LIST'
export const RECEIVE_MAP_SPORT_LIST = 'RECEIVE_MAP_SPORT_LIST'

export const SET_PROGRAM_DURATION = 'SET_PROGRAM_DURATION'
export const GET_PROGRAM_DURATION = 'GET_PROGRAM_DURATION'
export const SAVE_PROGRAM = 'SAVE_PROGRAM'
