import {
  RECEIVE_PROFILE,
  RECEIVE_LOGIN,
  RECEIVE_REGISTER,
  RECEIVE_IS_NOT_LOGGED_IN,
  RECEIVE_LOGOUT,
  RECEIVE_PREFERENCES,
  SET_POSITION,
} from '@/actions/types'

const initialState = {
  isLoggedIn: false,
  position: [],
  profile: {
    nickname: '',
    email: '',
    marketing: false,
    wmm_consent: true,
    updated_at: '2021-04-16T12:26:08.000000Z',
    created_at: '2021-04-16T12:26:08.000000Z',
  },
  experiences: [],
  uplifterPrograms: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROFILE:
      state = {
        ...state,
        isLoggedIn: true,
        profile: action.payload.profile,
        experiences: action.payload.experiences,
        uplifterPrograms: action.payload.uplifterPrograms,
        phase:action.payload.phase,
        isPhaseFirstExpDone:action.payload.isPhaseFirstExpDone,
        phaseTwoExpUUID:action.payload.phaseTwoExpUUID
      }
      break
    case RECEIVE_LOGIN:
      state = {
        ...state,
        isLoggedIn: true,
        profile: action.payload.profile,
        experiences: action.payload.experiences,
        uplifterPrograms: action.payload.uplifterPrograms,
        token: action.payload.token,
        phase:action.payload.phase,
        isPhaseFirstExpDone:action.payload.isPhaseFirstExpDone,
        phaseTwoExpUUID:action.payload.phaseTwoExpUUID
      }
      break
    case RECEIVE_REGISTER:
      state = {
        ...state,
        ...action.payload,
      }
      break
    case RECEIVE_PREFERENCES:
      state = {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }
      break
    case RECEIVE_IS_NOT_LOGGED_IN:
      state = {
        ...state,
        isLoggedIn: false,
      }
      break
    case RECEIVE_LOGOUT:
      state = {
        ...state,
        isLoggedIn: false,
        token: '',
        profile: {},
        experiences: [],
        uplifterPrograms: [],
      }
      break
    case SET_POSITION:
      state = {
        ...state,
        position: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
