import create from 'zustand'

const useStore = create((set) => ({
  isFaceDetected: false,
  setFaceDetected: (bool) => set(() => ({ isFaceDetected: bool })),
  isFaceScanFinish: false,
  setIsFaceScanFinish: (bool) => set(() => ({ isFaceScanFinish: bool })),
  isCameraEnabled: 'unset',
  setCameraEnabled: (str) => set(() => ({ isCameraEnabled: str })),
  isGettingEmotions: false,
  setStartGettingEmotions: (bool) => set(() => ({ isGettingEmotions: bool })),

  isStartFaceScan: false,
  setStartFaceScan: (bool) => set(() => ({ isStartFaceScan: bool })),

  isFaceInCamera: false,
  setFaceInCamera: (bool) => set(() => ({ isFaceInCamera: bool })),

  isTimerStarted: false,
  setTimerStarted: (bool) => set(() => ({ isTimerStarted: bool })),

  initFaceScan: true,
  setInitFaceScan: (bool) => set(() => ({ initFaceScan: bool })),

  isModalClosed: false,
  setModalClosed: (bool) => set(() => ({ isModalClosed: bool })),
  /*------------------------------
  BEFORE/AFTER FLAG
  --------------------------------
  Serve per il pulsante before/after.
  Before è true
  After è false
  ------------------------------*/
  beforeAfterFlag: false,
  setBeforeAfterFlag: (bool) => set(() => ({ beforeAfterFlag: bool })),

  /*------------------------------
  SCALES
  --------------------------------
  Setto gli scale per ogni ID
  per modificare gli spikes
  ------------------------------*/
  scales: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  setScales: (arr) => set(() => ({ scales: arr })),

  /*------------------------------
  ACTIVE
  --------------------------------
  Setto l'id dell'emozione
  ------------------------------*/
  active: -1,
  setActive: (e) => set(() => ({ active: e })),

  /*------------------------------
  FIRST TIME ANIMATION
  --------------------------------
  Mi serve per visualizzare i
  controlli dei risultati dopo
  l'inizio dell'animazione
  ------------------------------*/
  firstTimeAnimation: true,
  setFirstTimeAnimation: (bool) => set(() => ({ firstTimeAnimation: bool })),

  /*------------------------------
  HOVER DAL PIN
  --------------------------------
  Setto il rollover al cursore
  per evidenziare il nome
  corrispondente nel panello laterale
  ------------------------------*/
  hover: -1,
  setHover: (e) => set(() => ({ hover: e })),

  /*------------------------------
  CURSOR
  --------------------------------
  controllo se il pulsante del
  mouse sia cliccato o meno
  ------------------------------*/
  cursorDown: false,
  setCursorDown: (bool) => set(() => ({ cursorDown: bool })),

  /*------------------------------
  CURSOR VISIBILITY
  --------------------------------
  In base alle differenze di valori
  tra il before e il post training
  setto le 5 emozioni principali
  ------------------------------*/
  cursorVisibilities: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  setCursorVisibilities: (arr) => set(() => ({ cursorVisibilities: arr })),

  /*------------------------------
  SELECTED SPORT
  ------------------------------*/
  selectedSport: 1,
  setSelectedSport: (sport) => set(() => ({ selectedSport: sport })),

  /*------------------------------
  TIMER
  ------------------------------*/
  startTime: 0,
  setStartTime: (date) => set(() => ({ startTime: date })),
  finalTime: 0,
  setFinalTime: (date) => set(() => ({ finalTime: date })),

  /*------------------------------
  SCAN STATUS
  ------------------------------*/
  scanStatus: 0,
  setScanStatus: (num) => set(() => ({ scanStatus: num })),

  /*------------------------------
  SET ROTATIONS
  --------------------------------
  Genero rotazioni random per il
  salvataggio delle immagini
  ------------------------------*/
  rotations: { x: Math.random() * Math.PI * 2, y: Math.random() * Math.PI * 2 },

  /*------------------------------
  DEBUG
  --------------------------------
  Toglie il tempo di attesa della
  sfera al primo load
  debug = 1 => tempo di attesa
  debug = 0 => nessun tempo di attesa
  ------------------------------*/
  debug: 1,
  setDebug: (num) => set(() => ({ debug: num })),

  /*------------------------------
  OVERLAY
  --------------------------------
  ------------------------------*/
  isTooltipOpen: false,
  setTooltipOpen: (bool) => set(() => ({ isTooltipOpen: bool })),
  tooltipBounds: { left: '20px', top: '120px', right: '250px', bottom: '250px' },
  tooltipMargin: 20,
  tooltipStep: 0,
  setTooltipStep: (tooltipStep) => set(() => ({ tooltipStep: tooltipStep % 3 })),
  // setTooltipBounds: (bounds) => set(() => ({ tooltipBounds: { left: bounds.left, top: bounds.top, right: bounds.right, bottom: bounds.bottom } })),
  setTooltipBounds: (tooltipBounds) => set(() => ({ tooltipBounds })),
  tooltipOpacity: 0,
  setTooltipOpacity: (tooltipOpacity) => set(() => ({ tooltipOpacity })),
  tooltipText: 'The Pre and Post-Exercise scores are averages of all 10 metrics scores using the facial scan and question data you provided before and after exercise.',
  setTooltipText: (tooltipText) => set(() => ({ tooltipText })),

}))

export default useStore
