import React, { useState, useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { useTheme } from 'react-jss'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as ScrollbarContext } from '@/context/scrollbar'

gsap.registerPlugin(ScrollTrigger)

const InViewTrigger = ({
  children,
  offset,
  once,
  className,
}) => {
  const $root = useRef()
  const theme = useTheme()
  const trigger = useRef()
  const [inview, setInview] = useState(false)
  const { scrollElement } = useContext(ScrollbarContext)

  useEffect(() => {
    if (scrollElement) {
      trigger.current = ScrollTrigger.create({
        trigger: $root.current,
        scroller: theme.detect.isNativeScroll ? window : scrollElement,
        start: `top ${offset}`,
        once,
        onEnter: () => once && setInview(true),
        onToggle: (e) => !once && setInview(e.isActive),
      })
    }
    return () => {
      if (scrollElement) trigger.current.kill()
    }
  }, [scrollElement])

  return (
    <div ref={$root} className={className}>
      {React.Children.map(children, (child) => React.cloneElement(child, { inview }))}
    </div>
  )
}

InViewTrigger.defaultProps = {
  offset: '90%',
  once: true,
}

export default InViewTrigger
