/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo, useState } from 'react'
import injectSheet, { useTheme } from 'react-jss'
import classNames from 'classnames'
import Select from 'react-select'
import style from './style'

const SelectField = ({
  classes,
  className,
  defaultValue,
  error,
  label,
  name,
  onBlur,
  options,
  required,
  setFieldValue,
  submitCount,
  touched,
  value,
  withIcon,
  withLabel,
  isDisabled,
  sports
}) => {
  const [newValue, setNewValue] = useState()
  const [icon,setIconState] = useState()
  const theme = useTheme()
  const getOptions = useMemo(() => options.map((option) => ({ value: option.id, label: option.name, color: option.color })))

  const colourStyles = {
    option: (styles, { data }) => {
      const { color } = data
      return {
        ...styles,
        color,
      }
    },
  }
  const setIcon = (id) => setIconState(sports.find((s) => s.id === id)?.image?.file?.url)
  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlSelect]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
        [classes.withIcon]: icon && withIcon,
        [className]: className,
      })}
      style={{ '--icon': `url(https:${icon})` }}
    >
      <label className={classes.select}>
        {withLabel && (
          <span className={classes.label}>
            {label}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <Select
          isDisabled = {isDisabled ? isDisabled : false}
          styles={colourStyles}
          placeholder={label}
          options={getOptions}
          name={name}
          value={value ? { label: value } : ''}
          onChange={(option) => {
            setNewValue(option.value);
            setFieldValue(option.label, option.value);
            if(withIcon) setIcon(option.value);
          }}
          onBlur={onBlur}
          className="selectContainer"
          classNamePrefix="customSelect"
          captureMenuScroll={true}
          isSearchable={!theme.detect.isNativeScroll}
          {...(defaultValue && { // eslint-disable-line
            defaultValue: { value: defaultValue.id, label: defaultValue.name },
          })}
          // menuIsOpen
        />
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

SelectField.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(SelectField)
