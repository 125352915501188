const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      minHeight: 'var(--vh)',
      paddingTop: 100,
      background: '#3E50BC',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    box: {
      textAlign: 'center',
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },

    sport: {
      color: theme.colors[1],
      fontSize: 14,
      margin: [50, 'auto', 0],
      textAlign: 'center',
      maxWidth: 300,
      '& img': {
        width: 45,
        height: 45,
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'inline-block',
        marginBottom: 8,
        boxShadow: `0 0 0 4px ${theme.colors[1]}`,
        background: theme.colors[1],
      },
      '& span': {
        display: 'block',
      },
    },

    timer: {
      fontFamily: theme.fonts[0],
      fontSize: '21vw',
      zIndex: 1,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      color: 'rgba(0, 0, 0, 0)',
      WebkitTextFillColor: 'none',
      WebkitTextStrokeWidth: '1px',
      WebkitTextStrokeColor: theme.colors[1],
      textTransform: 'uppercase',
      lineHeight: 0.9,
      '& span': {
        display: 'inline-block',
        width: '.66em',
        textAlign: 'center',
      },
    },
    buttons: {
      marginBottom: 40,
      '& $btn': {
        margin: [0, 10],
      },
    },
    hint: {
      maxWidth: 360,
      margin: [0, 'auto', 20],
      color: theme.colors[1],
      fontSize: 14,
    },
  }

  /*------------------------------
  Min Width > 1680
  ------------------------------*/
  output['@media (min-width: 1680px)'] = {
    timer: {
      fontSize: 250,
    },
  }

  /*------------------------------
  Min Width < 670
  ------------------------------*/
  output['@media (max-height: 670px)'] = {
    sport: {
      marginTop: 0,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    // Hero
    hero: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      padding: [0, 0, 100],
    },
    title: {
      fontSize: 24,
      marginBottom: 35,
    },
    text: {
      fontSize: 14,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },
    hint: {
      padding: [0, 20],
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    buttons: {
      marginTop: 40,
      '& $btn': {
        margin: 10,
      },
    },
  }

  return output
}

export default style
