const initialState = {
  'email-modal': {
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
      },
    ],
  },
  login: {
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        required: true,
        type: 'password',
      },
    ],
    forgotPassword: true,
    registerLink: true,
  },
  register: {
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        required: true,
        type: 'password',
      },
      {
        label: 'Nickname',
        name: 'nickname',
        placeholder: 'Username',
        required: true,
        type: 'text',
      },
      {
        label: 'By creating an account, you agree to <a href="/privacy-notice">ASICS Privacy Policy</a> and <a href="/terms-conditions">Terms of Use.</a>',
        name: 'privacy',
        placeholder: '',
        required: true,
        type: 'checkbox',
      },
      {
        label: 'Check box to keep up with ASICS products and innovations',
        name: 'marketing',
        placeholder: '',
        required: false,
        type: 'checkbox',
      },
    ],
    forgotPassword: true,
    registerLink: true,
  },
  portal: {
    fields: [
      {
        label: 'Keep up with new ASICS products,<br> styles and technologies',
        name: 'marketing',
        placeholder: '',
        required: false,
        type: 'checkbox',
      },
    ],
  },
  'forgot-password': {
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
      },
    ],
  },
  'reset-password': {
    fields: [
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        required: true,
        type: 'password',
      },
    ],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return { ...state }
  }
}
