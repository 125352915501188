import { useState, useEffect, useContext } from 'react'
import { debounce } from '@/utils/debounce'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { theme } from '@/style'

const useViewport = () => {
  const { scrollbar } = useContext(ScrollbarContext)
  const [vw, setVW] = useState(0)
  const [vh, setVH] = useState(0)

  const setSizes = debounce(() => {
    setVW(window.innerWidth)
    setVH(window.innerHeight)
  }, 300)

  useEffect(() => {
    setSizes()
    window.addEventListener('resize', () => {
      if (!theme.detect.hasTouch) setSizes()
    })
    return () => window.removeEventListener('resize', setSizes)
  }, [])

  /*------------------------------
  On Touch device listen orientationchange
  ------------------------------*/
  useEffect(() => {
    setSizes()
    if (scrollbar) {
      window.addEventListener('orientationchange', () => {
        // To get post-orientationchange width/height, listen resize event
        const afterOrientationChange = () => {
          scrollbar.scrollTo(0, {
            disableLerp: true,
            callback: () => {
              setSizes()
              window.removeEventListener('resize', afterOrientationChange)
            },
          })
          // if (theme.detect.hasTouch) setSizes()
        }
        window.addEventListener('resize', afterOrientationChange)
      })
    }
    return () => {
      if (scrollbar) window.removeEventListener('orientationchange', setSizes)
    }
  }, [scrollbar])

  useEffect(() => {
    document.documentElement.style.setProperty('--vw', `${vw}px`)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [vh, vw])

  return { vw, vh }
}

export default useViewport
