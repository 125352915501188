import ResultsEvent from '@/pages/EventResults/Experience/EventResult'
import * as localeActions from '@/actions/locale'
import * as experienceActions from '@/actions/experience'
import { useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { API_BASE_URL } from '@/constants'
import axios from 'axios'

const ResultsEventPage = () => {
  const dispatch = useDispatch()
  const setLanguage = useCallback((code) => dispatch(localeActions.setLanguage(code)), [dispatch])
  const setEmotion = useCallback((value, key, s) => dispatch(experienceActions.setSingleEmotion(value, key, s)), [dispatch])
  const [render, setRender] = useState(false)
  const [uplift, setUplift] = useState(0)
  const [eventName, setEventName] = useState('')
  const history = useHistory()
  setLanguage('en-US')
  const urlSplitted = history.location.pathname.split('/')
  const eventId = urlSplitted[urlSplitted.length - 1]

  useLayoutEffect(() => {
    axios.get(`${API_BASE_URL}/event/${eventId}`).then((response) => {
      const { data } = response
      setEmotion(data.composed_pre_avg, 'composed', 'pre')
      setEmotion(data.resilient_pre_avg, 'resilient', 'pre')
      setEmotion(data.positive_pre_avg, 'positive', 'pre')
      setEmotion(data.content_pre_avg, 'content', 'pre')
      setEmotion(data.relaxed_pre_avg, 'relaxed', 'pre')
      setEmotion(data.confident_pre_avg, 'confident', 'pre')
      setEmotion(data.alert_pre_avg, 'alert', 'pre')
      setEmotion(data.calm_pre_avg, 'calm', 'pre')
      setEmotion(data.focused_pre_avg, 'focused', 'pre')
      setEmotion(data.energized_pre_avg, 'energized', 'pre')

      setEmotion(data.composed_post_avg, 'composed', 'post')
      setEmotion(data.resilient_post_avg, 'resilient', 'post')
      setEmotion(data.positive_post_avg, 'positive', 'post')
      setEmotion(data.content_post_avg, 'content', 'post')
      setEmotion(data.relaxed_post_avg, 'relaxed', 'post')
      setEmotion(data.confident_post_avg, 'confident', 'post')
      setEmotion(data.alert_post_avg, 'alert', 'post')
      setEmotion(data.calm_post_avg, 'calm', 'post')
      setEmotion(data.focused_post_avg, 'focused', 'post')
      setEmotion(data.energized_post_avg, 'energized', 'post')
      setUplift(data.variation)
      setEventName(data.event_name)
      setTimeout(() => {
        setRender(true)
      }, 2000)
    }).catch((error) => {
      if (eventId !== 'world-uplift-map') alert(`Unable to find that event id error:${error}`)
    })
  })

  if (render) {
    return (
      <ResultsEvent key="event-uplift-results" totalUplift={uplift} eventName={eventName} />
    )
  }
  return <></>
}

export default ResultsEventPage
