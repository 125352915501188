const style = (theme) => {
    const output = {
        overlay: {
            width: '100vw',
            height: '100vh',
            inset: 0,
            position: 'absolute',
            // backgroundColor: 'hsla(222, 53%, 10%, 0.7)',
            backgroundColor: 'hsla(222, 53%, 90%, 0.6)',
            backdropFilter: 'blur(2px)',
            zIndex: 1000,
            transition: `opacity 550ms ${theme.easings['power3.out']} 0s`,
            pointerEvents: 'none',
            opacity: 0,
            // opacity: (props) => props.overlayOpacity.current,
            // clipPath:
            //   'polygon(\n\t\t0% 0%,\n\t\t0% 100%,\n\t\tvar(--bounds-left) 100%,\n\t\tvar(--bounds-left) var(--bounds-top),\n\t\tvar(--bounds-right) var(--bounds-top),\n\t\tvar(--bounds-right) var(--bounds-bottom),\n\t\tvar(--bounds-left) var(--bounds-bottom),\n\t\tvar(--bounds-left) 100%,\n\t\t100% 100%,\n\t\t100% 0%\n\t)',
        },
        overlayVisible: {
            opacity: 1,
            pointerEvents: 'initial',
        },
        btn: {
            extend: theme.button,
            // marginTop: 44,
            height: 44,
            padding: '10px 22px',
            minWidth: 120,
            overflow: 'visible',
            border: `1px solid ${theme.colors[7]}`,
            // '& .white': {
            //   background: theme.colors[7],
            //   color: theme.colors[3],
            // },
        },
        tooltip: {
            // extend: theme.button,
            position: 'absolute',
            top: 'var(--bounds-top)',
            left: 'calc(var(--bounds-right) + 20px)',
            background: theme.colors[3],
            borderRadius: '15px',
            width: 340,
            // height: '160px',
            padding: '20px 25px',
            color: theme.colors[7],
            fontSize: 16,
            zIndex: 1002,
            transition: `opacity 550ms ${theme.easings['power3.out']} 0s`,
            pointerEvents: 'none',
            opacity: 0,
            '& p': {
                textAlign: 'center',
            },
            '&.tooltip2': {
                width: 510,
            },
        },
        tooltipVisible: {
            opacity: 1,
            pointerEvents: 'initial',
        },
        buttonRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },

    }

    /*------------------------------
    SM
    ------------------------------*/
    output[theme.mediaInverse.md] = {
        tooltip: {
            top: 'calc(var(--bounds-bottom) + 20px)',
            left: 'var(--bounds-left)',
            fontSize: 11,
            '&.tooltip2': {
                width: 'calc( 100vw - 24px )',
            },
        },
        btn: {
            fontSize: 11,
            height: 28,
            padding: '5px 11px',
            // minWidth: 60,
        },
    }

    return output
}

export default style
