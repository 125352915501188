import { useState, useEffect, useRef } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import classNames from 'classnames'
import { Formik } from 'formik'
import { createYupSchema, createInitialValues } from '@/utils/form'
import DelayLink from '@/components/DelayLink'
import TextField from '@/components/Form/Fields/TextField'
import TextareaField from '@/components/Form/Fields/TextareaField'
import SelectField from '@/components/Form/Fields/SelectField'
import FileField from '@/components/Form/Fields/FileField'
import CheckboxField from '@/components/Form/Fields/CheckboxField'

import style from './style'

const Form = ({
  className,
  onSuccessSend,
  classes,
  fields,
  sendContactForm,
  submitLabel,
  hiddenValues,
  forgotPassword,
  forgotPasswordLabel,
  inverseColor,
  initialValues: initialValuesFromProps,
  disabled,
  emailRequired
}) => {
  const [state, setState] = useState(null || '')
  const [status, setStatus] = useState(0)
  const formSchema = useRef({})
  const initialValues = useRef({})
  const [isMultipart, setMultipart] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings } = useSelector((states) => ({
    strings: states.options.strings,
  }), shallowEqual)

  useEffect(() => {
    if (Object.keys(strings).length > 0) {
      formSchema.current = createYupSchema(
        fields,
        {
          generic: strings['form.errors.generic'],
          email: strings['form.errors.email'],
          number: strings['form.errors.number'],
          privacy: strings['form.errors.privacy'],
          password: strings['form.errors.password'],
        },
      )
      initialValues.current = { ...createInitialValues(fields), ...initialValuesFromProps }

      // Check if is Multipart
      setMultipart(fields.some((field) => field.type === 'file'))
    }
  }, [strings])

  useEffect(() => {
    initialValues.current = { ...createInitialValues(fields), ...initialValuesFromProps }
  }, [initialValuesFromProps])

  const translateDataMessage = (message) => {
    if (message === 'User does not exist') return strings['form.response.user.notexist']
    if (message === 'Password mismatch') return strings['form.response.password.mismatch']
    if (message === 'Data did saved') return strings['form.response.datasaved']
    if (message === 'The given data was invalid.') return strings['form.response.emailtaken']
    if (message === 'Email not verified') return strings['form.response.emailnotverified']
    return message
  }

  return fields.length > 0 && (
    <>
      <Formik
        initialValues={initialValues.current}
        validationSchema={formSchema.current}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = { ...values, ...hiddenValues }
          const result = sendContactForm(data, isMultipart)

          result
            .then((response) => {
              // TO FIX
              if (response?.status === 200) onSuccessSend()
              setState(translateDataMessage(response?.data.message))
              setStatus(response?.status)
              setSubmitting(false)
            })
            .then(() => {
              setTimeout(() => {
                resetForm()
                setState('')
                setStatus(0)
              }, 2000)
            })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount, setFieldValue }) => {
          return (
            <form
              className={classNames({
                [classes.root]: true,
                [classes.rootInverse]: inverseColor,
                [className]: className,
              })}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {fields.map((field, i) => {
                if (field.type === 'text' || field.type === 'tel' || field.type === 'email' || field.type === 'url' || field.type === 'number' || field.type === 'date' || field.type === 'password') {
                  return (
                    <TextField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      disabled={disabled}
                    />
                  )
                }
                if (field.type === 'textarea') {
                  return (
                    <TextareaField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.placeholder} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                    />
                  )
                }
                if (field.type === 'select') {
                  return (
                    <SelectField
                      key={i.toString()}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      options={field.options}
                      includeBlank={field.include_blank}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'file') {
                  return (
                    <FileField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'checkbox') {
                  return (
                    <CheckboxField
                      key={i.toString()}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                return null
              })}
              {forgotPassword && (
                <div className={classes.forgotPassword}>
                  <DelayLink to="/forgot-password">{forgotPasswordLabel}</DelayLink>
                </div>
              )}

              { emailRequired ?
                <button
                  className={classNames({
                    [classes.btnEmail]: true,
                    disabled: !values['email'],
                  })}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <span>{submitLabel}</span>
                </button>
              :
                <button
                  className={classes.btn}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <span>{submitLabel}</span>
                </button>
              }
            </form>
          )
        }}
      </Formik>
      <p
        className={classNames({
          [classes.result]: true,
          [classes.visible]: state !== '',
          [classes.errorStatus]: status === 422,
          [classes.successStatus]: status === 200,
        })}
      >
        {state}
      </p>
    </>
  )
}

Form.defaultProps = {
  submitLabel: 'submit',
  onSuccessSend: () => {},
  hiddenValues: {},
  inverseColor: false,
  initialValues: {},
  disabled: false,
}

export default injectSheet(style)(Form)
