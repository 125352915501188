import content from '@/reducers/content'
import emotions from '@/reducers/emotions'
import events from '@/reducers/events'
import experience from '@/reducers/experience'
import fakerouter from '@/reducers/fakerouter'
import form from '@/reducers/form'
import layer from '@/reducers/layer'
import locale from '@/reducers/locale'
import map from '@/reducers/map'
import nav from '@/reducers/nav'
import options from '@/reducers/options'
import questions from '@/reducers/questions'
import sports from '@/reducers/sports'
import steps from '@/reducers/steps'
import user from '@/reducers/user'
import upliftProgram from '@/reducers/upliftProgram'

export default {
  content,
  emotions,
  events,
  experience,
  fakerouter,
  form,
  layer,
  locale,
  map,
  nav,
  options,
  questions,
  sports,
  steps,
  user,
  upliftProgram,
}
