import axios from 'axios'
import { REQUEST_NAV, RECEIVE_NAV, REQUEST_NAV_ITEMS } from '@/actions/types'
import { API, CONTENTFUL_TOKEN, CONTENTFUL_SPACE } from '@/constants'

const requestNavItems = () => ({ type: REQUEST_NAV_ITEMS })

const fetchNavItems = () => async (dispatch, getState) => {
  dispatch(requestNavItems())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'nav',
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const nav = response.data.items
    .map((it) => ({ ...it.fields, sysId: it.sys.id })).filter(it=>it.link!=='/portal')
  return nav
}

const requestNav = () => ({ type: REQUEST_NAV })

const receiveNav = (data) => ({
  type: RECEIVE_NAV,
  payload: data,
})

const fetchMenu = () => async (dispatch, getState) => {
  dispatch(requestNav())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'menu',
      locale: getState().locale.currentLanguage,
    },
  }
  const navItems = await dispatch(fetchNavItems())
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const navs = response.data.items
    .map((it) => it.fields)
    .reduce((obj, i) => Object.assign(obj, { [i.slug]: i.item.map((nav) => navItems.find((navi) => navi.sysId === nav.sys.id)) }), {})
  dispatch(receiveNav(navs))
}

export {
  fetchMenu,
  fetchNavItems,
}
