import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import CountUp from 'react-countup'
import { Context as GeneralContext } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import useStore from '@/base/zustand'
import { convertRichText } from '@/utils/strings'
import style from './style'
import { API_BASE_URL } from '@/constants'
import NewQuestions from '../NewQuestions'
import classNames from 'classnames'

const useStyles = createUseStyles(style)

const Begin = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setAudioActive, setAudioControlsVisible, setFirstTimeAudio, isFirstTimeAudio } = useContext(AudioContext)
  const { setFirstTimeAnimation, setScales, setActive } = useStore()
  const [isDataFetched, setDataFetched] = useState(false)
  const [introductionScore, setIntroductionScore] = useState(0)
  const [introductionVariation, setIntroductionVariation] = useState(0)
  const $root = useRef()
  const slug = 'landing-page'
  /*------------------------------
  Redux Store
  ------------------------------*/
  const { lastNotCompletedExpUUID,uuid,experience,phase,isPhaseFirstExpDone,page, fakeLocation, dailyEvents } = useSelector((state) => ({
    content:state.content,
    page: state.content.['landing-page'] || {},
    fakeLocation: state.fakerouter.location,
    dailyEvents: state.events.dailyEvents,
    phase:state.experience.phase,
    isPhaseFirstExpDone:state.experience.isPhaseFirstExpDone,
    experience:state.experience,
    uuid:state.experience.uuid,
    lastNotCompletedExpUUID:state.experience.lastNotCompletedExpUUID
  }))

  const classes = useStyles({ headerHeight })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  const changeLocation = useCallback((slug) => dispatch(routerActions.changeLocation(slug)), [dispatch])
  const setStartingExperience = useCallback((bool) => dispatch(experienceActions.setStartingExperience(bool)), [dispatch])
  const setEndedExperience = useCallback((bool) => dispatch(experienceActions.setEndedExperience(bool)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'mindraceLandingPage',phase)), [dispatch,phase])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const setStep = useCallback((num) => dispatch(experienceActions.setStep(num)), [dispatch])
  const fetchExperience = useCallback((uuid) => dispatch(experienceActions.fetchExperience(uuid)), [dispatch])
  const location = useLocation()
  const history = useHistory()

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent(slug)
  }, [])

  useEffect(() => {
    localStorage.removeItem('expState')
    resetExperience()

    if(phase===3&&isPhaseFirstExpDone===false){
      fetchExperience(uuid);
      setStep(2);
    }

    if(phase!==2 || !(phase===3&&isPhaseFirstExpDone==false)){
      const q = queryString.parse(location.search)
      const uuid = q.complete ? q.complete : lastNotCompletedExpUUID
      manageQS(uuid)
    }
  }, [phase,isPhaseFirstExpDone,lastNotCompletedExpUUID])

  // Fetch Introduction score and percentage
  useEffect(() => {
    fetch(`${API_BASE_URL}/map/mind?level=world`).then((response) => response.json()).then((response) => {
      setIntroductionVariation(parseInt(response.data.variation))
      setIntroductionScore(parseInt(response.data.score * 100))
    }).catch((error) => {
      setIntroductionVariation(18)
      setIntroductionScore(73)
    })
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  useEffect(() => {
  }, [load])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Manage Query String
  ------------------------------*/
  const manageQS = (uuid) => {
    if (uuid) { // [http://URL?complete=175d27ae-aa6e-44f8-a9ca-e3b35c5d615d]
      // changeLocation('blank')
      fetchExperience(uuid)
        .then((res) => {
          // Move to "confirm-timer" only if experience is partial
          if (res.status === 200 && res.data.completed === 0) {
            changeLocation('confirm-timer')
          } else {
            changeLocation('begin')
          }
        })
    }
  }


  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  const handleClickNext = () => {
    // RESET
    setFirstTimeAnimation(true)
    setScales([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setActive(-1)
    //

    setStartingExperience(false)
    setEndedExperience(false)
    setAudioControlsVisible(true)
    if (isFirstTimeAudio) {
      setFirstTimeAudio(false)
      setAudioActive(true)
    }
    moveForward()
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return (
      <div className={classes.hero}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <div className={classes.wrapper}>
            <div className={classes.wrapperInside}>
              <RevealText
                tag="h1"
                type="lines"
                value={convertRichText(page.heading)}
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.beginTitle}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertRichText(page.bodyCopy)}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={1.3}
                className={classes.beginText}
              />
              <RevealFadeIn
                delay={2}
                visible={pageAnimationReady}
              >
                <div className={classes.introButtonWrapper}>
                  <button
                    className={classNames({
                      [classes.btn]: true,
                      ['disabled']: phase===2&&isPhaseFirstExpDone===true,
                    })}
                    onClick={handleClickNext}
                    disabled={phase===2&&isPhaseFirstExpDone===true}
                  >
                    <span>{page.button}</span>
                  </button>
                  <div className={classes.buttonSeparator} />
                  <div className={classes.firstTimeText}>
                    <RevealText
                      tag="div"
                      type="lines"
                      value={convertRichText(page.subCopy)}
                      visible={pageAnimationReady}
                      lineHeight={1.2}
                      delay={0}
                      className={classes.findOutButton}
                    />                 
                  </div>
                </div>
              </RevealFadeIn>
            </div>
            <RevealFadeIn
                delay={0}
                visible={pageAnimationReady}
                position={'static'}
              >
            <div className={classes.introRight}>
              <h1>WORLD UPLIFT</h1>
              <h1 className={classes.percentage}>
                +
                {pageAnimationReady ? <CountUp start={0} end={introductionVariation} duration={2} /> : '0'}
                %
              </h1>
              <img src={page?.images?.find((img) => img.id === page?.background?.sys.id)?.file?.url} style={{ position: 'absolute', right: 0, bottom: 0, zIndex: -1 }} />
              <div className={classes.score}>
                <h1>SCORE</h1>
                <h1>
                  {pageAnimationReady ? <CountUp start={0} end={introductionScore} duration={2} /> : '0'}
                  /100
                </h1>
              </div>
            </div>
            </RevealFadeIn>
          </div>
        </div>
        {/* <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.disclaimer)}
          visible={pageAnimationReady}
          lineHeight={1.2}
          delay={2.2}
          className={classes.beginDisclaimer}
        /> */}
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderHero()}
          </div>
        </>
      )
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageBegin ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Begin
