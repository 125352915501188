import injectSheet from 'react-jss'
import classNames from 'classnames'
import style from './style'

const Estimate = ({
  classes,
  className,
  color,
  trait,
  value,
  variation,
}) => {
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
      style={{ '--color': color }}
    >
      <span className={classes.label}>
        <span>{trait}</span>
      </span>
      <span className={classes.value}>
        {variation > 0
          ? `+${parseInt(variation, 10).toFixed(0)}`
          : `${parseInt(variation, 10).toFixed(0)}`}
        %
        {' '}
        {value && (
          <span className={classes.diff}>
            {`(${parseInt(value * 100, 10)}/100)`}
          </span>
        )}
      </span>
      <span className={classes.line}>
        <span
          className={classes.lineValue}
          style={{ width: `${value * 100}%` }}
        />
      </span>
    </div>
  )
}

export default injectSheet(style)(Estimate)
