import gsap from 'gsap'
import { useRef, useEffect } from 'react'
import { TextureLoader } from 'three'
import { useLoader } from 'react-three-fiber'

const PlaneShadow = () => {
  const texture = useLoader(TextureLoader, '/frontend/static/images/results/shadow.png')
  const $ref = useRef()

  useEffect(() => {
    gsap.fromTo($ref.current.scale, {
      x: 0.5,
      y: 0.5,
      z: 0.5,
    }, {
      x: 1,
      y: 1,
      z: 1,
      duration: 10,
      delay: 2,
      ease: 'power3.inOut',
    })
    gsap.from($ref.current.material, {
      opacity: 0,
      duration: 10,
      delay: 2,
      ease: 'power3.inOut',
    })
  }, [])

  return (
    <mesh position-y={-2.5} rotation-x={Math.PI * -0.5} ref={$ref}>
      <planeBufferGeometry args={[4, 3]} />
      <meshBasicMaterial
        transparent={true}
        opacity={0.18}
        map={texture}
        depthWrite={false}
        depthTest={false}
        color="black"
      />
    </mesh>
  )
}
export default PlaneShadow
