import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as contentActions from '@/actions/content'
import * as experienceActions from '@/actions/experience'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const CountdownQuestions = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, fakeLocation, strings, isLoggedIn, profile } = useSelector((state) => ({
    page: state.content['countdown-questions'] || {}, 
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    profile: state.user.profile,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveNext = useCallback((name) => dispatch(routerActions.changeLocation(name)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug)), [dispatch])
  const setExperienceGender = useCallback((number) => dispatch(experienceActions.setGender(number)), [dispatch])
  const setExperienceAge = useCallback((number) => dispatch(experienceActions.setAge(number)), [dispatch])
  
  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => { 
    if (Object.keys(page).length === 0) fetchContent(fakeLocation)
  }, [])

  //
  let questionsBackgroundImage = page?.images?.find((img) => img.id === page?.fullBackground?.sys?.id)?.file?.url;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      questionsBackgroundImage = page?.images?.find((img) => img.id === page?.mobileBackground?.sys?.id)?.file?.url;
    }
  }
  resizeBg();


  window.addEventListener('resize', () => {
    resizeBg();
  });

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    if (isLoggedIn && profile.gender !== null && profile.age !== null){
      setExperienceGender(profile.gender)
      setExperienceAge(profile.age)
      moveNext('questions-1')
    } else {
      moveNext('choose-age-gender')
    }
  }

  /*------------------------------
  Render Countdown
  ------------------------------*/
  const renderCountdown = () => {
    return (
      <div className={classes.page} style={{ background: `url(${questionsBackgroundImage}) center center no-repeat` }}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <RevealText
              tag="h1"
              type="lines"
              value={convertRichText(page.title)}
              visible={pageAnimationReady}
              lineHeight={1.2}
              delay={0}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.text)}
              visible={pageAnimationReady}
              lineHeight={1.333}
              delay={1.3}
              className={classes.text}
            />
            
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.disclaimer)}
              visible={pageAnimationReady}
              lineHeight={1.333}
              delay={2.3}
              className={classes.hint}
            />

            <RevealFadeIn
              delay={1.5}
              visible={pageAnimationReady}
            >
              <button
                onClick={handleClickNext}
                className={classes.btn}
              >
                <span>{page.nextLabel || strings['general.continue']}</span>
              </button>
            </RevealFadeIn>
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          {renderCountdown()}
        </>
      )
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageCountdownQuestions ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default CountdownQuestions
