import { useEffect, useRef, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import Logo from '@/components/Logo'
// import LanguageSwitcher from '@/components/LanguageSwitcher'
import { Context as LoadingContext } from '@/context/loading'
import * as localeActions from '@/actions/locale'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const Loader = () => {
  const classes = useStyles()
  const [loaderReady, setLoaderReady] = useState(false)
  const [isLangSelectorVisible, setLangSelectorVisible] = useState(false)
  const { siteLoaded, setLoaderExited, isLanguageExist } = useContext(LoadingContext)
  const $root = useRef()
  const $logo = useRef()
  const $spinner = useRef()
  const delay = useRef(2)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLanguage = useCallback((code) => dispatch(localeActions.setLanguage(code)), [dispatch])

  /*------------------------------
  On Complete Loader End
  ------------------------------*/
  const onCompleteLoaderEnd = () => {
    setLoaderExited(true)
  }

  /*------------------------------
  On Complete Hide Spinner
  ------------------------------*/
  const onCompleteHideSpinner = () => {
    setLangSelectorVisible(true)
  }

  /*------------------------------
  Loader End
  ------------------------------*/
  const loaderEnd = () => {
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd, delay: delay.current })
    timeline
      .to($root.current, ({
        autoAlpha: 0,
      }))
  }

  /*------------------------------
  Hide Logo & spinner
  ------------------------------*/
  const hideSpinner = () => {
    const timeline = gsap.timeline({ onComplete: onCompleteHideSpinner, delay: 2 })
    timeline
      .to([$logo.current, $spinner.current], ({
        autoAlpha: 0,
      }), 0.5)
  }

  /*------------------------------
  Manage hide spinner & loaderEnd
  ------------------------------*/
  useEffect(() => {
    if (siteLoaded) hideSpinner()
  }, [siteLoaded])

  useEffect(() => {
    if (loaderReady) loaderEnd()
  }, [loaderReady])

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  useEffect(()=> {
    delay.current = 0.2
    setLanguage('en-US')
  },[])

  /*------------------------------
  Check if language exist
  ------------------------------*/
  useEffect(() => {
    if (isLanguageExist) setLoaderReady(true)
  }, [isLanguageExist])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: loaderReady,
      })}
      ref={$root}
    >
      <header
        className={classNames({
          [classes.header]: true,
          [classes.headerVisible]: isLangSelectorVisible,
        })}
      >
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.logo}>
              <Logo />
            </div>
          </div>
        </div>
      </header>
      <div className={classes.logoContainer}>
        <Logo
          className={classes.logo}
          ref={$logo}
        />
      </div>
      <div className={classes.spinnerContainer}>
        <svg
          ref={$spinner}
          className={classes.spinner}
          viewBox="0 0 50 50"
          width="50"
          height="50"
        >
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        </svg>
      </div>
      <div
        className={classNames({
          [classes.langContainer]: true,
          [classes.hide]: isLanguageExist,
        })}
      >
      </div>
    </div>
  )
}

export default Loader
