import gsap from 'gsap'
import { Color, TextureLoader } from 'three'
import { useMemo, useRef, useEffect } from 'react'
import { useLoader, useFrame, useThree } from 'react-three-fiber'
import useStore from '@/base/zustand'
import { fragmentShader, vertexShader } from './shaders/sphereShaders'

export const Sphere = ({ settings }) => {
  const { camera } = useThree()
  const { beforeAfterFlag, setScales, scales, active, rotations } = useStore()
  const material = useRef()
  const groupRotation = useRef()
  const groupDrag = useRef()
  const sphere = useRef()
  const wireframe = useRef()
  const texture = useLoader(TextureLoader, '/frontend/static/images/results/brushes-map.jpg')

  /*------------------------------
  Uniforms
  ------------------------------*/
  const uniforms = settings.map((s) => {
    const { pos, uv, uvMap, color, scale, prevScale, postScale } = s
    return ({
      pos,
      uv,
      uvMap,
      color: new Color(color),
      scale,
      prevScale,
      postScale,
    })
  })

  /*------------------------------
  Shader
  ------------------------------*/
  const shaderArgs = useMemo(() => ({
    uniforms: {
      uAlpha: { value: 1 },
      uNoise: { value: 0 },
      uTime: { value: 0 },
      data: { value: uniforms },
      uTexture: { value: texture },
    },
    transparent: true,
    vertexShader,
    fragmentShader,
  }), [])
  const shaderArgsWireframe = useMemo(() => ({
    uniforms: {
      uAlpha: { value: 0.3 },
      uTime: { value: 0 },
      uNoise: { value: 1 },
      data: { value: uniforms },
      uTexture: { value: texture },
    },
    transparent: true,
    vertexShader,
    fragmentShader,
  }), [])

  /*------------------------------
  Scale Spikes
  ------------------------------*/
  useEffect(() => {
    const newScales = uniforms.map((u) => (
      // beforeAfterFlag ? u.prevScale * 2 : u.postScale * 2
      beforeAfterFlag ? u.prevScale * 0 : u.postScale * 2
    ))

    // Rotazione automatica
    gsap.killTweensOf(groupRotation.current.rotation)
    gsap.set(groupDrag.current.rotation, {
      y: rotations.y,
      x: rotations.x,
    })

    setScales(newScales)
  }, [beforeAfterFlag])

  /*------------------------------
  Use Frame
  ------------------------------*/
  useFrame(({ clock }) => {
    if (material.current) {
      material.current.uniforms.data.value.forEach((u, ind) => {
      // Se siamo nello zoom, sposto tutti i colori all'index
      // E metto tutti gli spikes a 0, tranne all'attivo
        if (active !== ind && active !== -1) {
          u.color = new Color(settings[active].color)
        } else {
          u.color = new Color(settings[ind].color)
        }
      })

      // Time wireframe
      material.current.uniforms.uTime.value = clock.getElapsedTime()
    }
  })

  /*------------------------------
  Gsap Uniforms
  ------------------------------*/
  useEffect(() => {
    if (material.current) {
      material.current.uniforms.data.value.forEach((u, ind) => {
        gsap.set(u, {
          scale: active === -1 || active === ind ? scales[ind] : 0, // <= Se active !== -1
          delay: 0,
        })
      })
    }
  }, [scales, active])

  /*------------------------------
  Camera Zoom
  ------------------------------*/
  useEffect(() => {
    gsap.killTweensOf(camera.position)
    gsap.to(camera.position, {
      z: active !== -1 ? 5 : 8.5,
      // x: active !== -1 ? 1 : 0.5,
      y: active !== -1 ? 0.25 : 0,
      duration: 1.2,
      ease: 'power3.out',
    })
  }, [active])

  return (
    <group ref={groupRotation}>
      <group ref={groupDrag}>
        <mesh ref={sphere} scale={[0.75, 0.75, 0.75]}>
          <icosahedronBufferGeometry
            attach="geometry"
            args={[1, 20]}
          />
          <shaderMaterial
            ref={material}
            attach="material"
            args={[shaderArgs]}
          />
        </mesh>

        <mesh ref={wireframe} scale={[0.75, 0.75, 0.75]}>
          <icosahedronBufferGeometry
            attach="geometry"
            args={[1.5, 8]}
          />
          <shaderMaterial
            ref={material}
            attach="material"
            args={[shaderArgsWireframe]}
            wireframe={true}
          />
        </mesh>
      </group>
    </group>
  )
}
