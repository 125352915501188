import { useEffect, useCallback,useState } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import LoadingContext from '@/context/loading'
import AudioContext from '@/context/audio'
import Handlers from '@/components/Handlers'
import Routes from '@/router'
import Layout from '@/layout'
import * as localeActions from '@/actions/locale'
import * as eventsActions from '@/actions/events'
import { theme } from './style'

const App = () => {
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchLocales = useCallback(() => dispatch(localeActions.fetchLocales()), [dispatch])
  const fetchExistentLanguage = useCallback(() => dispatch(localeActions.fetchExistentLanguage()), [dispatch])
  const fetchExistentCountryCode = useCallback(() => dispatch(eventsActions.fetchExistentCountryCode()), [dispatch])

  /*------------------------------
  Fetch All Languages on Start
  ------------------------------*/
  useEffect(() => {
    console.warn('Version 3-12-21.1')
    fetchExistentLanguage()
    fetchExistentCountryCode()
    fetchLocales()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <AudioContext>
            <LoadingContext>
              <ScrollbarContext>
               <Layout>
                  <Routes />
                </Layout>
              </ScrollbarContext>
            </LoadingContext>
          </AudioContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
