import constants from '@/constants'

const pushToDataLayer = (data) => {
  (global.dataLayer || []).push({ ...data })
}

export default () => {
  const {
    SEE_RESULTS,
    PAGE_VIEW,
  } = constants.EVENTS.DATALAYER
  const { EMITTER } = constants

  EMITTER.on(SEE_RESULTS, () => {
    pushToDataLayer({
      event: 'see-results',
    })
  })

  EMITTER.on(PAGE_VIEW, (page) => {
    pushToDataLayer({
      event: 'pageview',
      ...(page && {
        page,
      }),
    })
  })
}
