const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[3],
      background: theme.colors[3],
      paddingTop: (props) => props.headerHeight,
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    btn: {
      extend: theme.button,
      '&:disabled': {
        opacity: .5,
      }
    },
    wrapper: {
      extend: theme.wrapper,
    },

    // Profile
    profile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 50,
    },
    profileAvatar: {
      width: 88,
      height: 88,
      lineHeight: '88px',
      background: theme.colors[10],
      color: theme.colors[1],
      border: `2px solid ${theme.colors[1]}`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 32,
      fontFamily: theme.fonts[1],
      userSelect: 'none',
    },
    username: {
      fontFamily: theme.fonts[1],
      fontSize: 43,
    },
    profileInfo: {
      marginTop: 30,
      textAlign: 'center',
      color: theme.colors[1],
    },
    logout: {
      color: theme.colors[1],
      position: 'relative',
      fontSize: 18,
      cursor: 'pointer',
      '&::before': {
        content: '""',
        bottom: 0,
        left: 0,
        display: 'block',
        height: 1,
        position: 'absolute',
        background: 'currentColor',
        transition: 'width 0.4s ease 0s',
        width: '100%',
      },
      '@media (hover: hover)': {
        '&:hover': {
          '&::before': {
            width: 0,
          },
        },
      },
    },
    noUplift: {
      width: '70%',
      margin: 0,
    },

    // Dashboard
    dashboard: {
      paddingTop: 90,
      paddingBottom: 80,
      display: 'flex',
      flexDirection: 'column',
    },
    dashboardNav: {
      display: 'flex',
      '& li': {
        position: 'relative',
        zIndex: 1,
        background: theme.colors[1],
        fontFamily: theme.fonts[1],
        fontSize: 14,
        padding: '20px 80px',
        borderTopLeftRadius: 22,
        borderTopRightRadius: 22,
        cursor: 'pointer',
        '&:not(:first-child)': {
          marginLeft: 4,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgb(71, 92, 197)',
          width: '100%',
          height: 4,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          display: 'block',
          bottom: -10,
          left: 0,
          width: '100%',
          height: 20,
          background: theme.colors[1],
        },
        '&.currentPage:before': {
          backgroundColor: '#fff!important',
        }
      },
    },
    dashboardContent: {
      padding: '80px 30px 20px 30px',
      background: theme.colors[1],
      borderRadius: 22,
      borderTopLeftRadius: 0,
    },
    dashboardTitle: {
      marginBottom: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 20,
      '& h2': {
        fontFamily: theme.fonts[1],
        fontSize: 24,
        display: 'inline-block',
      },
    },
    ultraUpliftBackground: {
      left: 0,
      top: 0,
      position: 'absolute',
      width: '85%',
      height: '85%',
      borderRadius: 12,
      background: '#fff',
      border: '2px solid #ddd',
      borderColor: 'rgb(174, 174, 208)',
      
      '&:before': {
        content: "''",
        width: 80,
        height: 27,
        top: 15,
        left: 15,
        color: 'rgb(0, 30, 98)',
        border: '1px solid rgb(231, 231, 231)',
        padding: [6, 20],
        zIndex: 0,
        position: 'absolute',
        fontSize: 12,
        background: 'rgb(231, 231, 231)',
        borderRadius: 22,
        pointerEvents: 'none',
      },
    },
    quantity: {
      width: 25,
      height: 25,
      background: theme.colors[3],
      color: theme.colors[1],
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 20,
      '& span': {
        lineHeight: 1,
        fontSize: 14,
      },
    },
    grid: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -15,

      '& > $item': {
        marginBottom: 40,
      },

      '& > $item > div': {
        position: 'relative',
      }
    },
    cardWrapper: {
      width: '100%',
      maxWidth: 644,
      height: 488,
      position: 'relative',
      marginBottom: 40,
    },
    itemInner: {},
    verticalItemWrapper: {
      display: 'flex',
      alignItems: 'center',

      '& $item': {
        '& $itemInner': {
          width: '85%',
          height: '85%',
          top: '15%',
          left: '15%',
          position: 'absolute',

          '& $itemImg': {
            borderColor: '#AEAED0',
            borderWidth: 2,
          },
        },
      },
    },
    upliftDetails: {
      paddingLeft: 50,

      '& h3': {
        margin: 0,
        fontSize: 36,
        fontWeight: 'bold',
      },
      '& h5': {
        margin: 0,
        fontSize: 28,
        fontWeight: 'lighter',
        position: 'relative',
        marginBottom: 50,

        '&:after': {
          content: "''",
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: 92,
          height: 3,
          background: '#485BC7',
        }
      },
      '& table tr td': {
        padding: [5, 0],
      },
      '& table tr td:first-child': {
        paddingRight: 40,
      },
      '& table tr td .image': {
        width: 70,
        height: 70,
        marginRight: 20,
        display: 'inline-block',
        padding: 10,
      },
      '& table tr td .image img': {
        width: '100%',
        height: '100%',
      },
      '& p': {
        fontSize: 20,
        marginTop: 40,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginLeft: 63,
      },
      '& a': {
        marginLeft: 63,
      },
    },
    item: {
      paddingLeft: 15,
      flexBasis: '33.333%',
    },
    reload: {
      position: 'relative',
      zIndex: 1,
      borderRadius: 12,
      width: '100%',
      overflow: 'hidden',
      cursor: 'pointer',
      border: `1px solid ${theme.colors[3]}`,
      '&::before': {
        content: '""',
        width: '100%',
        paddingBottom: '75%',
        display: 'block',
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& $roundIco': {
           // transform: 'rotate(360deg)',
          },
        },
      },
    },
    reloadBtn: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& span': {
        marginTop: 15,
        fontSize: 18,
        fontFamily: theme.fonts[1],
        color: theme.colors[3],
      },
    },
    roundIco: {
      background: theme.colors[3],
      border: 'none',
      width: 128,
      height: 128,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'transform 0.4s ease-out',
      '& svg': {
        fill: theme.colors[1],
      },
    },
    itemImg: {
      position: 'relative',
      zIndex: 1,
      borderRadius: 12,
      width: '100%',
      overflow: 'hidden',
      cursor: 'pointer',
      background: theme.colors[1],
      border: `1px solid ${theme.colors[3]}`,
      '&::before': {
        content: '""',
        width: '100%',
        paddingBottom: '75%',
        display: 'block',
      },
      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        backfaceVisibility: 'hidden',
        fontFamily: "'object-fit: cover;'",
        transition: 'transform 0.3s ease-out',
        transform: 'translate(-50%, -50%) scale(1.03)',
      },

      '@media (hover: hover)': {
        '&:hover': {
          '& img': {
            transform: 'translate(-50%, -50%) scale(1.05)',
          },
        },
      },
    },

    ultraUpliftBadge: {
      position: 'absolute',
      left: 25,
      bottom: 18,
      width: 91,
      height: 91,
      zIndex: 1,
    },
    createLabel: {
      position: 'absolute',
      zIndex: 1,
      top: 120,
      color: theme.colors[4],
      fontSize: 10,
      padding: '6px 20px',
      width: 150      
    },
    sportLabel: {
      position: 'absolute',
      zIndex: 1,
      top: 15,
      left: 15,
      borderRadius: 22,
      background: '#E7E7E7',
      color: theme.colors[4],
      border: `1px solid #E7E7E7`,
      fontSize: 12,
      padding: '6px 20px',
      pointerEvents: 'none',
    },
    shareDiv:{
      position: 'relative',
    },
    shareBtn: {
      extend: theme.button,
      position: 'absolute',
      height: 50,
      width: '120px!important',
      minWidth: '120px!important',
      padding: '6px 20px',
      zIndex: 2,
      extend: theme.button,
      right: 15,
      bottom: 18,
      fontWeight: 'bold',
    },
    sortDiv: {
      alignItems: 'right',
    },
    sortSelect: {
      marginBottom: 0,
      width: 300,      
    },
    itemInfo: {
      marginTop: 10,
      color: theme.colors[4],
      display: 'flex',
      justifyContent: 'space-between',
    },
    stateOfMind: {
      fontSize: 16,
      color: '#485BC7',
      fontWeight: 'bold',
      marginTop: 3,
    },
    itemTitle: {
      fontFamily: theme.fonts[1],
      color: theme.colors[4],
      fontSize: 14,
      marginBottom: 5,
      textAlign: 'left',
      cursor: 'pointer',
    },
    itemDate: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#485BC7',
    },
    itemTime: {
      fontSize: 16,
      color: '#485BC7',
    },
    itemExertion:{
      fontSize: 16,
      color: '#485BC7',
    },

    // Choice
    choice: {
      paddingBottom: 80,
      color: theme.colors[1],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h3': {
        fontFamily: theme.fonts[1],
        color: theme.colors[1],
        fontSize: 14,
        marginBottom: 20,
      },
    },
    form: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: 'auto',
      textAlign: 'center',
    },
  }

  output['@media all and (max-width: 1250px)'] = {
    cardWrapper: {
      maxWidth: 440,
      height: 330,
    }
  }

  output['@media all and (max-width: 1024px)'] = {
    item: {
      flexBasis: '50%',
    },
    cardWrapper: {
      maxWidth: 240,
      height: 165,

      '& $ultraUpliftBadge': {
        width: 40,
        bottom: -30,
      },
      '& $itemImg': {
        maxHeight: '100%',
      }
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    dashboardNav: {
      '& li': {
        padding: [15, 40],
      },
    },
    shareBtn: {
      padding: [12, 20],
    },
    ultraUpliftBadge: {
      left: 9,
      width: 60,
      height: 60,
    },
    itemDate: {
      fontSize: 14,
    },
    itemTime: {
      fontSize: 14,
    },
    itemExertion:{
      fontSize: 14,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    roundIco: {
      transform: 'scale(.7)',
    },
    item: {
      flexBasis: '100%',
    },
    buttonsWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& $btn': {
        margin: 0,
        marginBottom: 10,
        width: '100%',
      },
    },
    ultraUpliftBackground: {
      display: 'none',
    },

    verticalItemWrapper: {
      flexDirection: 'column',
      border: `2px solid rgb(174, 174, 208)`,
      marginBottom:20,
      padding:10,
      borderRadius:8,

      '& $item': {
        '& $itemInner': {
          width: '100%',
          height: '100%',
          top: '5%',
          left: '0%',
        }
      }
    },
    cardWrapper: {
      '& $ultraUpliftBadge': {
        bottom: -10,
      },
    },
    upliftDetails: {
      padding: 0,
      marginTop: 20,
      
      '& > h3': {
        fontSize: 16,
      },
      '& > h5': {
        fontSize: 14,
        marginTop: 10,
        marginBottom: 10,

        '&:after': {
          height: 2,
        },
      },
      '& table tr td:first-child': {
        paddingRight: 20,
        fontSize: 14,
      },
      '& table tr td .image': {
        width: 30,
        height: 30,
        marginRight: 3,
        marginBottom: 5,
        padding: 2,
      },

      '& > p': {
        fontSize: 12,
        marginTop: 10,
      },

      '& $noUplift': {
        width: '100%',
        margin: '0!important',
        fontSize: 14,
      },
    },
    shareBtn: {
      minWidth: 90,
      width: 90,
      height: 37,
    },
  }

  return output
}

export default style
