const style = (theme) => {
  const output = {
    root: {
      '--color': theme.colors[3],
      display: 'block',
      textAlign: 'left',
      fontSize: 14,
      width: '100%',
      position: 'relative',
      padding: '4px 0',
      letterSpacing: '0.04em',
      textTransform: 'capitalize',
      marginBottom: 8,
      color: 'var(--color)',
      transition: 'color .3s ease-in-out',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        background: 'var(--color)',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        width: 'calc(100% + 70px)',
        height: 'calc(100% + 6px)',
        top: -3,
        left: -35,
        transition: 'all .5s ease-in-out',
        transformOrigin: '0 0',
        transform: 'scaleX(0)',
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '& span:last-child': {},
    },
    value: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      fontFamily: theme.fonts[1],
    },
    line: {
      height: '10px',
      width: 'calc(100% - 100px)',
      marginTop: '4px',
      display: 'block',
    },
    lineValue: {
      '--delay': '0s',
      display: 'block',
      height: '100%',
      background: 'var(--color)',
      borderRadius: '20px',
      transition: 'width 1.2s cubic-bezier(0.54, 0.03, 0, 0.96) var(--delay), color .3s ease-in-out, background-color .3s ease-in-out',
      minWidth: '10px',
    },
    diff: {
      display: 'inline-block',
      fontSize: 9,
      transform: 'translateY(-4px)',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
