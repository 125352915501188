import {
  RECEIVE_MAP,
  SET_MAP_EMOTION,
  SET_MAP_LEVEL,
  SET_MAP_ZONE,
  SET_MAP_ZONE_NAME,
  SET_MAP_SPORT,
  SET_MAP_EVENT,
  SET_MAP_CURRENT_COUNTRY,
  RESET_MAP_MARKERS,
  RECEIVE_MAP_SPORT_LIST,
} from '@/actions/types'

const initialState = {
  mind: {
    world: {},
    country: {},
    region: {},
    city: {},
  },
  sports: {
    world: {},
    country: {},
    region: {},
    city: {},
  },
  events: {
    world: {},
    country: {},
    region: {},
    city: {},
  },
  currentLevel: 'world',
  currentZone: '',
  currentZoneName: '',
  currentEmotion: '',
  currentCountry: '',
  currentSport: '',
  currentEvent: '',
  sportPlayed: [],
  bbox: {
    AD: ['Andorra', [1.417, 42.433, 1.783, 42.65]],
    AE: ['United Arab Emirates', [51.58, 22.5, 56.4, 26.06]],
    AF: ['Afghanistan', [60.53, 29.32, 75.16, 38.49]],
    AG: ['Antigua and Barbuda', [-62.417, 16.817, -61.65, 17.75]],
    AI: ['Anguilla', [-63.429668, 18.161139, -62.926277, 18.607834]],
    AL: ['Albania', [19.3, 39.62, 21.02, 42.69]],
    AM: ['Armenia', [43.58, 38.74, 46.51, 41.25]],
    AN: ['Netherlands Antilles', [3.30944, 50.750667, 7.230902, 53.565815]],
    AO: ['Angola', [11.64, -17.93, 24.08, -4.44]],
    AQ: ['Antarctica', [-180.0, -90.0, 180.0, -63.27]],
    AR: ['Argentina', [-73.42, -55.25, -53.63, -21.83]],
    AS: ['American Samoa', [-170.874588, -14.552944, -168.17131, -14.159583]],
    AT: ['Austria', [9.48, 46.43, 16.98, 49.04]],
    AU: ['Australia', [113.34, -43.63, 153.57, -10.67]],
    AW: ['Aruba', [-70.983, 12.4, -69.85, 12.617]],
    AX: ['Åland Islands', [19.084003, 59.454159, 21.3454, 60.876682]],
    AZ: ['Azerbaijan', [44.79, 38.27, 50.39, 41.86]],
    BA: ['Bosnia and Herzegovina', [15.75, 42.65, 19.6, 45.23]],
    BB: ['Barbados', [-59.667, 12.967, -59.383, 13.333]],
    BD: ['Bangladesh', [88.08, 20.67, 92.67, 26.45]],
    BE: ['Belgium', [2.51, 49.53, 6.16, 51.48]],
    BF: ['Burkina Faso', [-5.47, 9.61, 2.18, 15.12]],
    BG: ['Bulgaria', [22.38, 41.23, 28.56, 44.23]],
    BH: ['Bahrain', [45, 25, 50.954, 26.566]],
    BI: ['Burundi', [29.02, -4.5, 30.75, -2.35]],
    BJ: ['Benin', [0.77, 6.14, 3.8, 12.24]],
    BM: ['Bermuda', [-64.888474, 32.244835, -64.64753, 32.390778]],
    BN: ['Brunei', [114.2, 4.01, 115.45, 5.45]],
    BO: ['Bolivia', [-69.59, -22.87, -57.5, -9.76]],
    BR: ['Brazil', [-73.99, -33.77, -34.73, 5.24]],
    BS: ['Bahamas', [-78.98, 23.71, -77.0, 27.04]],
    BT: ['Bhutan', [88.81, 26.72, 92.1, 28.3]],
    BV: ['Bouvet Island', [3.278, -54.467, 3.483, -54.386]],
    BW: ['Botswana', [19.9, -26.83, 29.43, -17.66]],
    BY: ['Belarus', [23.2, 51.32, 32.69, 56.17]],
    BZ: ['Belize', [-89.23, 15.89, -88.11, 18.5]],
    CA: ['Canada', [-141.0, 41.68, -52.65, 73.23]],
    CC: ['Cocos (Keeling) Islands', [-12.4055983, -11.6213132, 96.612524, 97.1357343]],
    CD: ['Congo (Kinshasa)', [12.18, -13.26, 31.17, 5.26]],
    CF: ['Central African Republic', [14.46, 2.27, 27.37, 11.14]],
    CG: ['Congo (Brazzaville)', [11.09, -5.04, 18.45, 3.73]],
    CH: ['Switzerland', [6.02, 45.78, 10.44, 47.83]],
    CI: ['Ivory Coast', [-8.6, 4.34, -2.56, 10.52]],
    CK: ['Cook Islands', [96.817, -12.208, 96.925, -11.817]],
    CL: ['Chile', [-75.64, -55.61, -66.96, -17.58]],
    CM: ['Cameroon', [8.49, 1.73, 16.01, 12.86]],
    CN: ['China', [73.68, 18.2, 135.03, 53.46]],
    CO: ['Colombia', [-78.99, -4.3, -66.88, 12.44]],
    CR: ['Costa Rica', [-85.94, 8.23, -82.55, 11.22]],
    CS: ['Serbia and Montenegro', [18.859, 41.844, 22.967, 46.167]],
    CU: ['Cuba', [-84.97, 19.86, -74.18, 23.19]],
    CV: ['Cape Verde', [-25.367, 14.8, -22.65, 17.2]],
    CX: ['Christmas Island', [105.533276992, -10.5704829995, 105.712596992, -10.412356007]],
    CY: ['Cyprus', [32.26, 34.57, 34.0, 35.17]],
    CZ: ['Czech Republic', [12.24, 48.56, 18.85, 51.12]],
    DE: ['Germany', [5.99, 47.3, 15.02, 54.98]],
    DJ: ['Djibouti', [41.66, 10.93, 43.32, 12.7]],
    DK: ['Denmark', [8.09, 54.8, 12.69, 57.73]],
    DM: ['Dominica', [-61.55, 15.167, -61.25, 15.633]],
    DO: ['Dominican Republic', [-71.95, 17.6, -68.32, 19.88]],
    DZ: ['Algeria', [-8.68, 19.06, 12.0, 37.12]],
    EC: ['Ecuador', [-80.97, -4.96, -75.23, 1.38]],
    EE: ['Estonia', [23.34, 57.47, 28.13, 59.61]],
    EG: ['Egypt', [24.7, 22.0, 36.87, 31.59]],
    EH: ['Western Sahara', [-17.108889, 20.770066, -8.666665, 27.668767]],
    ER: ['Eritrea', [36.32, 12.46, 43.08, 18.0]],
    ES: ['Spain', [-9.39, 35.95, 3.04, 43.75]],
    ET: ['Ethiopia', [32.95, 3.42, 47.79, 14.96]],
    FI: ['Finland', [20.65, 59.85, 31.52, 70.16]],
    FJ: ['Fiji', [-180.0, -18.29, 180.0, -16.02]],
    FK: ['Falkland Islands', [-61.2, -52.3, -57.75, -51.1]],
    FM: ['Micronesia', [137.374619, 1.025389, 163.035828, 10.090389]],
    FO: ['Faroe Islands', [-7.8, 61.333, -5.333, 67.283]],
    FR: ['France', [-5.0, 42.5, 9.56, 51.15]],
    GA: ['Gabon', [8.8, -3.98, 14.43, 2.33]],
    GB: ['United Kingdom', [-7.57, 49.96, 1.68, 58.64]],
    GD: ['Grenada', [-61.8, 11.983, -61.25, 12.667]],
    GE: ['Georgia', [39.96, 41.06, 46.64, 43.55]],
    GF: ['French Guiana', [-54.615292, 2.110673, -51.649037, 5.744765]],
    GG: ['Guernsey', [-2.709556, 49.400391, -2.155306, 49.73875]],
    GH: ['Ghana', [-3.24, 4.71, 1.06, 11.1]],
    GI: ['Gibraltar', [-5.433, 36.1, -5.333, 36.183]],
    GL: ['Greenland', [-73.3, 60.04, -12.21, 83.65]],
    GM: ['Gambia', [-16.84, 13.13, -13.84, 13.88]],
    GN: ['Guinea', [-15.13, 7.31, -7.83, 12.59]],
    GP: ['Guadeloupe', [-86, 13, -60.9, 21]],
    GQ: ['Equatorial Guinea', [9.31, 1.01, 11.29, 2.28]],
    GR: ['Greece', [20.15, 34.92, 26.6, 41.83]],
    GS: ['South Georgia and the South Sandwich Islands', [-59.684, -53.3500755, -42.354739, -25.8468303]],
    GT: ['Guatemala', [-92.23, 13.74, -88.23, 17.82]],
    GU: ['Guam', [144.61795, 13.232028, 144.956253, 13.652945]],
    GW: ['Guinea Bissau', [-16.68, 11.04, -13.7, 12.63]],
    GY: ['Guyana', [-61.41, 1.27, -56.54, 8.37]],
    HK: ['Hong Kong', [113.833, 15, 115, 22.6]],
    HM: ['Heard Island and Mcdonald Islands', [72.567, -53.2, 73.85, -52.9]],
    HN: ['Honduras', [-89.35, 12.98, -83.15, 16.01]],
    HR: ['Croatia', [13.66, 42.48, 19.39, 46.5]],
    HT: ['Haiti', [-74.46, 18.03, -71.62, 19.92]],
    HU: ['Hungary', [16.2, 45.76, 22.71, 48.62]],
    ID: ['Indonesia', [95.29, -10.36, 141.03, 5.48]],
    IE: ['Ireland', [-9.98, 51.67, -6.03, 55.13]],
    IL: ['Israel', [34.27, 29.5, 35.84, 33.28]],
    IM: ['Isle of Man', [-5.45, 53.15, -4.083, 54.45]],
    IN: ['India', [68.18, 7.97, 97.4, 35.49]],
    IO: ['British Indian Ocean Territory', [70.25, -7.417, 72.6, -4.917]],
    IQ: ['Iraq', [38.79, 29.1, 48.57, 37.39]],
    IR: ['Iran', [44.11, 25.08, 63.32, 39.71]],
    IS: ['Iceland', [-24.33, 63.5, -13.61, 66.53]],
    IT: ['Italy', [6.75, 36.62, 18.48, 47.12]],
    JE: ['Jersey', [-2.328, 49.1, -1.897, 49.316]],
    JM: ['Jamaica', [-78.34, 17.7, -76.2, 18.52]],
    JO: ['Jordan', [34.92, 29.2, 39.2, 33.38]],
    JP: ['Japan', [129.41, 31.03, 145.54, 45.55]],
    KE: ['Kenya', [33.89, -4.68, 41.86, 5.51]],
    KG: ['Kyrgyzstan', [69.46, 39.28, 80.26, 43.3]],
    KH: ['Cambodia', [102.35, 10.49, 107.61, 14.57]],
    KI: ['Kiribati', [-179.217, -45, 180, 30]],
    KM: ['Comoros', [43.067, -13, 45.317, -11.35]],
    KN: ['Saint Kitts and Nevis', [-62.85, 17.083, -62.517, 17.417]],
    KP: ['North Korea', [124.27, 37.67, 130.78, 42.99]],
    KR: ['South Korea', [126.12, 34.39, 129.47, 38.61]],
    KW: ['Kuwait', [46.57, 28.53, 48.42, 30.06]],
    KY: ['Cayman Islands', [-81.420471, 19.262917, -79.722084, 19.757944]],
    KZ: ['Kazakhstan', [46.47, 40.66, 87.36, 55.39]],
    LA: ['Laos', [100.12, 13.88, 107.56, 22.46]],
    LB: ['Lebanon', [35.13, 33.09, 36.61, 34.64]],
    LC: ['Saint Lucia', [-61.083, 13.5, -60.867, 14.15]],
    LI: ['Liechtenstein', [9.471683, 47.048427, 9.635643, 47.270575]],
    LK: ['Sri Lanka', [79.7, 5.97, 81.79, 9.82]],
    LR: ['Liberia', [-11.44, 4.36, -7.54, 8.54]],
    LS: ['Lesotho', [27.0, -30.65, 29.33, -28.65]],
    LT: ['Lithuania', [21.06, 53.91, 26.59, 56.37]],
    LU: ['Luxembourg', [5.67, 49.44, 6.24, 50.13]],
    LV: ['Latvia', [21.06, 55.62, 28.18, 57.97]],
    LY: ['Libya', [9.32, 19.58, 25.16, 33.14]],
    MA: ['Morocco', [-17.02, 21.42, -1.12, 35.76]],
    MC: ['Monaco', [7.4, 43.717, 7.439, 43.746]],
    MD: ['Moldova', [26.62, 45.49, 30.02, 48.47]],
    ME: ['Montenegro', [18.45, 41.88, 20.34, 43.52]],
    MG: ['Madagascar', [43.25, -25.6, 50.48, -12.04]],
    MH: ['Marshall Islands', [-62.233, 16.667, -62.15, 16.817]],
    MK: ['Macedonia', [20.46, 40.84, 22.95, 42.32]],
    ML: ['Mali', [-12.17, 10.1, 4.27, 24.97]],
    MM: ['Myanmar', [92.3, 9.93, 101.18, 28.34]],
    MN: ['Mongolia', [87.75, 41.6, 119.77, 52.05]],
    MO: ['Macao', [113.528492, 22.109444, 113.591269, 22.217153]],
    MP: ['Northern Mariana Islands', [144.890366, 14.107917, 146.082886, 20.545389]],
    MQ: ['Martinique', [-61.228831, 14.408108, -60.810414, 14.876899]],
    MR: ['Mauritania', [-17.06, 14.62, -4.92, 27.4]],
    MS: ['Montserrat', [-62.230133, 16.67536, -62.140533, 16.819322]],
    MT: ['Malta', [14.184, 35.782, 15, 36.333]],
    MU: ['Mauritius', [56.567, -20.522, 73.567, 10.4]],
    MV: ['Maldives', [61.5, -10.5, 75, 20]],
    MW: ['Malawi', [32.69, -16.8, 35.77, -9.23]],
    MX: ['Mexico', [-117.13, 14.54, -86.81, 32.72]],
    MY: ['Malaysia', [100.09, 0.77, 119.18, 6.93]],
    MZ: ['Mozambique', [30.18, -26.74, 40.78, -10.32]],
    NA: ['Namibia', [11.73, -29.05, 25.08, -16.94]],
    NC: ['New Caledonia', [164.03, -22.4, 167.12, -20.11]],
    NE: ['Niger', [0.3, 11.66, 15.9, 23.47]],
    NF: ['Norfolk Island', [167.95, -29.05, 167.95, -29.033]],
    NG: ['Nigeria', [2.69, 4.24, 14.58, 13.87]],
    NI: ['Nicaragua', [-87.67, 10.73, -83.15, 15.02]],
    NL: ['Netherlands', [3.31, 50.8, 7.09, 53.51]],
    NO: ['Norway', [4.99, 58.08, 31.29, 70.92]],
    NP: ['Nepal', [80.09, 26.4, 88.17, 30.42]],
    NR: ['Nauru', [166.917, -0.55, 166.95, -0.5]],
    NU: ['Niue', [-169.952942, -19.156334, -169.778778, -18.95125]],
    NZ: ['New Zealand', [166.51, -46.64, 178.52, -34.45]],
    OM: ['Oman', [52.0, 16.65, 59.81, 26.4]],
    PA: ['Panama', [-82.97, 7.22, -77.24, 9.61]],
    PE: ['Peru', [-81.41, -18.35, -68.67, -0.06]],
    PF: ['French Polynesia', [104.883, 467, 120.317, 20.95]],
    PG: ['Papua New Guinea', [141.0, -10.65, 156.02, -2.5]],
    PH: ['Philippines', [117.17, 5.58, 126.54, 18.51]],
    PK: ['Pakistan', [60.87, 23.69, 77.84, 37.13]],
    PL: ['Poland', [14.07, 49.03, 24.03, 54.85]],
    PM: ['Saint Pierre and Miquelon', [-56.408611, 46.748417, -56.082359, 47.142502]],
    PN: ['Pitcairn', [-130.753082, -25.077081, -124.778066, -23.924412]],
    PR: ['Puerto Rico', [-67.24, 17.95, -65.59, 18.52]],
    PS: ['West Bank', [34.93, 31.35, 35.55, 32.53]],
    PT: ['Portugal', [-9.53, 36.84, -6.39, 42.28]],
    PW: ['Palau', [131.175, 2.874, 134.716, 8.342]],
    PY: ['Paraguay', [-62.69, -27.55, -54.29, -19.34]],
    QA: ['Qatar', [50.74, 24.56, 51.61, 26.11]],
    RE: ['Reunion', [55.217, -21.383, 57, -20]],
    RO: ['Romania', [20.22, 43.69, 29.63, 48.22]],
    RS: ['Serbia', [18.83, 42.25, 22.99, 46.17]],
    RU: ['Russia', [-180.0, 41.15, 180.0, 81.25]],
    RW: ['Rwanda', [29.02, -2.92, 30.82, -1.13]],
    SA: ['Saudi Arabia', [34.63, 16.35, 55.67, 32.16]],
    SB: ['Solomon Islands', [156.49, -10.83, 162.4, -6.6]],
    SC: ['Seychelles', [20.462, -10.217, 57.083, 42.641]],
    SD: ['Sudan', [21.94, 8.62, 38.41, 22.0]],
    SE: ['Sweden', [11.03, 55.36, 23.9, 69.11]],
    SG: ['Singapore', [101.333, 1.083, 104.412, 4]],
    SH: ['Saint Helena', [-14.424611, -40.371307, -5.632111, -7.890417]],
    SI: ['Slovenia', [13.7, 45.45, 16.56, 46.85]],
    SJ: ['Svalbard and Jan Mayen', [10.484386, 74.347357, 33.640391, 80.770087]],
    SK: ['Slovakia', [16.88, 47.76, 22.56, 49.57]],
    SL: ['Sierra Leone', [-13.25, 6.79, -10.23, 10.05]],
    SM: ['San Marino', [12.417, 43.908, 12.5, 43.983]],
    SN: ['Senegal', [-17.63, 12.33, -11.47, 16.6]],
    SO: ['Somalia', [40.98, -1.68, 51.13, 12.02]],
    SR: ['Suriname', [-58.04, 1.82, -53.96, 6.03]],
    SS: ['South Sudan', [23.89, 3.51, 35.3, 12.25]],
    ST: ['Sao Tome and Principe', [6.467, -17, 7.633, 1.733]],
    SV: ['El Salvador', [-90.1, 13.15, -87.72, 14.42]],
    SY: ['Syria', [35.7, 32.31, 42.35, 37.23]],
    SZ: ['Swaziland', [30.68, -27.29, 32.07, -25.66]],
    TC: ['Turks and Caicos Islands', [-72.481313, 21.290107, -71.128896, 21.959215]],
    TD: ['Chad', [13.54, 7.42, 23.89, 23.41]],
    TF: ['French Southern Territories', [68.72, -49.78, 70.56, -48.63]],
    TG: ['Togo', [-0.05, 5.93, 1.87, 11.02]],
    TH: ['Thailand', [97.38, 5.69, 105.59, 20.42]],
    TJ: ['Tajikistan', [67.44, 36.74, 74.98, 40.96]],
    TK: ['Tokelau', [-172.520762, -9.444776, -171.181066, -8.532366]],
    TL: ['East Timor', [124.97, -9.39, 127.34, -8.27]],
    TM: ['Turkmenistan', [52.5, 35.27, 66.55, 42.75]],
    TN: ['Tunisia', [7.52, 30.31, 11.49, 37.35]],
    TO: ['Tonga', [-176.219635, -22.342083, -173.700439, -15.560416]],
    TR: ['Turkey', [26.04, 35.82, 44.79, 42.14]],
    TT: ['Trinidad and Tobago', [-61.933723, 10.041139, -60.49461, 11.363472]],
    TV: ['Tuvalu', [176.05763, -10.766341, 179.872329, -5.641625]],
    TW: ['Taiwan', [120.11, 21.97, 121.95, 25.3]],
    TZ: ['Tanzania', [29.34, -11.72, 40.32, -0.95]],
    UA: ['Ukraine', [22.09, 44.36, 40.08, 52.34]],
    UG: ['Uganda', [29.58, -1.44, 35.04, 4.25]],
    UM: ['United States Minor Outlying Islands', [-177.389597, -0.388767, 166.652354, 28.215318]],
    US: ['United States', [-125.0, 25.0, -66.96, 49.5]],
    UY: ['Uruguay', [-58.43, -34.95, -53.21, -30.11]],
    UZ: ['Uzbekistan', [55.93, 37.14, 73.06, 45.59]],
    VA: ['Holy See (Vatican City State)', [12.445909, 41.900276, 12.458336, 41.907417]],
    VE: ['Venezuela', [-73.3, 0.72, -59.76, 12.16]],
    VG: ['Virgin Islands British', [-64.852112, 18.305334, -64.271248, 18.750305]],
    VI: ['Virgin Islands U.S.', [-65.099609, 17.672861, -64.563751, 18.415361]],
    VN: ['Vietnam', [102.17, 8.6, 109.34, 23.35]],
    VU: ['Vanuatu', [166.63, -16.6, 167.84, -14.63]],
    WF: ['Wallis and Futuna', [-178.19545, -14.381278, -176.125473, -13.179639]],
    WS: ['Samoa', [-172.815219, -14.074901, -171.390994, -13.437429]],
    YE: ['Yemen', [42.6, 12.59, 53.11, 19.0]],
    YT: ['Mayotte', [45.042491, -12.98919, 45.290863, -12.647231]],
    ZA: ['South Africa', [16.34, -34.82, 32.83, -22.09]],
    ZM: ['Zambia', [21.89, -17.96, 33.49, -8.24]],
    ZW: ['Zimbabwe', [25.26, -22.27, 32.85, -15.51]],
  },
  countryNames: {
    'en-US': {
      AF: 'Afghanistan',
      AX: 'Åland Islands',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua & Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaijan',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia & Herzegovina',
      BW: 'Botswana',
      BV: 'Bouvet Island',
      BR: 'Brazil',
      IO: 'British Indian Ocean Territory',
      VG: 'British Virgin Islands',
      BN: 'Brunei',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodia',
      CM: 'Cameroon',
      CA: 'Canada',
      CV: 'Cape Verde',
      BQ: 'Caribbean Netherlands',
      KY: 'Cayman Islands',
      CF: 'Central African Republic',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands',
      CO: 'Colombia',
      KM: 'Comoros',
      CG: 'Congo - Brazzaville',
      CD: 'Congo - Kinshasa',
      CK: 'Cook Islands',
      CR: 'Costa Rica',
      CI: 'Côte d’Ivoire',
      HR: 'Croatia',
      CU: 'Cuba',
      CW: 'Curaçao',
      CY: 'Cyprus',
      CZ: 'Czechia',
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      SZ: 'Eswatini',
      ET: 'Ethiopia',
      FK: 'Falkland Islands',
      FO: 'Faroe Islands',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germany',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard & McDonald Islands',
      HN: 'Honduras',
      HK: '',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: 'Laos',
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MO: 'Macao SAR China',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia',
      MD: 'Moldova',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar (Burma)',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      KP: 'North Korea',
      MK: 'North Macedonia',
      MP: 'Northern Mariana Islands',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestinian Territories',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines',
      PN: 'Pitcairn Islands',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RU: 'Russia',
      RW: 'Rwanda',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'São Tomé & Príncipe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SX: 'Sint Maarten',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia & South Sandwich Islands',
      KR: 'South Korea',
      SS: 'South Sudan',
      ES: 'Spain',
      LK: 'Sri Lanka',
      BL: 'St. Barthélemy',
      SH: 'St. Helena',
      KN: 'St. Kitts & Nevis',
      LC: 'St. Lucia',
      MF: 'St. Martin',
      PM: 'St. Pierre & Miquelon',
      VC: 'St. Vincent & Grenadines',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard & Jan Mayen',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syria',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad & Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks & Caicos Islands',
      TV: 'Tuvalu',
      UM: 'U.S. Outlying Islands',
      VI: 'U.S. Virgin Islands',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates',
      GB: 'United Kingdom',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VA: 'Vatican City',
      VE: 'Venezuela',
      VN: 'Vietnam',
      WF: 'Wallis & Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
    zh: {
      AL: '阿尔巴尼亚',
      DZ: '阿尔及利亚',
      AF: '阿富汗',
      AR: '阿根廷',
      AE: '阿拉伯联合酋长国',
      AW: '阿鲁巴',
      OM: '阿曼',
      AZ: '阿塞拜疆',
      EG: '埃及',
      ET: '埃塞俄比亚',
      IE: '爱尔兰',
      EE: '爱沙尼亚',
      AD: '安道尔',
      AO: '安哥拉',
      AI: '安圭拉',
      AG: '安提瓜和巴布达',
      AT: '奥地利',
      AX: '奥兰群岛',
      AU: '澳大利亚',
      BB: '巴巴多斯',
      PG: '巴布亚新几内亚',
      BS: '巴哈马',
      PK: '巴基斯坦',
      PY: '巴拉圭',
      PS: '巴勒斯坦领土',
      BH: '巴林',
      PA: '巴拿马',
      BR: '巴西',
      BY: '白俄罗斯',
      BM: '百慕大',
      BG: '保加利亚',
      MP: '北马里亚纳群岛',
      MK: '北马其顿',
      BJ: '贝宁',
      BE: '比利时',
      IS: '冰岛',
      PR: '波多黎各',
      PL: '波兰',
      BA: '波斯尼亚和黑塞哥维那',
      BO: '玻利维亚',
      BZ: '伯利兹',
      BW: '博茨瓦纳',
      BT: '不丹',
      BF: '布基纳法索',
      BI: '布隆迪',
      BV: '布韦岛',
      KP: '朝鲜',
      GQ: '赤道几内亚',
      DK: '丹麦',
      DE: '德国',
      TL: '东帝汶',
      TG: '多哥',
      DO: '多米尼加共和国',
      DM: '多米尼克',
      RU: '俄罗斯',
      EC: '厄瓜多尔',
      ER: '厄立特里亚',
      FR: '法国',
      FO: '法罗群岛',
      PF: '法属波利尼西亚',
      GF: '法属圭亚那',
      TF: '法属南部领地',
      MF: '法属圣马丁',
      VA: '梵蒂冈',
      PH: '菲律宾',
      FJ: '斐济',
      FI: '芬兰',
      CV: '佛得角',
      FK: '福克兰群岛',
      GM: '冈比亚',
      CG: '刚果（布）',
      CD: '刚果（金）',
      CO: '哥伦比亚',
      CR: '哥斯达黎加',
      GD: '格林纳达',
      GL: '格陵兰',
      GE: '格鲁吉亚',
      GG: '根西岛',
      CU: '古巴',
      GP: '瓜德罗普',
      GU: '关岛',
      GY: '圭亚那',
      KZ: '哈萨克斯坦',
      HT: '海地',
      KR: '韩国',
      NL: '荷兰',
      BQ: '荷属加勒比区',
      SX: '荷属圣马丁',
      HM: '赫德岛和麦克唐纳群岛',
      ME: '黑山',
      HN: '洪都拉斯',
      KI: '基里巴斯',
      DJ: '吉布提',
      KG: '吉尔吉斯斯坦',
      GN: '几内亚',
      GW: '几内亚比绍',
      CA: '加拿大',
      GH: '加纳',
      GA: '加蓬',
      KH: '柬埔寨',
      CZ: '捷克',
      ZW: '津巴布韦',
      CM: '喀麦隆',
      QA: '卡塔尔',
      KY: '开曼群岛',
      CC: '科科斯（基林）群岛',
      KM: '科摩罗',
      CI: '科特迪瓦',
      KW: '科威特',
      HR: '克罗地亚',
      KE: '肯尼亚',
      CK: '库克群岛',
      CW: '库拉索',
      LV: '拉脱维亚',
      LS: '莱索托',
      LA: '老挝',
      LB: '黎巴嫩',
      LT: '立陶宛',
      LR: '利比里亚',
      LY: '利比亚',
      LI: '列支敦士登',
      RE: '留尼汪',
      LU: '卢森堡',
      RW: '卢旺达',
      RO: '罗马尼亚',
      MG: '马达加斯加',
      IM: '马恩岛',
      MV: '马尔代夫',
      MT: '马耳他',
      MW: '马拉维',
      MY: '马来西亚',
      ML: '马里',
      MH: '马绍尔群岛',
      MQ: '马提尼克',
      YT: '马约特',
      MU: '毛里求斯',
      MR: '毛里塔尼亚',
      US: '美国',
      UM: '美国本土外小岛屿',
      AS: '美属萨摩亚',
      VI: '美属维尔京群岛',
      MN: '蒙古',
      MS: '蒙特塞拉特',
      BD: '孟加拉国',
      PE: '秘鲁',
      FM: '密克罗尼西亚',
      MM: '缅甸',
      MD: '摩尔多瓦',
      MA: '摩洛哥',
      MC: '摩纳哥',
      MZ: '莫桑比克',
      MX: '墨西哥',
      NA: '纳米比亚',
      ZA: '南非',
      AQ: '南极洲',
      GS: '南乔治亚和南桑威奇群岛',
      SS: '南苏丹',
      NR: '瑙鲁',
      NI: '尼加拉瓜',
      NP: '尼泊尔',
      NE: '尼日尔',
      NG: '尼日利亚',
      NU: '纽埃',
      NO: '挪威',
      NF: '诺福克岛',
      PW: '帕劳',
      PN: '皮特凯恩群岛',
      PT: '葡萄牙',
      JP: '日本',
      SE: '瑞典',
      CH: '瑞士',
      SV: '萨尔瓦多',
      WS: '萨摩亚',
      RS: '塞尔维亚',
      SL: '塞拉利昂',
      SN: '塞内加尔',
      CY: '塞浦路斯',
      SC: '塞舌尔',
      SA: '沙特阿拉伯',
      BL: '圣巴泰勒米',
      CX: '圣诞岛',
      ST: '圣多美和普林西比',
      SH: '圣赫勒拿',
      KN: '圣基茨和尼维斯',
      LC: '圣卢西亚',
      SM: '圣马力诺',
      PM: '圣皮埃尔和密克隆群岛',
      VC: '圣文森特和格林纳丁斯',
      LK: '斯里兰卡',
      SK: '斯洛伐克',
      SI: '斯洛文尼亚',
      SJ: '斯瓦尔巴和扬马延',
      SZ: '斯威士兰',
      SD: '苏丹',
      SR: '苏里南',
      SB: '所罗门群岛',
      SO: '索马里',
      TJ: '塔吉克斯坦',
      TW: '台湾',
      TH: '泰国',
      TZ: '坦桑尼亚',
      TO: '汤加',
      TC: '特克斯和凯科斯群岛',
      TT: '特立尼达和多巴哥',
      TN: '突尼斯',
      TV: '图瓦卢',
      TR: '土耳其',
      TM: '土库曼斯坦',
      TK: '托克劳',
      WF: '瓦利斯和富图纳',
      VU: '瓦努阿图',
      GT: '危地马拉',
      VE: '委内瑞拉',
      BN: '文莱',
      UG: '乌干达',
      UA: '乌克兰',
      UY: '乌拉圭',
      UZ: '乌兹别克斯坦',
      ES: '西班牙',
      EH: '西撒哈拉',
      GR: '希腊',
      SG: '新加坡',
      NC: '新喀里多尼亚',
      NZ: '新西兰',
      HU: '匈牙利',
      SY: '叙利亚',
      JM: '牙买加',
      AM: '亚美尼亚',
      YE: '也门',
      IQ: '伊拉克',
      IR: '伊朗',
      IL: '以色列',
      IT: '意大利',
      IN: '印度',
      ID: '印度尼西亚',
      GB: '英国',
      VG: '英属维尔京群岛',
      IO: '英属印度洋领地',
      JO: '约旦',
      VN: '越南',
      ZM: '赞比亚',
      JE: '泽西岛',
      TD: '乍得',
      GI: '直布罗陀',
      CL: '智利',
      CF: '中非共和国',
      CN: '中国',
      MO: '中国澳门特别行政区',
      HK: '',
    },
    de: {
      AF: 'Afghanistan',
      EG: 'Ägypten',
      AX: 'Ålandinseln',
      AL: 'Albanien',
      DZ: 'Algerien',
      AS: 'Amerikanisch-Samoa',
      VI: 'Amerikanische Jungferninseln',
      UM: 'Amerikanische Überseeinseln',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarktis',
      AG: 'Antigua und Barbuda',
      GQ: 'Äquatorialguinea',
      AR: 'Argentinien',
      AM: 'Armenien',
      AW: 'Aruba',
      AZ: 'Aserbaidschan',
      ET: 'Äthiopien',
      AU: 'Australien',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesch',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgien',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivien',
      BQ: 'Bonaire, Sint Eustatius und Saba',
      BA: 'Bosnien und Herzegowina',
      BW: 'Botsuana',
      BV: 'Bouvetinsel',
      BR: 'Brasilien',
      VG: 'Britische Jungferninseln',
      IO: 'Britisches Territorium im Indischen Ozean',
      BN: 'Brunei Darussalam',
      BG: 'Bulgarien',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      CV: 'Cabo Verde',
      CL: 'Chile',
      CN: 'China',
      CK: 'Cookinseln',
      CR: 'Costa Rica',
      CI: 'Côte d’Ivoire',
      CW: 'Curaçao',
      DK: 'Dänemark',
      DE: 'Deutschland',
      DM: 'Dominica',
      DO: 'Dominikanische Republik',
      DJ: 'Dschibuti',
      EC: 'Ecuador',
      SV: 'El Salvador',
      ER: 'Eritrea',
      EE: 'Estland',
      SZ: 'Eswatini',
      FK: 'Falklandinseln',
      FO: 'Färöer',
      FJ: 'Fidschi',
      FI: 'Finnland',
      FR: 'Frankreich',
      GF: 'Französisch-Guayana',
      PF: 'Französisch-Polynesien',
      TF: 'Französische Süd- und Antarktisgebiete',
      GA: 'Gabun',
      GM: 'Gambia',
      GE: 'Georgien',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GD: 'Grenada',
      GR: 'Griechenland',
      GL: 'Grönland',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard und McDonaldinseln',
      HN: 'Honduras',
      IN: 'Indien',
      ID: 'Indonesien',
      IQ: 'Irak',
      IR: 'Iran',
      IE: 'Irland',
      IS: 'Island',
      IM: 'Isle of Man',
      IL: 'Israel',
      IT: 'Italien',
      JM: 'Jamaika',
      JP: 'Japan',
      YE: 'Jemen',
      JE: 'Jersey',
      JO: 'Jordanien',
      KY: 'Kaimaninseln',
      KH: 'Kambodscha',
      CM: 'Kamerun',
      CA: 'Kanada',
      KZ: 'Kasachstan',
      QA: 'Katar',
      KE: 'Kenia',
      KG: 'Kirgisistan',
      KI: 'Kiribati',
      CC: 'Kokosinseln',
      CO: 'Kolumbien',
      KM: 'Komoren',
      CG: 'Kongo-Brazzaville',
      CD: 'Kongo-Kinshasa',
      HR: 'Kroatien',
      CU: 'Kuba',
      KW: 'Kuwait',
      LA: 'Laos',
      LS: 'Lesotho',
      LV: 'Lettland',
      LB: 'Libanon',
      LR: 'Liberia',
      LY: 'Libyen',
      LI: 'Liechtenstein',
      LT: 'Litauen',
      LU: 'Luxemburg',
      MG: 'Madagaskar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Malediven',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marokko',
      MH: 'Marshallinseln',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexiko',
      FM: 'Mikronesien',
      MC: 'Monaco',
      MN: 'Mongolei',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MZ: 'Mosambik',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NC: 'Neukaledonien',
      NZ: 'Neuseeland',
      NI: 'Nicaragua',
      NL: 'Niederlande',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      KP: 'Nordkorea',
      MP: 'Nördliche Marianen',
      MK: 'Nordmazedonien',
      NF: 'Norfolkinsel',
      NO: 'Norwegen',
      OM: 'Oman',
      AT: 'Österreich',
      PK: 'Pakistan',
      PS: 'Palästinensische Autonomiegebiete',
      PW: 'Palau',
      PA: 'Panama',
      PG: 'Papua-Neuguinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippinen',
      PN: 'Pitcairninseln',
      PL: 'Polen',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      MD: 'Republik Moldau',
      RE: 'Réunion',
      RW: 'Ruanda',
      RO: 'Rumänien',
      RU: 'Russland',
      SB: 'Salomonen',
      ZM: 'Sambia',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'São Tomé und Príncipe',
      SA: 'Saudi-Arabien',
      SE: 'Schweden',
      CH: 'Schweiz',
      SN: 'Senegal',
      RS: 'Serbien',
      SC: 'Seychellen',
      SL: 'Sierra Leone',
      ZW: 'Simbabwe',
      SG: 'Singapur',
      SX: 'Sint Maarten',
      SK: 'Slowakei',
      SI: 'Slowenien',
      SO: 'Somalia',
      HK: '',
      MO: 'Sonderverwaltungsregion Macau',
      ES: 'Spanien',
      SJ: 'Spitzbergen und Jan Mayen',
      LK: 'Sri Lanka',
      BL: 'St. Barthélemy',
      SH: 'St. Helena',
      KN: 'St. Kitts und Nevis',
      LC: 'St. Lucia',
      MF: 'St. Martin',
      PM: 'St. Pierre und Miquelon',
      VC: 'St. Vincent und die Grenadinen',
      ZA: 'Südafrika',
      SD: 'Sudan',
      GS: 'Südgeorgien und die Südlichen Sandwichinseln',
      KR: 'Südkorea',
      SS: 'Südsudan',
      SR: 'Suriname',
      SY: 'Syrien',
      TJ: 'Tadschikistan',
      TW: 'Taiwan',
      TZ: 'Tansania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad und Tobago',
      TD: 'Tschad',
      CZ: 'Tschechien',
      TN: 'Tunesien',
      TR: 'Türkei',
      TM: 'Turkmenistan',
      TC: 'Turks- und Caicosinseln',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      HU: 'Ungarn',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VU: 'Vanuatu',
      VA: 'Vatikanstadt',
      VE: 'Venezuela',
      AE: 'Vereinigte Arabische Emirate',
      US: 'Vereinigte Staaten',
      GB: 'Vereinigtes Königreich',
      VN: 'Vietnam',
      WF: 'Wallis und Futuna',
      CX: 'Weihnachtsinsel',
      EH: 'Westsahara',
      CF: 'Zentralafrikanische Republik',
      CY: 'Zypern',
    },
    es: {
      AF: 'Afganistán',
      AL: 'Albania',
      DE: 'Alemania',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguila',
      AQ: 'Antártida',
      AG: 'Antigua y Barbuda',
      SA: 'Arabia Saudí',
      DZ: 'Argelia',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaiyán',
      BS: 'Bahamas',
      BD: 'Bangladés',
      BB: 'Barbados',
      BH: 'Baréin',
      BE: 'Bélgica',
      BZ: 'Belice',
      BJ: 'Benín',
      BM: 'Bermudas',
      BY: 'Bielorrusia',
      BO: 'Bolivia',
      BA: 'Bosnia y Herzegovina',
      BW: 'Botsuana',
      BR: 'Brasil',
      BN: 'Brunéi',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      BT: 'Bután',
      CV: 'Cabo Verde',
      KH: 'Camboya',
      CM: 'Camerún',
      CA: 'Canadá',
      BQ: 'Caribe neerlandés',
      QA: 'Catar',
      TD: 'Chad',
      CZ: 'Chequia',
      CL: 'Chile',
      CN: 'China',
      CY: 'Chipre',
      VA: 'Ciudad del Vaticano',
      CO: 'Colombia',
      KM: 'Comoras',
      CG: 'Congo',
      KP: 'Corea del Norte',
      KR: 'Corea del Sur',
      CR: 'Costa Rica',
      CI: 'Côte d’Ivoire',
      HR: 'Croacia',
      CU: 'Cuba',
      CW: 'Curazao',
      DK: 'Dinamarca',
      DM: 'Dominica',
      EC: 'Ecuador',
      EG: 'Egipto',
      SV: 'El Salvador',
      AE: 'Emiratos Árabes Unidos',
      ER: 'Eritrea',
      SK: 'Eslovaquia',
      SI: 'Eslovenia',
      ES: 'España',
      US: 'Estados Unidos',
      EE: 'Estonia',
      SZ: 'Esuatini',
      ET: 'Etiopía',
      PH: 'Filipinas',
      FI: 'Finlandia',
      FJ: 'Fiyi',
      FR: 'Francia',
      GA: 'Gabón',
      GM: 'Gambia',
      GE: 'Georgia',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GD: 'Granada',
      GR: 'Grecia',
      GL: 'Groenlandia',
      GP: 'Guadalupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GF: 'Guayana Francesa',
      GG: 'Guernsey',
      GN: 'Guinea',
      GQ: 'Guinea Ecuatorial',
      GW: 'Guinea-Bisáu',
      GY: 'Guyana',
      HT: 'Haití',
      HN: 'Honduras',
      HU: 'Hungría',
      IN: 'India',
      ID: 'Indonesia',
      IQ: 'Irak',
      IR: 'Irán',
      IE: 'Irlanda',
      BV: 'Isla Bouvet',
      IM: 'Isla de Man',
      CX: 'Isla de Navidad',
      NF: 'Isla Norfolk',
      IS: 'Islandia',
      AX: 'Islas Åland',
      KY: 'Islas Caimán',
      CC: 'Islas Cocos',
      CK: 'Islas Cook',
      FO: 'Islas Feroe',
      GS: 'Islas Georgia del Sur y Sandwich del Sur',
      HM: 'Islas Heard y McDonald',
      FK: 'Islas Malvinas',
      MP: 'Islas Marianas del Norte',
      MH: 'Islas Marshall',
      UM: 'Islas menores alejadas de EE. UU.',
      PN: 'Islas Pitcairn',
      SB: 'Islas Salomón',
      TC: 'Islas Turcas y Caicos',
      VG: 'Islas Vírgenes Británicas',
      VI: 'Islas Vírgenes de EE. UU.',
      IL: 'Israel',
      IT: 'Italia',
      JM: 'Jamaica',
      JP: 'Japón',
      JE: 'Jersey',
      JO: 'Jordania',
      KZ: 'Kazajistán',
      KE: 'Kenia',
      KG: 'Kirguistán',
      KI: 'Kiribati',
      KW: 'Kuwait',
      LA: 'Laos',
      LS: 'Lesoto',
      LV: 'Letonia',
      LB: 'Líbano',
      LR: 'Liberia',
      LY: 'Libia',
      LI: 'Liechtenstein',
      LT: 'Lituania',
      LU: 'Luxemburgo',
      MK: 'Macedonia del Norte',
      MG: 'Madagascar',
      MY: 'Malasia',
      MW: 'Malaui',
      MV: 'Maldivas',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marruecos',
      MQ: 'Martinica',
      MU: 'Mauricio',
      MR: 'Mauritania',
      YT: 'Mayotte',
      MX: 'México',
      FM: 'Micronesia',
      MD: 'Moldavia',
      MC: 'Mónaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MZ: 'Mozambique',
      MM: 'Myanmar (Birmania)',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NI: 'Nicaragua',
      NE: 'Níger',
      NG: 'Nigeria',
      NU: 'Niue',
      NO: 'Noruega',
      NC: 'Nueva Caledonia',
      NZ: 'Nueva Zelanda',
      OM: 'Omán',
      NL: 'Países Bajos',
      PK: 'Pakistán',
      PW: 'Palaos',
      PA: 'Panamá',
      PG: 'Papúa Nueva Guinea',
      PY: 'Paraguay',
      PE: 'Perú',
      PF: 'Polinesia Francesa',
      PL: 'Polonia',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      HK: '',
      MO: 'RAE de Macao (China)',
      GB: 'Reino Unido',
      CF: 'República Centroafricana',
      CD: 'República Democrática del Congo',
      DO: 'República Dominicana',
      RE: 'Reunión',
      RW: 'Ruanda',
      RO: 'Rumanía',
      RU: 'Rusia',
      EH: 'Sáhara Occidental',
      WS: 'Samoa',
      AS: 'Samoa Americana',
      BL: 'San Bartolomé',
      KN: 'San Cristóbal y Nieves',
      SM: 'San Marino',
      MF: 'San Martín',
      PM: 'San Pedro y Miquelón',
      VC: 'San Vicente y las Granadinas',
      SH: 'Santa Elena',
      LC: 'Santa Lucía',
      ST: 'Santo Tomé y Príncipe',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leona',
      SG: 'Singapur',
      SX: 'Sint Maarten',
      SY: 'Siria',
      SO: 'Somalia',
      LK: 'Sri Lanka',
      ZA: 'Sudáfrica',
      SD: 'Sudán',
      SS: 'Sudán del Sur',
      SE: 'Suecia',
      CH: 'Suiza',
      SR: 'Surinam',
      SJ: 'Svalbard y Jan Mayen',
      TH: 'Tailandia',
      TW: 'Taiwán',
      TZ: 'Tanzania',
      TJ: 'Tayikistán',
      IO: 'Territorio Británico del Océano Índico',
      TF: 'Territorios Australes Franceses',
      PS: 'Territorios Palestinos',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad y Tobago',
      TN: 'Túnez',
      TM: 'Turkmenistán',
      TR: 'Turquía',
      TV: 'Tuvalu',
      UA: 'Ucrania',
      UG: 'Uganda',
      UY: 'Uruguay',
      UZ: 'Uzbekistán',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      WF: 'Wallis y Futuna',
      YE: 'Yemen',
      DJ: 'Yibuti',
      ZM: 'Zambia',
      ZW: 'Zimbabue',
    },
    fr: {
      AF: 'Afghanistan',
      ZA: 'Afrique du Sud',
      AL: 'Albanie',
      DZ: 'Algérie',
      DE: 'Allemagne',
      AD: 'Andorre',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctique',
      AG: 'Antigua-et-Barbuda',
      SA: 'Arabie saoudite',
      AR: 'Argentine',
      AM: 'Arménie',
      AW: 'Aruba',
      AU: 'Australie',
      AT: 'Autriche',
      AZ: 'Azerbaïdjan',
      BS: 'Bahamas',
      BH: 'Bahreïn',
      BD: 'Bangladesh',
      BB: 'Barbade',
      BE: 'Belgique',
      BZ: 'Belize',
      BJ: 'Bénin',
      BM: 'Bermudes',
      BT: 'Bhoutan',
      BY: 'Biélorussie',
      BO: 'Bolivie',
      BA: 'Bosnie-Herzégovine',
      BW: 'Botswana',
      BR: 'Brésil',
      BN: 'Brunéi Darussalam',
      BG: 'Bulgarie',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodge',
      CM: 'Cameroun',
      CA: 'Canada',
      CV: 'Cap-Vert',
      CL: 'Chili',
      CN: 'Chine',
      CY: 'Chypre',
      CO: 'Colombie',
      KM: 'Comores',
      CG: 'Congo-Brazzaville',
      CD: 'Congo-Kinshasa',
      KP: 'Corée du Nord',
      KR: 'Corée du Sud',
      CR: 'Costa Rica',
      CI: 'Côte d’Ivoire',
      HR: 'Croatie',
      CU: 'Cuba',
      CW: 'Curaçao',
      DK: 'Danemark',
      DJ: 'Djibouti',
      DM: 'Dominique',
      EG: 'Égypte',
      AE: 'Émirats arabes unis',
      EC: 'Équateur',
      ER: 'Érythrée',
      ES: 'Espagne',
      EE: 'Estonie',
      SZ: 'Eswatini',
      VA: 'État de la Cité du Vatican',
      FM: 'États fédérés de Micronésie',
      US: 'États-Unis',
      ET: 'Éthiopie',
      FJ: 'Fidji',
      FI: 'Finlande',
      FR: 'France',
      GA: 'Gabon',
      GM: 'Gambie',
      GE: 'Géorgie',
      GS: 'Géorgie du Sud et îles Sandwich du Sud',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Grèce',
      GD: 'Grenade',
      GL: 'Groenland',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernesey',
      GN: 'Guinée',
      GQ: 'Guinée équatoriale',
      GW: 'Guinée-Bissau',
      GY: 'Guyana',
      GF: 'Guyane française',
      HT: 'Haïti',
      HN: 'Honduras',
      HU: 'Hongrie',
      BV: 'Île Bouvet',
      CX: 'Île Christmas',
      IM: 'Île de Man',
      NF: 'Île Norfolk',
      AX: 'Îles Åland',
      KY: 'Îles Caïmans',
      CC: 'Îles Cocos',
      CK: 'Îles Cook',
      FO: 'Îles Féroé',
      HM: 'Îles Heard et McDonald',
      FK: 'Îles Malouines',
      MP: 'Îles Mariannes du Nord',
      MH: 'Îles Marshall',
      UM: 'Îles mineures éloignées des États-Unis',
      PN: 'Îles Pitcairn',
      SB: 'Îles Salomon',
      TC: 'Îles Turques-et-Caïques',
      VG: 'Îles Vierges britanniques',
      VI: 'Îles Vierges des États-Unis',
      IN: 'Inde',
      ID: 'Indonésie',
      IQ: 'Irak',
      IR: 'Iran',
      IE: 'Irlande',
      IS: 'Islande',
      IL: 'Israël',
      IT: 'Italie',
      JM: 'Jamaïque',
      JP: 'Japon',
      JE: 'Jersey',
      JO: 'Jordanie',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KG: 'Kirghizistan',
      KI: 'Kiribati',
      KW: 'Koweït',
      RE: 'La Réunion',
      LA: 'Laos',
      LS: 'Lesotho',
      LV: 'Lettonie',
      LB: 'Liban',
      LR: 'Libéria',
      LY: 'Libye',
      LI: 'Liechtenstein',
      LT: 'Lituanie',
      LU: 'Luxembourg',
      MK: 'Macédoine du Nord',
      MG: 'Madagascar',
      MY: 'Malaisie',
      MW: 'Malawi',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malte',
      MA: 'Maroc',
      MQ: 'Martinique',
      MU: 'Maurice',
      MR: 'Mauritanie',
      YT: 'Mayotte',
      MX: 'Mexique',
      MD: 'Moldavie',
      MC: 'Monaco',
      MN: 'Mongolie',
      ME: 'Monténégro',
      MS: 'Montserrat',
      MZ: 'Mozambique',
      MM: 'Myanmar (Birmanie)',
      NA: 'Namibie',
      NR: 'Nauru',
      NP: 'Népal',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigéria',
      NU: 'Niue',
      NO: 'Norvège',
      NC: 'Nouvelle-Calédonie',
      NZ: 'Nouvelle-Zélande',
      OM: 'Oman',
      UG: 'Ouganda',
      UZ: 'Ouzbékistan',
      PK: 'Pakistan',
      PW: 'Palaos',
      PA: 'Panama',
      PG: 'Papouasie-Nouvelle-Guinée',
      PY: 'Paraguay',
      NL: 'Pays-Bas',
      BQ: 'Pays-Bas caribéens',
      PE: 'Pérou',
      PH: 'Philippines',
      PL: 'Pologne',
      PF: 'Polynésie française',
      PR: 'Porto Rico',
      PT: 'Portugal',
      QA: 'Qatar',
      HK: '',
      MO: 'R.A.S. chinoise de Macao',
      CF: 'République centrafricaine',
      DO: 'République dominicaine',
      RO: 'Roumanie',
      GB: 'Royaume-Uni',
      RU: 'Russie',
      RW: 'Rwanda',
      EH: 'Sahara occidental',
      BL: 'Saint-Barthélemy',
      KN: 'Saint-Christophe-et-Niévès',
      SM: 'Saint-Marin',
      MF: 'Saint-Martin',
      SX: 'Saint-Martin (partie néerlandaise)',
      PM: 'Saint-Pierre-et-Miquelon',
      VC: 'Saint-Vincent-et-les-Grenadines',
      SH: 'Sainte-Hélène',
      LC: 'Sainte-Lucie',
      SV: 'Salvador',
      WS: 'Samoa',
      AS: 'Samoa américaines',
      ST: 'Sao Tomé-et-Principe',
      SN: 'Sénégal',
      RS: 'Serbie',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapour',
      SK: 'Slovaquie',
      SI: 'Slovénie',
      SO: 'Somalie',
      SD: 'Soudan',
      SS: 'Soudan du Sud',
      LK: 'Sri Lanka',
      SE: 'Suède',
      CH: 'Suisse',
      SR: 'Suriname',
      SJ: 'Svalbard et Jan Mayen',
      SY: 'Syrie',
      TJ: 'Tadjikistan',
      TW: 'Taïwan',
      TZ: 'Tanzanie',
      TD: 'Tchad',
      CZ: 'Tchéquie',
      TF: 'Terres australes françaises',
      IO: 'Territoire britannique de l’océan Indien',
      PS: 'Territoires palestiniens',
      TH: 'Thaïlande',
      TL: 'Timor oriental',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinité-et-Tobago',
      TN: 'Tunisie',
      TM: 'Turkménistan',
      TR: 'Turquie',
      TV: 'Tuvalu',
      UA: 'Ukraine',
      UY: 'Uruguay',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      WF: 'Wallis-et-Futuna',
      YE: 'Yémen',
      ZM: 'Zambie',
      ZW: 'Zimbabwe',
    },
    it: {
      AF: 'Afghanistan',
      AL: 'Albania',
      DZ: 'Algeria',
      UM: 'Altre isole americane del Pacifico',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antartide',
      AG: 'Antigua e Barbuda',
      SA: 'Arabia Saudita',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaigian',
      BS: 'Bahamas',
      BH: 'Bahrein',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BE: 'Belgio',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BY: 'Bielorussia',
      BO: 'Bolivia',
      BA: 'Bosnia ed Erzegovina',
      BW: 'Botswana',
      BR: 'Brasile',
      BN: 'Brunei',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambogia',
      CM: 'Camerun',
      CA: 'Canada',
      CV: 'Capo Verde',
      BQ: 'Caraibi olandesi',
      CZ: 'Cechia',
      TD: 'Ciad',
      CL: 'Cile',
      CN: 'Cina',
      CY: 'Cipro',
      VA: 'Città del Vaticano',
      CO: 'Colombia',
      KM: 'Comore',
      CD: 'Congo - Kinshasa',
      CG: 'Congo-Brazzaville',
      KP: 'Corea del Nord',
      KR: 'Corea del Sud',
      CI: 'Costa d’Avorio',
      CR: 'Costa Rica',
      HR: 'Croazia',
      CU: 'Cuba',
      CW: 'Curaçao',
      DK: 'Danimarca',
      DM: 'Dominica',
      EC: 'Ecuador',
      EG: 'Egitto',
      SV: 'El Salvador',
      AE: 'Emirati Arabi Uniti',
      ER: 'Eritrea',
      EE: 'Estonia',
      ET: 'Etiopia',
      FJ: 'Figi',
      PH: 'Filippine',
      FI: 'Finlandia',
      FR: 'Francia',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      GS: 'Georgia del Sud e Sandwich australi',
      DE: 'Germania',
      GH: 'Ghana',
      JM: 'Giamaica',
      JP: 'Giappone',
      GI: 'Gibilterra',
      DJ: 'Gibuti',
      JO: 'Giordania',
      GR: 'Grecia',
      GD: 'Grenada',
      GL: 'Groenlandia',
      GP: 'Guadalupa',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GQ: 'Guinea Equatoriale',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      GF: 'Guyana francese',
      HT: 'Haiti',
      HN: 'Honduras',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Irlanda',
      IS: 'Islanda',
      BV: 'Isola Bouvet',
      CX: 'Isola Christmas',
      IM: 'Isola di Man',
      NF: 'Isola Norfolk',
      AX: 'Isole Åland',
      KY: 'Isole Cayman',
      CC: 'Isole Cocos (Keeling)',
      CK: 'Isole Cook',
      FO: 'Isole Fær Øer',
      FK: 'Isole Falkland',
      HM: 'Isole Heard e McDonald',
      MP: 'Isole Marianne settentrionali',
      MH: 'Isole Marshall',
      PN: 'Isole Pitcairn',
      SB: 'Isole Salomone',
      TC: 'Isole Turks e Caicos',
      VI: 'Isole Vergini Americane',
      VG: 'Isole Vergini Britanniche',
      IL: 'Israele',
      IT: 'Italia',
      JE: 'Jersey',
      KZ: 'Kazakistan',
      KE: 'Kenya',
      KG: 'Kirghizistan',
      KI: 'Kiribati',
      KW: 'Kuwait',
      LA: 'Laos',
      LS: 'Lesotho',
      LV: 'Lettonia',
      LB: 'Libano',
      LR: 'Liberia',
      LY: 'Libia',
      LI: 'Liechtenstein',
      LT: 'Lituania',
      LU: 'Lussemburgo',
      MK: 'Macedonia del Nord',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldive',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marocco',
      MQ: 'Martinica',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Messico',
      FM: 'Micronesia',
      MD: 'Moldavia',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MZ: 'Mozambico',
      MM: 'Myanmar (Birmania)',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NO: 'Norvegia',
      NC: 'Nuova Caledonia',
      NZ: 'Nuova Zelanda',
      OM: 'Oman',
      NL: 'Paesi Bassi',
      PK: 'Pakistan',
      PW: 'Palau',
      PA: 'Panamá',
      PG: 'Papua Nuova Guinea',
      PY: 'Paraguay',
      PE: 'Perù',
      PF: 'Polinesia francese',
      PL: 'Polonia',
      PT: 'Portogallo',
      PR: 'Portorico',
      QA: 'Qatar',
      HK: '',
      MO: 'RAS di Macao',
      GB: 'Regno Unito',
      CF: 'Repubblica Centrafricana',
      DO: 'Repubblica Dominicana',
      RE: 'Riunione',
      RO: 'Romania',
      RW: 'Ruanda',
      RU: 'Russia',
      EH: 'Sahara occidentale',
      KN: 'Saint Kitts e Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin',
      VC: 'Saint Vincent e Grenadine',
      BL: 'Saint-Barthélemy',
      PM: 'Saint-Pierre e Miquelon',
      WS: 'Samoa',
      AS: 'Samoa americane',
      SM: 'San Marino',
      SH: 'Sant’Elena',
      ST: 'São Tomé e Príncipe',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SX: 'Sint Maarten',
      SY: 'Siria',
      SK: 'Slovacchia',
      SI: 'Slovenia',
      SO: 'Somalia',
      ES: 'Spagna',
      LK: 'Sri Lanka',
      US: 'Stati Uniti',
      SS: 'Sud Sudan',
      ZA: 'Sudafrica',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard e Jan Mayen',
      SE: 'Svezia',
      CH: 'Svizzera',
      SZ: 'Swaziland',
      TJ: 'Tagikistan',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      TF: 'Terre australi francesi',
      PS: 'Territori palestinesi',
      IO: 'Territorio britannico dell’Oceano Indiano',
      TH: 'Thailandia',
      TL: 'Timor Est',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad e Tobago',
      TN: 'Tunisia',
      TR: 'Turchia',
      TM: 'Turkmenistan',
      TV: 'Tuvalu',
      UA: 'Ucraina',
      UG: 'Uganda',
      HU: 'Ungheria',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      WF: 'Wallis e Futuna',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
    ja: {
      IS: 'アイスランド',
      IE: 'アイルランド',
      AZ: 'アゼルバイジャン',
      AF: 'アフガニスタン',
      US: 'アメリカ合衆国',
      AE: 'アラブ首長国連邦',
      DZ: 'アルジェリア',
      AR: 'アルゼンチン',
      AW: 'アルバ',
      AL: 'アルバニア',
      AM: 'アルメニア',
      AI: 'アンギラ',
      AO: 'アンゴラ',
      AG: 'アンティグア・バーブーダ',
      AD: 'アンドラ',
      YE: 'イエメン',
      GB: 'イギリス',
      IL: 'イスラエル',
      IT: 'イタリア',
      IQ: 'イラク',
      IR: 'イラン',
      IN: 'インド',
      ID: 'インドネシア',
      WF: 'ウォリス・フツナ',
      UG: 'ウガンダ',
      UA: 'ウクライナ',
      UZ: 'ウズベキスタン',
      UY: 'ウルグアイ',
      EC: 'エクアドル',
      EG: 'エジプト',
      EE: 'エストニア',
      SZ: 'エスワティニ',
      ET: 'エチオピア',
      ER: 'エリトリア',
      SV: 'エルサルバドル',
      AU: 'オーストラリア',
      AT: 'オーストリア',
      AX: 'オーランド諸島',
      OM: 'オマーン',
      NL: 'オランダ',
      BQ: 'オランダ領カリブ',
      GH: 'ガーナ',
      CV: 'カーボベルデ',
      GG: 'ガーンジー',
      GY: 'ガイアナ',
      KZ: 'カザフスタン',
      QA: 'カタール',
      CA: 'カナダ',
      GA: 'ガボン',
      CM: 'カメルーン',
      GM: 'ガンビア',
      KH: 'カンボジア',
      GN: 'ギニア',
      GW: 'ギニアビサウ',
      CY: 'キプロス',
      CU: 'キューバ',
      CW: 'キュラソー',
      GR: 'ギリシャ',
      KI: 'キリバス',
      KG: 'キルギス',
      GT: 'グアテマラ',
      GP: 'グアドループ',
      GU: 'グアム',
      KW: 'クウェート',
      CK: 'クック諸島',
      GL: 'グリーンランド',
      CX: 'クリスマス島',
      GD: 'グレナダ',
      HR: 'クロアチア',
      KY: 'ケイマン諸島',
      KE: 'ケニア',
      CI: 'コートジボワール',
      CC: 'ココス(キーリング)諸島',
      CR: 'コスタリカ',
      KM: 'コモロ',
      CO: 'コロンビア',
      CG: 'コンゴ共和国(ブラザビル)',
      CD: 'コンゴ民主共和国(キンシャサ)',
      SA: 'サウジアラビア',
      GS: 'サウスジョージア・サウスサンドウィッチ諸島',
      WS: 'サモア',
      BL: 'サン・バルテルミー',
      MF: 'サン・マルタン',
      ST: 'サントメ・プリンシペ',
      ZM: 'ザンビア',
      PM: 'サンピエール島・ミクロン島',
      SM: 'サンマリノ',
      SL: 'シエラレオネ',
      DJ: 'ジブチ',
      GI: 'ジブラルタル',
      JE: 'ジャージー',
      JM: 'ジャマイカ',
      GE: 'ジョージア',
      SY: 'シリア',
      SG: 'シンガポール',
      SX: 'シント・マールテン',
      ZW: 'ジンバブエ',
      CH: 'スイス',
      SE: 'スウェーデン',
      SD: 'スーダン',
      SJ: 'スバールバル諸島・ヤンマイエン島',
      ES: 'スペイン',
      SR: 'スリナム',
      LK: 'スリランカ',
      SK: 'スロバキア',
      SI: 'スロベニア',
      SC: 'セーシェル',
      SN: 'セネガル',
      RS: 'セルビア',
      KN: 'セントクリストファー・ネーヴィス',
      VC: 'セントビンセント及びグレナディーン諸島',
      SH: 'セントヘレナ',
      LC: 'セントルシア',
      SO: 'ソマリア',
      SB: 'ソロモン諸島',
      TC: 'タークス・カイコス諸島',
      TH: 'タイ',
      TJ: 'タジキスタン',
      TZ: 'タンザニア',
      CZ: 'チェコ',
      TD: 'チャド',
      TN: 'チュニジア',
      CL: 'チリ',
      TV: 'ツバル',
      DK: 'デンマーク',
      DE: 'ドイツ',
      TG: 'トーゴ',
      TK: 'トケラウ',
      DO: 'ドミニカ共和国',
      DM: 'ドミニカ国',
      TT: 'トリニダード・トバゴ',
      TM: 'トルクメニスタン',
      TR: 'トルコ',
      TO: 'トンガ',
      NG: 'ナイジェリア',
      NR: 'ナウル',
      NA: 'ナミビア',
      NU: 'ニウエ',
      NI: 'ニカラグア',
      NE: 'ニジェール',
      NC: 'ニューカレドニア',
      NZ: 'ニュージーランド',
      NP: 'ネパール',
      NF: 'ノーフォーク島',
      NO: 'ノルウェー',
      HM: 'ハード島・マクドナルド諸島',
      BH: 'バーレーン',
      HT: 'ハイチ',
      PK: 'パキスタン',
      VA: 'バチカン市国',
      PA: 'パナマ',
      VU: 'バヌアツ',
      BS: 'バハマ',
      PG: 'パプアニューギニア',
      BM: 'バミューダ',
      PW: 'パラオ',
      PY: 'パラグアイ',
      BB: 'バルバドス',
      PS: 'パレスチナ自治区',
      HU: 'ハンガリー',
      BD: 'バングラデシュ',
      PN: 'ピトケアン諸島',
      FJ: 'フィジー',
      PH: 'フィリピン',
      FI: 'フィンランド',
      BT: 'ブータン',
      BV: 'ブーベ島',
      PR: 'プエルトリコ',
      FO: 'フェロー諸島',
      FK: 'フォークランド諸島',
      BR: 'ブラジル',
      FR: 'フランス',
      BG: 'ブルガリア',
      BF: 'ブルキナファソ',
      BN: 'ブルネイ',
      BI: 'ブルンジ',
      VN: 'ベトナム',
      BJ: 'ベナン',
      VE: 'ベネズエラ',
      BY: 'ベラルーシ',
      BZ: 'ベリーズ',
      PE: 'ペルー',
      BE: 'ベルギー',
      PL: 'ポーランド',
      BA: 'ボスニア・ヘルツェゴビナ',
      BW: 'ボツワナ',
      BO: 'ボリビア',
      PT: 'ポルトガル',
      HN: 'ホンジュラス',
      MH: 'マーシャル諸島',
      MG: 'マダガスカル',
      YT: 'マヨット',
      MW: 'マラウイ',
      ML: 'マリ',
      MT: 'マルタ',
      MQ: 'マルティニーク',
      MY: 'マレーシア',
      IM: 'マン島',
      FM: 'ミクロネシア連邦',
      MM: 'ミャンマー (ビルマ)',
      MX: 'メキシコ',
      MU: 'モーリシャス',
      MR: 'モーリタニア',
      MZ: 'モザンビーク',
      MC: 'モナコ',
      MV: 'モルディブ',
      MD: 'モルドバ',
      MA: 'モロッコ',
      MN: 'モンゴル',
      ME: 'モンテネグロ',
      MS: 'モントセラト',
      JO: 'ヨルダン',
      LA: 'ラオス',
      LV: 'ラトビア',
      LT: 'リトアニア',
      LY: 'リビア',
      LI: 'リヒテンシュタイン',
      LR: 'リベリア',
      RO: 'ルーマニア',
      LU: 'ルクセンブルク',
      RW: 'ルワンダ',
      LS: 'レソト',
      LB: 'レバノン',
      RE: 'レユニオン',
      RU: 'ロシア',
      IO: '英領インド洋地域',
      VG: '英領ヴァージン諸島',
      KR: '韓国',
      UM: '合衆国領有小離島',
      EH: '西サハラ',
      GQ: '赤道ギニア',
      TW: '台湾',
      CF: '中央アフリカ共和国',
      MO: '中華人民共和国マカオ特別行政区',
      HK: '',
      CN: '中国',
      TL: '東ティモール',
      ZA: '南アフリカ',
      SS: '南スーダン',
      AQ: '南極',
      JP: '日本',
      GF: '仏領ギアナ',
      PF: '仏領ポリネシア',
      TF: '仏領極南諸島',
      VI: '米領ヴァージン諸島',
      AS: '米領サモア',
      MK: '北マケドニア',
      MP: '北マリアナ諸島',
      KP: '北朝鮮',
    },
    nl: {
      AF: 'Afghanistan',
      AX: 'Åland',
      AL: 'Albanië',
      DZ: 'Algerije',
      AS: 'Amerikaans-Samoa',
      VI: 'Amerikaanse Maagdeneilanden',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua en Barbuda',
      AR: 'Argentinië',
      AM: 'Armenië',
      AW: 'Aruba',
      AU: 'Australië',
      AZ: 'Azerbeidzjan',
      BS: 'Bahama’s',
      BH: 'Bahrein',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'België',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnië en Herzegovina',
      BW: 'Botswana',
      BV: 'Bouveteiland',
      BR: 'Brazilië',
      IO: 'Brits Indische Oceaanterritorium',
      VG: 'Britse Maagdeneilanden',
      BN: 'Brunei',
      BG: 'Bulgarije',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodja',
      CA: 'Canada',
      BQ: 'Caribisch Nederland',
      CF: 'Centraal-Afrikaanse Republiek',
      CL: 'Chili',
      CN: 'China',
      CX: 'Christmaseiland',
      CC: 'Cocoseilanden',
      CO: 'Colombia',
      KM: 'Comoren',
      CG: 'Congo-Brazzaville',
      CD: 'Congo-Kinshasa',
      CK: 'Cookeilanden',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CW: 'Curaçao',
      CY: 'Cyprus',
      DK: 'Denemarken',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominicaanse Republiek',
      DE: 'Duitsland',
      EC: 'Ecuador',
      EG: 'Egypte',
      SV: 'El Salvador',
      GQ: 'Equatoriaal-Guinea',
      ER: 'Eritrea',
      EE: 'Estland',
      SZ: 'eSwatini',
      ET: 'Ethiopië',
      FO: 'Faeröer',
      FK: 'Falklandeilanden',
      FJ: 'Fiji',
      PH: 'Filipijnen',
      FI: 'Finland',
      FR: 'Frankrijk',
      GF: 'Frans-Guyana',
      PF: 'Frans-Polynesië',
      TF: 'Franse Gebieden in de zuidelijke Indische Oceaan',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgië',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GD: 'Grenada',
      GR: 'Griekenland',
      GL: 'Groenland',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinee',
      GW: 'Guinee-Bissau',
      GY: 'Guyana',
      HT: 'Haïti',
      HM: 'Heard en McDonaldeilanden',
      HN: 'Honduras',
      HU: 'Hongarije',
      HK: '',
      IE: 'Ierland',
      IS: 'IJsland',
      IN: 'India',
      ID: 'Indonesië',
      IQ: 'Irak',
      IR: 'Iran',
      IM: 'Isle of Man',
      IL: 'Israël',
      IT: 'Italië',
      CI: 'Ivoorkust',
      JM: 'Jamaica',
      JP: 'Japan',
      YE: 'Jemen',
      JE: 'Jersey',
      JO: 'Jordanië',
      KY: 'Kaaimaneilanden',
      CV: 'Kaapverdië',
      CM: 'Kameroen',
      KZ: 'Kazachstan',
      KE: 'Kenia',
      KG: 'Kirgizië',
      KI: 'Kiribati',
      UM: 'Kleine afgelegen eilanden van de Verenigde Staten',
      KW: 'Koeweit',
      HR: 'Kroatië',
      LA: 'Laos',
      LS: 'Lesotho',
      LV: 'Letland',
      LB: 'Libanon',
      LR: 'Liberia',
      LY: 'Libië',
      LI: 'Liechtenstein',
      LT: 'Litouwen',
      LU: 'Luxemburg',
      MO: '',
      MG: 'Madagaskar',
      MW: 'Malawi',
      MV: 'Maldiven',
      MY: 'Maleisië',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marokko',
      MH: 'Marshalleilanden',
      MQ: 'Martinique',
      MR: 'Mauritanië',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia',
      MD: 'Moldavië',
      MC: 'Monaco',
      MN: 'Mongolië',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MZ: 'Mozambique',
      MM: 'Myanmar (Birma)',
      NA: 'Namibië',
      NR: 'Nauru',
      NL: 'Nederland',
      NP: 'Nepal',
      NI: 'Nicaragua',
      NC: 'Nieuw-Caledonië',
      NZ: 'Nieuw-Zeeland',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      KP: 'Noord-Korea',
      MK: 'Noord-Macedonië',
      MP: 'Noordelijke Marianen',
      NO: 'Noorwegen',
      NF: 'Norfolk',
      UG: 'Oeganda',
      UA: 'Oekraïne',
      UZ: 'Oezbekistan',
      OM: 'Oman',
      TL: 'Oost-Timor',
      AT: 'Oostenrijk',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestijnse gebieden',
      PA: 'Panama',
      PG: 'Papoea-Nieuw-Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PN: 'Pitcairneilanden',
      PL: 'Polen',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Roemenië',
      RU: 'Rusland',
      RW: 'Rwanda',
      KN: 'Saint Kitts en Nevis',
      LC: 'Saint Lucia',
      VC: 'Saint Vincent en de Grenadines',
      BL: 'Saint-Barthélemy',
      MF: 'Saint-Martin',
      PM: 'Saint-Pierre en Miquelon',
      SB: 'Salomonseilanden',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tomé en Principe',
      SA: 'Saoedi-Arabië',
      SN: 'Senegal',
      RS: 'Servië',
      SC: 'Seychellen',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SH: 'Sint-Helena',
      SX: 'Sint-Maarten',
      SI: 'Slovenië',
      SK: 'Slowakije',
      SD: 'Soedan',
      SO: 'Somalië',
      ES: 'Spanje',
      SJ: 'Spitsbergen en Jan Mayen',
      LK: 'Sri Lanka',
      SR: 'Suriname',
      SY: 'Syrië',
      TJ: 'Tadzjikistan',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      TH: 'Thailand',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad en Tobago',
      TD: 'Tsjaad',
      CZ: 'Tsjechië',
      TN: 'Tunesië',
      TR: 'Turkije',
      TM: 'Turkmenistan',
      TC: 'Turks- en Caicoseilanden',
      TV: 'Tuvalu',
      UY: 'Uruguay',
      VU: 'Vanuatu',
      VA: 'Vaticaanstad',
      VE: 'Venezuela',
      GB: 'Verenigd Koninkrijk',
      AE: 'Verenigde Arabische Emiraten',
      US: 'Verenigde Staten',
      VN: 'Vietnam',
      WF: 'Wallis en Futuna',
      EH: 'Westelijke Sahara',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
      ZA: 'Zuid-Afrika',
      GS: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
      KR: 'Zuid-Korea',
      SS: 'Zuid-Soedan',
      SE: 'Zweden',
      CH: 'Zwitserland',
    },
    pl: {
      AF: 'Afganistan',
      AL: 'Albania',
      DZ: 'Algieria',
      AD: 'Andora',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarktyda',
      AG: 'Antigua i Barbuda',
      SA: 'Arabia Saudyjska',
      AR: 'Argentyna',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbejdżan',
      BS: 'Bahamy',
      BH: 'Bahrajn',
      BD: 'Bangladesz',
      BB: 'Barbados',
      BE: 'Belgia',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermudy',
      BT: 'Bhutan',
      BY: 'Białoruś',
      BO: 'Boliwia',
      BA: 'Bośnia i Hercegowina',
      BW: 'Botswana',
      BR: 'Brazylia',
      BN: 'Brunei',
      IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      VG: 'Brytyjskie Wyspy Dziewicze',
      BG: 'Bułgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      CL: 'Chile',
      CN: 'Chiny',
      HR: 'Chorwacja',
      CI: 'Côte d’Ivoire',
      CW: 'Curaçao',
      CY: 'Cypr',
      TD: 'Czad',
      ME: 'Czarnogóra',
      CZ: 'Czechy',
      UM: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
      DK: 'Dania',
      CD: 'Demokratyczna Republika Konga',
      DM: 'Dominika',
      DO: 'Dominikana',
      DJ: 'Dżibuti',
      EG: 'Egipt',
      EC: 'Ekwador',
      ER: 'Erytrea',
      EE: 'Estonia',
      SZ: 'Eswatini',
      ET: 'Etiopia',
      FK: 'Falklandy',
      FJ: 'Fidżi',
      PH: 'Filipiny',
      FI: 'Finlandia',
      FR: 'Francja',
      TF: 'Francuskie Terytoria Południowe i Antarktyczne',
      GA: 'Gabon',
      GM: 'Gambia',
      GS: 'Georgia Południowa i Sandwich Południowy',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Grecja',
      GD: 'Grenada',
      GL: 'Grenlandia',
      GE: 'Gruzja',
      GU: 'Guam',
      GG: 'Guernsey',
      GY: 'Gujana',
      GF: 'Gujana Francuska',
      GP: 'Gwadelupa',
      GT: 'Gwatemala',
      GN: 'Gwinea',
      GW: 'Gwinea Bissau',
      GQ: 'Gwinea Równikowa',
      HT: 'Haiti',
      ES: 'Hiszpania',
      NL: 'Holandia',
      HN: 'Honduras',
      IN: 'Indie',
      ID: 'Indonezja',
      IQ: 'Irak',
      IR: 'Iran',
      IE: 'Irlandia',
      IS: 'Islandia',
      IL: 'Izrael',
      JM: 'Jamajka',
      JP: 'Japonia',
      YE: 'Jemen',
      JE: 'Jersey',
      JO: 'Jordania',
      KY: 'Kajmany',
      KH: 'Kambodża',
      CM: 'Kamerun',
      CA: 'Kanada',
      QA: 'Katar',
      KZ: 'Kazachstan',
      KE: 'Kenia',
      KG: 'Kirgistan',
      KI: 'Kiribati',
      CO: 'Kolumbia',
      KM: 'Komory',
      CG: 'Kongo',
      KR: 'Korea Południowa',
      KP: 'Korea Północna',
      CR: 'Kostaryka',
      CU: 'Kuba',
      KW: 'Kuwejt',
      LA: 'Laos',
      LS: 'Lesotho',
      LB: 'Liban',
      LR: 'Liberia',
      LY: 'Libia',
      LI: 'Liechtenstein',
      LT: 'Litwa',
      LU: 'Luksemburg',
      LV: 'Łotwa',
      MK: 'Macedonia Północna',
      MG: 'Madagaskar',
      YT: 'Majotta',
      MW: 'Malawi',
      MV: 'Malediwy',
      MY: 'Malezja',
      ML: 'Mali',
      MT: 'Malta',
      MP: 'Mariany Północne',
      MA: 'Maroko',
      MQ: 'Martynika',
      MR: 'Mauretania',
      MU: 'Mauritius',
      MX: 'Meksyk',
      FM: 'Mikronezja',
      MM: 'Mjanma (Birma)',
      MD: 'Mołdawia',
      MC: 'Monako',
      MN: 'Mongolia',
      MS: 'Montserrat',
      MZ: 'Mozambik',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      BQ: 'Niderlandy Karaibskie',
      DE: 'Niemcy',
      NE: 'Niger',
      NG: 'Nigeria',
      NI: 'Nikaragua',
      NU: 'Niue',
      NF: 'Norfolk',
      NO: 'Norwegia',
      NC: 'Nowa Kaledonia',
      NZ: 'Nowa Zelandia',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PA: 'Panama',
      PG: 'Papua-Nowa Gwinea',
      PY: 'Paragwaj',
      PE: 'Peru',
      PN: 'Pitcairn',
      PF: 'Polinezja Francuska',
      PL: 'Polska',
      PR: 'Portoryko',
      PT: 'Portugalia',
      ZA: 'Republika Południowej Afryki',
      CF: 'Republika Środkowoafrykańska',
      CV: 'Republika Zielonego Przylądka',
      RE: 'Reunion',
      RU: 'Rosja',
      RO: 'Rumunia',
      RW: 'Rwanda',
      EH: 'Sahara Zachodnia',
      KN: 'Saint Kitts i Nevis',
      LC: 'Saint Lucia',
      VC: 'Saint Vincent i Grenadyny',
      BL: 'Saint-Barthélemy',
      MF: 'Saint-Martin',
      PM: 'Saint-Pierre i Miquelon',
      SV: 'Salwador',
      WS: 'Samoa',
      AS: 'Samoa Amerykańskie',
      SM: 'San Marino',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seszele',
      SL: 'Sierra Leone',
      SG: 'Singapur',
      SX: 'Sint Maarten',
      SK: 'Słowacja',
      SI: 'Słowenia',
      SO: 'Somalia',
      HK: '',
      MO: '',
      LK: 'Sri Lanka',
      US: 'Stany Zjednoczone',
      SD: 'Sudan',
      SS: 'Sudan Południowy',
      SR: 'Surinam',
      SJ: 'Svalbard i Jan Mayen',
      SY: 'Syria',
      CH: 'Szwajcaria',
      SE: 'Szwecja',
      TJ: 'Tadżykistan',
      TH: 'Tajlandia',
      TW: 'Tajwan',
      TZ: 'Tanzania',
      PS: 'Terytoria Palestyńskie',
      TL: 'Timor Wschodni',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trynidad i Tobago',
      TN: 'Tunezja',
      TR: 'Turcja',
      TM: 'Turkmenistan',
      TC: 'Turks i Caicos',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraina',
      UY: 'Urugwaj',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      WF: 'Wallis i Futuna',
      VA: 'Watykan',
      VE: 'Wenezuela',
      HU: 'Węgry',
      GB: 'Wielka Brytania',
      VN: 'Wietnam',
      IT: 'Włochy',
      BV: 'Wyspa Bouveta',
      CX: 'Wyspa Bożego Narodzenia',
      IM: 'Wyspa Man',
      SH: 'Wyspa Świętej Heleny',
      AX: 'Wyspy Alandzkie',
      CK: 'Wyspy Cooka',
      VI: 'Wyspy Dziewicze Stanów Zjednoczonych',
      HM: 'Wyspy Heard i McDonalda',
      CC: 'Wyspy Kokosowe',
      MH: 'Wyspy Marshalla',
      FO: 'Wyspy Owcze',
      SB: 'Wyspy Salomona',
      ST: 'Wyspy Świętego Tomasza i Książęca',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
      AE: 'Zjednoczone Emiraty Arabskie',
    },
    ru: {
      AU: 'Австралия',
      AT: 'Австрия',
      AZ: 'Азербайджан',
      AX: 'Аландские о-ва',
      AL: 'Албания',
      DZ: 'Алжир',
      AS: 'Американское Самоа',
      AI: 'Ангилья',
      AO: 'Ангола',
      AD: 'Андорра',
      AQ: 'Антарктида',
      AG: 'Антигуа и Барбуда',
      AR: 'Аргентина',
      AM: 'Армения',
      AW: 'Аруба',
      AF: 'Афганистан',
      BS: 'Багамы',
      BD: 'Бангладеш',
      BB: 'Барбадос',
      BH: 'Бахрейн',
      BY: 'Беларусь',
      BZ: 'Белиз',
      BE: 'Бельгия',
      BJ: 'Бенин',
      BM: 'Бермудские о-ва',
      BG: 'Болгария',
      BO: 'Боливия',
      BQ: 'Бонэйр, Синт-Эстатиус и Саба',
      BA: 'Босния и Герцеговина',
      BW: 'Ботсвана',
      BR: 'Бразилия',
      IO: 'Британская территория в Индийском океане',
      BN: 'Бруней-Даруссалам',
      BF: 'Буркина-Фасо',
      BI: 'Бурунди',
      BT: 'Бутан',
      VU: 'Вануату',
      VA: 'Ватикан',
      GB: 'Великобритания',
      HU: 'Венгрия',
      VE: 'Венесуэла',
      VG: 'Виргинские о-ва (Великобритания)',
      VI: 'Виргинские о-ва (США)',
      UM: 'Внешние малые о-ва (США)',
      TL: 'Восточный Тимор',
      VN: 'Вьетнам',
      GA: 'Габон',
      HT: 'Гаити',
      GY: 'Гайана',
      GM: 'Гамбия',
      GH: 'Гана',
      GP: 'Гваделупа',
      GT: 'Гватемала',
      GN: 'Гвинея',
      GW: 'Гвинея-Бисау',
      DE: 'Германия',
      GG: 'Гернси',
      GI: 'Гибралтар',
      HN: 'Гондурас',
      HK: '',
      GD: 'Гренада',
      GL: 'Гренландия',
      GR: 'Греция',
      GE: 'Грузия',
      GU: 'Гуам',
      DK: 'Дания',
      JE: 'Джерси',
      DJ: 'Джибути',
      DM: 'Доминика',
      DO: 'Доминиканская Республика',
      EG: 'Египет',
      ZM: 'Замбия',
      EH: 'Западная Сахара',
      ZW: 'Зимбабве',
      IL: 'Израиль',
      IN: 'Индия',
      ID: 'Индонезия',
      JO: 'Иордания',
      IQ: 'Ирак',
      IR: 'Иран',
      IE: 'Ирландия',
      IS: 'Исландия',
      ES: 'Испания',
      IT: 'Италия',
      YE: 'Йемен',
      CV: 'Кабо-Верде',
      KZ: 'Казахстан',
      KH: 'Камбоджа',
      CM: 'Камерун',
      CA: 'Канада',
      QA: 'Катар',
      KE: 'Кения',
      CY: 'Кипр',
      KG: 'Киргизия',
      KI: 'Кирибати',
      CN: 'Китай',
      KP: 'КНДР',
      CC: 'Кокосовые о-ва',
      CO: 'Колумбия',
      KM: 'Коморы',
      CG: 'Конго - Браззавиль',
      CD: 'Конго - Киншаса',
      CR: 'Коста-Рика',
      CI: 'Кот-д’Ивуар',
      CU: 'Куба',
      KW: 'Кувейт',
      CW: 'Кюрасао',
      LA: 'Лаос',
      LV: 'Латвия',
      LS: 'Лесото',
      LR: 'Либерия',
      LB: 'Ливан',
      LY: 'Ливия',
      LT: 'Литва',
      LI: 'Лихтенштейн',
      LU: 'Люксембург',
      MU: 'Маврикий',
      MR: 'Мавритания',
      MG: 'Мадагаскар',
      YT: 'Майотта',
      MO: '',
      MW: 'Малави',
      MY: 'Малайзия',
      ML: 'Мали',
      MV: 'Мальдивы',
      MT: 'Мальта',
      MA: 'Марокко',
      MQ: 'Мартиника',
      MH: 'Маршалловы Острова',
      MX: 'Мексика',
      MZ: 'Мозамбик',
      MD: 'Молдова',
      MC: 'Монако',
      MN: 'Монголия',
      MS: 'Монтсеррат',
      MM: 'Мьянма (Бирма)',
      NA: 'Намибия',
      NR: 'Науру',
      NP: 'Непал',
      NE: 'Нигер',
      NG: 'Нигерия',
      NL: 'Нидерланды',
      NI: 'Никарагуа',
      NU: 'Ниуэ',
      NZ: 'Новая Зеландия',
      NC: 'Новая Каледония',
      NO: 'Норвегия',
      BV: 'о-в Буве',
      IM: 'о-в Мэн',
      NF: 'о-в Норфолк',
      CX: 'о-в Рождества',
      SH: 'о-в Св. Елены',
      PN: 'о-ва Питкэрн',
      TC: 'о-ва Тёркс и Кайкос',
      HM: 'о-ва Херд и Макдональд',
      AE: 'ОАЭ',
      OM: 'Оман',
      KY: 'Острова Кайман',
      CK: 'Острова Кука',
      PK: 'Пакистан',
      PW: 'Палау',
      PS: 'Палестинские территории',
      PA: 'Панама',
      PG: 'Папуа — Новая Гвинея',
      PY: 'Парагвай',
      PE: 'Перу',
      PL: 'Польша',
      PT: 'Португалия',
      PR: 'Пуэрто-Рико',
      KR: 'Республика Корея',
      RE: 'Реюньон',
      RU: 'Россия',
      RW: 'Руанда',
      RO: 'Румыния',
      SV: 'Сальвадор',
      WS: 'Самоа',
      SM: 'Сан-Марино',
      ST: 'Сан-Томе и Принсипи',
      SA: 'Саудовская Аравия',
      MK: 'Северная Македония',
      MP: 'Северные Марианские о-ва',
      SC: 'Сейшельские Острова',
      BL: 'Сен-Бартелеми',
      MF: 'Сен-Мартен',
      PM: 'Сен-Пьер и Микелон',
      SN: 'Сенегал',
      VC: 'Сент-Винсент и Гренадины',
      KN: 'Сент-Китс и Невис',
      LC: 'Сент-Люсия',
      RS: 'Сербия',
      SG: 'Сингапур',
      SX: 'Синт-Мартен',
      SY: 'Сирия',
      SK: 'Словакия',
      SI: 'Словения',
      US: 'Соединенные Штаты',
      SB: 'Соломоновы Острова',
      SO: 'Сомали',
      SD: 'Судан',
      SR: 'Суринам',
      SL: 'Сьерра-Леоне',
      TJ: 'Таджикистан',
      TH: 'Таиланд',
      TW: 'Тайвань',
      TZ: 'Танзания',
      TG: 'Того',
      TK: 'Токелау',
      TO: 'Тонга',
      TT: 'Тринидад и Тобаго',
      TV: 'Тувалу',
      TN: 'Тунис',
      TM: 'Туркменистан',
      TR: 'Турция',
      UG: 'Уганда',
      UZ: 'Узбекистан',
      UA: 'Украина',
      WF: 'Уоллис и Футуна',
      UY: 'Уругвай',
      FO: 'Фарерские о-ва',
      FM: 'Федеративные Штаты Микронезии',
      FJ: 'Фиджи',
      PH: 'Филиппины',
      FI: 'Финляндия',
      FK: 'Фолклендские о-ва',
      FR: 'Франция',
      GF: 'Французская Гвиана',
      PF: 'Французская Полинезия',
      TF: 'Французские Южные территории',
      HR: 'Хорватия',
      CF: 'Центрально-Африканская Республика',
      TD: 'Чад',
      ME: 'Черногория',
      CZ: 'Чехия',
      CL: 'Чили',
      CH: 'Швейцария',
      SE: 'Швеция',
      SJ: 'Шпицберген и Ян-Майен',
      LK: 'Шри-Ланка',
      EC: 'Эквадор',
      GQ: 'Экваториальная Гвинея',
      ER: 'Эритрея',
      SZ: 'Эсватини',
      EE: 'Эстония',
      ET: 'Эфиопия',
      GS: 'Южная Георгия и Южные Сандвичевы о-ва',
      ZA: 'Южно-Африканская Республика',
      SS: 'Южный Судан',
      JM: 'Ямайка',
      JP: 'Япония',
    },
    pt: {
      AF: 'Afeganistão',
      ZA: 'África do Sul',
      AL: 'Albânia',
      DE: 'Alemanha',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguila',
      AQ: 'Antártida',
      AG: 'Antígua e Barbuda',
      SA: 'Arábia Saudita',
      DZ: 'Argélia',
      AR: 'Argentina',
      AM: 'Armênia',
      AW: 'Aruba',
      AU: 'Austrália',
      AT: 'Áustria',
      AZ: 'Azerbaijão',
      BS: 'Bahamas',
      BH: 'Bahrein',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BE: 'Bélgica',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermudas',
      BY: 'Bielorrússia',
      BO: 'Bolívia',
      BA: 'Bósnia e Herzegovina',
      BW: 'Botsuana',
      BR: 'Brasil',
      BN: 'Brunei',
      BG: 'Bulgária',
      BF: 'Burquina Faso',
      BI: 'Burundi',
      BT: 'Butão',
      CV: 'Cabo Verde',
      CM: 'Camarões',
      KH: 'Camboja',
      CA: 'Canadá',
      QA: 'Catar',
      KZ: 'Cazaquistão',
      TD: 'Chade',
      CL: 'Chile',
      CN: 'China',
      CY: 'Chipre',
      VA: 'Cidade do Vaticano',
      CO: 'Colômbia',
      KM: 'Comores',
      CD: 'Congo - Kinshasa',
      KP: 'Coreia do Norte',
      KR: 'Coreia do Sul',
      CI: 'Costa do Marfim',
      CR: 'Costa Rica',
      HR: 'Croácia',
      CU: 'Cuba',
      CW: 'Curaçao',
      DK: 'Dinamarca',
      DJ: 'Djibuti',
      DM: 'Dominica',
      EG: 'Egito',
      SV: 'El Salvador',
      AE: 'Emirados Árabes Unidos',
      EC: 'Equador',
      ER: 'Eritreia',
      SK: 'Eslováquia',
      SI: 'Eslovênia',
      ES: 'Espanha',
      SZ: 'Essuatíni',
      US: 'Estados Unidos',
      EE: 'Estônia',
      ET: 'Etiópia',
      FJ: 'Fiji',
      PH: 'Filipinas',
      FI: 'Finlândia',
      FR: 'França',
      GA: 'Gabão',
      GM: 'Gâmbia',
      GH: 'Gana',
      GE: 'Geórgia',
      GI: 'Gibraltar',
      GD: 'Granada',
      GR: 'Grécia',
      GL: 'Groenlândia',
      GP: 'Guadalupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GY: 'Guiana',
      GF: 'Guiana Francesa',
      GN: 'Guiné',
      GQ: 'Guiné Equatorial',
      GW: 'Guiné-Bissau',
      HT: 'Haiti',
      HN: 'Honduras',
      HK: '',
      HU: 'Hungria',
      YE: 'Iêmen',
      BV: 'Ilha Bouvet',
      CX: 'Ilha Christmas',
      IM: 'Ilha de Man',
      NF: 'Ilha Norfolk',
      AX: 'Ilhas Aland',
      KY: 'Ilhas Cayman',
      CC: 'Ilhas Cocos (Keeling)',
      CK: 'Ilhas Cook',
      FO: 'Ilhas Faroe',
      GS: 'Ilhas Geórgia do Sul e Sandwich do Sul',
      HM: 'Ilhas Heard e McDonald',
      FK: 'Ilhas Malvinas',
      MP: 'Ilhas Marianas do Norte',
      MH: 'Ilhas Marshall',
      UM: 'Ilhas Menores Distantes dos EUA',
      PN: 'Ilhas Pitcairn',
      SB: 'Ilhas Salomão',
      TC: 'Ilhas Turcas e Caicos',
      VI: 'Ilhas Virgens Americanas',
      VG: 'Ilhas Virgens Britânicas',
      IN: 'Índia',
      ID: 'Indonésia',
      IR: 'Irã',
      IQ: 'Iraque',
      IE: 'Irlanda',
      IS: 'Islândia',
      IL: 'Israel',
      IT: 'Itália',
      JM: 'Jamaica',
      JP: 'Japão',
      JE: 'Jersey',
      JO: 'Jordânia',
      KW: 'Kuwait',
      LA: 'Laos',
      LS: 'Lesoto',
      LV: 'Letônia',
      LB: 'Líbano',
      LR: 'Libéria',
      LY: 'Líbia',
      LI: 'Liechtenstein',
      LT: 'Lituânia',
      LU: 'Luxemburgo',
      MO: '',
      MK: 'Macedônia do Norte',
      MG: 'Madagascar',
      MY: 'Malásia',
      MW: 'Malaui',
      MV: 'Maldivas',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marrocos',
      MQ: 'Martinica',
      MU: 'Maurício',
      MR: 'Mauritânia',
      YT: 'Mayotte',
      MX: 'México',
      MM: 'Mianmar (Birmânia)',
      FM: 'Micronésia',
      MZ: 'Moçambique',
      MD: 'Moldova',
      MC: 'Mônaco',
      MN: 'Mongólia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      NA: 'Namíbia',
      NR: 'Nauru',
      NP: 'Nepal',
      NI: 'Nicarágua',
      NE: 'Níger',
      NG: 'Nigéria',
      NU: 'Niue',
      NO: 'Noruega',
      NC: 'Nova Caledônia',
      NZ: 'Nova Zelândia',
      OM: 'Omã',
      NL: 'Países Baixos',
      BQ: 'Países Baixos Caribenhos',
      PW: 'Palau',
      PA: 'Panamá',
      PG: 'Papua-Nova Guiné',
      PK: 'Paquistão',
      PY: 'Paraguai',
      PE: 'Peru',
      PF: 'Polinésia Francesa',
      PL: 'Polônia',
      PR: 'Porto Rico',
      PT: 'Portugal',
      KE: 'Quênia',
      KG: 'Quirguistão',
      KI: 'Quiribati',
      GB: 'Reino Unido',
      CF: 'República Centro-Africana',
      CG: 'República do Congo',
      DO: 'República Dominicana',
      RE: 'Reunião',
      RO: 'Romênia',
      RW: 'Ruanda',
      RU: 'Rússia',
      EH: 'Saara Ocidental',
      WS: 'Samoa',
      AS: 'Samoa Americana',
      SM: 'San Marino',
      SH: 'Santa Helena',
      LC: 'Santa Lúcia',
      BL: 'São Bartolomeu',
      KN: 'São Cristóvão e Névis',
      MF: 'São Martinho',
      PM: 'São Pedro e Miquelão',
      ST: 'São Tomé e Príncipe',
      VC: 'São Vicente e Granadinas',
      SC: 'Seicheles',
      SN: 'Senegal',
      SL: 'Serra Leoa',
      RS: 'Sérvia',
      SG: 'Singapura',
      SX: 'Sint Maarten',
      SY: 'Síria',
      SO: 'Somália',
      LK: 'Sri Lanka',
      SD: 'Sudão',
      SS: 'Sudão do Sul',
      SE: 'Suécia',
      CH: 'Suíça',
      SR: 'Suriname',
      SJ: 'Svalbard e Jan Mayen',
      TJ: 'Tadjiquistão',
      TH: 'Tailândia',
      TW: 'Taiwan',
      TZ: 'Tanzânia',
      CZ: 'Tchéquia',
      IO: 'Território Britânico do Oceano Índico',
      TF: 'Territórios Franceses do Sul',
      PS: 'Territórios palestinos',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad e Tobago',
      TN: 'Tunísia',
      TM: 'Turcomenistão',
      TR: 'Turquia',
      TV: 'Tuvalu',
      UA: 'Ucrânia',
      UG: 'Uganda',
      UY: 'Uruguai',
      UZ: 'Uzbequistão',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnã',
      WF: 'Wallis e Futuna',
      ZM: 'Zâmbia',
      ZW: 'Zimbábue',
    },
    'pt-BR': {
      AF: 'Afeganistão',
      ZA: 'África do Sul',
      AL: 'Albânia',
      DE: 'Alemanha',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguila',
      AQ: 'Antártida',
      AG: 'Antígua e Barbuda',
      SA: 'Arábia Saudita',
      DZ: 'Argélia',
      AR: 'Argentina',
      AM: 'Armênia',
      AW: 'Aruba',
      AU: 'Austrália',
      AT: 'Áustria',
      AZ: 'Azerbaijão',
      BS: 'Bahamas',
      BH: 'Bahrein',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BE: 'Bélgica',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermudas',
      BY: 'Bielorrússia',
      BO: 'Bolívia',
      BA: 'Bósnia e Herzegovina',
      BW: 'Botsuana',
      BR: 'Brasil',
      BN: 'Brunei',
      BG: 'Bulgária',
      BF: 'Burquina Faso',
      BI: 'Burundi',
      BT: 'Butão',
      CV: 'Cabo Verde',
      CM: 'Camarões',
      KH: 'Camboja',
      CA: 'Canadá',
      QA: 'Catar',
      KZ: 'Cazaquistão',
      TD: 'Chade',
      CL: 'Chile',
      CN: 'China',
      CY: 'Chipre',
      VA: 'Cidade do Vaticano',
      CO: 'Colômbia',
      KM: 'Comores',
      CD: 'Congo - Kinshasa',
      KP: 'Coreia do Norte',
      KR: 'Coreia do Sul',
      CI: 'Costa do Marfim',
      CR: 'Costa Rica',
      HR: 'Croácia',
      CU: 'Cuba',
      CW: 'Curaçao',
      DK: 'Dinamarca',
      DJ: 'Djibuti',
      DM: 'Dominica',
      EG: 'Egito',
      SV: 'El Salvador',
      AE: 'Emirados Árabes Unidos',
      EC: 'Equador',
      ER: 'Eritreia',
      SK: 'Eslováquia',
      SI: 'Eslovênia',
      ES: 'Espanha',
      SZ: 'Essuatíni',
      US: 'Estados Unidos',
      EE: 'Estônia',
      ET: 'Etiópia',
      FJ: 'Fiji',
      PH: 'Filipinas',
      FI: 'Finlândia',
      FR: 'França',
      GA: 'Gabão',
      GM: 'Gâmbia',
      GH: 'Gana',
      GE: 'Geórgia',
      GI: 'Gibraltar',
      GD: 'Granada',
      GR: 'Grécia',
      GL: 'Groenlândia',
      GP: 'Guadalupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GY: 'Guiana',
      GF: 'Guiana Francesa',
      GN: 'Guiné',
      GQ: 'Guiné Equatorial',
      GW: 'Guiné-Bissau',
      HT: 'Haiti',
      HN: 'Honduras',
      HK: '',
      HU: 'Hungria',
      YE: 'Iêmen',
      BV: 'Ilha Bouvet',
      CX: 'Ilha Christmas',
      IM: 'Ilha de Man',
      NF: 'Ilha Norfolk',
      AX: 'Ilhas Aland',
      KY: 'Ilhas Cayman',
      CC: 'Ilhas Cocos (Keeling)',
      CK: 'Ilhas Cook',
      FO: 'Ilhas Faroe',
      GS: 'Ilhas Geórgia do Sul e Sandwich do Sul',
      HM: 'Ilhas Heard e McDonald',
      FK: 'Ilhas Malvinas',
      MP: 'Ilhas Marianas do Norte',
      MH: 'Ilhas Marshall',
      UM: 'Ilhas Menores Distantes dos EUA',
      PN: 'Ilhas Pitcairn',
      SB: 'Ilhas Salomão',
      TC: 'Ilhas Turcas e Caicos',
      VI: 'Ilhas Virgens Americanas',
      VG: 'Ilhas Virgens Britânicas',
      IN: 'Índia',
      ID: 'Indonésia',
      IR: 'Irã',
      IQ: 'Iraque',
      IE: 'Irlanda',
      IS: 'Islândia',
      IL: 'Israel',
      IT: 'Itália',
      JM: 'Jamaica',
      JP: 'Japão',
      JE: 'Jersey',
      JO: 'Jordânia',
      KW: 'Kuwait',
      LA: 'Laos',
      LS: 'Lesoto',
      LV: 'Letônia',
      LB: 'Líbano',
      LR: 'Libéria',
      LY: 'Líbia',
      LI: 'Liechtenstein',
      LT: 'Lituânia',
      LU: 'Luxemburgo',
      MO: '',
      MK: 'Macedônia do Norte',
      MG: 'Madagascar',
      MY: 'Malásia',
      MW: 'Malaui',
      MV: 'Maldivas',
      ML: 'Mali',
      MT: 'Malta',
      MA: 'Marrocos',
      MQ: 'Martinica',
      MU: 'Maurício',
      MR: 'Mauritânia',
      YT: 'Mayotte',
      MX: 'México',
      MM: 'Mianmar (Birmânia)',
      FM: 'Micronésia',
      MZ: 'Moçambique',
      MD: 'Moldova',
      MC: 'Mônaco',
      MN: 'Mongólia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      NA: 'Namíbia',
      NR: 'Nauru',
      NP: 'Nepal',
      NI: 'Nicarágua',
      NE: 'Níger',
      NG: 'Nigéria',
      NU: 'Niue',
      NO: 'Noruega',
      NC: 'Nova Caledônia',
      NZ: 'Nova Zelândia',
      OM: 'Omã',
      NL: 'Países Baixos',
      BQ: 'Países Baixos Caribenhos',
      PW: 'Palau',
      PA: 'Panamá',
      PG: 'Papua-Nova Guiné',
      PK: 'Paquistão',
      PY: 'Paraguai',
      PE: 'Peru',
      PF: 'Polinésia Francesa',
      PL: 'Polônia',
      PR: 'Porto Rico',
      PT: 'Portugal',
      KE: 'Quênia',
      KG: 'Quirguistão',
      KI: 'Quiribati',
      GB: 'Reino Unido',
      CF: 'República Centro-Africana',
      CG: 'República do Congo',
      DO: 'República Dominicana',
      RE: 'Reunião',
      RO: 'Romênia',
      RW: 'Ruanda',
      RU: 'Rússia',
      EH: 'Saara Ocidental',
      WS: 'Samoa',
      AS: 'Samoa Americana',
      SM: 'San Marino',
      SH: 'Santa Helena',
      LC: 'Santa Lúcia',
      BL: 'São Bartolomeu',
      KN: 'São Cristóvão e Névis',
      MF: 'São Martinho',
      PM: 'São Pedro e Miquelão',
      ST: 'São Tomé e Príncipe',
      VC: 'São Vicente e Granadinas',
      SC: 'Seicheles',
      SN: 'Senegal',
      SL: 'Serra Leoa',
      RS: 'Sérvia',
      SG: 'Singapura',
      SX: 'Sint Maarten',
      SY: 'Síria',
      SO: 'Somália',
      LK: 'Sri Lanka',
      SD: 'Sudão',
      SS: 'Sudão do Sul',
      SE: 'Suécia',
      CH: 'Suíça',
      SR: 'Suriname',
      SJ: 'Svalbard e Jan Mayen',
      TJ: 'Tadjiquistão',
      TH: 'Tailândia',
      TW: 'Taiwan',
      TZ: 'Tanzânia',
      CZ: 'Tchéquia',
      IO: 'Território Britânico do Oceano Índico',
      TF: 'Territórios Franceses do Sul',
      PS: 'Territórios palestinos',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad e Tobago',
      TN: 'Tunísia',
      TM: 'Turcomenistão',
      TR: 'Turquia',
      TV: 'Tuvalu',
      UA: 'Ucrânia',
      UG: 'Uganda',
      UY: 'Uruguai',
      UZ: 'Uzbequistão',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnã',
      WF: 'Wallis e Futuna',
      ZM: 'Zâmbia',
      ZW: 'Zimbábue',
    },
    ko: {
      GH: '가나',
      GA: '가봉',
      GY: '가이아나',
      GM: '감비아',
      GG: '건지',
      GP: '과들루프',
      GT: '과테말라',
      GU: '괌',
      GD: '그레나다',
      GR: '그리스',
      GL: '그린란드',
      GN: '기니',
      GW: '기니비사우',
      NA: '나미비아',
      NR: '나우루',
      NG: '나이지리아',
      AQ: '남극 대륙',
      SS: '남수단',
      ZA: '남아프리카',
      NL: '네덜란드',
      BQ: '네덜란드령 카리브',
      NP: '네팔',
      NO: '노르웨이',
      NF: '노퍽섬',
      NZ: '뉴질랜드',
      NC: '뉴칼레도니아',
      NU: '니우에',
      NE: '니제르',
      NI: '니카라과',
      TW: '대만',
      KR: '대한민국',
      DK: '덴마크',
      DM: '도미니카',
      DO: '도미니카 공화국',
      DE: '독일',
      TL: '동티모르',
      LA: '라오스',
      LR: '라이베리아',
      LV: '라트비아',
      RU: '러시아',
      LB: '레바논',
      LS: '레소토',
      RO: '루마니아',
      LU: '룩셈부르크',
      RW: '르완다',
      LY: '리비아',
      RE: '리유니온',
      LT: '리투아니아',
      LI: '리히텐슈타인',
      MG: '마다가스카르',
      MQ: '마르티니크',
      MH: '마셜 제도',
      YT: '마요트',
      MO: '',
      MW: '말라위',
      MY: '말레이시아',
      ML: '말리',
      IM: '맨 섬',
      MX: '멕시코',
      MC: '모나코',
      MA: '모로코',
      MU: '모리셔스',
      MR: '모리타니',
      MZ: '모잠비크',
      ME: '몬테네그로',
      MS: '몬트세라트',
      MD: '몰도바',
      MV: '몰디브',
      MT: '몰타',
      MN: '몽골',
      US: '미국',
      VI: '미국령 버진아일랜드',
      UM: '미국령 해외 제도',
      MM: '미얀마',
      FM: '미크로네시아',
      VU: '바누아투',
      BH: '바레인',
      BB: '바베이도스',
      VA: '바티칸 시국',
      BS: '바하마',
      BD: '방글라데시',
      BM: '버뮤다',
      BJ: '베냉',
      VE: '베네수엘라',
      VN: '베트남',
      BE: '벨기에',
      BY: '벨라루스',
      BZ: '벨리즈',
      BA: '보스니아 헤르체고비나',
      BW: '보츠와나',
      BO: '볼리비아',
      BI: '부룬디',
      BF: '부르키나파소',
      BV: '부베섬',
      BT: '부탄',
      MP: '북마리아나제도',
      MK: '북마케도니아',
      KP: '북한',
      BG: '불가리아',
      BR: '브라질',
      BN: '브루나이',
      WS: '사모아',
      SA: '사우디아라비아',
      GS: '사우스조지아 사우스샌드위치 제도',
      SM: '산마리노',
      ST: '상투메 프린시페',
      MF: '생마르탱',
      BL: '생바르텔레미',
      PM: '생피에르 미클롱',
      EH: '서사하라',
      SN: '세네갈',
      RS: '세르비아',
      SC: '세이셸',
      LC: '세인트루시아',
      VC: '세인트빈센트그레나딘',
      KN: '세인트키츠 네비스',
      SH: '세인트헬레나',
      SO: '소말리아',
      SB: '솔로몬 제도',
      SD: '수단',
      SR: '수리남',
      LK: '스리랑카',
      SJ: '스발바르제도-얀마웬섬',
      SE: '스웨덴',
      CH: '스위스',
      ES: '스페인',
      SK: '슬로바키아',
      SI: '슬로베니아',
      SY: '시리아',
      SL: '시에라리온',
      SX: '신트마르턴',
      SG: '싱가포르',
      AE: '아랍에미리트',
      AW: '아루바',
      AM: '아르메니아',
      AR: '아르헨티나',
      AS: '아메리칸 사모아',
      IS: '아이슬란드',
      HT: '아이티',
      IE: '아일랜드',
      AZ: '아제르바이잔',
      AF: '아프가니스탄',
      AD: '안도라',
      AL: '알바니아',
      DZ: '알제리',
      AO: '앙골라',
      AG: '앤티가 바부다',
      AI: '앵귈라',
      ER: '에리트리아',
      SZ: '에스와티니',
      EE: '에스토니아',
      EC: '에콰도르',
      ET: '에티오피아',
      SV: '엘살바도르',
      GB: '영국',
      VG: '영국령 버진아일랜드',
      IO: '영국령 인도양 식민지',
      YE: '예멘',
      OM: '오만',
      AU: '오스트레일리아',
      AT: '오스트리아',
      HN: '온두라스',
      AX: '올란드 제도',
      WF: '왈리스-푸투나 제도',
      JO: '요르단',
      UG: '우간다',
      UY: '우루과이',
      UZ: '우즈베키스탄',
      UA: '우크라이나',
      IQ: '이라크',
      IR: '이란',
      IL: '이스라엘',
      EG: '이집트',
      IT: '이탈리아',
      IN: '인도',
      ID: '인도네시아',
      JP: '일본',
      JM: '자메이카',
      ZM: '잠비아',
      JE: '저지',
      GQ: '적도 기니',
      GE: '조지아',
      CN: '중국',
      CF: '중앙 아프리카 공화국',
      DJ: '지부티',
      GI: '지브롤터',
      ZW: '짐바브웨',
      TD: '차드',
      CZ: '체코',
      CL: '칠레',
      CM: '카메룬',
      CV: '카보베르데',
      KZ: '카자흐스탄',
      QA: '카타르',
      KH: '캄보디아',
      CA: '캐나다',
      KE: '케냐',
      KY: '케이맨 제도',
      KM: '코모로',
      CR: '코스타리카',
      CC: '코코스 제도',
      CI: '코트디부아르',
      CO: '콜롬비아',
      CG: '콩고-브라자빌',
      CD: '콩고-킨샤사',
      CU: '쿠바',
      KW: '쿠웨이트',
      CK: '쿡 제도',
      CW: '퀴라소',
      HR: '크로아티아',
      CX: '크리스마스섬',
      KG: '키르기스스탄',
      KI: '키리바시',
      CY: '키프로스',
      TJ: '타지키스탄',
      TZ: '탄자니아',
      TH: '태국',
      TC: '터크스 케이커스 제도',
      TR: '터키',
      TG: '토고',
      TK: '토켈라우',
      TO: '통가',
      TM: '투르크메니스탄',
      TV: '투발루',
      TN: '튀니지',
      TT: '트리니다드 토바고',
      PA: '파나마',
      PY: '파라과이',
      PK: '파키스탄',
      PG: '파푸아뉴기니',
      PW: '팔라우',
      PS: '팔레스타인 지구',
      FO: '페로 제도',
      PE: '페루',
      PT: '포르투갈',
      FK: '포클랜드 제도',
      PL: '폴란드',
      PR: '푸에르토리코',
      FR: '프랑스',
      TF: '프랑스 남부 지방',
      GF: '프랑스령 기아나',
      PF: '프랑스령 폴리네시아',
      FJ: '피지',
      FI: '핀란드',
      PH: '필리핀',
      PN: '핏케언 섬',
      HM: '허드 맥도널드 제도',
      HU: '헝가리',
      HK: '',
    },
    'zh-Hans-HK': {
      AL: '阿尔巴尼亚',
      DZ: '阿尔及利亚',
      AF: '阿富汗',
      AR: '阿根廷',
      AE: '阿拉伯联合酋长国',
      AW: '阿鲁巴',
      OM: '阿曼',
      AZ: '阿塞拜疆',
      EG: '埃及',
      ET: '埃塞俄比亚',
      IE: '爱尔兰',
      EE: '爱沙尼亚',
      AD: '安道尔',
      AO: '安哥拉',
      AI: '安圭拉',
      AG: '安提瓜和巴布达',
      AT: '奥地利',
      AX: '奥兰群岛',
      AU: '澳大利亚',
      BB: '巴巴多斯',
      PG: '巴布亚新几内亚',
      BS: '巴哈马',
      PK: '巴基斯坦',
      PY: '巴拉圭',
      PS: '巴勒斯坦领土',
      BH: '巴林',
      PA: '巴拿马',
      BR: '巴西',
      BY: '白俄罗斯',
      BM: '百慕大',
      BG: '保加利亚',
      MP: '北马里亚纳群岛',
      MK: '北马其顿',
      BJ: '贝宁',
      BE: '比利时',
      IS: '冰岛',
      PR: '波多黎各',
      PL: '波兰',
      BA: '波斯尼亚和黑塞哥维那',
      BO: '玻利维亚',
      BZ: '伯利兹',
      BW: '博茨瓦纳',
      BT: '不丹',
      BF: '布基纳法索',
      BI: '布隆迪',
      BV: '布韦岛',
      KP: '朝鲜',
      GQ: '赤道几内亚',
      DK: '丹麦',
      DE: '德国',
      TL: '东帝汶',
      TG: '多哥',
      DO: '多米尼加共和国',
      DM: '多米尼克',
      RU: '俄罗斯',
      EC: '厄瓜多尔',
      ER: '厄立特里亚',
      FR: '法国',
      FO: '法罗群岛',
      PF: '法属波利尼西亚',
      GF: '法属圭亚那',
      TF: '法属南部领地',
      MF: '法属圣马丁',
      VA: '梵蒂冈',
      PH: '菲律宾',
      FJ: '斐济',
      FI: '芬兰',
      CV: '佛得角',
      FK: '福克兰群岛',
      GM: '冈比亚',
      CG: '刚果（布）',
      CD: '刚果（金）',
      CO: '哥伦比亚',
      CR: '哥斯达黎加',
      GD: '格林纳达',
      GL: '格陵兰',
      GE: '格鲁吉亚',
      GG: '根西岛',
      CU: '古巴',
      GP: '瓜德罗普',
      GU: '关岛',
      GY: '圭亚那',
      KZ: '哈萨克斯坦',
      HT: '海地',
      KR: '韩国',
      NL: '荷兰',
      BQ: '荷属加勒比区',
      SX: '荷属圣马丁',
      HM: '赫德岛和麦克唐纳群岛',
      ME: '黑山',
      HN: '洪都拉斯',
      KI: '基里巴斯',
      DJ: '吉布提',
      KG: '吉尔吉斯斯坦',
      GN: '几内亚',
      GW: '几内亚比绍',
      CA: '加拿大',
      GH: '加纳',
      GA: '加蓬',
      KH: '柬埔寨',
      CZ: '捷克',
      ZW: '津巴布韦',
      CM: '喀麦隆',
      QA: '卡塔尔',
      KY: '开曼群岛',
      CC: '科科斯（基林）群岛',
      KM: '科摩罗',
      CI: '科特迪瓦',
      KW: '科威特',
      HR: '克罗地亚',
      KE: '肯尼亚',
      CK: '库克群岛',
      CW: '库拉索',
      LV: '拉脱维亚',
      LS: '莱索托',
      LA: '老挝',
      LB: '黎巴嫩',
      LT: '立陶宛',
      LR: '利比里亚',
      LY: '利比亚',
      LI: '列支敦士登',
      RE: '留尼汪',
      LU: '卢森堡',
      RW: '卢旺达',
      RO: '罗马尼亚',
      MG: '马达加斯加',
      IM: '马恩岛',
      MV: '马尔代夫',
      MT: '马耳他',
      MW: '马拉维',
      MY: '马来西亚',
      ML: '马里',
      MH: '马绍尔群岛',
      MQ: '马提尼克',
      YT: '马约特',
      MU: '毛里求斯',
      MR: '毛里塔尼亚',
      US: '美国',
      UM: '美国本土外小岛屿',
      AS: '美属萨摩亚',
      VI: '美属维尔京群岛',
      MN: '蒙古',
      MS: '蒙特塞拉特',
      BD: '孟加拉国',
      PE: '秘鲁',
      FM: '密克罗尼西亚',
      MM: '缅甸',
      MD: '摩尔多瓦',
      MA: '摩洛哥',
      MC: '摩纳哥',
      MZ: '莫桑比克',
      MX: '墨西哥',
      NA: '纳米比亚',
      ZA: '南非',
      AQ: '南极洲',
      GS: '南乔治亚和南桑威奇群岛',
      SS: '南苏丹',
      NR: '瑙鲁',
      NI: '尼加拉瓜',
      NP: '尼泊尔',
      NE: '尼日尔',
      NG: '尼日利亚',
      NU: '纽埃',
      NO: '挪威',
      NF: '诺福克岛',
      PW: '帕劳',
      PN: '皮特凯恩群岛',
      PT: '葡萄牙',
      JP: '日本',
      SE: '瑞典',
      CH: '瑞士',
      SV: '萨尔瓦多',
      WS: '萨摩亚',
      RS: '塞尔维亚',
      SL: '塞拉利昂',
      SN: '塞内加尔',
      CY: '塞浦路斯',
      SC: '塞舌尔',
      SA: '沙特阿拉伯',
      BL: '圣巴泰勒米',
      CX: '圣诞岛',
      ST: '圣多美和普林西比',
      SH: '圣赫勒拿',
      KN: '圣基茨和尼维斯',
      LC: '圣卢西亚',
      SM: '圣马力诺',
      PM: '圣皮埃尔和密克隆群岛',
      VC: '圣文森特和格林纳丁斯',
      LK: '斯里兰卡',
      SK: '斯洛伐克',
      SI: '斯洛文尼亚',
      SJ: '斯瓦尔巴和扬马延',
      SZ: '斯威士兰',
      SD: '苏丹',
      SR: '苏里南',
      SB: '所罗门群岛',
      SO: '索马里',
      TJ: '塔吉克斯坦',
      TW: '台湾',
      TH: '泰国',
      TZ: '坦桑尼亚',
      TO: '汤加',
      TC: '特克斯和凯科斯群岛',
      TT: '特立尼达和多巴哥',
      TN: '突尼斯',
      TV: '图瓦卢',
      TR: '土耳其',
      TM: '土库曼斯坦',
      TK: '托克劳',
      WF: '瓦利斯和富图纳',
      VU: '瓦努阿图',
      GT: '危地马拉',
      VE: '委内瑞拉',
      BN: '文莱',
      UG: '乌干达',
      UA: '乌克兰',
      UY: '乌拉圭',
      UZ: '乌兹别克斯坦',
      ES: '西班牙',
      EH: '西撒哈拉',
      GR: '希腊',
      SG: '新加坡',
      NC: '新喀里多尼亚',
      NZ: '新西兰',
      HU: '匈牙利',
      SY: '叙利亚',
      JM: '牙买加',
      AM: '亚美尼亚',
      YE: '也门',
      IQ: '伊拉克',
      IR: '伊朗',
      IL: '以色列',
      IT: '意大利',
      IN: '印度',
      ID: '印度尼西亚',
      GB: '英国',
      VG: '英属维尔京群岛',
      IO: '英属印度洋领地',
      JO: '约旦',
      VN: '越南',
      ZM: '赞比亚',
      JE: '泽西岛',
      TD: '乍得',
      GI: '直布罗陀',
      CL: '智利',
      CF: '中非共和国',
      CN: '中国',
      MO: '',
      HK: '',
    },
    'zh-Hant-TW': {
      TT: '千里達及托巴哥',
      TR: '土耳其',
      TC: '土克斯及開科斯群島',
      TM: '土庫曼',
      BT: '不丹',
      CF: '中非共和國',
      CN: '中國',
      HK: '',
      MO: '',
      DK: '丹麥',
      EC: '厄瓜多',
      ER: '厄利垂亞',
      PG: '巴布亞紐幾內亞',
      BR: '巴西',
      BB: '巴貝多',
      PY: '巴拉圭',
      BH: '巴林',
      BS: '巴哈馬',
      PA: '巴拿馬',
      PS: '巴勒斯坦自治區',
      PK: '巴基斯坦',
      JP: '日本',
      BE: '比利時',
      JM: '牙買加',
      IL: '以色列',
      CA: '加拿大',
      GA: '加彭',
      MP: '北馬利安納群島',
      MK: '北馬其頓',
      KP: '北韓',
      QA: '卡達',
      CU: '古巴',
      TW: '台灣',
      SZ: '史瓦帝尼',
      NE: '尼日',
      NI: '尼加拉瓜',
      NP: '尼泊爾',
      BF: '布吉納法索',
      BV: '布威島',
      GP: '瓜地洛普',
      GT: '瓜地馬拉',
      WF: '瓦利斯群島和富圖那群島',
      GM: '甘比亞',
      BY: '白俄羅斯',
      PN: '皮特肯群島',
      LT: '立陶宛',
      IQ: '伊拉克',
      IR: '伊朗',
      IS: '冰島',
      LI: '列支敦斯登',
      HU: '匈牙利',
      ID: '印尼',
      IN: '印度',
      DJ: '吉布地',
      KI: '吉里巴斯',
      KG: '吉爾吉斯',
      TV: '吐瓦魯',
      DM: '多米尼克',
      DO: '多明尼加共和國',
      TG: '多哥',
      AG: '安地卡及巴布達',
      AI: '安奎拉',
      AO: '安哥拉',
      AD: '安道爾',
      TK: '托克勞群島',
      BM: '百慕達',
      ET: '衣索比亞',
      ES: '西班牙',
      EH: '西撒哈拉',
      HR: '克羅埃西亞',
      LY: '利比亞',
      HN: '宏都拉斯',
      GR: '希臘',
      BN: '汶萊',
      SA: '沙烏地阿拉伯',
      BZ: '貝里斯',
      BJ: '貝南',
      GQ: '赤道幾內亞',
      ZW: '辛巴威',
      AM: '亞美尼亞',
      AZ: '亞塞拜然',
      TZ: '坦尚尼亞',
      NG: '奈及利亞',
      VE: '委內瑞拉',
      BD: '孟加拉',
      ZM: '尚比亞',
      PW: '帛琉',
      LV: '拉脫維亞',
      TO: '東加',
      TL: '東帝汶',
      FR: '法國',
      FO: '法羅群島',
      GF: '法屬圭亞那',
      TF: '法屬南部屬地',
      PF: '法屬玻里尼西亞',
      MF: '法屬聖馬丁',
      BA: '波士尼亞與赫塞哥維納',
      BW: '波札那',
      PR: '波多黎各',
      PL: '波蘭',
      GI: '直布羅陀',
      KE: '肯亞',
      FI: '芬蘭',
      AE: '阿拉伯聯合大公國',
      AR: '阿根廷',
      OM: '阿曼',
      AF: '阿富汗',
      DZ: '阿爾及利亞',
      AL: '阿爾巴尼亞',
      RU: '俄羅斯',
      BG: '保加利亞',
      ZA: '南非',
      GS: '南喬治亞與南三明治群島',
      AQ: '南極洲',
      KR: '南韓',
      SS: '南蘇丹',
      KZ: '哈薩克',
      TD: '查德',
      KH: '柬埔寨',
      BO: '玻利維亞',
      CC: '科克斯（基靈）群島',
      KW: '科威特',
      TN: '突尼西亞',
      JO: '約旦',
      US: '美國',
      UM: '美國本土外小島嶼',
      VI: '美屬維京群島',
      AS: '美屬薩摩亞',
      GB: '英國',
      IO: '英屬印度洋領地',
      VG: '英屬維京群島',
      MR: '茅利塔尼亞',
      GH: '迦納',
      CG: '剛果（布拉薩）',
      CD: '剛果（金夏沙）',
      CO: '哥倫比亞',
      CR: '哥斯大黎加',
      EG: '埃及',
      CK: '庫克群島',
      CW: '庫拉索',
      NO: '挪威',
      SJ: '挪威屬斯瓦巴及尖棉',
      GG: '根息',
      GL: '格陵蘭',
      GD: '格瑞那達',
      TH: '泰國',
      HT: '海地',
      UG: '烏干達',
      UA: '烏克蘭',
      UY: '烏拉圭',
      UZ: '烏茲別克',
      RE: '留尼旺',
      PE: '秘魯',
      NA: '納米比亞',
      NZ: '紐西蘭',
      NU: '紐埃島',
      SO: '索馬利亞',
      SB: '索羅門群島',
      MQ: '馬丁尼克',
      ML: '馬利',
      MY: '馬來西亞',
      MW: '馬拉威',
      YT: '馬約特島',
      MH: '馬紹爾群島',
      MG: '馬達加斯加',
      MT: '馬爾他',
      MV: '馬爾地夫',
      FM: '密克羅尼西亞',
      CZ: '捷克',
      SY: '敘利亞',
      IM: '曼島',
      VA: '梵蒂岡',
      AW: '荷屬阿魯巴',
      SX: '荷屬聖馬丁',
      NL: '荷蘭',
      BQ: '荷蘭加勒比區',
      MZ: '莫三比克',
      CM: '喀麥隆',
      GE: '喬治亞',
      GN: '幾內亞',
      GW: '幾內亞比索',
      FJ: '斐濟',
      LK: '斯里蘭卡',
      SK: '斯洛伐克',
      SI: '斯洛維尼亞',
      CL: '智利',
      PH: '菲律賓',
      CI: '象牙海岸',
      VN: '越南',
      KY: '開曼群島',
      TJ: '塔吉克',
      SN: '塞內加爾',
      SC: '塞席爾',
      RS: '塞爾維亞',
      AT: '奧地利',
      AX: '奧蘭群島',
      EE: '愛沙尼亞',
      IE: '愛爾蘭',
      SG: '新加坡',
      NC: '新喀里多尼亞',
      SL: '獅子山',
      CH: '瑞士',
      SE: '瑞典',
      VU: '萬那杜',
      IT: '義大利',
      BL: '聖巴瑟米',
      VC: '聖文森及格瑞那丁',
      PM: '聖皮埃與密克隆群島',
      ST: '聖多美普林西比',
      KN: '聖克里斯多福及尼維斯',
      SM: '聖馬利諾',
      SH: '聖赫勒拿島',
      CX: '聖誕島',
      LC: '聖露西亞',
      YE: '葉門',
      KM: '葛摩',
      PT: '葡萄牙',
      FK: '福克蘭群島',
      CV: '維德角',
      MN: '蒙古',
      MS: '蒙哲臘',
      ME: '蒙特內哥羅',
      BI: '蒲隆地',
      GY: '蓋亞那',
      HM: '赫德島及麥唐納群島',
      LA: '寮國',
      DE: '德國',
      MA: '摩洛哥',
      MC: '摩納哥',
      MD: '摩爾多瓦',
      MU: '模里西斯',
      MM: '緬甸',
      LB: '黎巴嫩',
      MX: '墨西哥',
      JE: '澤西島',
      AU: '澳洲',
      RW: '盧安達',
      LU: '盧森堡',
      NF: '諾福克島',
      NR: '諾魯',
      LR: '賴比瑞亞',
      LS: '賴索托',
      CY: '賽普勒斯',
      SV: '薩爾瓦多',
      WS: '薩摩亞',
      RO: '羅馬尼亞',
      GU: '關島',
      SD: '蘇丹',
      SR: '蘇利南',
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MAP:
      state = {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          [action.level]: action.payload.data,
        },
      }
      break
    case SET_MAP_EMOTION:
      state = {
        ...state,
        currentEmotion: action.payload,
      }
      break
    case SET_MAP_ZONE:
      state = {
        ...state,
        currentZone: action.payload,
      }
      break
    case SET_MAP_CURRENT_COUNTRY:
      state = {
        ...state,
        currentCountry: action.payload,
      }
      break
    case SET_MAP_ZONE_NAME:
      state = {
        ...state,
        currentZoneName: action.payload,
      }
      break
    case SET_MAP_LEVEL:
      state = {
        ...state,
        currentLevel: action.payload,
      }
      break
    case SET_MAP_SPORT:
      state = {
        ...state,
        currentSport: action.payload,
      }
      break
    case SET_MAP_EVENT:
      state = {
        ...state,
        currentEvent: action.payload,
      }
      break
    case RECEIVE_MAP_SPORT_LIST:
      state = {
        ...state,
        sportPlayed: action.payload,
      }
      break
    case RESET_MAP_MARKERS:
      state = {
        ...state,
        mind: {
          world: {},
          country: {},
          region: {},
          city: {},
        },
        sports: {
          world: {},
          country: {},
          region: {},
          city: {},
        },
        events: {
          world: {},
          country: {},
          region: {},
          city: {},
        },
        currentLevel: 'world',
        currentZone: '',
        currentZoneName: '',
        currentEmotion: '',
        currentCountry: '',
        currentSport: '',
        currentEvent: '',
        sportPlayed: [],
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
