import { RECEIVE_EMOTIONS } from '@/actions/types'

const initialState = {
  items: [
    {
      key: 'composed',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: 0.5, y: -1 }, // => In basso
      uv: { x: 0.4, y: 0.4 },
      uvMap: { x: 0.6, y: 0.4 },
      rot: { x: -1.9578, y: 1.561 },
      cursor: { x: 0, y: -1, z: 0 },
    },
    {
      key: 'resilient',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: 0.5, y: -0.65 }, // => In basso a destra
      uv: { x: 0, y: 0.2 },
      uvMap: { x: 0.2, y: 0.2 },
      rot: { x: -0.8651, y: -1.9177 },
      cursor: { x: 2, y: -1, z: 0 },
    },
    {
      key: 'positive',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: 0.85, y: -1 }, // => In basso frontale
      uv: { x: 0.4, y: 0.2 },
      uvMap: { x: 0.6, y: 0.2 },
      rot: { x: -0.6943, y: -0.2553 },
      cursor: { x: 0, y: -1, z: 2 },
    },
    {
      key: 'content',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: 0.5, y: 0.65 }, // => In alto a sinistra
      uv: { x: 0, y: 0 },
      uvMap: { x: 0.2, y: 0 },
      rot: { x: -0.6299, y: 1.3107 },
      cursor: { x: -2, y: -1, z: 0 },
    },
    {
      key: 'relaxed',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: -0.85, y: 0 }, // => In basso retro
      uv: { x: 0.4, y: 0 },
      uvMap: { x: 0.6, y: 0 },
      rot: { x: -0.9964, y: 2.85 },
      cursor: { x: 0, y: -1, z: -2 },
    },
    {
      key: 'confident',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: -0.5, y: -1 }, // => In alto
      uv: { x: 0, y: 0.8 },
      uvMap: { x: 0.2, y: 0.8 },
      rot: { x: 1.2066, y: -1.6493 },
      cursor: { x: 0, y: 1, z: 0 },
    },
    {
      key: 'alert',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: -0.5, y: -0.65 }, // => In alto a destra
      uv: { x: 0.4, y: 0.8 },
      uvMap: { x: 0.6, y: 0.8 },
      rot: { x: 0.0455, y: -1.8522 },
      cursor: { x: 2, y: 1, z: 0 },
    },
    {
      key: 'calm',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: -0.85, y: -1 }, // => In alto frontale
      uv: { x: 0, y: 0.6 },
      uvMap: { x: 0.2, y: 0.6 },
      rot: { x: 0.2462, y: -0.3125 },
      cursor: { x: 0, y: 1, z: 2 },
    },
    {
      key: 'focused',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: -0.5, y: 0.65 }, // => In alto a sinistra
      uv: { x: 0.4, y: 0.6 },
      uvMap: { x: 0.6, y: 0.6 },
      rot: { x: 0.1219, y: 1.119 },
      cursor: { x: -2, y: 1, z: 0 },
    },
    {
      key: 'energized',
      scale: 0,
      postScale: 0.5 + Math.random() * 0.5,
      prevScale: Math.random() * 0.5,
      pos: { x: 0.85, y: 0 }, // => In alto retro
      uv: { x: 0, y: 0.4 },
      uvMap: { x: 0.2, y: 0.4 },
      rot: { x: 0.1935, y: 2.8438 },
      cursor: { x: 0, y: 1, z: -2 },
    },
  ],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_EMOTIONS:
      state = {
        ...state,
        items: state.items.map((item, i) => ({ ...item, ...action.payload[i] })),
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
