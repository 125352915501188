import { useEffect, useState, useRef, useContext, useCallback, Suspense } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Canvas } from 'react-three-fiber'
import { Context as GeneralContext } from '@/context'
import classNames from 'classnames'
import usePagePrecache from '@/hooks/usePagePrecache'
import useStore from '@/base/zustand'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as layerActions from '@/actions/layer'
import Main from './three/main'
import Logo from './three/logo'
import Text3D from './three/Text3D'

import style from './style'

const useStyles = createUseStyles(style)

const ResultsSaveThumb = () => {
  const { setBeforeAfterFlag } = useStore()
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const history = useHistory()
  const $canvasWrapper = useRef()
  const $canvas = useRef()
  const [gifState, setGifState] = useState(0)
  const [couldPrint, setCouldPrint] = useState(false)
  const textReady = useRef(0)
  const timer = useRef()
  const time = 300

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions, experience, gif_1, gif_2, gif_3, gif_4, totalUplift, imageThumb, imageDownload, strings, isLoggedIn, modal } = useSelector((state) => ({
    emotions: state.emotions.items,
    experience: state.experience.emotions,
    gif_1: state.experience.gif_1,
    gif_2: state.experience.gif_2,
    gif_3: state.experience.gif_3,
    gif_4: state.experience.gif_4,
    imageThumb: state.experience.image,
    imageDownload: state.experience.image_download,
    strings: state.options.strings,
    totalUplift: state.experience.total_uplift,
    isLoggedIn: state.user.isLoggedIn,
    modal: state.experience.modal
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  //const moveNext = useCallback(() => dispatch(routerActions.changeLocation('share-page')), [dispatch])
  const setImageGif1 = useCallback((data) => dispatch(experienceActions.setImageGif1(data)), [dispatch])
  const setImageGif2 = useCallback((data) => dispatch(experienceActions.setImageGif2(data)), [dispatch])
  const setImageGif3 = useCallback((data) => dispatch(experienceActions.setImageGif3(data)), [dispatch])
  const setImageGif4 = useCallback((data) => dispatch(experienceActions.setImageGif4(data)), [dispatch])
  const setImageThumb = useCallback((data) => dispatch(experienceActions.setImageThumb(data)), [dispatch])
  const setImageDownload = useCallback((data) => dispatch(experienceActions.setImageDownload(data)), [dispatch])
  const openRestartModal = useCallback(() => dispatch(layerActions.openLayer({ id: 'restart' })), [dispatch])
  const openGenericRestartModal = useCallback(() => dispatch(layerActions.openLayer({ id: 'generic-restart' })), [dispatch])
  const changeLocation = useCallback((location) => dispatch(routerActions.changeLocation(location)), [dispatch])
  //const saveFullExperience = useCallback(() => dispatch(experienceActions.saveFullExperience()), [dispatch])
  const saveExperienceImages = useCallback(() => dispatch(experienceActions.saveExperienceImages()), [dispatch])

  /*------------------------------
  Handle Text Ready
  ------------------------------*/
  const handleTextReady = (() => {
    textReady.current += 1
    setCouldPrint(textReady.current === 10)
  })

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(emotions).length > 0) setDataFetched(true)

    Object.keys(experience).forEach((e, index) => {
      /*------------------------------
      Origina Pre & Post
      Commentare per debug
      ------------------------------*/
      const { pre, post } = experience[e]
      emotions[index].prevScale = pre
      emotions[index].postScale = post
    })
  }, [emotions, experience])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    setBeforeAfterFlag(true)
  }, [])

  /*------------------------------
  Frame 0
  ------------------------------*/
  useEffect(() => {
    if (gifState === 0 && couldPrint) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageGif1($canvas.current.toDataURL('image/jpeg', 0.95))
      }, 1000)
    }
  }, [couldPrint, gifState])

  /*------------------------------
  Frame 1
  ------------------------------*/
  useEffect(() => {
    if (gif_1 && gifState === 0) {
      // if (debug) window.console.log('gif_1 ---->', gif_1)
      setBeforeAfterFlag(false)
      setGifState(1)
    }
    if (gif_1 && gifState === 1) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageGif2($canvas.current.toDataURL('image/jpeg', 0.95))
      }, time)
    }
  }, [gifState, gif_1])

  /*------------------------------
  Frame 2
  ------------------------------*/
  useEffect(() => {
    if (gif_2 && gifState === 1) {
      // if (debug) window.console.log('gif_2 ---->', gif_2)
      setGifState(2)
    }
    if (gif_2 && gifState === 2) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageGif3($canvas.current.toDataURL('image/jpeg', 0.95))
      }, time)
    }
  }, [gifState, gif_2])

  /*------------------------------
  Frame 3
  ------------------------------*/
  useEffect(() => {
    if (gif_3 && gifState === 2) {
      // if (debug) window.console.log('gif_3 ---->', gif_3)
      setGifState(3)
    }
    if (gif_3 && gifState === 3) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageGif4($canvas.current.toDataURL('image/jpeg', 0.95))
      }, time)
    }
  }, [gifState, gif_3])

  /*------------------------------
  Frame 4
  ------------------------------*/
  useEffect(() => {
    if (gif_4 && gifState === 3) {
      // if (debug) window.console.log('gif_4 ---->', gif_4)
      setGifState(4)
    }
    if (gif_4 && gifState === 4) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageThumb($canvas.current.toDataURL('image/jpeg', 0.95))
        setGifState(5)
      }, time)
    }
  }, [gifState, gif_4])

  /*------------------------------
  Thumb
  ------------------------------*/
  useEffect(() => {
    if (imageThumb && gifState === 5) {
      // if (debug) window.console.log('imageThumb ---->', imageThumb)

      $canvasWrapper.current.style.width = '800px'
      $canvasWrapper.current.style.height = '800px'
    }
    if (imageThumb && gifState === 5) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setImageDownload($canvas.current.toDataURL('image/jpeg', 0.95))
        setGifState(6)
      }, time)
    }
  }, [gifState, imageThumb])

  /*------------------------------
  Download
  ------------------------------*/
  useEffect(() => {
    if (imageDownload && gifState === 6) {
      // if (debug) window.console.log('imageDownload ---->', imageDownload)
      saveExperienceImages()
        .then((res) => {
          // Move next only if experience is saved
          // if (res.status === 200) moveNext()
          if (res.status === 404) {
            openRestartModal()
          } else if (res.status === 200) {
            changeLocation('share-page')
          } else {
            openGenericRestartModal()
          }
        })
      setGifState(7)
    }
  }, [gifState, imageDownload])

  return (
    <>
      <div
        className={classNames({
          [classes.root]: true,
        })}
        ref={$root}
      >
        <div className={classes.spinnerContainer}>
          <svg
            className={classes.spinner}
            viewBox="0 0 50 50"
            width="50"
            height="50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        </div>
      </div>
      {load && (
      <div style={{ width: '400px', height: '400px' }} ref={$canvasWrapper}>
        <Canvas
          colorManagement={false}
          pixelRatio={2}
          gl={{ antialias: false, preserveDrawingBuffer: true }}
          camera={{ fov: 50, position: [0, 0, 10], near: 0.1, far: 201 }}
          onCreated={({ camera, gl }) => {
            $canvas.current = gl.domElement
            camera.lookAt(0, 0, 0)
          }}
          className={classes.canvas}
        >
          <Suspense fallback={null}>
            {/* Background */}
            <mesh position={[0, 0, -190]}>
              <planeBufferGeometry args={[190, 190, 1, 1]} />
              <meshBasicMaterial color="#ffffff" />
            </mesh>

            {/* Logo */}
            <Logo
              visible={gifState < 4}
            />

            {/* Sfera */}
            { gifState !== 3 && (
              <Main settings={emotions} />
            )}

            {/* Frame 0 */}
            <Text3D
              position={[0, 75, 0]}
              text={strings['gif.frame0.top']}
              onReady={handleTextReady}
              visible={gifState === 0}
            />
            <Text3D
              position={[0, 65, 0]}
              text={strings['gif.frame0.bottom']}
              onReady={handleTextReady}
              visible={gifState === 0}
            />

            {/* Frame 1 */}
            <Text3D
              position={[0, 75, 0]}
              text={strings['gif.frame1.top']}
              onReady={handleTextReady}
              visible={gifState === 1}
            />
            <Text3D
              position={[0, 65, 0]}
              text={strings['gif.frame1.bottom']}
              onReady={handleTextReady}
              visible={gifState === 1}
            />

            {/* Frame 2 */}
            <Text3D
              position={[0, 75, 0]}
              text={strings['gif.frame2.top']}
              onReady={handleTextReady}
              visible={gifState === 2}
            />
            <Text3D
              position={[0, 65, 0]}
              text={strings['gif.frame2.bottom']}
              onReady={handleTextReady}
              visible={gifState === 2}
            />
            <Text3D
              layout="big"
              text={totalUplift}
              onReady={handleTextReady}
              visible={gifState === 2}
            />

            {/* Frame 3 */}
            <Text3D
              position={[0, 75, 0]}
              text={strings['gif.frame3.top']}
              onReady={handleTextReady}
              visible={gifState === 3}
            />
            <Text3D
              position={[0, 65, 0]}
              text={strings['gif.frame3.bottom']}
              onReady={handleTextReady}
              visible={gifState === 3}
            />
            <Text3D
              layout="big"
              text={totalUplift}
              onReady={handleTextReady}
              visible={gifState === 3}
            />

            { /* Download */ }
            <Text3D
              position={[0, 75, 0]}
              text={`${totalUplift} ${strings['general.image.download.text1']}`}
              onReady={handleTextReady}
              visible={gifState === 5}
            />
            <Text3D
              position={[0, 65, 0]}
              text={strings['general.image.download.text2']}
              onReady={handleTextReady}
              visible={gifState === 5}
            />

          </Suspense>
        </Canvas>
      </div>
      )}
    </>
  )
}

export default ResultsSaveThumb
