import { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import injectSheet from 'react-jss'
import { Context } from '@/context'
import style from './style'

const AudioWave = ({
  classes,
  className,
  isActive,
  isHover,
}) => {
  const { headerInverse, headerExtraDark } = useContext(Context)
  const [inverse, setInverse] = useState(headerInverse)
  const [extraDark, setExtraDark] = useState(headerExtraDark)

  useEffect(() => {
    setInverse(headerInverse)
    setExtraDark(headerExtraDark)
  }, [headerInverse, headerExtraDark])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
        [classes.inverse]: inverse,
        [classes.extraDark]: extraDark,
        [classes.active]: isActive,
        [classes.isHover]: isHover,
      })}
    >
      <svg viewBox="0 0 50 50" width="50" height="50">
        <path d="M26.31,15.06v19.87l-7.39-6.27h-2.98c-1.28,0-2.34-1.06-2.34-2.34v-2.76c0-1.28,1.06-2.34,2.34-2.34h2.98 L26.31,15.06z" />
        <path d="M30.88,20.83c0,0,1.27,1.73,1.27,4.38s-1.27,4.24-1.27,4.24" />
        <path d="M34.57,18.77c0,0,1.84,2.4,1.84,6.44s-1.84,6.55-1.84,6.55" />
      </svg>
    </div>
  )
}

export default injectSheet(style)(AudioWave)
