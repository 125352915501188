import { useEffect, useCallback, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import FakeRouter from '@/components/FakeRouter'
import StartingSoon from '@/pages/StartingSoon'
import Expired from '@/pages/Expired'
import Login from '@/pages/Login'
import DeniedAccess from '@/pages/DeniedAccess'
import Begin from '@/pages/Experience/Begin'
import ChooseEvent from '@/pages/Experience/ChooseEvent'
//import Steps from '@/pages/Experience/Steps'
import CountdownFaceScan from '@/pages/Experience/CountdownFaceScan'
import FaceScanCamera from '@/pages/Experience/FaceScanCamera'
import CountdownQuestions from '@/pages/Experience/CountdownQuestions'
import Questions from '@/pages/Experience/Questions'
import ChooseAgeGender from '@/pages/Experience/ChooseAgeGender'
import ChooseSport from '@/pages/Experience/ChooseSport'
import Timer from '@/pages/Experience/Timer'
import ConfirmTimer from '@/pages/Experience/ConfirmTimer'
import Results from '@/pages/Experience/Results'
import ResultsSaveThumb from '@/pages/Experience/ResultsSaveThumb'
import SharePage from '@/pages/Experience/SharePage'
import FacialScanDenied from '@/pages/Experience/FacialScanDenied'
import Debug from '@/pages/Experience/Debug'
import Blank from '@/pages/Experience/Blank'
import EmailModal from '@/components/EmailModal'
import RestartModal from '@/components/RestartModal'
import GenericRestartModal from '@/components/GenericRestartModal'
import usePagePrecache from '@/hooks/usePagePrecache'
import useStore from '@/base/zustand'
import * as routerActions from '@/actions/fakerouter'
import * as eventsActions from '@/actions/events'
import * as experienceActions from '@/actions/experience'
import { getYYYYMMDD } from '@/utils/date'

const Experience = () => {
  const { setFirstTimeAnimation, setScales, setActive } = useStore()
  const location = useLocation()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { fakeLocation, isStartingExperience, currentLanguage, currentTag } = useSelector((state) => ({
    fakeLocation: state.fakerouter.location,
    isStartingExperience: state.experience.isStartingExperience,
    currentLanguage: state.locale.currentLanguage,
    currentTag: state.events.currentTag,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const changeLocation = useCallback((slug) => dispatch(routerActions.changeLocation(slug)), [dispatch])
  const fetchEvents = useCallback((date, tag) => dispatch(eventsActions.fetchEvents(date, tag)), [dispatch])
  const fetchExperience = useCallback((uuid) => dispatch(experienceActions.fetchExperience(uuid)), [dispatch])
  const setEvent = useCallback((id, name) => dispatch(experienceActions.setEvent(id, name)), [dispatch])


  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false)
  const [load] = usePagePrecache({ // eslint-disable-line
    init: true,
    sources: [],
    callback: () => setRealLoad(true),
  })

  useEffect(() => {
    if (realLoad) {
      // Fetch all Daily Events
      const today = getYYYYMMDD(new Date())
      fetchEvents(today, `country_all,${currentTag}`)
      setEvent('1QNJUjr8Sr9ZWMJ8lOaWAF','Mindrace')
    }
  }, [realLoad, currentTag])



  /*------------------------------
  Reset Zustand Results if Starting Experience
  ------------------------------*/
  useEffect(() => {
    if (isStartingExperience) {
      setFirstTimeAnimation(true)
      setScales([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
      setActive(-1)
    }
  }, [isStartingExperience])

  return currentLanguage && (
    <>
      <FakeRouter location={fakeLocation}>
        <Expired key="expired"/>
        <StartingSoon key="startingSoon"/>
        <Login key="login"/>
        <DeniedAccess key="access-denied"/>
        <Begin key="begin" />
        <ChooseEvent key="choose-event" />
        {/*<Steps key="steps" />*/} 
        <CountdownFaceScan key="countdown-face-scan" />
        <FaceScanCamera first={true} key="face-scan-camera-1" />
        <CountdownQuestions key="countdown-questions" />
        <ChooseAgeGender key="choose-age-gender" />
        <Questions key="questions-1" />
        <ChooseSport key="choose-sport" />
        <Timer key="timer" />
        <ConfirmTimer key="confirm-timer" />
        <FaceScanCamera key="face-scan-camera-2" />
        <Questions key="questions-2" />
        <Results key="results" />
        <ResultsSaveThumb key="results-save-thumb" />
        <SharePage key="share-page" />
        <FacialScanDenied key="facial-scan-denied" />
        <Debug key="debug" />
        <Blank key="blank" />
      </FakeRouter>
      {/* <EmailModal /> */}
      <RestartModal />
      <GenericRestartModal />
    </>
  )
}

export default Experience
