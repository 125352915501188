const style = (theme) => {
  const output = {
    formControl: {
      padding: '0 10px',
      marginBottom: 30,
      position: 'relative',
      zIndex: 1,
    },
    formControlSelect: {
      width: '100%',
      marginBottom: 30,
      zIndex: 2,
    },

    // Select
    select: {
      '& .customSelect__control': {
        color: theme.colors[3],
        background: 'transparent',
        fontSize: 14,
        fontFamily: theme.fonts[1],
        borderRadius: 30,
        '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
        padding: '8px 18px 8px 26px',
        border: `1px solid ${theme.colors[3]}`,
        overflow: 'hidden',
        '&:hover': {
          border: `1px solid ${theme.colors[3]}`,
        },
      },
      '& .customSelect__placeholder': {
        color: theme.colors[3],
      },
      '& .customSelect__single-value': {
        color: theme.colors[3],
      },
      '& .customSelect__menu': {
        color: theme.colors[3],
        background: theme.colors[1],
        fontSize: 14,
        fontFamily: theme.fonts[1],
        borderRadius: 30,
        overflow: 'hidden',
        '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
        textAlign: 'left',
        border: `1px solid ${theme.colors[3]}`,
        padding: [5, 12],
      },
      '& .customSelect__value-container': {
        padding: 0,
      },
      '& .customSelect__indicator-separator': {
        display: 'none',
      },
      '& .customSelect__dropdown-indicator': {
        '& svg': {
          fill: theme.colors[3],
        },
      },
      '& .customSelect__control--is-focused': {
        border: `1px solid ${theme.colors[3]}`,
        boxShadow: 'none',
      },
      '& .customSelect__option': {
        borderRadius: 20,
        fontSize: 14,
        padding: [12, 16],
      },
      '& .customSelect__option:active, & .customSelect__option--is-focused': {
        background: theme.getRgba(theme.colors[3], 0.2),
        color: theme.colors[3],
      },
      '& .customSelect__option--is-selected, & .customSelect__option--is-selected:active': {
        background: theme.getRgba(theme.colors[3], 0.4),
        color: theme.colors[3],
      },

      // Container has value
      '& .customSelect__value-container--has-value': {
        background: theme.colors[3],
        boxShadow: `0 0 0 100px ${theme.colors[3]}`,
        '& .customSelect__single-value': {
          color: theme.colors[1],
        },
        '& + .customSelect__indicators': {
          position: 'relative',
          zIndex: 1,
          pointerEvents: 'none',
          '& svg': {
            fill: theme.colors[1],
          },
        },
      },
    },

    withIcon: {
      '& $select': {
        '& .customSelect__value-container--has-value': {
          paddingLeft: 35,
          position: 'relative',
          zIndex: 1,
          overflow: 'visible',
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            left: -22,
            width: 44,
            height: 44,
            display: 'block',
            backgroundImage: 'var(--icon)',
            backgroundSize: '90%',
            backgroundPosition: 'center',
            backgroundColor: theme.colors[1],
            borderRadius: '50%',
            overflow: 'hidden',
          },
        },
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    select: {
      '& .customSelect__control': {
        fontSize: 16,
      },
      '& .customSelect__menu': {
        fontSize: 16,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    formControl: {
      width: '100%',
    },
  }

  return output
}

export default style
