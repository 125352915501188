import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import stylo from './style'
import useStore from '@/base/zustand'

const useStyles = createUseStyles(stylo)

const tempCopy = [
  'The Pre and Post-Exercise scores are averages of all 10 metrics scores using the facial scan and question data you provided before and after exercise.',
  'This is a metric, an understanding of your emotional state based on your facial scan and how you answered the questions pre and post-exercise.',
  'This percentage shows your overall Uplift after your workout:<br />Uplift = (Post Exercise Score - Pre Exercise Score) ÷ (Pre Exercise Score x 100)',
]

const Overlay = ({ style, isVisible, children }) => {
  const classes = useStyles({ })
  return (
    <div className={`${classes.overlay} ${isVisible ? classes.overlayVisible : ''}`} style={style}>
      {children}
    </div>
  )
}

const Tooltip = ({ style, isVisible, children }) => {
  const {
    setTooltipOpen,
    tooltipStep,
    setTooltipStep,
    setTooltipText,
  } = useStore()

  const onNextTipClick = () => {
    setTooltipOpen(false)
    const nextStep = tooltipStep + 1
    setTimeout(() => {
      setTooltipStep(nextStep)
    }, 400)
    setTimeout(() => {
      setTooltipOpen(true)
    }, 750)
  }
  const onCloseClick = () => {
    setTooltipOpen(false)
  }
  useEffect(() => {
    setTooltipText(tempCopy[tooltipStep % tempCopy.length])
  }, [tooltipStep])

  const classes = useStyles({ })
  return (
    <div className={`${classes.tooltip} ${isVisible ? classes.tooltipVisible : ''} tooltip${tooltipStep}`} style={style}>
      {/* <p>
        {children}
      </p> */}
      <p dangerouslySetInnerHTML={{ __html: children }} />
      <div className={classes.buttonRow}>
        {(tooltipStep < 2) && (
          <button
            className={`${classes.btn} white`}
            style={{ boxShadow: 'none' }}
            onClick={onNextTipClick}
          >
            <span>
              Next tip
            </span>
          </button>
        )}
        <button
          className={classes.btn}
          onClick={onCloseClick}
        >
          <span>
            Close
          </span>
        </button>
      </div>
    </div>

  )
}

const TooltipOverlay = () => {
  const {
    isTooltipOpen,
    tooltipBounds,
    tooltipText,
  } = useStore()

  // TODO: Replace with translations
  return (
    <>
      <Overlay
        isVisible={isTooltipOpen}
        style={{
          '--bounds-left': tooltipBounds.left,
          '--bounds-top': tooltipBounds.top,
          '--bounds-right': tooltipBounds.right,
          '--bounds-bottom': tooltipBounds.bottom,
        }}
      />
      <Tooltip
        isVisible={isTooltipOpen}
        style={{
          '--bounds-left': tooltipBounds.left,
          '--bounds-top': tooltipBounds.top,
          '--bounds-right': tooltipBounds.right,
          '--bounds-bottom': tooltipBounds.bottom,
        }}
      >
        {tooltipText}
      </Tooltip>
    </>
  )
}

export default TooltipOverlay
