import axios from 'axios'
import { API_BASE_URL } from '@/constants'

const createAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

axios.defaults.baseURL = API_BASE_URL

export {
  createAxiosInstance,
}
