import { useRef, useContext, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'
import DelayLink from '@/components/DelayLink'
import Logo from '@/components/Logo'
import Burger from '@/components/Burger'
import AudioWave from '@/components/AudioWave'
import { Context } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { Context as AudioContext } from '@/context/audio'
import { useRaf, useResize } from '@/components/Handlers'
import * as layerActions from '@/actions/layer'
import style from './style'

import { API,SITE } from '@/constants'

const useStyles = createUseStyles(style)

const Header = ({
  treshold,
}) => {
  const { setHeaderRef, setHeaderHeight, headerInverse, headerExtraDark, hideHeader, isLogoWhite, isBackButtonVisible, headerMap } = useContext(Context)
  const { isAudioActive, setAudioActive, isAudioControlsVisible } = useContext(AudioContext)
  const classes = useStyles({ headerInverse, headerExtraDark })
  const theme = useTheme()
  const [isMainButtonHover, setMainButtonHover] = useState(false)
  const [isAudioButtonHover, setAudioButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const $root = useRef()
  const rootBounds = useRef()
  const history = useHistory()
  const { scroll } = useContext(ScrollbarContext)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, isLayerOpen, isLoggedIn, fakeLocation, strings, user } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    isLayerOpen: state.layer.layerIsOpen,
    isLoggedIn: state.user.isLoggedIn,
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    user: state.user,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])
  const closeAllLayer = useCallback(() => dispatch(layerActions.closeAllLayer()), [dispatch])

  const handleMouseMoveMainButton = (e) => {
    if (e.type === 'mouseenter') setMainButtonHover(true)
    if (e.type === 'mouseleave') setMainButtonHover(false)
  }

  const handleMouseMoveAudioButton = (e) => {
    if (e.type === 'mouseenter') setAudioButtonHover(true)
    if (e.type === 'mouseleave') setAudioButtonHover(false)
  }

  // share header ref to using it in <Nav/> for accessibility purpose
  useEffect(() => {
    setHeaderRef($root)
  }, [])

  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderHeight($root.current.getBoundingClientRect().height)
    }
  })

  const handleMainMenuClick = () => {
    if (isLayerOpen) closeAllLayer()
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }

  const handleAudioClick = () => {
    setAudioActive(!isAudioActive)
  }

  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  useRaf(() => {
    if (scroll.current.y <= treshold && !isTop) setTop(true)
    if (scroll.current.y > treshold && isTop) setTop(false)
  }, [isTop])

  /*------------------------------
  Render BackButton
  ------------------------------*/
  const renderBackButton = () => {
    return isBackButtonVisible && (
      <button
        className={classNames({
          [classes.button]: true,
          [classes.buttonInverse]: true,
          [classes.hide]: isMenuOpen,
        })}
        onClick={() => history.goBack()}
        aria-label="go-back"
        ref={preventPressSpacebar}
      >
        <svg className={classes.arrow}><use xlinkHref="#ico-arrow" /></svg>
      </button>
    )
  }

  /*------------------------------
  Render Audio
  ------------------------------*/
  const renderAudioButton = () => {
    return isAudioControlsVisible && (
      <button
        className={classNames({
          [classes.button]: true,
          [classes.buttonInverse]: true,
          [classes.buttonInverse]: true,
          [classes.hide]: isMenuOpen,
        })}
        onClick={handleAudioClick}
        aria-label="toggle-audio"
        ref={preventPressSpacebar}
        onMouseEnter={handleMouseMoveAudioButton}
        onMouseLeave={handleMouseMoveAudioButton}
      >
        <AudioWave
          isActive={isAudioActive}
          isHover={isAudioButtonHover}
          color={theme.colors[3]}
        />
      </button>
    )
  }

  /*------------------------------
  Not Logged Header
  ------------------------------*/
  const renderNotLoggedHeader = () => {
    return (
      <div className={classes.notLoggedHeader}>
        <DelayLink to={API.OAUTH_URL} className={classes.link} targetReplace={true}> 
        {strings['form.login']} / {strings['form.signup']}
        </DelayLink>
      </div>
    )
  }

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = () => {
    return (
      <button
        className={classNames({
          [classes.button]: true,
          [classes.buttonMenuOpen]: isMenuOpen,
          [classes.buttonBurger]: headerMap,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={handleMouseMoveMainButton}
        onMouseLeave={handleMouseMoveMainButton}
      >
        <Burger
          isActive={isMenuOpen}
          isHover={isMainButtonHover}
          color={theme.colors[3]}
          headerInverse={headerInverse}
          headerMap={headerMap}
        />
      </button>
    )
  }

  return (
    <header
      className={classNames({
        [classes.root]: true,
        [classes.hide]: hideHeader,
      })}
      ref={$root}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div
            className={classNames({
              [classes.logo]: true,
              [classes.logoHidden]: isMenuOpen,
              [classes.hide]: headerMap,
              [classes.logoWhite]: isLogoWhite,
            })}
          >
            {isTop && <Logo />}
          </div>
          {/* <div className={classNames({[classes.nav]:true,[classes.noLogo]:!isTop})}  >
            {renderBackButton()}
            {renderAudioButton()}
            {isLoggedIn && renderBurgerButton()}
          </div> */}
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  treshold: 10,
  menuLabel: 'Menu',
  headerNav: [],
}

export default Header
