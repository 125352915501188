import axios from 'axios'
import { REQUEST_LOCALES, RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestLocales = () => ({ type: REQUEST_LOCALES })

const receiveLocales = (data) => ({
  type: RECEIVE_LOCALES,
  payload: data,
})

const fetchLocales = () => async (dispatch) => {
  dispatch(requestLocales())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/locales`, params)
  dispatch(receiveLocales(response.data.items))
}

const fetchExistentLanguage = () => {
  const currentLanguage = localStorage.getItem('language') || null
  return ({
    type: SET_LANGUAGE,
    payload: currentLanguage,
  })
}

const setLanguage = (code) => {
  localStorage.setItem('language', code)
  return ({
    type: SET_LANGUAGE,
    payload: code,
  })
}

export {
  fetchLocales,
  fetchExistentLanguage,
  setLanguage,
}
