import { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const scrollIcon = ({
  className,
  visible,
  inverse,
}) => {
  const classes = useStyles()
  const $root = useRef()

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    if (visible) {
      gsap.to($root.current, {
        duration: 1.3,
        opacity: 1,
        y: '0%',
        ease: 'power3.out',
        delay: 0.6,
      })
    } else {
      gsap.to($root.current, {
        duration: 1.3,
        opacity: 0,
        y: '100%',
        ease: 'power3.inOut',
      })
    }
  }, [visible])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
        [classes.visible]: visible,
        [classes.inverse]: inverse,
      })}
      ref={$root}
    >
      <svg className={classes.arrow}><use xlinkHref="#ico-arrow" /></svg>
    </div>
  )
}

/*------------------------------
Default Props
------------------------------*/
scrollIcon.defaultProps = {
  className: '',
  visible: true,
}

export default scrollIcon
