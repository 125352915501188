import axios from 'axios'
import { REQUEST_STEPS, RECEIVE_STEPS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestSteps = () => ({ type: REQUEST_STEPS })

const receiveSteps = (data) => ({
  type: RECEIVE_STEPS,
  payload: data,
})

const fetchSteps = () => async (dispatch, getState) => {
  dispatch(requestSteps())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'steps',
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const orderedSteps = response.data.items
    .map((it) => it.fields)
    .sort((a, b) => a.order - b.order)
  dispatch(receiveSteps(orderedSteps))
}

export {
  fetchSteps,
}
