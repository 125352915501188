import { TextureLoader } from 'three'
import { useLoader } from 'react-three-fiber'

const Logo = () => {
  const texture = useLoader(TextureLoader, '/frontend/static/images/share/asics-logo.png')

  return (
    <mesh position={[0, -3.2, 0]}>
      <planeBufferGeometry args={[2.6, 2.6]} />
      <meshBasicMaterial
        transparent={true}
        map={texture}
      />
    </mesh>
  )
}
export default Logo
