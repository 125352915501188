import { RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'

const initialState = {
  currentLanguage: null,
  languages: [],
  schema: {
    'en-US': { title: 'English', code: 'en', mapCode: 'en' },
    zh: { title: '汉语, 漢語', code: 'zh', mapCode: 'zh' },
    de: { title: 'Deutsch', code: 'de', mapCode: 'de' },
    es: { title: 'Español', code: 'es', mapCode: 'es' },
    fr: { title: 'Française', code: 'fr', mapCode: 'fr' },
    it: { title: 'Italiano', code: 'it', mapCode: 'it' },
    ja: { title: '日本語', code: 'ja', mapCode: 'ja' },
    nl: { title: 'Nederlands', code: 'nl', mapCode: 'en' },
    pl: { title: 'Polski', code: 'pl', mapCode: 'en' },
    ru: { title: 'русский язык', code: 'ru', mapCode: 'ru' },
    pt: { title: 'Português', code: 'pt', mapCode: 'pt' },
    tr: { title: 'Türkçe', code: 'tr', mapCode: 'tr' },
    'pt-BR': { title: 'Português (BR)', code: 'br', mapCode: 'pt' },
    ko: { title: '한국어', code: 'ko', mapCode: 'ko' },
    'zh-Hans-HK': { title: '香港', code: 'hk', mapCode: 'zh-Hans' },
    'zh-Hant-TW': { title: '臺灣話', code: 'tw', mapCode: 'zh-Hant' },
  },
  strings: {
    title: 'Choose Language',
    select: 'Select a Language',
    cta: 'Continue',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOCALES:
      state = {
        ...state,
        languages: action.payload,
      }
      break
    case SET_LANGUAGE:
      state = {
        ...state,
        currentLanguage: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
