const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      backgroundPosition: 'center center',
      backgroundRepeatX: 'no-repeat',
      backgroundRepeatY: 'repeat',
      backgroundSize: 'contain',
    },
    page: {
      overflow: 'hidden',
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
      height: 46,
      padding: [15, 50],
      '& span': {
        fontSize: 18,
        lineHeight: '16px',
      }
    },
    ghostBtn: {
      extend: theme.button,
      backgroundColor: 'transparent',
      color: '#485BC7',
      border: '1px solid #485BC7',
      transition: 'all 350ms',

      '&:hover': {
        backgroundColor: '#485BC7',
        color: '#fff',
      }
    },

    // Hero
    hero: {
      color: theme.colors[3],
      textAlign: 'left',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      minHeight: 'var(--vh)',
    },

    // Find Out Link
    findOutButton: {
      '& a':{
        textDecoration: 'underline',
        fontWeight: 'bold',
        color: '#485bc7',
      }
    },

    buttonSeparator: {
      height: 40,
    },

    // First Time
    firstTimeText: {
      marginBottom: 10,
    },

    // Begin
    begin: {
      padding: [170, 0, 80],
      position: 'relative',
      zIndex: 3,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '74%',
      margin: [0, 'auto'],

      '& > div:first-child': {
        width: '41%',
      },
    },
    wrapperInside: {
      paddingLeft: 110,
      zIndex: 2,
    },
    beginTitle: {
      extend: [theme.titleSize],
      textAlign: 'left',
      marginBottom: 43,
      width: '70%',
      fontWeight: 'bold',
      fontSize: 54,
      lineHeight: 59,
    },
    beginText: {
      maxWidth: 700,
      margin: [0, 0, 25],
      textAlign: 'left',
      lineHeight: '100%',
      '& a': {
        '--color': theme.colors[3],
        color: 'currentColor',
        extend: theme.link,
      },
      '& p': {
        marginBottom: 10,
      }
    },
    beginDisclaimer: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 30,
      maxWidth: 500,
      fontSize: 14,
      margin: [0, 'auto'],
      opacity: 0.5,
      '& a': {
        '--color': theme.colors[3],
        extend: theme.link,
      },
    },
    introRight: {
      zIndex: 1,

      '& h1': {
        fontSize: 100,
        '-webkit-text-stroke-width': '2px',
        '-webkit-text-stroke-color': '#485bc7',
        color: 'transparent',
        letterSpacing: 5,
      },
    },
    percentage: {
      marginTop: '-25px!important',
      textAlign: 'right',
    },
    score: {
      position: 'absolute',
      right: '13%',
      bottom: '2%',

      '& h1': {
        fontWeight: 'normal',
        fontSize: 70,
        marginBottom: '-10px',
        letterSpacing: 0,
      }
    },
    introButtonWrapper: {
      display: 'inline-block',
      textAlign: 'center',
    },
  }

  // Needed custom screen size
  output['@media (max-width: 1600px)'] = {
    wrapperInside: {
      width: '50%',
    },
    beginTitle: {
      width: '100%',
      fontSize: 40,
    },
    introRight: {
      width: '95%',

      '& h1': {
        fontSize: 60,
        '-webkit-text-stroke-width': '1px',
        letterSpacing: 3,
        textAlign: 'right',
      },

      '& img': {
        right: '-15%!important',
      },
    },
    percentage: {
      marginTop: '20px!important',
    },
    score: {
      '& h1': {
        fontWeight: 'normal',
        fontSize: 40,
        marginBottom: '0px',
        letterSpacing: 0,
      }
    },
  }

  // Needed custom screen size
  output['@media (max-width: 1750px)'] = {
    introRight: {
      '& h1': {
        fontSize: 85,
      },
    },
  }

  // HD Macbook
  output['@media (max-width: 1440px)'] = {
    wrapperInside: {
      width: '50%!important',
      paddingLeft: 90,
    }
  }

  // Ipad PRO
  output['@media (max-width: 1025px)'] = {
    wrapperInside: {
      width: '57%!important',
      paddingLeft: 0,
    }
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {}

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    // White Background
    beforeWhiteBackground: {
      '&:before': {
        width: '90%',
        left: '5%',
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%)',
      },
    },

    buttonSeparator: {
      height: 10,
    },

    // First Time
    firstTimeText: {
      fontSize: 13,
      marginBottom: 0,
    },

    // wrapper
    wrapper: {
      flexDirection: 'column',
      // paddingTop: (props) => `calc(${props.headerHeight}px - 20px)`,
      width: '90%',

      '& > div:first-child': {
        width: '100%',
      },
    },

    introButtonWrapper: {
      textAlign: 'center',

      '& > div': {
        width: 'auto!important',
      },

      '& button, & a': {
        transform: 'scale(.7)',
      },

      '& br': {
        display: 'none',
      }
    },

    // Begin
    beginText: {
      maxWidth: 360,
      margin: [0, 'auto', 0],
      padding: [0, 20],
      textAlign: 'center',
      fontSize: 13,
    },
    circles: {
      top: 50,
    },
    beginTitle: {
      width: '60%',
      paddding: 0,
      margin: 0,
      marginBottom: 15,
      textAlign: 'center',
      fontSize: 28,
    },
    wrapperInside: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 2,
      width: '100%!important',
      marginTop: -100,
    },
    beginDisclaimer: {
      position: 'relative',
      left: 'auto',
      transform: 'none',
      bottom: 'auto',
      maxWidth: '100%',
      fontSize: 14,
      marginTop: 40,
    },
    introRight: {
      width: '95%',
      marginTop: 40,
      marginBottom: -20,

      '& h1': {
        fontSize: 40,
        '-webkit-text-stroke-width': '1px',
        letterSpacing: 3,
        textAlign: 'right',
      },

      '& img': {
        transform: 'scale(2)',
        bottom: '30px!important',
        right: '15%!important',
      },
    },
    btn: {
      padding: [15, 35],
    },
    percentage: {
      marginTop: '0px!important',
    },
    score: {
      right: '10%',

      '& h1': {
        fontWeight: 'normal',
        fontSize: 32,
        marginBottom: '0px',
        letterSpacing: 0,
      }
    },
  }

  return output
}

export default style
