/* Polyfill */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import ResizeObserver from 'resize-observer-polyfill'
/* Accessibility */
import 'focus-visible'

import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import createStore, { history } from '@/base/store'
import '@/base/axios'
import tracking from '@/tracking'
import App from './app'

window.ResizeObserver = ResizeObserver

tracking()
objectFitImages()

const store = createStore()

const renderApp = (AppComponent) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
        <AppComponent />
      </JssProvider>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(
  renderApp(App),
  document.getElementById('root'),
)
