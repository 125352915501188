import { useRef, useContext, useEffect, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import { isFirefox } from 'react-device-detect'
import SmoothScroll from 'locomotive-scroll'
import { useTheme } from 'react-jss'
import { Context as ScrollbarContext } from '@/context/scrollbar'

const matchFirefox = (multiplier = 1, level = 2.5) => Math.round((isFirefox ? level * multiplier : multiplier) * 100) / 100

const LocomotiveScroll = forwardRef(({
  className,
  children,
  init,
  direction,
}, ref) => {
  const theme = useTheme()

  const $rootVirtual = useRef(null)

  const scrollRef = useRef()
  const { setScrollbar } = useContext(ScrollbarContext)

  useEffect(() => {
    if (
      $rootVirtual.current
      && init
      && !theme.detect.isIE11
    ) {
      scrollRef.current = new SmoothScroll({
        el: $rootVirtual.current,
        direction,
        smooth: false,
        smoothMobile: false,
        getDirection: true,
        getSpeed: true,
        repeat: false,
        touchMultiplier: 2,
        multiplier: matchFirefox(1.2),
      })
      setScrollbar(scrollRef.current)
    }
    return () => {
      if (
        init
        && scrollRef.current
      ) scrollRef.current.destroy()
    }
  }, [$rootVirtual, init])

  useImperativeHandle(ref, () => ({
    ref: $rootVirtual.current,
  }))

  return (
    <div
      className={classNames({
        [className]: className,
        'smooth-scroll': true,
      })}
      ref={$rootVirtual}
      data-scroll-container
    >
      {children}
    </div>
  )
})

export default LocomotiveScroll
