import axios from 'axios'
import { REQUEST_QUESTIONS, RECEIVE_QUESTIONS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestQuestions = () => ({ type: REQUEST_QUESTIONS })

const receiveQuestions = (data) => ({
  type: RECEIVE_QUESTIONS,
  payload: data,
})

const fetchQuestions = (step) => async (dispatch, getState) => {
  dispatch(requestQuestions())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: step===1?'preQuestions':'postQuestions',                     // to test updated content, change as questions
      locale: getState().locale.currentLanguage,
      'fields.slug':step===1?'pre-questions':'post-questions',
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params); 

  // let orderedQuestions = response.data.items.map((it) => it.fields)
  //   .sort((a, b) => a.id - b.id);
  dispatch(receiveQuestions(response.data.items[0].fields))

  // let exertionItem = orderedQuestions.pop();        // remove exertion from end of list

  // if(step===2){                                     // add exertion at the top of list from 2nd set of questions
  //   orderedQuestions.unshift(exertionItem)
  // }
}

export {
  fetchQuestions,
}
