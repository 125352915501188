import { useEffect, useState, useCallback } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import DelayLink from '@/components/DelayLink'
import classNames from 'classnames'
import { cleanOrigin } from '@/utils/path'
import { convertRichText } from '@/utils/strings'

import style from '../style'

const CheckboxField = ({
  classes,
  label,
  name,
  onBlur,
  // required,
  type,
  value,
  error,
  touched,
  submitCount,
  setFieldValue,
}) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings } = useSelector((state) => ({
    strings: state.options.strings,
  }), shallowEqual)

  const [transformedContent, setTransformedContent] = useState()

  const transformContent = useCallback(() => {
    const transform = (node, i) => { // eslint-disable-line
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <DelayLink
            key={i.toString()}
            to={cleanOrigin(node.attribs.href)}
          >
            {node.children[0].data}
          </DelayLink>
        )
      }
    }
    const realLabel = typeof strings[`form.fields.${name}`] === 'string' ? strings[`form.fields.${name}`] : convertRichText(strings[`form.fields.${name}`])
    setTransformedContent(ReactHtmlParser(realLabel, { transform }))
  }, [strings])

  useEffect(() => {
    transformContent()
  }, [strings])

  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlCheckbox]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label
        htmlFor={name}
        className={classes.checkbox}
      >
        <input
          id={name}
          type={type}
          onChange={() => {
            setFieldValue(name, !value)
          }}
          onBlur={onBlur}
          checked={value || false}
        />
        <span className={classes.label}>
          {transformedContent}
        </span>
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

export default injectSheet(style)(CheckboxField)
