const style = (theme) => {
  const output = {
    root: {
      width: 50,
      height: 50,
      border: `1px solid ${theme.colors[3]}`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    arrow: {
      width: 50,
      height: 50,
      animation: '$scroll .7s infinite alternate',
      '& *': {
        fill: 'none',
        stroke: theme.colors[3],
        strokeWidth: 1,
      },
    },
    inverse: {
      border: `1px solid ${theme.colors[1]}`,
      '& $arrow': {
        '& *': {
          stroke: theme.colors[1],
        },
      },
    },
    '@keyframes scroll': {
      '0%': {
        transform: 'translateY(1px)',
      },
      '100%': {
        transform: 'translateY(-1px)',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
