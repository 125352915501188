const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      width: '45vw',
      left: 'calc(50% - 22.5vw)',
      top: (props) => `calc(50% - ${props.modalHeight / 2}px)`,
      background: theme.colors[1],
      color: theme.colors[3],
      zIndex: theme.zindex.modal,
      padding: 50,
      borderRadius: 22,
      opacity: 0,
      transform: 'translateY(50px)',
      visibility: 'hidden',
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 40,
      marginBottom: 17,
      textAlign: 'center',
    },
    text: {
      textAlign: 'center',
      fontSize: 20,
      lineHeight: '22px',
      marginBottom: 30,
    },
    secondText: {
      marginTop: 30,
      maxWidth: '70%',
    },
    modalImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -60,
      marginBottom: -50,
    },
    strokeText: {
      fontSize: 64,
      margin: 0,
      '-webkit-text-stroke-width': '2px',
      '-webkit-text-stroke-color': '#485bc7',
      color: 'transparent',
      '& p': {
        marginBottom: 0,
      }
    },
    score: {
      fontSize: 64,
      margin: 0,
      marginTop: -10,
      '-webkit-text-stroke-width': '2px',
      '-webkit-text-stroke-color': '#485bc7',
      color: 'transparent',
    },
    btn: {
      extend: theme.button,
      display: 'block !important',
      marginTop: 20,
      width: 280,
      maxWidth: '100%',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '90vw',
      left: 'calc(50% - 45vw)',
      padding: [30, 20],
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 24,
    },
    modalImage: {
      '& h1': {
        fontSize: 30,
        fontWeight: 300,
        '-webkit-text-stroke-width': '1px',
      },
    },
    text: {
      maxWidth: '100%',
      fontSize: 16,
    },
  }

  return output
}

export default style