import injectSheet from 'react-jss'
import classNames from 'classnames'

import style from '../style'

const TextareaField = ({
  classes,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  value,
  error,
  touched,
  withLabel,
  submitCount,
}) => {
  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlTextarea]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label>
        {withLabel && (
          <span className={classes.label}>
            {label}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <textarea
          className={classes.textarea}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''}
          rows={3}
        />
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

TextareaField.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(TextareaField)
