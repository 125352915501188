const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      paddingBottom: 140,
    },
    title: {
      fontSize: 14,
      fontFamily: theme.fonts[1],
      fontWeight: 'bold',
      lineHeight: 1.25,
      marginBottom: 20,
      color: 'var(--color)',
    },
    ball: {
      display: 'block',
      margin: 'auto',
      background: 'var(--color)',
      borderRadius: '50%',
      width: 210,
      height: 210,
    },
    galleryControls: {
      position: 'absolute',
      zIndex: 1,
      bottom: -4,
      right: '25vw',
      display: 'flex',
      alignItems: 'center',
    },
    arrowWrapper: {
      position: 'relative',
      display: 'flex',
      '& .arrow': {
        position: 'relative',
        top: 0,
        zIndex: 10,
        clear: 'both',
        width: '50px',
        height: '50px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: `1px solid ${theme.colors[3]}`,
        borderRadius: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: 10,
        margin: '8px 0',
        padding: '0',
        transition: 'opacity 0.3s ease-out, background 0.3s ease-out',
        '& svg': {
          width: 'auto',
          height: '10px',
          transform: 'translateX(1px)',
          stroke: 'none',
          fill: theme.colors[3],
          transition: 'fill .3s ease-out',
        },
        '&:not(:last-child)': {
          marginRight: '14px',
          '& svg': {
            transform: 'translateX(-1px)',
          },
        },
        '&:active:focus': {
          background: theme.colors[3],
          '& svg': {
            fill: theme.colors[1],
          },
        },
      },
    },
    hide: {
      opacity: '0.4',
      cursor: 'default',
    },
    slide: {
    },
    scrollbar: {
      position: 'absolute',
      zIndex: 1,
      left: '25vw',
      bottom: 27,
      width: 'calc(50vw - 150px)',
      height: 2,
      background: theme.getRgba(theme.colors[2], 0.15),
      display: 'flex',
    },
    scrollThumbPre: {
      background: theme.colors[3],
    },
    scrollthumb: {
      flex: '1 0 auto',
      background: theme.colors[3],
      transform: 'scaleX(0)',
      transformOrigin: '0 0',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      '& .swiper-container': {
        overflow: 'hidden',
      },
      '& .swiper-slide': {
        padding: [0, 20],
        '&:nth-child(2n + 2)': {
          paddingTop: 0,
        },
      },
    },
    ball: {
      width: 100,
      height: 100,
    },
    scrollbar: {
      display: 'none',
    },
    galleryControls: {
      right: 0,
      left: 0,
      margin: 'auto',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      paddingBottom: 120,
    },
    title: {
      marginBottom: 10,
    },
    text: {
      fontSize: 13,
    },
  }

  return output
}

export default style
