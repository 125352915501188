const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      backgroundSize: 'cover!important',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    // Hero
    hero: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      minHeight: 'var(--vh)',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: [100, 0, 0, 0],
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 36,
      marginBottom: 30,
    },
    text: {
      maxWidth: 500,
      margin: [0, 'auto', 40],
      color: theme.colors[3],
    },
    box: {
      maxWidth: 490,
      margin: [0, 'auto'],
    },
    buttons: {
      marginTop: 50,
      marginBottom: 40,
      '& $btn': {
        margin: [0, 10],
      },
    },
    hint: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      color: theme.colors[3],
      fontSize: 14,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      fontSize: 24,
      marginBottom: 35,
    },
    text: {
      fontSize: 14,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },
    hint: {
      padding: [0, 20],
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    buttons: {
      marginTop: 40,
      '& $btn': {
        margin: 10,
      },
    },
  }

  return output
}

export default style
