import Experience from '@/pages/Experience'
import Landing from '@/pages/Landing'
import Logout from '@/pages/Logout'
// import Login from '@/pages/Login'
// import Register from '@/pages/Register'
// import ResetPassword from '@/pages/ResetPassword'
// import ForgotPassword from '@/pages/ForgotPassword'
import Portal from '@/pages/Portal'
import Generic from '@/pages/Generic'
import WorldUpliftMap from '@/pages/WorldUpliftMap'
import NotFound from '@/pages/NotFound'
import { internazionalizePath } from '@/utils/path'
import ResultsEventPage from '@/pages/EventResults'
import NewQuestions from '../pages/Experience/NewQuestions'
import QuestionsCompleted from '../pages/Experience/QuestionsCompleted'
import QuestionsAfterEmail from '../pages/Experience/QuestionsAfterEmail'
import FinalQuestionsModal from '../pages/Experience/FinalQuestionsModal'

export default () => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Experience />,
  },
  {
    key: 1,
    path: internazionalizePath('/mind-uplift'),
    exact: true,
    render: () => <Landing />,
  },
  {
    key: 2,
    path: internazionalizePath('/ultra-uplift-questions'),
    exact: true,
    render: () => <NewQuestions />,
  },
  {
    key: 3,
    path: internazionalizePath('/questions-completed'),
    exact: true,
    render: () => <QuestionsCompleted />,
  },
  {
    key: 4,
    path: internazionalizePath('/logout'),
    exact: true,
    render: () => <Logout />,
  },
  {
    key: 5,
    path: internazionalizePath('/ultra-uplift-questions-final'),
    exact: true,
    render: () => <QuestionsAfterEmail />,
  },
  {
    key: 11,
    path: internazionalizePath('/questions-after-email-modal'),
    exact: true,
    render: () => <FinalQuestionsModal />,
  },
  // {
  //   key: 2,
  //   path: internazionalizePath('/login'),
  //   exact: true,
  //   render: () => <Login />,
  // },
  // {
  //   key: 3,
  //   path: internazionalizePath('/register'),
  //   exact: true,
  //   render: () => <Register />,
  // },
  // {
  //   key: 4,
  //   path: internazionalizePath('/forgot-password'),
  //   exact: true,
  //   render: () => <ForgotPassword />,
  // },
  // {
  //   key: 5,
  //   path: internazionalizePath('/reset-password'),
  //   exact: true,
  //   render: () => <ResetPassword />,
  // },
  {
    key: 6,
    path: internazionalizePath('/portal'),
    exact: true,
    render: () => <Portal />,
  },
  {
    key: 7,
    path: internazionalizePath('/privacy-notice'),
    exact: true,
    render: () => <Generic />,
  },
  {
    key: 8,
    path: internazionalizePath('/terms-conditions'),
    exact: true,
    render: () => <Generic />,
  },
  {
    key: 9,
    path: internazionalizePath('/facial-scan-info'),
    exact: true,
    render: () => <Generic />,
  },
  {
    key: 10,
    path: internazionalizePath('/world-uplift-map'),
    exact: true,
    render: () => <WorldUpliftMap />,
  },
  {
    key: 150,
    path: internazionalizePath('/event-uplift-results'),
    exact: false,
    render: () => <ResultsEventPage />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
