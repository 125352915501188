import { useEffect, useRef, useContext } from 'react'
import { array } from 'prop-types'
import { Context as LoadingContext } from '@/context/loading'
import FontFaceObserver from 'fontfaceobserver'

const useFontPreload = ({
  fonts,
}) => {
  const { isFontReady, setFontReady } = useContext(LoadingContext)
  const fontsToPreload = useRef([])

  useEffect(() => {
    if (!isFontReady && fonts.length > 0) {
      fonts.map((font) => {
        fontsToPreload.current.push(new FontFaceObserver(font))
        return null
      })
      Promise.all(fontsToPreload.current.map((f) => f.load())).then(() => {
        setFontReady(true)
      })
    }
  }, [])
}

useFontPreload.defaultProps = {
  fonts: [],
}

useFontPreload.propTypes = {
  fonts: array.isRequired,
}

export default useFontPreload
