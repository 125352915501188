import { RECEIVE_DAILY_EVENTS, RECEIVE_TOTAL_EVENTS,RECEIVE_WORLD_MAP_EVENTS, RECEIVE_EVENT_IMAGE, RECEIVE_TAGS, SET_COUNTRY_CODE, SET_CURRENT_TAG } from '@/actions/types'

const initialState = {
  worldMapEvents:[],
  totalEvents: [],
  tags: [],
  currentTag: '',
  countryCode: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_DAILY_EVENTS:
      state = {
        ...state,
        dailyEvents: action.payload,
      }
      break
    case RECEIVE_WORLD_MAP_EVENTS:
      state = {
        ...state,
        worldMapEvents: action.payload,
      }
      break
    case RECEIVE_TOTAL_EVENTS:
      state = {
        ...state,
        totalEvents: action.payload,
      }
      break
    case RECEIVE_EVENT_IMAGE:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    case RECEIVE_TAGS:
      state = {
        ...state,
        tags: action.payload,
      }
      break
    case SET_COUNTRY_CODE:
      if (action.payload === null) break
      state = {
        ...state,
        countryCode: action.payload.toLowerCase(),
      }
      break
    case SET_CURRENT_TAG:
      state = {
        ...state,
        currentTag: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
