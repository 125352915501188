const style = {
  logo: {
    display: 'block',
    lineHeight: 0,
  },
  logoButton: {
    cursor: 'pointer',
  },
}
export default style
