import gsap from 'gsap'
import usePrevious from '@/hooks/usePrevious'
import { useState, useEffect, Children, useRef } from 'react'

const FakeRouter = ({
  children,
  location,
}) => {
  const firstLocation = useRef(location)
  const [currentLocation, setCurrentLocation] = useState(firstLocation.current)
  const $wrapper = useRef()

  const prevLocation = usePrevious(location)
  useEffect(() => {
    if (prevLocation !== undefined) {
      gsap.timeline()
        .to($wrapper.current, {
          autoAlpha: 0,
          onComplete: () => setCurrentLocation(location),
        })
        .to($wrapper.current, { autoAlpha: 1, duration: 2 })
    }
  }, [location])

  return (
    <>
      <div ref={$wrapper}>
        {Children.map(children, (child) => {
          if (child.key === currentLocation) {
            return child
          }
          return null
        })}
      </div>
    </>
  )
}

export default FakeRouter
