const style = (theme) => {
  const output = {
    root: {
      willChange: 'clip-path',
      overflow: 'hidden',
      display: 'block',
      clipPath: 'inset(100% 0% 0%)',
      '& span': {
        display: 'block',
        willChange: 'transform',
      },
    },
    image: {
      willChange: 'transform',
      transformOrigin: 'center',
      transform: 'scale(1.3)',
      '& span': {
        paddingBottom: (props) => (props.padding ? `${props.padding * 100}%` : 0),
        position: (props) => (props.padding ? 'relative' : 'static'),
      },
      '& img': {
        position: (props) => (props.padding ? 'absolute' : 'static'),
        top: (props) => (props.padding ? 0 : 'auto'),
        left: (props) => (props.padding ? 0 : 'auto'),
        width: (props) => (props.padding ? '100%' : 'auto'),
        height: (props) => (props.padding ? '100%' : 'auto'),
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
