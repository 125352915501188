const global = (theme) => {
  const output = {
    root: {},
    background: {
      position: 'fixed',
      zIndex: -1,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundImage: 'url(./frontend/static/images/general/background.png)',
      backgroundSize: 'cover',
      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: 'all 0.5s ease-out',
        background: (props) => (props.headerInverse && !props.headerMap ? '#b7a438' : 'transparent'),
        mixBlendMode: 'difference',
      },
    },
    '@global': {
      body: {
        extend: theme.typography.general,
        color: theme.colors[0],
        background: theme.colors[1],
        overflowY: theme.detect.isNativeScroll ? 'visible' : 'hidden',
        overflowX: 'hidden',
        position: 'relative',
        zIndex: 1,
        '& a': {
          textDecoration: 'none',
          transition: 'color .3s ease-in-out',
        },
        '& button': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& input': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
      },
      '::selection': {
        background: theme.colors[12],
        color: theme.colors[1],
      },
    },
  }

  return output
}

export default global
