const style = (theme) => {
  const output = {
    '@global': {
      body: {
        background: theme.colors[3],
      },
    },
    root: {
      color: theme.colors[1],
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      overflow: 'hidden',
      background: '#3D50BC!important',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },
    downArrow: {
      position: 'absolute',
      bottom: 40,
      width: '100%',
      textAlign: 'center',
    },

    roundBtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& $circle': {
        width: 50,
        height: 50,
        margin: [10, 10],
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors[1],
        border: `1px solid ${theme.colors[1]}`,
        cursor: 'pointer',
        transition: `all .3s ${theme.easings['power3.out']}`,
      },
      '& svg': {
        fill: theme.colors[3],
        width: 25,
        height: 25,
        transition: `all .3s ${theme.easings['power3.out']}`,
      },
      '& $label': {
        fontSize: 14,
        color: theme.colors[1],
        position: 'relative',
        zIndex: 1,
        display: 'inline-block',
        '&::before': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          left: 0,
          bottom: 1,
          width: '100%',
          height: 1,
          backgroundColor: 'currentColor',
          transition: `transform .6s ${theme.easings['power3.out']}`,
          transform: 'scaleX(0)',
          transformOrigin: '100% 0',
        },
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& $circle': {
            background: 'transparent',
          },
          '& svg': {
            fill: theme.colors[1],
          },
          '& $label': {
            '&::before': {
              transform: 'scaleX(1)',
              transformOrigin: '0 0',
            },
          },
        },
      },
    },
    circle: {},
    label: {},
    circleSm: {
      '& svg': {
        width: 16,
        height: 16,
      },
    },

    roundIco: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme.colors[1]}`,
      cursor: 'pointer',
      '& svg': {
        fill: theme.colors[1],
      },
    },

    // Hero
    hero: {
      minHeight: 'var(--vh)',
      paddingTop: (props) => `${props.headerHeight}px`,
      paddingBottom: 80,
      paddingLeft: 30,
      paddingRight: 30,
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& $wrapper': {
        display: 'flex',
      },
    },
    heroBox: {
      flex: '0 0 50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    heroImageWrapper: {
      flex: 1,
    },
    heroImage: {
      flex: '0 0 50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    heroImg: {
      width: '100%',
      height: 0,
      paddingBottom: '75%',
      position: 'relative',
      zIndex: 1,
      '& img': {
        position: 'absolute',
        top: -40,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transform: 'scale(1.5)',
      },
    },
    heroImageText: {
      fontSize: 14,
      color: theme.colors[1],
    },
    ctaButtons: {
      poistion: 'relative',
      zIndex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      '& > *:not(:last-child)': {
        marginRight: 20,
      },
    },
    registerLink: {
      marginTop: 10,
      fontSize: 14,
      textAlign: 'center',
      '& a': {
        color: theme.colors[1],
        '--color': 'currentColor',
        extend: theme.linkInverse,
      },
    },

    title: {
      extend: theme.titleSize,
      fontFamily: theme.fonts[1],
      marginBottom: 30,
    },
    text: {
      fontSize: 18,
      marginBottom: 20,
    },
    hint: {
      fontSize: 14,
      color: theme.getRgba(theme.colors[1], 0.5),
      '& strong': {
        color: theme.colors[1],
      },
    },
    signinBtn: {
      extend: theme.button,
    },
    shareButtons: {
      position: 'relative',
      zIndex: 2,
      marginBottom: 40,
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        flexBasis: '25%',
      },
    },

    // Scroll Icon
    scrollIcon: {
      position: 'absolute',
      zIndex: 1,
      left: '3.2vw',
      top: 'calc(var(--vh) - 80px)',
    },

    // MindMap
    mindMap: {
      margin: [200, 0],
      display: 'flex',
      alignItems: 'center',
      '& $wrapper': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    mindMapBox: {
      flex: '0 0 40%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: 40,
      '& $title': {
        marginBottom: 50,
      },
      '& $text': {
        maxWidth: 420,
        marginBottom: 50,
      },
    },
    mindMapImage: {
      textAlign: 'right',
      flex: '0 0 50%',
      paddingRight: 60,
    },

    // Links
    links: {
      margin: [200, 0],
      '& $wrapper': {
        display: 'flex',
        justifyContent: 'space-around',
      },
    },
    link: {
      width: 230,
      margin: 30,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    linkImage: {
      width: 230,
      height: 230,
      marginBottom: 30,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    linkTitle: {
      fontSize: 18,
      fontFamily: theme.fonts[1],
      marginBottom: 20,
    },
    linkText: {
      fontSize: 18,
      marginBottom: 50,
      flexGrow: 1,
    },

    // Reload
    reload: {
      margin: [100, 0],
      textAlign: 'center',
      paddingBottom: 100,
    },
    reloadBtn: {
      textAlign: 'center',
      '& $roundIco': {
        background: theme.colors[1],
        border: 'none',
        '& svg': {
          fill: theme.colors[3],
          transform: 'translateX(1px)',
        },
      },
      '& span': {
        display: 'block',
        marginTop: 10,
        position: 'relative',
        zIndex: 1,
        fontSize: 18,
        fontFamily: theme.fonts[1],
        color: theme.colors[1],
        '&:before': {
          content: '""',
          background: theme.colors[1],
          position: 'absolute',
          zIndex: 1,
          left: 0,
          bottom: -1,
          height: 1,
          width: '100%',
          transition: `transform .6s ${theme.easings['power3.out']}`,
          transform: 'scaleX(0)',
          transformOrigin: '100% 0',
        },
      },
      '&:hover': {
        '& span': {
          '&:before': {
            transform: 'scaleX(1)',
            transformOrigin: '0% 0',
          },
        },
      },
    },
    tooltip: {
      position: 'relative',
      zIndex: 1,
      color: theme.colors[1],
      '&::after': {
        content: 'attr(data-tooltip)',
        position: 'absolute',
        zIndex: 1,
        fontSize: 10,
        bottom: -15,
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
        transition: 'opacity 0.3s ease-out',
        opacity: 0,
      },
    },
    tooltipVisible: {
      '&::after': {
        opacity: 1,
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output['@media (max-width: 1279px)'] = {
    // Hero
    hero: {
      paddingTop: (props) => `${props.headerHeight + 50}px`,
      display: 'block',
      '& $wrapper': {
        display: 'block',
      },
    },
    heroImage: {
      margin: [70, 'auto'],
      '& img': {
        maxWidth: 'none',
      },
    },

    // MindMap
    mindMap: {
      '& $wrapper': {
        display: 'block',
      },
    },
    mindMapImage: {
      textAlign: 'center',
      marginBottom: 40,
      paddingRight: 0,
    },
    mindMapBox: {
      textAlign: 'center',
      paddingLeft: 0,
      '& $text': {
        margin: [0, 'auto', 50],
      },
    },
    ctaButtons: {
      justifyContent: 'center',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {

    // Links
    links: {
      '& $wrapper': {
        display: 'block',
      },
    },
    link: {
      textAlign: 'center',
      margin: [80, 'auto'],
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    // Hero
    hero: {
      margin: [0, 0, 100],
      padding: [0, 30],
      marginBottom: 0,
      '& $wrapper': {
        padding: 0,
      },
    },
    heroImage: {
      marginTop: 150,
    },

    // Scrollicon
    scrollIcon: {
      display: 'none',
    },

    // MindMap
    mindMap: {
      padding: [0, 30, 100],
      '& $title, & $text': {
        marginBottom: 30,
      },
    },

    downArrow: {
      display: 'none',
    },

    // Links
    links: {
      marginTop: 0,
      marginBottom: 200,
    },

    // Reload
    reload: {
      marginBottom: 0,
    },
    reloadBtn: {
      '& span': {
        fontSize: 16,
      },
    },

    ctaButtons: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > *:not(:last-child)': {
        marginRight: 0,
        marginBottom: 20,
      },
    },

    shareButtons: {
      flexWrap: 'wrap',

      '& > *': {
        flexBasis: '25%',
        marginBottom: 10,
      },
    },
  }

  return output
}

export default style
