const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundPosition: 'center center',
      backgroundRepeatX: 'no-repeat',
      backgroundRepeatY: 'repeat',
      backgroundSize: 'contain',
    },
    hide: {
      pointerEvents: 'none',
      '& $logo': {
        pointerEvents: 'none',
      },
    },
    logoContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinnerContainer: {
      width: '260px',
      height: '260px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinner: {
      width: '260px',
      height: '260px',
      transformOrigin: 'center',
      animation: '$rotate 2s linear infinite',
      transition: 'all 0.3s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      '& .path': {
        stroke: theme.colors[3],
        strokeWidth: 0.3,
        fill: 'none',
        animation: '$dash 2.5s ease-in-out infinite',
      },
    },
    btnContainer: {
      pointerEvents: 'none',
      opacity: 0,
    },
    btn: {
      extend: theme.button,
    },
    '@keyframes rotate': {
      from: { transform: 'rotate(0)' },
      to: { transform: 'rotate(360deg)' },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
    langContainer: {
      // maxWidth: 490,
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    header: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1,
      pointerEvents: 'none',
      background: 'transparent',
      transition: `opacity 0.3s ${theme.easings['power4.out']}`,
      opacity: 0,
    },
    headerVisible: {
      opacity: 1,
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapperHeader,
      position: 'relative',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
    logo: {
      display: 'inline-block',
      pointerEvents: 'all',
      '& svg': {
        width: '100%',
        fill: theme.colors[3],
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    logo: {
      width: 100,
    },
    wrapper: {
      justifyContent: 'left',
    },
    container: {
      paddingTop: theme.header.topMobile,
    },
    langContainer: {
      maxWidth: 'auto',
      width: '100%',
    },
  }

  return output
}

export default style
