import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Context as GeneralContext } from "@/context";
import Meta from "@/components/Meta";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import RevealText from "@/components/RevealText";
import RevealFadeIn from "@/components/RevealFadeIn";
import TextField from "@/components/Form/Fields/TextField";
import usePagePrecache from "@/hooks/usePagePrecache";
import * as routerActions from "@/actions/fakerouter";
import * as experienceActions from "@/actions/experience";
import * as contentActions from "@/actions/content";
import * as eventsActions from "@/actions/events";
import * as layerActions from "@/actions/layer";
import { convertRichText } from "@/utils/strings";
import style from "./style";

const useStyles = createUseStyles(style);

const ChooseEvent = () => {
  const {
    setPageAnimationReady,
    pageAnimationReady,
    headerHeight,
    setLogoWhite,
  } = useContext(GeneralContext);
  const [isDataFetched, setDataFetched] = useState(false);
  const [fieldValue, setFieldValue] = useState(
    localStorage.getItem("lastSelectedEventName")
  );
  const [selectedEventImage, setSelectedEventImage] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [prize, setPrize] = useState(
    localStorage.getItem("checkPrizeBox") == "true"
  );
  const [featuredEventImage, setFeaturedEventImage] = useState("");
  const [isBrandedEvent, setIsBrandedEvent] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const $root = useRef();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, events, brandedEvent, fakeLocation, strings, user } =
    useSelector(
      (state) => ({
        page: state.content["event-page"] || {},
        events: state.events.dailyEvents,
        brandedEvent: state.events?.dailyEvents?.find(
          (ev) => ev.fields.branded
        ),
        eventLogo: state.events.eventLogo || {},
        fakeLocation: state.fakerouter.location,
        strings: state.options.strings,
        user: state.user,
      }),
      shallowEqual
    );

  const classes = useStyles({ headerHeight });

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  // const moveForward = useCallback(() => dispatch(routerActions.changeLocation('steps')), [dispatch])
  const moveBack = useCallback(
    () => dispatch(routerActions.changeLocation("login")),
    [dispatch]
  );
  const fetchEventImage = useCallback(
    (id) => dispatch(eventsActions.fetchEventImage(id)),
    [dispatch]
  );
  const fetchSelectedEventImage = useCallback(
    (id) => dispatch(eventsActions.fetchSelectedEventImage(id)),
    [dispatch]
  );
  const fetchEventLogo = useCallback(
    (id) => dispatch(eventsActions.fetchEventLogo(id)),
    [dispatch]
  );
  const setEvent = useCallback(
    (id, name) => dispatch(experienceActions.setEvent(id, name)),
    [dispatch]
  );
  const fetchContent = useCallback(
    (slug) => dispatch(contentActions.fetchContent(slug, "eventPage")),
    [dispatch]
  );
  const setEventLocation = useCallback(
    (coord) => dispatch(experienceActions.setEventLocation(coord)),
    [dispatch]
  );
  const setLottery = useCallback(
    (value) => dispatch(experienceActions.setLottery(value)),
    [dispatch]
  );
  const setPrizeMailBody = useCallback(
    (value) => dispatch(experienceActions.setPrizeMailBody(value)),
    [dispatch]
  );
  const openModal = useCallback(
    (modal, data = {}) =>
      dispatch(layerActions.openLayer({ id: modal, ...data })),
    [dispatch]
  );
  const fetchEventLocation = useCallback(
    (coord, city) => {
      dispatch(experienceActions.fetchEventLocation(coord, city));
    },
    [dispatch]
  );

  //
  let backgroundImage = `/frontend/static/images/general/page-background.png`;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      backgroundImage = `/frontend/static/images/general/page-mobile-background.png`;
    }
  };
  resizeBg();

  window.addEventListener("resize", () => {
    resizeBg();
  });

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent("event-page");
  }, []);

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (events && Object.keys(events).length > 0) setDataFetched(true);
    if (events) {
      setDataFetched(true);
    }
  }, [page, events]);
  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [
      // Object.keys(eventImage).length > 0 && { ...{ type: 'image', src: eventImage.url } },
    ],
    callback: () => setPageAnimationReady(true),
  });

  /*------------------------------
  Reset Logo White
  ------------------------------*/
  useEffect(() => {
    if (isBrandedEvent) setLogoWhite(true);
    return () => {
      setLogoWhite(false);
    };
  }, [isBrandedEvent]);

  useEffect(() => {
    const selectedEvent = events?.filter(
      (item) => item.fields?.title == fieldValue
    )[0];
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      fetchImageForSelectedEvent(selectedEvent.sys?.id);
    }
    localStorage.removeItem("lastSelectedEventName");
    localStorage.removeItem("checkPrizeBox");
  }, [fieldValue, events]);

  const handlePrizeCheckboxChange = () => {
    if (!user.isLoggedIn) {
      openModal("prize-login", { eventName: selectedEvent.fields.title });
      return;
    }

    setPrize(!prize);
  };

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />;
  };

  const handleClickNext = () => {
    moveForward();
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderChoose = () => {
    return (
      <div className={classes.page} style={{ background: `url(${backgroundImage}) center center no-repeat` }}>
        <div
          className={classNames({
            [classes.hero]: true,
          })}
        >
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <RevealText
                tag="h1"
                type="lines"
                value={"Denied Access."}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
            </div>
            <div className={classes.buttons}>
              <RevealFadeIn delay={1} inview={pageAnimationReady}>
                <button
                  onClick={moveBack}
                  className={classNames({
                    [classes.btn]: true,
                    disabled: false,
                  })}
                >
                  <span>{'Retry'}</span>
                </button>
              </RevealFadeIn>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>{renderChoose()}</div>
        </>
      )
    );
  };

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageChooseEvent ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  );
};
export default ChooseEvent;
