import {
  FAKEROUTER_CHANGE_LOCATION,
  FAKEROUTER_MOVE_FORWARD,
  FAKEROUTER_MOVE_BACKWARD,
  RESET_EXPERIENCE,
} from '@/actions/types'
import {SITE} from '@/constants'
const initialState = {
  release: 1,
  location: 'expired', // da cambiare se si vuole partire da un'altra pagina
  locations: [
    { id: 0, name: 'expired' },
    { id: 1, name: 'begin' },
    { id: 2, name: 'choose-event' },
    //{ id: 2, name: 'steps' },
    { id: 3, name: 'countdown-face-scan' },
    { id: 4, name: 'face-scan-camera-1' },
    { id: 5, name: 'countdown-questions' },
    { id: 6, name: 'choose-age-gender' },
    { id: 7, name: 'questions-1' },
    { id: 8, name: 'choose-sport' },
    { id: 9, name: 'timer' },
    { id: 10, name: 'confirm-timer' },
    { id: 11, name: 'face-scan-camera-2' },
    { id: 12, name: 'questions-2' },
    { id: 13, name: 'results' },
    { id: 14, name: 'results-save-thumb' },
    { id: 15, name: 'share-page' },
    { id: 16, name: 'facial-scan-denied' },
    { id: 17, name: 'debug' },
    { id: 18, name: 'blank' },
    { id: 19, name: 'access-denied' },
    { id: 20, name: 'startingSoon' },
  ],
}

export default (state = initialState, action) => {
  const currentIndex = state.locations.find((l) => l.name === state.location).id

  switch (action.type) {
    case FAKEROUTER_CHANGE_LOCATION:
      state = {
        ...state,
        location: action.payload,
        index: state.locations.find((l) => l.name === action.payload).id,
      }
      break
    case FAKEROUTER_MOVE_FORWARD:
      state = {
        ...state,
        location: state.locations.find((l) => l.id === currentIndex + 1).name,
        index: currentIndex + 1,
      }
      break
    case FAKEROUTER_MOVE_BACKWARD:
      state = {
        ...state,
        location: state.locations.find((l) => l.id === currentIndex - 1).name,
        index: currentIndex - 1,
      }
      break
    case RESET_EXPERIENCE:
      state = {
        ...state,
        location: 'begin',
        index: 0,
        startTime: 0,
        endTime: 0,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
