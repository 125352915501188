import { useEffect, useState, useCallback, forwardRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import DelayLink from '@/components/DelayLink'
import classNames from 'classnames'

const ParseText = forwardRef(({
  className,
  text,
  onReadyCallback,
}, ref) => {
  const [transformedContent, setTransformedContent] = useState()

  const transformContent = useCallback(() => {
    const transform = (node, i) => { // eslint-disable-line
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <DelayLink
            key={i.toString()}
            to={node.attribs.href}
          >
            {node.children[0]?.data}
          </DelayLink>
        )
      }
    }
    setTransformedContent(ReactHtmlParser(text, { transform }))
  }, [text])

  useEffect(() => {
    transformContent()
  }, [text])

  useEffect(() => {
    if (transformedContent && onReadyCallback) onReadyCallback()
  }, [transformedContent])

  return (
    <div
      ref={ref}
      className={classNames({
        [className]: className,
      })}
    >
      {transformedContent}
    </div>
  )
})

export default ParseText
