const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      minHeight: 'var(--vh)',
      paddingTop: 100,
      background: '#EDE04B',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
      textAlign: 'center',
      color: '#001E61',
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    btn: {
      extend: theme.button,
      background: '#001E61!important',
      color: '#E8DC4A!important',
    },

    title: {
      fontFamily: theme.fonts[0],
      fontSize: '10vw',
      zIndex: 1,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      color: 'rgba(0, 0, 0, 0)',
      WebkitTextFillColor: 'none',
      WebkitTextStrokeWidth: '2px',
      WebkitTextStrokeColor: '#001E61',
      textTransform: 'uppercase',
      '& span': {
        display: 'inline-block',
        width: '.66em',
        textAlign: 'center',
      },
    },
    text: {
      maxWidth: 420,
      margin: [30, 'auto', 10],
    },

    hint: {
      maxWidth: 360,
      margin: [10, 'auto'],
      color: '#001D5F',
      fontSize: 14,
    },

    timer: {
      marginTop: 60,
      marginBottom: 20,
      fontFamily: theme.fonts[0],
      color: '#001E61',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& div': {
        '& span': {
          fontSize: 14,
          display: 'block',
          marginTop: 9,
          marginBottom: 0,
          transition: 'opacity .2s ease-out',
          color: '#001E61!important',
        },
      },
      '& strong': {
        display: 'block',
        fontSize: 32,
        padding: [0, 7],
        fontWeight: 'normal',
        transform: 'translateY(-17px)',
      },
      '& input': {
        fontFamily: theme.fonts[1],
        fontSize: 23,
        color: '#E8DC4A',
        background: '#001E61',
        boxShadow: 'none',
        border: '1px solid transparent',
        MozAppearance: 'textfield',
        width: 60,
        borderRadius: 10,
        padding: [20, 10],
        textAlign: 'center',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          display: 'none',
        },
        '&:focus, &:active': {
          border: `1px solid #001E61`,
          color: '#001E61',
          background: 'transparent',
          '& + span': {
            opacity: 1,
          },
        },
      },
    },
    buttons: {
      position: 'relative',
      zIndex: 1,
      margin: [0, 'auto', 40],
      '& $btn': {
        margin: [0, 10],
      },
      '&.disabled': {
        cursor: 'not-allowed',
        '& $btn': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
        '&:hover': {
          '& $disabledHint': {
            opacity: 1,
            transform: 'translate(-50%, 0px)',
          },
        },
      },
    },

    disabledHint: {
      position: 'absolute',
      zIndex: 2,
      background: theme.colors[1],
      borderRadius: 10,
      color: theme.colors[3],
      padding: 10,
      fontSize: 12,
      bottom: 57,
      width: 170,
      textAlign: 'center',
      left: '50%',
      transform: 'translate(-50%, -5px)',
      boxShadow: '0 10px -5px 10px rgba(0, 0, 0, .2)',
      border: `1px solid ${theme.colors[3]}`,
      opacity: '0',
      fontFamily: theme.fonts[1],
      pointerEvents: 'none',
      transition: 'all .3s ease-out',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        bottom: -5,
        left: '50%',
        transform: 'translateX(-50%) rotate(-135deg)',
        width: 10,
        height: 10,
        background: theme.colors[1],
        borderTop: `1px solid ${theme.colors[3]}`,
        borderLeft: `1px solid ${theme.colors[3]}`,
      },
    },
  }

  /*------------------------------
  Min Width > 1680
  ------------------------------*/
  output['@media (min-width: 1680px)'] = {
    title: {
      fontSize: 200,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      fontSize: 60,
      marginBottom: 15,
      whiteSpace: 'normal',
    },
    text: {
      fontSize: 14,
      margin: [0, 'auto', 20],
      padding: [0, 20],
    },
    timer: {
      marginBottom: 0,
    },
    hint: {
      padding: [0, 20],
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    buttons: {
      marginTop: 40,
      '& $btn': {
        margin: 10,
      },
    },
  }

  return output
}

export default style
