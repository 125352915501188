const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.language,
      background: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      pointerEvents: 'none',
      opacity: 1,
      // opacity: 0,
    },
    hide: {
      pointerEvents: 'none',
    },
    logoContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinnerContainer: {
      width: '260px',
      height: '260px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinner: {
      width: '260px',
      height: '260px',
      transformOrigin: 'center',
      animation: '$rotate 2s linear infinite',
      transition: 'all 0.3s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      '& .path': {
        stroke: theme.colors[3],
        strokeWidth: 0.3,
        fill: 'none',
        animation: '$dash 2.5s ease-in-out infinite',
      },
    },
    btnContainer: {
      pointerEvents: 'none',
      opacity: 0,
    },
    btn: {
      extend: theme.button,
    },
    '@keyframes rotate': {
      from: { transform: 'rotate(0)' },
      to: { transform: 'rotate(360deg)' },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    canvas: {
      // opacity: 1,
      // zIndex: 10,
      opacity: 0,
      zIndex: -100,
      top: 60,
      left: 0,
      position: 'absolute',
    },

    '@keyframes loading': {
      '0%': { transform: 'scaleX(0)' },
      '10%': { transform: 'scaleX(0.1)' },
      '50%': { transform: 'scaleX(0.3)' },
      '70%': { transform: 'scaleX(0.8)' },
      '100%': { transform: 'scaleX(1)' },
    },
  }
  return output
}

export default style
