import axios from 'axios'
import { REQUEST_SPORTS, RECEIVE_SPORTS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestSports = () => ({ type: REQUEST_SPORTS })

const receiveSports = (data) => ({
  type: RECEIVE_SPORTS,
  payload: data,
})

const fetchSports = () => async (dispatch, getState) => {
  dispatch(requestSports())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'sport',
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  let items = response.data.items;
  const has = Object.prototype.hasOwnProperty
  items = await Promise.all(items
    .map(async (i) => {
      if(has.call(i.fields,'icon') && has.call(i.fields.icon,'sys') && has.call(i.fields.icon.sys,'id')){
        let image = (await dispatch(fetchImage(i.fields.icon.sys.id)))
        i.fields.image = image;
      }
      return i
    }))
  const orderedSports = items
    .map((it) => it.fields)
    .sort((a, b) => a.order - b.order)
  dispatch(receiveSports(orderedSports))
}
const fetchImage = (id) => async (dispatch, getState) => {
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`, params)
  return { ...response.data.fields, id }
}

export {
  fetchSports,
}
