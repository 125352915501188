const style = (theme) => {
  const output = {
    '*': {
      'scrollbar-width': 'auto',
      'scrollbar-color': '#39363a #ffffff',
    },
  
    /* Chrome, Edge, and Safari */
    '*::-webkit-scrollbar': {
      width: 10
    },
  
    '*::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
  
    '*::-webkit-scrollbar-thumb': {
      'background-color': '#39363a',
      'border-radius': 15,
      'border': '2px solid #ffffff',
    },

    root: {
      color: theme.colors[3],
      backgroundPosition: 'center center',
      backgroundRepeatX: 'no-repeat',
      backgroundRepeatY: 'repeat',
      backgroundSize: 'contain',
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      width: '100%',
      backgroundColor: '#F7F7F7!important',
    },
    slideTitle: {
      fontWeight: '600',
      fontSize: '40px',
      lineHeight: '44px',
      color: '#485BC7',
      fontFamily: theme.fonts[1],
    },
    slideText: {
      fontSize: '21px',
      lineHeight: '23px',
      color: '#485BC7',
      fontFamily: theme.fonts[1],
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    wrapperNoPad: {
      extend: theme.wrapper,
      padding: '0',
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },
    ghostBtn: {
      extend: theme.button,
      backgroundColor: 'transparent',
      color: '#485BC7',
      border: '1px solid #485BC7',
      transition: 'all 350ms',

      '&:hover': {
        backgroundColor: '#485BC7',
        color: '#fff',
      },
    },
    title: {
      marginBottom: 24,
      fontSize: 60,
      fontWeight: 'bold',
    },
    hint: {
      marginBottom: 30,
      fontSize: 21,
    },

    // Utils
    hideOnDesktop: {
      display: 'none',
    },

    // Intro
    intro: {
      display: 'none',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      position: 'relative',
      zIndex: 3,
      textAlign: 'center',

      '& $wrapper': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      '& h2': {
        marginBottom: 30,
        fontSize: 28,
        fontWeight: 'bold',
      },

      '& p': {
        fontSize: 13,
        marginBottom: 20,
      },

      '& a': {
        display: 'block',
        marginBottom: 10,
        color: '#485BC7',
      },

      '& button:first-child': {
        marginBottom: 15,
      },
    },

    // Hero
    hero: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: [20, 0, 50, 0],
    },
    heroTitle: {
      extend: theme.typography.headings,
      fontSize: 60,
      marginBottom: 43,
    },
    heroText: {
      maxWidth: '70%',
      margin: [0, 'auto', 34],
      '& b': {
        fontFamily: theme.fonts[1],
        fontWeight: 'normal',
      },
    },
    scrollIcon: {
      position: 'absolute',
      zIndex: 1,
      left: '3.2vw',
      bottom: 30,
    },

    // Half Width Text
    halfWidthText: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      justifyContent: 'space-between',
      position: 'relative',
      backgroundSize: 'cover!important',
      backgroundColor: '#F7F7F7!important',
      position: 'relative',

      '&:not(:first-child):before': {
        content: '""',
        width: '50%',
        height: 120,
        background: 'linear-gradient(180deg, #f7f7f7, rgba(255, 255, 255, .0))',
        position: 'absolute',
        top: 0,
      },

      '& *': {
        zIndex: 0,
      },
    },

    reverseImage: {
      flexDirection: 'row-reverse',
    },
    backgroundButtonWrapper: {
      display: 'flex',
      marginTop: 10,

      '& $startButton': {
        marginLeft: 20,
      },
    },
    halfWidthContent: {
      flex: 1,
      justifyContent: 'center',

      '& $backgroundButtonWrapper': {
        display: 'flex',
        marginTop: 100,
      },

      '& p a': {
        color: '#485bc7',
        fontWeight: 'bold',
      },
    },
    halfWidthContentWrapper: {
      width: '50%',
      margin: '0 auto',
    },
    halfWidthImage: {
      flex: 1,
      height: '100%',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: 0,
    },
    hideOnMobile: {},
    halfWidthTitle: {
      color: '#485BC7',
      fontSize: 40,
      fontWeight: 800,
    },
    halfWidthSubTitle: {
      color: '#485BC7',
      marginBottom: 4,
      fontSize: 21,
      fontWeight: 800,
    },
    button: {
      width: 60,
      height: 60,
      background: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[3]),
      border: '1px solid',
      borderColor: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[3]),
      borderRadius: '50%',
      cursor: 'pointer',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '@media (hover: hover)': {
        '&:hover': {
          background: (props) => (props.headerInverse ? theme.colors[3] : 'transparent'),
        },
      },
    },
    buttonInverse: {
      background: (props) => (props.headerInverse ? theme.colors[3] : 'transparent'),
      borderColor: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[3]),
      '& img': {
        transition: 'all 350ms',
      },
      '@media (hover: hover)': {
        '&:hover': {
          background: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[3]),
          '& img': {
            filter: 'brightness(0) invert(1)',
          },
        },
      },
    },

    // Glossary
    glossaryWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: 80,

      '& $keysTitle': {
        marginBottom: 20,
      },

      '& > div': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
      }
    },
    glossaryItem: {
      width: '48%',
      padding: [0, 20, 0, 0],
      display: 'flex',
      textAlign: 'left',
      alignItems: 'flex-start',

      '& h4': {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: 7,
      },

      '& p': {
        fontSize: 18,
      },
    },

    glossaryImage: {
      marginRight: 20,

      '& img': {
        maxWidth: 'none!important',
        width: 92,
      },
    },

    // Principles
    principles: {
      margin: [100, 0],
      '& $wrapper': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    principlesBox: {
      flex: '0 0 50%',
      paddingLeft: 130,
    },
    principlesTitle: {
      extend: theme.typography.headings,
      fontSize: 60,
      marginBottom: 35,
    },
    principlesText: {
      maxWidth: 360,
    },
    principlesParallax: {
      flex: '0 0 50%',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '50%',
    },
    princplesImage1: {
      position: 'relative',
      zIndex: 2,
      marginLeft: '50%',
      width: '37%',
    },
    princplesImage2: {
      position: 'relative',
      zIndex: 1,
      marginLeft: '0%',
      width: '67%',
    },

    princplesImage3: {
      position: 'relative',
      zIndex: 2,
      marginLeft: '51%',
      width: '55%',
    },
    princplesImage4: {
      position: 'relative',
      zIndex: 3,
      marginLeft: '33%',
      width: '35%',
      marginTop: '-20%',
    },

    // backgrounds
    backgrounds: {
      margin: [0, 0],
      minHeight: 'calc(100vh + 10px)',
      '& $wrapper': {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh + 10px)',
      },
    },
    backgroundsBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      paddingLeft: 150,
    },
    buttonBox: {
      position: 'relative',
      marginBottom: 150,
      height: 40,
    },
    btnBG: {
      marginLeft: 190,
    },
    scrollIcon2: {
      position: 'absolute',
      top: 0,
      left: 100,
    },

    backgroundsTitle: {
      maxWidth: 'calc(100% - 250px)',
      extend: theme.typography.headings,
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 44,
      marginBottom: 35,
    },
    backgroundsText: {
      maxWidth: 'calc(100% - 180px)',
      display: 'block',
      marginBottom: 60,
      fontSize: 20,
      lineHeight: 23,
    },
    backgroundsParallax: {
      zIndex: 1,
      right: 0,
      bottom: 0,
      top: 0,
      height: 'calc(100vh + 110px)',
      width: '50%',
      position: 'absolute',
    },
    backgroundImage: {
      position: 'absolute',
      zIndex: 1,
      right: 0,
      top: '-102px',
      bottom: '0',
      width: '50%',
      height: 'calc(100vh + 110px)',
      objectFit: 'cover',
    },
    // Brendon
    brendon: {
      margin: [200, 0, 100],
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    brendonTitle: {
      extend: theme.typography.headings,
      fontSize: 60,
      marginBottom: 70,
    },
    brendonImage: {
      width: 300,
      margin: [0, 'auto'],
    },
    brendonText: {
      position: 'relative',
      zIndex: 1,
      fontStyle: 'italic',
      maxWidth: 670,
      margin: [80, 'auto', 80],
      '&:before': {
        content: '"“"',
        fontSize: 100,
        position: 'absolute',
        zIndex: 1,
        top: -40,
        left: -20,
        fontFamily: theme.fonts[1],
        fontStyle: 'normal',
        opacity: 0,
        transition: 'opacity 2s ease-out 1s',
      },
      '&:after': {
        content: '"“"',
        fontSize: 100,
        position: 'absolute',
        zIndex: 1,
        bottom: -90,
        right: -20,
        fontFamily: theme.fonts[1],
        fontStyle: 'normal',
        opacity: 0,
        transition: 'opacity 2s ease-out 1s',
      },
      '&.visible': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 1,
        },
      },
    },

    // Start Button
    startButton: {
      width: 'calc(100% - 60px)',
      maxWidth: 250,
      height: 60,
      borderRadius: 80,
      lineHeight: '60px',
      fontSize: 16,
      fontWeight: 700,
      backgroundColor: '#485BC7',
      color: '#F8F8F6',
      display: 'block',
      textAlign: 'center',
      transition: 'all 350ms',

      '&:hover': {
        backgroundColor: '#31419f',
      },
    },

    faqWrapper: {
      minHeight: 'calc(100vh - 100px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    // Keys
    keys: {
      textAlign: 'center',
      padding: [50, 0, 50],
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundSize: 'cover!important',
      backgroundColor: '#F7F7F7!important',
    },
    keysTitle: {
      extend: theme.typography.headings,
      fontSize: 40,
      marginBottom: 34,
    },
    keysText: {
      textAlign: 'center',
      maxWidth: 360,
      margin: [0, 'auto', 80],
    },
    centerKeysTitle: {
      '& div div div': {
        textAlign: 'center!important',
      },
    },

    // Steps
    steps: {
      margin: [100, 0],
    },

    // Begin
    begin: {
      marginTop: -100,
      padding: [170, 0, 0],
      position: 'relative',
      zIndex: 3,
      textAlign: 'center',
      paddingBottom: 100,
      '& $wrapper': {
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    beginTitle: {
      extend: theme.typography.headings,
      fontSize: 60,
      marginBottom: 43,
    },
    circles: {
      position: 'absolute',
      zIndex: 0,
      top: 40,
      left: 0,
      width: '100%',
      height: '100%',
      overflowY: 'visible',
      overflowX: 'hidden',
    },
    circle: {
      position: 'absolute',
      zIndex: 1,
      width: 100,
      height: 100,
      borderRadius: '50%',
      border: `1px solid ${theme.colors[3]}`,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$pulse 4s ease-in infinite',
      opacity: 0,
      '&:nth-child(2)': {
        animationDelay: '1s',
      },
      '&:nth-child(3)': {
        animationDelay: '2s',
      },
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0.45,
      },
      '100%': {
        opacity: 0,
        width: 700,
        height: 700,
      },
    },

    emotions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '70%',
      margin: '0 auto',

      '& $backgroundButtonWrapper': {
        marginTop: '40px!important',
      },
    },

    emotionCircle: {
      width: '10%',

      '& > b': {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 700,
        textAlign: 'center',
        display: 'block',
      },

      '& > span': {
        display: 'block',
        width: 80,
        height: 80,
        borderRadius: '100%',
        margin: '0 auto',
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    emotionCircle: {
      width: '10%',
      marginBottom: 20,

      '& > b': {
        fontSize: 10,
        marginBottom: 10,
        fontWeight: 700,
        textAlign: 'center',
        display: 'block',
      },

      '& > span': {
        display: 'block',
        width: 50,
        height: 50,
        borderRadius: '100%',
        margin: '0 auto',
      },
    },
  }

  output['@media (max-width: 1440px)'] = {
    halfWidthTitle: {
      fontSize: 34,
    },
  }

  output['@media (max-width: 1330px)'] = {
    backgroundButtonWrapper: {
      '& $startButton': {
      },

      '& $button': {
        width: 65,
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    // Hero
    heroText: {
      maxWidth: '80%',
    },
    // Principles
    principlesBox: {
      paddingLeft: 100,
    },
    principlesTitle: {
      fontSize: 42,
      marginBottom: 30,
    },
    principlesText: {
      maxWidth: 'none',
    },
    // backgrounds
    backgroundsBox: {
      paddingLeft: 70,
    },
    backgroundsTitle: {
      fontSize: 24,
      maxWidth: 'calc(100% - 180px)',
    },
    backgroundsText: {
      fontSize: 12,
      maxWidth: 'calc(100% - 130px)',
    },
    btnBG: {
      marginLeft: 90,
    },
    scrollIcon2: {
      left: 10,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {

    root: {
      backgroundPosition: 'center center',
      backgroundRepeatX: 'no-repeat',
      backgroundRepeatY: 'repeat',
      backgroundSize: 'contain',
    },

    // Hero
    hero: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      padding: [0, 0, 100],
    },
    heroTitle: {
      fontSize: 42,
      marginBottom: 35,
    },
    heroText: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      padding: [0, 20],
      fontSize: 11,
    },
    scrollIcon: {
      position: 'absolute',
      zIndex: 1,
      left: '50%',
      marginLeft: -25,
      bottom: 20,
    },

    // Utils
    hideOnDesktop: {
      display: 'block',
    },

    // Intro
    intro: {
      display: 'flex',
    },
    title: {
      marginBottom: 24,
      fontSize: 40,
      fontWeight: 'bold',
    },
    hint: {
      marginBottom: 30,
      fontSize: 16,
    },

    // Glossary
    glossaryWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 20,
      gap: 0,

      '& > div': {
        gap: 0,

        '& p': {
          marginBottom: 0,
        }
      }
    },
    glossaryItem: {
      width: '100%',
      maxWidth: '100%',
      padding: [0, 20, 0, 0],
      alignItems: 'center',
      marginBottom: 20,

      '& h4': {
        fontSize: 10,
      },

      '& p': {
        fontSize: 10,
      },

      '& .startButton': {
        display: 'none',
      },
    },

    glossaryImage: {
      marginRight: 0,

      '& img': {
        width: 45,
        marginLeft: 30,
        // transformOrigin: 'right top 10px',
        // transform: 'scale(.5)',
        position: 'relative',
        right: 10,
      },
    },

    // Half Width Text
    halfWidthText: {
      flexDirection: 'column',
      minHeight: '100vh',
      height: 'auto',
      zIndex: 1,
      backgroundPosition: 'left top!important',
      backgroundSize: 'cover!important',

      '&:before': {
        content: '""',
        display: 'none',
      },
    },
    halfWidthContent: {
      width: '100%',
      padding: [0, 20],
      display: 'flex',
      alignItems: 'center',
      height: '50vh',
      position: 'relative',
      flex: 'none',
      zIndex: 1,
    },
    backgroundButtonWrapper: {
      justifyContent: 'center',
      position: 'absolute',
      margin: 0,
      bottom: -17,
      zIndex: 1,
      width: '100%',
      left: 0,

      '& $startButton': {
        width: 150,
        height: 35,
        marginLeft: 0,
        lineHeight: '35px',
        fontSize: 10,
      },

      '& $button': {
        position: 'relative',
        background: '#fff',
        width: 35,
        height: 35,
        marginRight: 20,

        '& img': {
          width: 10,
        }
      },
    },
    halfWidthTitle: {
      fontSize: 20,
      textAlign: 'center',
      padding: [0, 20],
      '& p': {
        fontSize: '20px!important',
      },
    },
    halfWidthSubTitle: {
      fontSize: 12,
      textAlign: 'center',
      marginTop: 0,
    },
    halfWidthContentWrapper: {
      width: '100%',

      '& p': {
        textAlign: 'center',
        fontSize: 12,
        padding: [0, 20],
      },
    },
    halfWidthImage: {
      height: '50vh',
      width: '100%',
      backgroundSize: 'cover',
      display: 'block!important',
    },

    hideOnMobile: {
      display: 'none!important',
    },

    // Principles
    principles: {
      margin: [160, 0, 60],
      '& $wrapper': {
        display: 'block',
      },
    },
    principlesBox: {
      padding: [0, 20],
      textAlign: 'center',
      marginTop: 100,
    },

    principlesParallax: {
      width: '100%',
      marginBottom: 100,
    },
    princplesImage1: {
      marginLeft: '50%',
      width: '45%',
      marginBottom: '-10%',
    },
    princplesImage2: {
      marginLeft: '-1%',
      width: '67%',
    },
    princplesImage3: {
      display: 'none',
    },
    princplesImage4: {
      display: 'none',
    },
    // backgrounds
    backgrounds: {
      margin: [0, 0],
      width: '100%',
      minHeight: '100%',
      '& $wrapper': {
        width: '100%',
        display: 'block',
        height: '100vh',
      },
    },
    backgroundsBox: {
      position: 'relative',
      display: 'block',
      alignItems: 'inherit',
      paddingLeft: 0,
      top: 30,
      left: 0,
      bottom: 0,
      width: '100%',
      height: '50vh',
    },
    buttonBox: {
      position: 'asolute',
      marginBottom: 0,
      left: 'calc(50% - 25px)',
      zIndex: 2,
      width: 50,
      height: 40,
    },
    btnBG: {
      display: 'none',
    },
    scrollIcon2: {
      left: 0,
      backgroundColor: 'white',
    },

    backgroundsTitle: {
      maxWidth: '90%',
      textAlign: 'center',
      extend: theme.typography.headings,
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 28,
      marginBottom: 20,
    },
    backgroundsText: {
      maxWidth: '100%',
      display: 'block',
      textAlign: 'center',
      marginBottom: 30,
      fontSize: 14,
      lineHeight: 16,
    },

    backgroundImage: {
      left: 0,
      right: 0,
      width: '100%',
      top: 'inherit',
      bottom: 0,
      height: 'calc(50vh + 190px)',
      objectFit: 'cover',
    },
    // Emotions
    emotions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      margin: '0 auto',

      '& $backgroundButtonWrapper': {
        marginTop: '0px!important',
      },
    },

    emotionCircle: {
      width: '20%',
      marginBottom: 20,

      '& > b': {
        fontSize: 10,
        marginBottom: 10,
        fontWeight: 700,
        textAlign: 'center',
        display: 'block',
      },

      '& > span': {
        display: 'block',
        width: 40,
        height: 40,
        borderRadius: '100%',
        margin: '0 auto',
      },
    },

    // Brendon
    brendon: {
      margin: [160, 0, 60],
    },
    brendonTitle: {
      fontSize: 42,
      marginBottom: 55,
    },
    brendonText: {
      maxWidth: 'none',
      padding: [0, 20],
      '&:before': {
        content: '"“"',
        fontSize: 100,
        position: 'absolute',
        zIndex: 1,
        top: -70,
        left: 0,
        fontFamily: theme.fonts[1],
        fontStyle: 'normal',
      },
      '&:after': {
        content: '"“"',
        fontSize: 100,
        position: 'absolute',
        zIndex: 1,
        bottom: -120,
        right: 0,
        fontFamily: theme.fonts[1],
        fontStyle: 'normal',
      },
    },

    // Faq Wrapper
    faqWrapper: {
      '& $startButton': {
        transform: 'scale(.7)',
      }
    },

    // Keys
    keys: {
      padding: [70, 20, 50],

      '&:last-child': {
        marginBottom: -50,
      },
    },
    keysTitle: {
      fontSize: 27,
      marginBottom: 30,
      padding: [0, 60],
    },
    keysText: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },

    // Begin
    begin: {
      marginTop: -100,
      paddingBottom: 70,
    },
    circles: {
      top: 50,
    },
    beginTitle: {
      fontSize: 42,
      marginBottom: 35,
    },
  }

  return output
}

export default style
