/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useEffect } from 'react'
import { extend } from 'react-three-fiber'
import { Text } from 'troika-three-text'

extend({ Text })

const Text3D = ({
  text,
  layout,
  onReady,
  visible,
  ...props
}) => {
  const $text = useRef()
  const opts = {
    font: layout === 'big' ? '/frontend/fonts/Graphik-Regular.woff' : '/frontend/fonts/Graphik-Medium.woff',
    fontSize: layout === 'big' ? 50 : 7,
    color: layout === 'big' ? '#ffffff' : '#485cc7',
    outlineWidth: layout === 'big' ? 1 : 0,
    outlineColor: '#485cc7',
    maxWidth: 150,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'justify',
  }

  useEffect(() => {
    $text.current.addEventListener('synccomplete', () => {
      if (onReady) onReady()
    })
  }, [])

  return (
    <>
      <group {...props}>
        <text
          ref={$text}
          position-y={0}
          position-z={-180}
          {...opts}
          text={text}
          anchorX="center"
          anchorY="middle"
        >
          <meshBasicMaterial attach="material" color={opts.color} transparent={true} opacity={visible ? 1 : 0.001} />
        </text>
      </group>
    </>
  )
}

export default Text3D
