import { Sphere } from './sphere'

/*------------------------------
Main
------------------------------*/
const Main = ({ settings }) => {
  return (
    <Sphere settings={settings} />
  )
}

export default Main
