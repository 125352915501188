import { useContext, useEffect, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import Nav from '@/components/Nav'
import Overlay from '@/components/Overlay'
import Spinner from '@/components/Spinner'
import SVGSprite from '@/components/SVGSprite'
import GeolocationModal from '@/components/GeolocationModal'
import FaceScanModal from '@/components/FaceScanModal'
import ShareModal from '@/components/ShareModal'
import CongratsModal from '@/components/CongratsModal'
import useFontPreload from '@/hooks/useFontPreload'
import useGLBPreload from '@/hooks/useGLBPreload'
import useViewport from '@/hooks/useViewport'
import * as optionsActions from '@/actions/options'
import * as navActions from '@/actions/nav'
import * as stepsActions from '@/actions/steps'
import * as emotionsActions from '@/actions/emotions'
import * as sportsActions from '@/actions/sports'
import * as userActions from '@/actions/user'
import * as experienceActions from '@/actions/experience'
import * as layerActions from '@/actions/layer'
import * as eventsActions from '@/actions/events'
import * as routerActions from '@/actions/fakerouter'
import { Context } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import global from '@/global'
import UltraUpliftIntroModal from './components/UltraUpliftModals/IntroModal'
import ChooseProgrammeModal from './components/UltraUpliftModals/ChooseProgrammeModal'
import QuestionsAfterEmailModal from './components/QuestionsAfterEmailModal'
import {SITE } from '@/constants'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const { headerInverse, headerMap } = useContext(Context)
  const { isLoaderExited } = useContext(LoadingContext)
  const classes = useStyles({ headerInverse, headerMap })
  const [currentPosition, setCurrentPosition] = useState([])
  const history = useHistory();
  const location = useLocation();

  /*------------------------------
  Font Preload
  ------------------------------*/
  useViewport()
  useFontPreload({ fonts: ['Graphik-Regular', 'Graphik-Medium'] })
  useGLBPreload({ models: ['./frontend/static/models/face.glb'] })

  /*------------------------------
  Redux Store
  ------------------------------*/
  const {currentLanguage, mainNav, dashboardNav, extraNav, socialNav, userNav, position, countryCode, tags, user } = useSelector((state) => ({
    mainNav: state.nav.mainnav,
    extraNav: state.nav.extranav,
    dashboardNav: state.nav.dashboardnav,
    socialNav: state.nav.socialnav,
    userNav: state.nav.usernav,
    currentLanguage: state.locale.currentLanguage,
    position: state.user.position,
    countryCode: state.events.countryCode,
    tags: state.events.tags,
    user: state.user,  
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchTags = useCallback(() => dispatch(eventsActions.fetchTags()), [dispatch])
  const setCurrentTag = useCallback((tag) => dispatch(eventsActions.setCurrentTag(tag)), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])
  const fetchMenus = useCallback(() => dispatch(navActions.fetchMenu()), [dispatch])
  const fetchNavItems = useCallback(() => dispatch(navActions.fetchNavItems()), [dispatch])
  const fetchSteps = useCallback(() => dispatch(stepsActions.fetchSteps()), [dispatch])
  const fetchEmotions = useCallback(() => dispatch(emotionsActions.fetchEmotions()), [dispatch])
  const fetchSports = useCallback(() => dispatch(sportsActions.fetchSports()), [dispatch])
  const fetchGeocoding = useCallback((coord) => dispatch(experienceActions.fetchGeocoding(coord)), [dispatch])
  const setPosition = useCallback((p) => dispatch(userActions.setPosition(p)), [dispatch])
  const openGeolocationModal = useCallback(() => dispatch(layerActions.openLayer({ id: 'geolocation' })), [dispatch])
  const fetchProfile = useCallback(() => dispatch(userActions.fetchProfile()), [dispatch])
  const fetchMindraceStatus = useCallback(() => dispatch(experienceActions.fetchMindraceStatus()), [dispatch])
  const changeLocation = useCallback((location) => dispatch(routerActions.changeLocation(location)), [dispatch])
  const setExperience = useCallback((data) => dispatch(experienceActions.setExperience(data)), [dispatch])

  /*------------------------------
  Manage Login
  ------------------------------*/
  useEffect(() => {
    if(localStorage.getItem('state')) localStorage.removeItem('state')
    if(localStorage.getItem('loginState')){
      fetchProfile()
      fetchMindraceStatus()
    }
  }, [])



  /*------------------------------
  Manage Geolocation
  ------------------------------*/
  // useEffect(() => {
  //   if (SITE !== 'https://www.upliftingmindsstudy.com' && position.length === 0 && isLoaderExited) {
  //     if ('geolocation' in navigator) {
  //       navigator.geolocation.getCurrentPosition(
  //         (pos) => {
  //           setCurrentPosition([pos.coords.latitude, pos.coords.longitude])
  //           setPosition([pos.coords.longitude, pos.coords.latitude])
  //         },
  //         (error) => {
  //           if (error.code === error.PERMISSION_DENIED) {
  //             openGeolocationModal()
  //           }
  //         },
  //       )
  //     }
  //   }
  // }, [isLoaderExited])

  /*------------------------------
  Reverse Geocoding from Coordinates
  ------------------------------*/
  useEffect(() => {
    if (currentPosition.length > 0) {
      fetchGeocoding(currentPosition)
    }
  }, [currentPosition])

  useEffect(() => {
    if (tags.length > 0 && countryCode !== '') {
      const currentTag = tags.find((t) => t.name.slice(-2) === countryCode)
      setCurrentTag(currentTag?.name)
    }
  }, [countryCode, tags])

  /*------------------------------
  Fetch all Strings
  ------------------------------*/
  useEffect(() => {
    if (currentLanguage) {
      fetchTags()
      fetchOptions()
      fetchMenus()
      fetchNavItems()
      fetchSteps()
      fetchEmotions()
      fetchSports()
    }
  }, [currentLanguage])

  return (
    <div className={`${classes.root} content-root`}>
      <div className={classes.background} />
      <Loader />
      <Overlay />
      <Spinner />
      <Header />
      <Nav
        mainNav={mainNav}
        extraNav={extraNav}
        dashboardNav={dashboardNav}
        socialNav={socialNav}
        userNav={userNav}
      />
      <div className="wrapper">
        {children}
      </div>
      <SVGSprite />
      <GeolocationModal />
      <FaceScanModal/>
      {/* <ShareModal /> */}
      {/* <QuestionsAfterEmailModal />
      <UltraUpliftIntroModal />
      <ChooseProgrammeModal />
      <CongratsModal /> */}
    </div>
  )
}

export default Layout
