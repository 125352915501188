const style = (theme) => {
  const output = {
    root: {
      position: 'var(--position)',
      opacity: 0,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
