export const splitSpan = (num) => (num.toString().split('').map((n) => `<span>${n}</span>`).join(''))

export const zeroDecimal = (num) => (splitSpan((num < 10 ? `0${num}` : num)))

export const convertTime = (time, single) => {
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((time % (1000 * 60)) / 1000)

  const h = hours > 0 ? `${zeroDecimal(hours)}:` : ''
  const m = `${zeroDecimal(minutes)}:`
  const s = zeroDecimal(seconds)

  if (single) {
    return { hours, minutes, seconds }
  }

  return h + m + s
}

export const getYYYYMMDD = (d) => {
  return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
}
