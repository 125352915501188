import axios from 'axios'
import {
  REQUEST_MAP,
  RECEIVE_MAP,
  SET_MAP_EMOTION,
  SET_MAP_ZONE,
  SET_MAP_LEVEL,
  SET_MAP_ZONE_NAME,
  SET_MAP_SPORT,
  SET_MAP_EVENT,
  SET_MAP_CURRENT_COUNTRY,
  RESET_MAP_MARKERS,
  REQUEST_MAP_SPORT_LIST,
  RECEIVE_MAP_SPORT_LIST,
} from '@/actions/types'
import { API } from '@/constants'
import { theme } from '../style'

const requestMap = () => ({ type: REQUEST_MAP })

const receiveMap = (data, level, filterType) => ({
  type: RECEIVE_MAP,
  payload: data,
  level,
  filterType,
})

const fetchMapMind = () => async (dispatch, getState) => {
  dispatch(requestMap())
  const params = {
    params: {
      ...(getState().map.currentLevel && {
        level: getState().map.currentLevel,
      }),
      ...((getState().map.currentEmotion.key && getState().map.currentEmotion.key !== 'none') && {
        emotion: getState().map.currentEmotion.key,
      }),
      ...(getState().map.currentZone && {
        zone: getState().map.currentZone,
      }),
      ...((getState().map.currentLevel === 'city' || getState().map.currentLevel === 'region') && {
        country_code: getState().map.currentCountry,
      }),
    },
  }
  const response = await axios.get(`${API.MAP_MIND}`, params)
  dispatch(receiveMap(response.data, getState().map.currentLevel, 'mind'))
}

const fetchMapSport = () => async (dispatch, getState) => {
  dispatch(requestMap())
  const params = {
    params: {
      ...(getState().map.currentLevel && {
        level: getState().map.currentLevel,
      }),
      ...(getState().map.currentSport.id && {
        sport: getState().map.currentSport.id,
      }),
      ...(getState().map.currentZone && {
        zone: getState().map.currentZone,
      }),
    },
  }
  const response = await axios.get(`${API.MAP_SPORTS}`, params)
  dispatch(receiveMap(response.data, getState().map.currentLevel, 'sports'))
}

const fetchMapEvent = () => async (dispatch, getState) => {
  dispatch(requestMap())
  const response = await axios.get(`${API.MAP_EVENTS}`)
  dispatch(receiveMap(response.data, getState().map.currentLevel, 'events'))
}

const requestMapSportList = () => ({ type: REQUEST_MAP_SPORT_LIST })

const receiveMapSportList = (data) => ({
  type: RECEIVE_MAP_SPORT_LIST,
  payload: data,
})

const fetchMapSportList = () => async (dispatch) => {
  dispatch(requestMapSportList())
  const response = await axios.get(`${API.MAP_SPORTS}/list`)
  dispatch(receiveMapSportList(response.data))
}

const setMapLevel = (value) => ({
  type: SET_MAP_LEVEL,
  payload: value,
})

const setMapCurrentCountry = (value) => ({
  type: SET_MAP_CURRENT_COUNTRY,
  payload: value,
})

const setEmotion = (value) => ({
  type: SET_MAP_EMOTION,
  payload: value,
})

const setMapEmotion = (value) => (dispatch, getState) => {
  const currentEmotion = getState().emotions.items
    .map((emotion) => ({
      color: emotion.color,
      key: emotion.key,
      name: emotion.name,
    }))
    .find((emo) => emo.key === value)
    || {
      key: value.toLowerCase(),
      name: getState().options.strings['map.filter.uplift'],
      color: theme.colors[3],
    }
  dispatch(setEmotion(currentEmotion))
}

const setMapZone = (value) => ({
  type: SET_MAP_ZONE,
  payload: value,
})

const setMapZoneName = (value) => ({
  type: SET_MAP_ZONE_NAME,
  payload: value,
})

const setSport = (value) => ({
  type: SET_MAP_SPORT,
  payload: value,
})

const setMapEvent = (value) => ({
  type: SET_MAP_EVENT,
  payload: value,
})

const setMapSport = (value) => (dispatch, getState) => {
  const currentSport = getState().sports.items
    .find((sport) => sport.id === value)
    || {
      id: '',
      name: '',
    }
  dispatch(setSport(currentSport))
}

const resetMapMarkers = () => ({ type: RESET_MAP_MARKERS })

export {
  fetchMapEvent,
  fetchMapMind,
  fetchMapSport,
  fetchMapSportList,
  resetMapMarkers,
  setMapEmotion,
  setMapLevel,
  setMapSport,
  setMapEvent,
  setMapZone,
  setMapZoneName,
  setMapCurrentCountry,
}
