const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
    },
    page: {
      overflow: 'hidden',
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    // Hero
    hero: {
      paddingTop: (props) => `${props.headerHeight}px`,
      paddingBottom: 80,
      color: theme.colors[3],
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 'var(--vh)',
    },

    // Begin
    begin: {
      marginTop: -100,
      padding: [170, 0, 0],
      position: 'relative',
      zIndex: 3,
      textAlign: 'center',
      paddingBottom: 100,
      '& $wrapper': {
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    beginTitle: {
      extend: [theme.typography.headings, theme.titleSize],
      marginBottom: 43,
    },
    beginText: {
      maxWidth: 700,
      margin: [0, 'auto', 43],
      '& a': {
        '--color': theme.colors[3],
        color: 'currentColor',
        extend: theme.link,
      },
    },
    beginDisclaimer: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 30,
      maxWidth: 500,
      fontSize: 14,
      margin: [0, 'auto'],
      opacity: 0.5,
      '& a': {
        '--color': theme.colors[3],
        extend: theme.link,
      },
    },
    circles: {
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflowY: 'visible',
      overflowX: 'hidden',
    },
    circle: {
      position: 'absolute',
      zIndex: 1,
      width: 100,
      height: 100,
      borderRadius: '50%',
      border: `1px solid ${theme.colors[3]}`,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$pulse 4s ease-in infinite',
      opacity: 0,
      '&:nth-child(2)': {
        animationDelay: '1s',
      },
      '&:nth-child(3)': {
        animationDelay: '2s',
      },
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0.45,
      },
      '100%': {
        opacity: 0,
        width: 700,
        height: 700,
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {}

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    // Hero
    hero: {
      padding: [0, 0, 100],
    },

    // Begin
    beginText: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },
    circles: {
      top: 50,
    },
    beginTitle: {
      marginBottom: 35,
    },
    beginDisclaimer: {
      position: 'relative',
      left: 'auto',
      transform: 'none',
      bottom: 'auto',
      maxWidth: '100%',
      fontSize: 14,
      marginTop: 40,
    },
  }

  return output
}

export default style
