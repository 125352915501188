import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Context as GeneralContext } from '@/context'

import * as userActions from '@/actions/user'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import * as routerActions from '@/actions/fakerouter'

import { Fragment } from 'react'

const Logout = () => {

  const history = useHistory()

  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.user.isLoggedIn,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchLogout = useCallback(async() => await dispatch(userActions.fetchLogout()), [dispatch])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const moveLogin = useCallback(
    () => dispatch(routerActions.changeLocation("expired")),
    [dispatch]
  );
  useEffect(() => {
    (async()=>{
      await fetchLogout()
      moveLogin()
      history.push('/');
    })()
  }, [])

  return (
    <Fragment></Fragment>
  )
}

export default Logout
