const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[3],
      paddingTop: (props) => props.headerHeight,
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      paddingBottom: 100,
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },

    // Title
    title: {
      textAlign: 'center',
      position: 'relative',
      extend: theme.typography.headings,
      marginBottom: 60,
      '& h1': {
        fontSize: 60,
      },
    },

    // Text
    text: {
      width: '80vw',
      margin: '0 auto',
      fontSize: 16,
      '& a': {
        color: theme.colors[3],
        '--color': theme.colors[3],
        extend: theme.linkInverse,
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    text: {
      width: 'auto',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
