export const getBoundingClientRect = (element, extra) => {
  if (!element) return false
  const bounding = {}
  const boundingRect = element.getBoundingClientRect();
  ['top', 'right', 'bottom', 'left', 'width', 'height'].forEach(
    (k) => (bounding[k] = boundingRect[k]),
  )

  if (extra) {
    const style = window.getComputedStyle(element)
    const styleKeys = [
      'margin-bottom',
      'margin-left',
      'margin-right',
      'margin-top',
      'padding-bottom',
      'padding-left',
      'padding-right',
      'padding-top',
    ]
    styleKeys.forEach((k) => {
      const edge = k.split('-')[1]
      const number = style[k].includes('px')
        ? Number(style[k].match(/\d/g).join(''))
        : 0
      bounding[edge] += number
      const size = ['left', 'right'].includes(edge) ? 'width' : 'height'
      bounding[size] += number
    })
  }
  return bounding
}

export const isInViewport = (el) => {
  const { top, height, left, width } = el.getBoundingClientRect()
  return (top + height > 0 && top <= window.innerHeight && left + width > 0 && left <= window.innerWidth)
}

export const externalPreload = (callback) => {
  if (document.documentElement.classList.contains('ready')) {
    callback(true)
  } else {
    document.documentElement.classList.add('ready')
    setTimeout(() => {
      callback(true)
    }, 500)
  }
}

export const getImageData = (data) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      context.drawImage(img, 0, 0)
      resolve(context.getImageData(0, 0, img.width, img.height))
    }
    img.onerror = (err) => reject(err)
    img.src = data
  })
}
