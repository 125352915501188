import { motion } from 'framer-motion'

const MountTransition = ({
  children,
  slide = 0,
  slideUp = 0,
  duration = 0.5,
  ease = 'easeOut',
}) => (
  <motion.div
    exit={{ opacity: 0, x: slide, y: slideUp }}
    initial={{ opacity: 0, x: slide, y: slideUp }}
    animate={{ opacity: 1, x: 0, y: 0 }}
    transition={{ duration, ease }}
  >
    {children}
  </motion.div>
)

export default MountTransition
