const style = (theme) => {
  const transition = `all 1s ${theme.easings['power4.out']}`

  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: theme.zindex.header,
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      pointerEvents: 'none',
      background: (props) => (props.headerInverse ? 'transparent' : 'transparent'),
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapperHeader,
      position: 'relative',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      transition,
    },
    logo: {
      display: 'inline-block',
      pointerEvents: 'all',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& svg': {
        width: '100%',
        transition,
        fill: (props) => (props.headerExtraDark ? 'rgb(0, 30, 97)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
      },
    },
    logoWhite: {
      '& svg': {
        fill: `${theme.colors[1]} !important`,
      },
    },
    notLoggedHeader: {},
    nav: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'all',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize: 15,
      '& > *:not(:last-child)': {
        marginRight: 15,
      },
    },
    noLogo:{
      top:30
    },
    link: {
      color: theme.colors[3],
    },
    button: {
      width: 50,
      height: 50,
      background: (props) => (props.headerExtraDark ? 'rgb(0, 30, 97)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
      border: '1px solid',
      borderColor: (props) => (props.headerExtraDark ? 'rgb(0, 39, 97)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
      borderRadius: '50%',
      cursor: 'pointer',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '@media (hover: hover)': {
        '&:hover': {
          background: (props) => (props.headerInverse ? theme.colors[3] : 'transparent'),
        },
      },
    },
    buttonBurger: {
      position: 'relative',
      borderColor: () => 'transparent',
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 58,
        height: 58,
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: () => 'transparent',
        },
      },
    },
    buttonInverse: {
      background: (props) => (props.headerInverse ? theme.colors[3] : 'transparent'),
      borderColor: (props) => (props.headerExtraDark ? 'rgb(0, 30, 79)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
      '& $arrow': {
        width: 50,
        height: 50,
        transition: `stroke 0.3s ${theme.easings['power4.out']}`,
        transform: 'rotate(90deg)',
        fill: 'transparent',
        stroke: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[3]),
      },
      '@media (hover: hover)': {
        '&:hover': {
          background: (props) => (props.headerExtraDark ? 'rgb(0, 30, 79)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
          '& $arrow': {
            stroke: (props) => (props.headerInverse ? theme.colors[3] : theme.colors[1]),
          },
        },
      },
    },
    buttonMenuOpen: {
      background: () => theme.colors[1],
      borderColor: () => theme.colors[1],
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    logoHidden: {},
    arrow: {},
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    btn: {
      display: 'block !important',
    },
    logoHidden: {
      pointerEvents: 'none',
      opacity: 0,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    logo: {
      width: 100,
    },
    wrapper: {
      justifyContent: 'left',
    },
    container: {
      paddingTop: theme.header.topMobile,
    },
    nav: {
      top: -3,
    },
    noLogo:{
      top:17
    },
    button: {
      width: 42,
      height: 42,
    },
    buttonMenuOpen: {},
    buttonBurger: {
      background: () => theme.colors[3],
      borderColor: () => theme.colors[3],
      '&::before': {
        background: 'transparent',
      },
      '&$buttonMenuOpen': {
        background: () => theme.colors[1],
        borderColor: () => theme.colors[1],
      },
    },
  }

  return output
}

export default style
