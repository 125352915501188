import { useRef, forwardRef, useEffect } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { useThree } from 'react-three-fiber'

const FaceModel = forwardRef((props, ref) => {
  const model = useRef()
  const $geometry = useRef()
  const $material = useRef()
  const loader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('/frontend/draco/')
  loader.setDRACOLoader(dracoLoader)
  const three = useThree()

  useEffect(() => {
    loader
      .load('./frontend/static/models/face.glb', (gltf) => {
        model.current.geometry = gltf.scene.children[0].geometry
      })
  }, [])

  useEffect(() => {
    const aspect = window.innerWidth / window.innerHeight
    const val = aspect > 0.7 ? three.viewport.height * 0.18 : three.viewport.width * 0.25
    ref.current.scale.set(val, val, val)
  }, [three])

  return (
    <group
      ref={ref}
    >
      <mesh
        ref={model}
        receiveShadow
        position={[0, 0, 0.5]}
      >
        <boxBufferGeometry
          ref={$geometry}
          args={[0, 0, 0]}
        />
        <meshStandardMaterial
          ref={$material}
          color={0x485cc7}
          wireframe={true}
        />
      </mesh>
    </group>
  )
})

export default FaceModel
