const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      paddingTop: (props) => props.headerHeight,
      '& .background-canvas': {
        zIndex: 0,
      },
    },
    emotionsRoot: {
      opacity: 0,
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    canvas: {
      position: 'absolute!important',
      zIndex: '1',
      top: '0',
      left: '0',
      height: 'var(--vh) !important',
      width: '100% !important',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    continueBtn: {
      position: 'absolute',
      zIndex: 1,
      bottom: 30,
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      pointerEvents: 'none',
    },

    restartBtn: {
      position: 'absolute',
      zIndex: 1,
      bottom: 30,
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      pointerEvents: 'none',
    },

    restartScan: {
      position: 'absolute',
      zIndex: 1,
      bottom: 30,
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > span': {
        fontFamily: theme.fonts[0],
        fontSize: 14,
        color: theme.colors[3],
        marginBottom: 10,
        textAlign: 'center',
      },
    },

    visible: {
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity .5s ease-out',
    },
    notVisible: {
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity .5s ease-out',
    },

    text: {
      position: 'absolute',
      zIndex: 1,
      bottom: 30,
      width: '100%',
      textAlign: 'center',
      fontFamily: theme.fonts[0],
      fontSize: 14,
      color: theme.colors[3],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& div': {
        marginBottom: 8,
      },
      '& strong': {
        animation: '$pulse1 .5s ease-out infinite alternate',
      },
    },

    '@keyframes pulse1': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },

    spike: {
      position: 'absolute',
      zIndex: 10,
      width: 1,
      height: '50%',
      top: 0,
      left: '50%',
      transformOrigin: '100% 100%',
      '--thickness': '2px',
      '--color': theme.colors[3],
      '&:before': {
        content: '""',
        height: 27,
        width: 1,
        position: 'absolute',
        zIndex: 1,
        left: -1,
        top: 1,
        background: theme.colors[3],
      },
      '&.active': {
        '&:before': {
          borderLeft: 'var(--thickness) solid var(--color)',
          borderRight: 'var(--thickness) solid var(--color)',
        },
      },
      '&$spikeFinish': {
        '&:before': {
          transition: 'border 1s ease-out',
          borderLeft: `var(--thickness) solid ${theme.colors[3]} !important`,
          borderRight: `var(--thickness) solid ${theme.colors[3]} !important`,
        },
      },
    },
    spikeFinish: {},

    loading: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)',
      width: 'var(--radius)',
      height: 'var(--radius)',
    },

    randomCircleWrapper: {
      position: 'relative',
      transform: 'translate(73%, 39%)',
      width: 'calc(var(--radius) / 2.5)',
      height: 'calc(var(--radius) / 1.9)',
    },

    circles: {
      position: 'absolute',
      zIndex: 0,
      top: 40,
      left: 0,
      width: 150,
      height: 150,
      overflow: 'hidden',
    },
    circle: {
      position: 'absolute',
      zIndex: 9999,
      width: 10,
      height: 10,
      borderRadius: '50%',
      border: `1px solid ${theme.colors[3]}`,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$circlePulse 2s ease-in infinite',
      opacity: 0,
      '&:nth-child(2)': {
        animationDelay: '1s',
      },
      '&:nth-child(3)': {
        animationDelay: '2s',
      },
    },
    '@keyframes circlePulse': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0.60,
      },
      '100%': {
        opacity: 0,
        width: 150,
        height: 150,
      },
    },

    frame: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      width: 'calc(var(--radius) * .8333)',
      height: 'calc(var(--radius) * .8333)',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      '--thickness': '20px',
    },

    error: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, -50%)',
      color: theme.colors[11],
      fontSize: 14,
      textAlign: 'center',
      transition: 'opacity 0.5s ease-out',
      opacity: 0,
      '&.visible': {
        opacity: 1,
      },
    },

    topleft: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '16%',
      borderTop: '2px solid var(--color)',
      borderLeft: '2px solid var(--color)',

      '&::before': {
        content: '""',
        display: 'block',
        width: '100%',
        paddingBottom: '100%',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderTop: '2px solid var(--color)',
        borderLeft: '2px solid var(--color)',
        top: 'var(--thickness)',
        left: 'var(--thickness)',
      },
    },

    topright: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '16%',
      borderTop: '2px solid var(--color)',
      borderRight: '2px solid var(--color)',

      '&::before': {
        content: '""',
        display: 'block',
        width: '100%',
        paddingBottom: '100%',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderTop: '2px solid var(--color)',
        borderRight: '2px solid var(--color)',
        top: 'var(--thickness)',
        right: 'var(--thickness)',
      },
    },

    bottomright: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '16%',
      borderBottom: '2px solid var(--color)',
      borderRight: '2px solid var(--color)',

      '&::before': {
        content: '""',
        display: 'block',
        width: '100%',
        paddingBottom: '100%',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderBottom: '2px solid var(--color)',
        borderRight: '2px solid var(--color)',
        bottom: 'var(--thickness)',
        right: 'var(--thickness)',
      },
    },

    bottomleft: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '16%',
      borderBottom: '2px solid var(--color)',
      borderLeft: '2px solid var(--color)',

      '&::before': {
        content: '""',
        display: 'block',
        width: '100%',
        paddingBottom: '100%',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderBottom: '2px solid var(--color)',
        borderLeft: '2px solid var(--color)',
        bottom: 'var(--thickness)',
        left: 'var(--thickness)',
      },
    },

    elNotVisible: {
      display: 'none',
      visibility: 'hidden',
    },

    canvasFace: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      left: 0,
      display: 'none',
      visibility: 'hidden',
    },

    // Traits
    traitList: {
      fontSize: 20,
      textAlign: 'center',
      height: 25,
      position: 'relative',
      zIndex: 1,
      width: '100%',
    },
    trait: {
      width: '100%',
      fontFamily: theme.fonts[1],
      textTransform: 'capitalize',
      position: 'absolute',
      top: 0,
      zIndex: 1,
      color: 'var(--color)',
      marginBottom: 0,
      transition: 'opacity .2s ease-out',
      opacity: 0,
    },
    traitActive: {
      opacity: 1,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    text: {
      paddingLeft: 30,
      paddingRight: 30,
      bottom: 10,
    },

    frame: {
      width: 'calc(var(--radius) * .8)',
      height: 'calc(var(--radius) * .8)',
      '--thickness': '10px',
    },

    spike: {
      '--thickness': '1px',
      '&:before': {
        height: 20,
      },
    },
    skipBtn: {
      minWidth: 0,
    },

    traitList: {
      fontSize: 18,
      height: 20,
    },

  }

  return output
}

export default style
