const style = (theme) => {
    const output = {
      root: {
        position: 'fixed',
        width: '45vw',
        left: 'calc(50% - 22.5vw)',
        top: (props) => `calc(50% - ${props.modalHeight / 2}px)`,
        background: theme.colors[1],
        color: theme.colors[3],
        zIndex: theme.zindex.modal,
        padding: 50,
        borderRadius: 22,
        opacity: 0,
        transform: 'translateY(50px)',
        visibility: 'hidden',
      },
      box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      title: {
        extend: theme.typography.headings,
        fontSize: 36,
        marginBottom: 30,
        textAlign: 'center',
      },
      text: {
        textAlign: 'center',
        fontSize: 14,
        marginBottom: 30,
      },
      btn: {
        extend: theme.button,
      },
    }
  
    /*------------------------------
    MD
    ------------------------------*/
    output[theme.mediaInverse.md] = {}
  
    /*------------------------------
    SM
    ------------------------------*/
    output[theme.mediaInverse.sm] = {
      root: {
        width: '90vw',
        left: 'calc(50% - 45vw)',
        padding: [30, 20],
      },
      title: {
        extend: theme.typography.headings,
        fontSize: 24,
      },
    }
  
    return output
  }
  
  export default style
  