import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Canvas } from 'react-three-fiber'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import useStore from '@/base/zustand'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import Controls from './controls'
import Main from './three/main'
import style from './style'

const useStyles = createUseStyles(style)

const ResultsEvent = (props) => {
  const { active, beforeAfterFlag } = useStore()
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const history = useHistory()
  const [firstTime, setFirsttime] = useState(true)
  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions, experience, step, strings, isLoggedIn, experiences, totalUplift } = useSelector((state) => ({
    emotions: state.emotions.items,
    experience: state.experience.emotions,
    step: state.experience.step,
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    experiences: state.user.experiences,
    totalUplift: state.experience.total_uplift,
  }), shallowEqual)
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (emotions.length > 0) setDataFetched(true)
  }, [emotions])

  /*------------------------------
  Set Scale value
  ------------------------------*/
  useEffect(() => {
    Object.keys(experience).forEach((e) => {
      /*------------------------------
      Origina Pre & Post
      Commentare per debug
      ------------------------------*/
      const { pre, post } = experience[e]
      emotions.find((exp) => exp.key === e).prevScale = pre
      emotions.find((exp) => exp.key === e).postScale = post
      // emotions[index].postScale = post

      /*------------------------------
      Fake Pre e Post Emotions
      per debug
      ------------------------------*/
      // emotions[index].prevScale = Math.random() * 0.5
      // emotions[index].postScale = Math.random() * 0.5 + 0.5
    })
  }, [experience])

  /*------------------------------
  Debug Save Thumb
  ------------------------------*/
  // useEffect(() => {
  //   window.console.log('debug ---->', window.location.search.indexOf('debug') !== -1)
  //   if (window.location.search.indexOf('debug') !== -1) changeLocation()
  // }, [])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Render Emotion Name
  ------------------------------*/
  const renderEmotionName = () => {
    return load && (
      <div className={classes.active} style={{ fontSize: '9vw' }}>
        {emotions.map((e, i) => {
          const diff = Math.abs((((e.postScale - e.prevScale) / e.prevScale) * 100).toFixed(0))
          const sign = diff > 0 ? '+' : ''
          return (
            <div>
              <RevealText
                key={`rt1${i.toString()}`}
                tag="span"
                type="lines"
                visible={active === i}
                value={`${e.name}`}
                lineHeight={1.2}
                delay={0}
                style={{ '--color': e.color }}
                className={classes.heroTitle}
              />
            </div>
          )
        })}
      </div>
    )
  }
  /*------------------------------
  Render Emotion Name
  ------------------------------*/
  const renderTotalUplift = () => {
    const preScale = emotions.map((a) => Number(a.prevScale.toFixed(2)))
    // const preAvarage = (
    //   (preScaleEmotional.reduce((a, b) => a + b) / preScaleEmotional.length)
    //   + (preScaleCognitive.reduce((a, b) => a + b) / preScaleCognitive.length)
    // ) / 2
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    // const postScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.postScale.toFixed(2)))
    // const postScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.postScale.toFixed(2)))
    const postScale = emotions.map((a) => Number(a.postScale.toFixed(2)))
    // const postAvarage = (
    //   (postScaleEmotional.reduce((a, b) => a + b) / postScaleEmotional.length)
    //   + (postScaleCognitive.reduce((a, b) => a + b) / postScaleCognitive.length)
    // ) / 2
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length

    // const diff = ((postAvarage * 100) - (preAvarage * 100)).toFixed(0)
    const diff = Math.abs((((postAvarage - preAvarage) / preAvarage) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''
    return load && (
      <div className={classes.active} style={{ fontSize: '9vw' }}>
        <RevealText
          key="TotalUplift"
          tag="span"
          type="lines"
          visible={active === -1 && !beforeAfterFlag}
          value={`+${props.totalUplift.toFixed(0)}%`}
          lineHeight={1.2}
          delay={firstTime ? 10 : 0}
          style={{ '--color': 'darkblue' }}
          className="nextLine"
        />
        <RevealText
          key="EventUplift"
          tag="span"
          type="lines"
          visible={active === -1 && !beforeAfterFlag}
          value="EVENT UPLIFT"
          lineHeight={1.2}
          delay={firstTime ? 10 : 0}
          style={{ '--color': 'darkblue' }}
        />
      </div>
    )
  }
  /*------------------------------
  Handle Click Next on STEP 1
  ------------------------------*/
  const handleClickNext = () => {
    history.push('/world-uplift-map')
  }

  setTimeout(() => {
    setFirsttime(false)
  }, 10000)
  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageResults ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
      </LocomotiveScroll>
      {renderEmotionName()}
      {renderTotalUplift()}
      {load && (
      <>
        <Canvas
          colorManagement={false}
          pixelRatio={[1, 2]}
          gl={{ antialias: false }}
          camera={{ fov: 50, position: [0, 0, 10], near: 0.1, far: 100 }}
          onCreated={({ camera }) => camera.lookAt(0, 0, 0)}
          className={classes.canvas}
        >
          <Main settings={emotions} />
        </Canvas>
        <Controls
          eventName={props.eventName}
          className={classes.controls}
          settings={emotions}
          handleClickNext={handleClickNext}
          step={step}
          strings={strings}
        />
      </>
      )}
    </>
  )
}

export default ResultsEvent
