import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useStore from '@/base/zustand'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'

const Debug = () => {
  const { setDebug } = useStore()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions } = useSelector((state) => ({
    emotions: state.emotions.items,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveNext = useCallback((name) => dispatch(routerActions.changeLocation(name)), [dispatch])
  const setStep = useCallback((num) => dispatch(experienceActions.setStep(num)), [dispatch])
  const setEmotion = useCallback((value, key, status) => dispatch(experienceActions.setSingleEmotion(value, key, status)), [dispatch])
  const setSport = useCallback((id) => dispatch(experienceActions.setSport(id)), [dispatch])
  //const setTime = useCallback((value) => dispatch(experienceActions.setTime(value)), [dispatch])
  const setAge = useCallback((number) => dispatch(experienceActions.setAge(number)), [dispatch])
  const setGender = useCallback((number) => dispatch(experienceActions.setGender(number)), [dispatch])

  /*------------------------------
  Debug
  ------------------------------*/
  useEffect(() => {
    emotions.forEach((emotion) => {
      const pre = Math.random() * 0.5
      const post = 0.5 + Math.random() * 0.5
      setEmotion(pre, emotion.key, 'pre')
      setEmotion(post, emotion.key, 'post')
    })
    setSport(2)
    moveNext('results')
    setStep(2)
    setDebug(0)
    //setTime(2100)
    setAge(18)
    setGender(1)

    // Auto Click su continua
    // setTimeout(() => {
    //   document.querySelector('.buttonContinue').click()
    // }, 1000)
  }, [])

  return (
    <div>
      Debug
    </div>
  )
}

export default Debug
