const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      backgroundSize: 'cover!important',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    // Hero
    hero: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      minHeight: 'var(--vh)',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      extend: [theme.typography.headings, theme.titleSize],
      marginBottom: 30,
    },
    text: {
      maxWidth: 540,
      margin: [0, 'auto', 40],
      color: theme.colors[3],
    },
    hint: {
      maxWidth: 540,
      margin: [40, 'auto', 0],
      color: theme.colors[3],
      fontSize: 18,
      marginBottom: 35,
    },
    countdown: {
      position: 'relative',
      zIndex: 1,
      width: 75,
      height: 75,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors[3],
      fontFamily: theme.fonts[1],
      fontSize: 30,
      margin: [0, 'auto', 40],
    },
    spinner: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      transform: 'rotate(-90deg)',
      '& .path': {
        stroke: theme.colors[3],
        strokeWidth: 0.8,
        strokeDasharray: '126 126',
        strokeDashoffset: -126,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      marginBottom: 35,
    },
    text: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },
  }

  return output
}

export default style
