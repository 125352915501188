import { createContext, useState, useEffect,useCallback } from 'react'
import { useSelector, shallowEqual,useDispatch } from 'react-redux'
import useMediaQuery from '@/hooks/useMediaQuery'
import usePrevious from '@/hooks/usePrevious'
import * as layerActions from "@/actions/layer";
import { EVENTS, EMITTER } from '@/constants'

const Context = createContext({})
const { Provider, Consumer } = Context

const ContextComponent = ({
  children,
}) => {
  const [background, setBackground] = useState('default')
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isFontsReady, setFontsReady] = useState(false)
  const [headerRef, setHeaderRef] = useState({})
  const [cookieRef, setCookieRef] = useState({})
  const [headerInverse, setHeaderInverse] = useState(false)
  const [headerExtraDark, setHeaderExtraDark] = useState(false)
  const [headerMap, setHeaderMap] = useState(false)
  const [isLogoWhite, setLogoWhite] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [pageAnimationReady, setPageAnimationReady] = useState(false)
  const [isBackButtonVisible, setBackButtonVisible] = useState(false)
  const [mediaQueryKey] = useMediaQuery()

  const { pathname, expLocation, routerAction,openedLayer } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    routerAction: state.router.action,
    expLocation: state.fakerouter.location,
    openedLayer: state.layer.layers.find(
      (layer) => layer.isOpen === true
    ),
  }), shallowEqual)
  // const prevPathname = usePrevious(pathname)
  
    /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const closeLayer = useCallback((id) => {
    dispatch(layerActions.closeLayer({ id }));
  }, [dispatch]);

  useEffect(() => {
    if (pathname !== '/') {
      setPageAnimationReady(false)
      EMITTER.emit(EVENTS.DATALAYER.PAGE_VIEW, pathname.substr(1))
    }
  }, [pathname])

  useEffect(() => {
    if (expLocation && pathname === '/') {
      setPageAnimationReady(false)
      EMITTER.emit(EVENTS.DATALAYER.PAGE_VIEW, expLocation)
    }
  }, [expLocation])

  /*------------------------------
  Show button if is not first enter in website
  ------------------------------*/
  useEffect(() => {
    if (
      routerAction !== 'POP'
      && mediaQueryKey > 2
      && pathname !== '/'
    ) {
      setBackButtonVisible(true)
    } else {
      setBackButtonVisible(false)
    }
  }, [routerAction, pathname, mediaQueryKey])

    /*------------------------------
  Listen router back for modals
  ------------------------------*/
  useEffect(() => {
    if (
      routerAction === 'POP' && openedLayer
    ) {
      closeLayer(openedLayer.id);
    }
  }, [routerAction])
  return (
    <Provider value={{
      background,
      setBackground,
      headerHeight,
      setHeaderHeight,
      setHeaderRef,
      headerRef,
      setCookieRef,
      cookieRef,
      setHeaderInverse,
      setHeaderExtraDark,
      headerInverse,
      headerExtraDark,
      setPageAnimationReady,
      pageAnimationReady,
      isFontsReady,
      setFontsReady,
      hideHeader,
      setHideHeader,
      isLogoWhite,
      setLogoWhite,
      isBackButtonVisible,
      setBackButtonVisible,
      headerMap,
      setHeaderMap,
    }}
    >
      {children}
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ContextComponent
