import axios from 'axios'
import { REQUEST_EMOTIONS, RECEIVE_EMOTIONS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestEmotions = () => ({ type: REQUEST_EMOTIONS })

const receiveEmotions = (data) => ({
  type: RECEIVE_EMOTIONS,
  payload: data,
})

const fetchEmotions = () => async (dispatch, getState) => {
  dispatch(requestEmotions())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'emotions',
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const orderedEmotions = response.data.items
    .map((it) => it.fields)
    .sort((a, b) => a.id - b.id)
  dispatch(receiveEmotions(orderedEmotions))
}

export {
  fetchEmotions,
}
