const style = (theme) => {
  const burgerBorderHeight = 1
  const output = {
    root: {
      width: (props) => props.width,
      height: (props) => props.height,
      position: 'relative',
    },
    box: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'inline-block',
      width: (props) => props.width,
      height: (props) => props.height,
    },
    inner: {
      position: 'absolute',
      display: 'inline-block',
      transition: '.3s',
      width: (props) => props.width,
      height: burgerBorderHeight,
      backgroundColor: (props) => (props.headerExtraDark ? '#E8DC4A' : (props.headerInverse ? theme.colors[3] : theme.colors[1])),
      left: 0,
      top: `calc(50% - ${burgerBorderHeight / 2}px)`,
      '&::before': {
        content: '""',
        display: 'inline-block',
        transition: '.3s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: (props) => (props.headerExtraDark ? '#E8DC4A' : (props.headerInverse ? theme.colors[3] : theme.colors[1])),
        left: 0,
        top: (props) => `calc(${props.height - burgerBorderHeight}px / 2)`,
        transformOrigin: '0 center',
        willChange: 'transform',
      },
      '&::after': {
        content: '""',
        display: 'inline-block',
        transition: '.3s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: (props) => (props.headerExtraDark ? '#E8DC4A' : (props.headerInverse ? theme.colors[3] : theme.colors[1])),
        left: 0,
        top: (props) => `calc((${props.height - burgerBorderHeight}px / 2) * -1)`,
        transformOrigin: '0 center',
        willChange: 'transform',
      },
    },
    isHover: {
      '@media (hover: hover)': {
        '& $inner': {
          backgroundColor: (props) => (props.headerExtraDark ? 'rgb(0, 30, 79)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
          '&::before': {
            backgroundColor: (props) => (props.headerExtraDark ? 'rgb(0, 30, 79)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
          },
          '&::after': {
            backgroundColor: (props) => (props.headerExtraDark ? 'rgb(0, 30, 79)' : (props.headerInverse ? theme.colors[1] : theme.colors[3])),
          },
        },
        '&:not($isActive)': {
          '& $inner': {
            '&::before': {
              transform: 'scaleX(.6)',
            },
            '&::after': {
              transform: 'scaleX(.82)',
            },
          },
        },
        '&$isActive': {
          '& $inner': {
            '&::before': {
              backgroundColor: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[1]),
            },
            '&::after': {
              backgroundColor: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[1]),
            },
          },
        },
      },
    },
    isActive: {
      '& $inner': {
        backgroundColor: (props) => (props.headerInverse ? 'transparent' : 'transparent'),
        '&::before': {
          transformOrigin: '50% 50%',
          top: 0,
          transform: 'rotate3d(0, 0, 1, 45deg)',
          backgroundColor: (props) => (props.headerInverse ? theme.colors[3] : theme.colors[3]),
        },
        '&::after': {
          transformOrigin: '50% 50%',
          top: 0,
          transform: 'rotate3d(0, 0, 1, -45deg)',
          backgroundColor: (props) => (props.headerInverse ? theme.colors[3] : theme.colors[3]),
        },
      },
    },
    headerMap: {},
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    headerMap: {
      '& $inner': {
        backgroundColor: () => theme.colors[1],
        '&::before': {
          backgroundColor: () => theme.colors[1],
        },
        '&::after': {
          backgroundColor: () => theme.colors[1],
        },
      },
      '&$isActive': {
        '& $inner': {
          backgroundColor: () => 'transparent',
          '&::before': {
            backgroundColor: () => theme.colors[3],
          },
          '&::after': {
            backgroundColor: () => theme.colors[3],
          },
        },
      },
    },
  }

  return output
}

export default style
