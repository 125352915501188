/* eslint-disable jsx-a11y/alt-text */
import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import EmotionsGallery from '@/components/EmotionsGallery'
import RevealImage from '@/components/RevealImage'
import RevealFadeIn from '@/components/RevealFadeIn'
import ScrollIcon from '@/components/ScrollIcon'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Landing = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const $root = useRef()
  const location = useLocation()
  const history = useHistory()
  const stub = useRef(getSlug(location.pathname))

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, emotions, currentLanguage, routerAction } = useSelector((state) => ({
    page: state.content['backgroundPages'] || {},
    emotions: state.emotions.items,
    routerAction: state.router.action,
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const classes = useStyles({
    headerHeight,
  })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchBackground()), [dispatch])

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (currentLanguage && Object.keys(page).length === 0) fetchPage(stub.current)
  }, [currentLanguage])

  useEffect(() => {
    setInterval(() => {
      document.body.style.overflowY = 'visible'
      document.documentElement.style.scrollBehavior = 'smooth'
    }, 2000)

    document.body.style.overflowY = 'visible'
    const scrollStyle = document.createElement('style')
    const cssCode = '* {scrollbar-width: auto;scrollbar-color: #39363a transparent;}*::-webkit-scrollbar {width: 10px;}*::-webkit-scrollbar-track {background: transparent;}*::-webkit-scrollbar-thumb {background-color: #39363a;border-radius: 15px; border: none; border-right: 2px solid #fff;}'
    document.body.appendChild(scrollStyle)
    if (scrollStyle.styleSheet) {
      // This is required for IE8 and below.
      scrollStyle.styleSheet.cssText = cssCode
    } else {
      scrollStyle.appendChild(document.createTextNode(cssCode))
    }
  }, []);
  

  let resultsBackgroundImage ='/frontend/static/images/portal/backgroundPage/glossary-desktop-background.png';;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      resultsBackgroundImage = '/frontend/static/images/portal/backgroundPage/glossary-mobile-background.png';
    }
  }
  resizeBg()

  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false)
  const [load] = usePagePrecache({ // eslint-disable-line
    init: true,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (
      Object.keys(page).length > 0
        && emotions.length > 0
    ) {
      setRealLoad(true)
    }
  }, [page, emotions])

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: realLoad,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return realLoad && <Meta />
  }

  const renderStartButton = (props) => {
    return (
      <Link
        to={{
          pathname: '/',
          hash: '',
        }}
        className={classes.startButton}
      >
        {props.title}

      </Link>
    )
  }

  const renderHalfWidthText = (props) => {
    const reverseImage = props.reverse || false
    const { title, subTitle, description, imageUrl } = props
    return (
      <div className={`${classes.halfWidthText} convert-anchors ${reverseImage ? classes.reverseImage : ''}`} style={{ background: `url(${props.sectionBackground}) no-repeat center center`, backgroundSize: 'cover' }} id={props.id}>
        <div className={classes.halfWidthContent} style={props.fullWidthImage ? { zIndex: 1 } : {}}>
          <div className={classes.halfWidthContentWrapper}>
            <h5 className={classes.halfWidthSubTitle}>{subTitle}</h5>
            <h3 className={classes.halfWidthTitle} dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: description }} />
            <div className={classes.backgroundButtonWrapper}>
              <a
                href={`/mind-uplift${props.target}`}
                className={classNames({
                  [classes.button]: true,
                  [classes.buttonInverse]: true,
                })}
              >
                <img src={'/frontend/static/images/general/arrow.svg'} />
              </a>
              {renderStartButton({ title: props.button })}
            </div>
          </div>
        </div>
        <div className={classes.halfWidthImage} style={{ backgroundImage: `url("${imageUrl}")`, ...(props.contain ? { backgroundSize: 'contain', backgroundColor: '#ffffff' } : {}) }} />
      </div>
    )
  }

  /*------------------------------
  Render Glossary
  ------------------------------*/
  const renderGlossary = () => {
    const image1 = (page?.length > 0) &&   page[3].images?.find((img) => img.id === page[3].glossaryImage1?.sys.id)?.file?.url
    const image2 = (page?.length > 0) &&   page[3].images?.find((img) => img.id === page[3].glossaryImage2?.sys.id)?.file?.url
    const image3 = (page?.length > 0) &&   page[3].images?.find((img) => img.id === page[3].glossaryImage3?.sys.id)?.file?.url
    const image4 = (page?.length > 0) &&   page[3].images?.find((img) => img.id === page[3].glossaryImage4?.sys.id)?.file?.url
    const image5 = (page?.length > 0) &&   page[3].images?.find((img) => img.id === page[3].glossaryImage5?.sys.id)?.file?.url

    return (
      <div className={`${classes.glossaryWrapper}`} style={{ backgroundImage: `url(${page?.images?.find((img) => img.id === page?.fullBackground?.sys.id)?.file?.url}) center center no-repeat`, backgroundSize: 'cover' }} id="glossary">
        <InViewTrigger>
          <RevealFadeIn
            delay={0.2}
            className={classes.glossaryItem}
          >
            <div className={classes.glossaryImage}>
              <img src={image1} />
            </div>
            <div>
              <h4>{page?.length > 0 && page[3].title1}</h4>
              <p dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].description1) }} />
            </div>
          </RevealFadeIn>
          <RevealFadeIn
            delay={0.2}
            className={classes.glossaryItem}
          >
            <div className={classes.glossaryImage}>
              <img src={image2} />
            </div>
            <div>
              <h4>{page?.length > 0 && page[3].title2}</h4>
              <p dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].description2) }} />
            </div>
          </RevealFadeIn>
          <RevealFadeIn
            delay={0.2}
            className={classes.glossaryItem}
          >
            <div className={classes.glossaryImage}>
              <img src={image3} />
            </div>
            <div>
              <h4>{page?.length > 0 && page[3].title3}</h4>
              <p dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].description3) }} />
            </div>
          </RevealFadeIn>
          <RevealFadeIn
            delay={0.2}
            className={classes.glossaryItem}
          >
            <div className={classes.glossaryImage}>
              <img src={image4} />
            </div>
            <div>
              <h4>{page?.length > 0 && page[3].title4}</h4>
              <p dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].description4) }} />
            </div>
          </RevealFadeIn>
          <RevealFadeIn
            delay={0.2}
            className={classes.glossaryItem}
          >
            <div className={classes.glossaryImage}>
              <img src={image5} />
            </div>
            <div>
              <h4>{page?.length > 0 && page[3].title5}</h4>
              <p dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].description5) }} />
            </div>
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Understanding Your Result
  ------------------------------*/
  const renderResultMeanings = () => {
    return (
      <div className={classes.keys} style={{ minHeight: '50vh', background: `url(${resultsBackgroundImage}) center center no-repeat` }} id="results">
        <div className={classes.wrapper}>
          <InViewTrigger>
            <RevealText
              tag="h2"
              type="lines"
              value={(page?.length > 0) && page[3].heading}
              lineHeight={1.2}
              delay={0.2}
              className={classes.keysTitle}
            />
            <RevealFadeIn
              delay={0.2}
              className={classes.heroText}
            >
              <div dangerouslySetInnerHTML={{ __html: convertRichText((page?.length > 0) && page[3].bodyCopy) }} />
            </RevealFadeIn>
          </InViewTrigger>
        </div>
        <EmotionsGallery items={emotions} />
      </div>
    )
  }

  /*------------------------------
  Glossary
  ------------------------------*/
  const renderGlossaryView = () => {
    return (
      <div className={classes.keys} style={{ background: `url(${resultsBackgroundImage}) center center no-repeat` }} id="results">
        <div className={classes.wrapper}>
          <InViewTrigger>
            <RevealText
              tag="h2"
              type="lines"
              value="Glossary"
              lineHeight={1.2}
              delay={0.2}
              className={classes.keysTitle}
            />
          </InViewTrigger>
        </div>
        <div className={classes.emotions}>

          {renderGlossary()}
          <div className={classes.backgroundButtonWrapper} style={{ position: 'relative', width: 310 }}>
            {renderStartButton({ title: page[3].button })}
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Handle Click Close
  ------------------------------*/
  const handleClickClose = () => {
    if (routerAction !== 'POP') {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  /*------------------------------
  Render Begin
  ------------------------------*/
  const renderBegin = () => {
    return (
      <div className={classes.begin} id="begin">
        <div className={classes.wrapper}>
          <InViewTrigger>
            <RevealFadeIn delay={0.5}>
              <button
                onClick={handleClickClose}
                className={classes.btn}
              >
                <span>{page.beginCta}</span>
              </button>
            </RevealFadeIn>
          </InViewTrigger>
        </div>
        <div className={classes.circles}>
          <div className={classes.circle} />
          <div className={classes.circle} />
          <div className={classes.circle} />
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    const backgroundImage1 = page.length > 0 ? page[0]?.images?.find((img) => img.id === page[0].rightImage.sys.id)?.file?.url : undefined;
    const backgroundImage2 = page.length > 0 && page[1]?.images?.find((img) => img.id === page[1].rightImage.sys.id)?.file?.url
    const backgroundImage3 = page.length > 0 && page[2]?.images?.find((img) => img.id === page[2].rightImage.sys.id)?.file?.url
    const section1Background ='/frontend/static/images/portal/backgroundPage/section-1-background.png';
    const section2Background = '/frontend/static/images/portal/backgroundPage/section-2-background.png';
    const section3Background = '/frontend/static/images/portal/backgroundPage/section-3-background.png';

    return realLoad && (
      <>
        {renderHalfWidthText({ title: convertRichText((page.length > 0) && page[0]?.heading), subTitle: page[0]?.subHeading, description: convertRichText((page.length > 0) && page[0].bodyCopy),button:(page.length > 0) && page[0]?.button, imageUrl: backgroundImage1, sectionBackground: section1Background, contain: true, id: "background-section-1", target: "#background-section-2" })}
        {renderHalfWidthText({ title: convertRichText((page.length > 0) && page[1]?.heading), subTitle: page[1]?.subHeading, description: convertRichText((page.length > 0) && page[1].bodyCopy),button:(page.length > 0) && page[0]?.button, imageUrl: backgroundImage2, sectionBackground: section2Background, id: "background-section-2", target: "#background-section-3" })}
        {renderHalfWidthText({ title: convertRichText((page.length > 0) && page[2]?.heading), subTitle: page[2]?.subHeading, description: convertRichText((page.length > 0) && page[2].bodyCopy),button:(page.length > 0) && page[0]?.button, imageUrl: backgroundImage3, sectionBackground: section3Background, id: "background-section-3", target: "#results" })}
        {renderResultMeanings()}
        {renderGlossaryView()}
      </>
    )
  }

  return (
    // <div
    //   className={`page pageLanding ${classes.root} smooth-scroll`}
    //   ref={$rootVirtual}
    //   data-scroll-container
    // >
    <>
      <LocomotiveScroll
        init={realLoad}
        className={`page pageLanding ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      {/* // </div> */}
    </>
  )
}

export default Landing
