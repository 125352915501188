const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -10,
      marginRight: -10,
    },
    formControl: {
      width: '100%',
      padding: '0 10px',
      marginBottom: 30,
      position: 'relative',
      zIndex: 1,
    },
    formControlTextarea: {
      width: '100%',
      marginBottom: 30,
    },
    formControlCheckbox: {
      width: '100%',
      marginBottom: 30,
    },
    formControlSelect: {
      width: '100%',
      marginBottom: 30,
      zIndex: 2,
    },
    btn: {
      extend: theme.button,
      margin: '0 auto',
    },
    btnEmail: {
      extend: theme.button,
      margin: '0 auto',
      width: '14vw',
    },

    // Generic Input
    input: {
      width: '100%',
      color: theme.colors[3],
      fontFamily: theme.fonts[1],
      appearance: 'none',
      padding: '16px 28px',
      fontSize: 14,
      borderRadius: 50,
      background: 'transparent',
      border: `1px solid ${theme.colors[3]}`,
      transition: 'border 0.2s ease-in',
      '&::placeholder': {
        fontWeight:'600',
        fontFamily: theme.fonts[0],
        color: theme.getRgba(theme.colors[3], 0.5),
      },
    },
    formControlDisabled: {
      '& $input': {
        color: theme.getRgba(theme.colors[3], 0.5),
      },
    },

    // Textarea
    textarea: {
      width: '100%',
      color: theme.colors[3],
      fontFamily: theme.fonts[1],
      background: theme.getRgba(theme.colors[2], 0.05),
      appearance: 'none',
      padding: '16px 28px',
      fontSize: 14,
      fontWeight: 300,
      borderRadius: 10,
      resize: 'none',
      border: '2px solid transparent',
      transition: 'border 0.3s ease-in',
      '&::placeholder': {
        fontFamily: theme.fonts[0],
        color: theme.getRgba(theme.colors[0], 0.3),
      },
    },

    // Select
    select: {
      '& .customSelect__control': {
        color: theme.colors[3],
        background: 'transparent',
        fontSize: 14,
        fontFamily: theme.fonts[0],
        borderRadius: 50,
        padding: '5px 28px',
        border: `1px solid ${theme.colors[3]}`,
        '&:hover': {
          borderColor: theme.colors[3],
        },
      },
      '& .customSelect__control--is-focused': {
        border: `1px solid ${theme.colors[3]}`,
      },
      '& .customSelect__placeholder': {
        color: theme.getRgba(theme.colors[3], 0.5),
      },
      '& .customSelect__indicator': {
        color: theme.colors[3],
      },
      '& .customSelect__menu': {
        color: theme.colors[3],
        background: theme.colors[1],
        fontSize: 14,
        fontFamily: theme.fonts[0],
        borderRadius: 25,
        overflow: 'hidden',
        border: `1px solid ${theme.colors[3]}`,
      },
      '& .customSelect__single-value': {
        color: theme.colors[3],
        fontFamily: theme.fonts[1],
      },
      '& .customSelect__menu-list': {
        paddingTop: 10,
        paddingBottom: 10,
      },
      '& .customSelect__value-container': {
        padding: 0,
      },
      '& .customSelect__indicator-separator': {
        display: 'none',
      },
      '& .customSelect__option--is-focused': {
        background: '#fae189',
      },
      '& .customSelect__option--is-selected': {
        background: theme.colors[3],
      },
    },

    // Checkbox
    checkbox: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      fontSize: 14,
      '& input[type="checkbox"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
      },
      '& input[type="checkbox"] + span': {
        display: 'inline-block',
        paddingLeft: 40,
        position: 'relative',
        zIndex: 1,
        '& a': {
          color: 'currentColor',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 30,
          height: 30,
          borderRadius: '50%',
          border: `1px solid ${theme.colors[3]}`,
          background: theme.colors[1],
          transition: 'all .2s ease-in-out',
        },
      },
      '& input[type="checkbox"]:checked + span': {
        '&:before': {
          background: theme.colors[3],
          boxShadow: `inset 0 0 0 5px ${theme.colors[1]}`,
        },
      },
    },

    // Labels
    label: {
      '& a': {
        display: 'inline-block',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 1,
          width: '100%',
          background: 'currentColor',
          transition: 'all .3s ease-out',
          transformOrigin: 'left center',
        },
        '&:hover': {
          '&::before': {
            animation: '$underline .6s forwards',
          },
        },
      },
    },
    '@keyframes underline': {
      '0%': { transform: 'scaleX(0)' },
      '100%': { transform: 'scaleX(1)' },
    },

    // File Field
    formControlUpload: {
      width: '100%',
      marginBottom: 30,
      '& input': {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      },
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        right: 40,
        width: 13,
        height: 13,
        fill: theme.colors[5],
      },
      '& label': {
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        color: theme.getRgba(theme.colors[0], 0.3),
        appearance: 'none',
        padding: '16px 28px',
        fontSize: 16,
        fontFamily: theme.fonts[0],
        border: '2px solid transparent',
        background: theme.getRgba(theme.colors[2], 0.05),
        borderRadius: 10,
        transition: 'all .3s ease-out',
        userSelect: 'none',
        '& span': {
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          verticalAlign: 'top',
          width: '100%',
        },
      },
    },
    formControlIsFill: {
      '& label': {
        color: theme.colors[2],
      },
    },

    // Errors
    formError: {
      '& input': {
        borderColor: '#cc0000',
      },
      '& input[type="checkbox"] + span': {
        '&:before': {
          borderColor: '#cc0000',
        },
      },
      '& .customSelect__control': {
        borderColor: '#cc0000',
      },
      '&$formControlUpload': {
        '& label': {
          borderColor: '#cc0000',
        },
      },
    },

    // Result Message
    result: {
      marginTop: 15,
      marginBottom: 25,
      fontSize: 12,
      opacity: 0,
      textAlign: 'center',
      transition: 'opacity 0.3s ease-out',
    },
    errorStatus: {
      color: '#cc0000',
    },
    successStatus: {
      color: '#31c23c',
    },

    // Error Field
    error: {
      position: 'absolute',
      left: 15,
      bottom: -20,
      fontSize: 10,
      color: '#cc0000',
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
    },

    forgotPassword: {
      marginBottom: 40,
      width: '100%',
      fontSize: 14,
      textAlign: 'center',
      '& a': {
        color: theme.colors[3],
        '--color': 'currentColor',
        extend: theme.linkInverse,
      },
    },

    visible: {
      opacity: 1,
    },

    rootInverse: {
      '& $btn': {
        backgroundColor: theme.colors[1],
        color: theme.colors[3],
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    input: {
      fontSize: 16,
    },
    textarea: {
      fontSize: 16,
    },
    // Select
    select: {
      fontSize: 16,
      '& .customSelect__control': {
        fontSize: 16,
        '& .customSelect__menu': {
          fontSize: 16,
        },
      },
    },
    checkbox: {
      fontSize: 16,
    },
    forgotPassword: {
      fontSize: 16,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    formControl: {
      width: '100%',
    },
  }

  return output
}

export default style
