import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import useMediaQuery from '@/hooks/useMediaQuery'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as questionsActions from '@/actions/ultraUpliftQuestions'
import * as upliftProgramActions from '@/actions/upliftProgram'
import * as layerActions from '@/actions/layer'
import * as userActions from '@/actions/user'
import { dividedByTen } from '@/utils/math'
import { EVENTS, EMITTER } from '@/constants'

import stylePre from './style'
import stylePost from './stylePost'
import CardWithCircle from '../../../components/CardWithCircle'
import CardWithDoubleInput from '../../../components/CardWithDoubleInput'
import CardWithTrueFalse from '../../../components/CardWithTrueFalse'
import CardWithDropdown from '../../../components/CardWithDropdown'

const useStylesPre = createUseStyles(stylePre)
const useStylesPost = createUseStyles(stylePost)

const QuestionsAfterEmail = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const [mediaQueryKey] = useMediaQuery()
  const $root = useRef()
  const [active, setActive] = useState(0)
  const activeRef = useRef(active)
  const [goOn, setGoOn] = useState(false)
  const [isDataFetched, setDataFetched] = useState(false)
  const cardWidth = useRef(mediaQueryKey > 2 ? 442 : 330)
  const timerNext = useRef()
  const questionsNum = useRef()
  const results = useRef()
  const history = useHistory()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions, step, strings, questions } = useSelector((state) => ({
    emotions: state.emotions.items.filter((e) => e.step === 'questions'),
    strings: state.options.strings,
    step: state.experience.step,
    questions: state.questions.items,
  }), shallowEqual)

  const classesPre = useStylesPre({ headerHeight })
  const classesPost = useStylesPost({ headerHeight })

  const classes = (step === 1) ? classesPre : classesPost

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveNext = useCallback((name) => dispatch(routerActions.changeLocation(name)), [dispatch])
  const setEmotion = useCallback((value, key, status) => dispatch(experienceActions.setSingleEmotion(value, key, status)), [dispatch])
  const fetchQuestions = useCallback((step) => dispatch(questionsActions.fetchQuestions(2)), [dispatch])
  const setExertion = useCallback((value) => dispatch(experienceActions.setExertion(value)), [dispatch])
  const saveUpliftProgram = useCallback((value) => dispatch(upliftProgramActions.saveUpliftProgram(value)), [dispatch])
  const fetchProfile = useCallback(() => dispatch(userActions.fetchProfile()), [dispatch])

  /*------------------------------
  Fetch Questions
  ------------------------------*/
  useEffect(() => {
    fetchQuestions(step)
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (questions.length > 0) {
      questionsNum.current = questions.length
      results.current = Array(questionsNum.current).fill(0)
      setDataFetched(true)
    }
  }, [questions])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => {
      cardWidth.current = mediaQueryKey > 1 ? 442 : 330
      setPageAnimationReady(true)
    },
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Active Ref
  ------------------------------*/
  useEffect(() => {
    activeRef.current = active
  }, [active])

  /*------------------------------
  Resize
  ------------------------------*/
  useEffect(() => {
    cardWidth.current = mediaQueryKey > 1 ? 442 : 330
  }, [mediaQueryKey])

  /*------------------------------
  SetResult
  ------------------------------*/
  const setResult = (answer, vote) => {
    console.log('ANSWER', answer, 'VOTE', vote)
    if (answer == 6) { // if exertion
      setExertion(vote)
    }

    results.current[answer] = vote

    if (!results.current.some((a) => a === 0)) {
      setGoOn(true) // Se nessuna delle risposte ha valore zero, allora abilito il pulsante continua
    } else {
      setActive(Math.min(activeRef.current + 1, questionsNum.current - 1))
    }
  }

  /*------------------------------
  Render Counter
  ------------------------------*/
  const renderCounter = () => {
    return (
      <div className={classes.counter}>
        <strong>{`0${active + 1}`}</strong>
        <span>{`0${questionsNum.current}`}</span>
      </div>
    )
  }

  /*------------------------------
  Render Questions
  ------------------------------*/
  const renderQuestions = () => {
    return (
      <>
        <div className={classes.questionsWrapper}>
          <div className={classes.questions} style={{ marginLeft: `${-(cardWidth.current * 0.5) - (active * cardWidth.current)}px` }}>
            {questions.map((q, ind) => {
              const myPos = ind - active
              if (q.type) {
                switch (q.type) {
                  case 'Knob':
                    return (
                      <CardWithCircle
                        key={(ind + 1000).toString()}
                        index={ind}
                        className={classes.card}
                        title={step === 1 ? q.questionPre : q.questionPost}
                        description={step === 1 ? q.descriptionPre : q.descriptionPost}
                        isActive={ind === active}
                        onClick={setResult}
                        step={step}
                        type={['circle']}
                        max={q.maxItemCount}
                        allowSelectZero={q.allowSelectZero}
                        showPlus={q.showPlus}
                        hint={step === 1 ? q.hintPre : q.hintPost}
                        style={{ transform: `rotate(${myPos * 4}deg) translateY(${Math.abs(myPos * 15 * myPos)}px)` }}
                      />
                    )
                    break
                  case 'Timer':
                    return (
                      <CardWithDoubleInput
                        key={(ind + 1000).toString()}
                        index={ind}
                        className={classes.card}
                        title={step === 1 ? q.questionPre : q.questionPost}
                        isActive={ind === active}
                        onClick={setResult}
                        step={step}
                        hint={step === 1 ? q.hintPre : q.hintPost}
                        style={{ transform: `rotate(${myPos * 4}deg) translateY(${Math.abs(myPos * 15 * myPos)}px)` }}
                      />
                    )
                    break
                  case 'Boolean':
                    return (
                      <CardWithTrueFalse
                        key={(ind + 1000).toString()}
                        index={ind}
                        className={classes.card}
                        title={step === 1 ? q.questionPre : q.questionPost}
                        isActive={ind === active}
                        onClick={setResult}
                        step={step}
                        style={{ transform: `rotate(${myPos * 4}deg) translateY(${Math.abs(myPos * 15 * myPos)}px)` }}
                      />
                    )
                    break
                  case 'Dropdown':
                    return (
                      <CardWithDropdown
                        key={(ind + 1000).toString()}
                        index={ind}
                        className={classes.card}
                        title={step === 1 ? q.questionPre : q.questionPost}
                        isActive={ind === active}
                        onClick={setResult}
                        dropdownOptions={q.dropdownOptions}
                        step={step}
                        style={{ transform: `rotate(${myPos * 4}deg) translateY(${Math.abs(myPos * 15 * myPos)}px)` }}
                      />
                    )
                    break
                }
              }
            })}
          </div>
        </div>
      </>
    )
  }

  /*------------------------------
  Handle Click Continue
  ------------------------------*/
  const handleClickContinue = async () => {
    const saveProgramResponse = await saveUpliftProgram({
      q5: results.current[0],
      q6: results.current[1],
      q7: results.current[2],
      q8: results.current[3],
      completed: '1',
    })

    await fetchProfile()
    history.push('/portal')
    // moveNext(step === 1 ? 'choose-sport' : 'results')
    // if (step !== 1) EMITTER.emit(EVENTS.DATALAYER.SEE_RESULTS)
  }

  /*------------------------------
  Render Next
  ------------------------------*/
  const renderNext = () => {
    return (
      <div
        className={classNames({
          [classes.buttonNext]: true,
          disabled: !goOn,
        })}
      >
        <button className={classes.btn} onClick={handleClickContinue}><span>{step === 1 ? strings['questions.pre.next'] : strings['questions.post.next']}</span></button>
        <div className={classes.hint}>{strings['questions.hint']}</div>
      </div>
    )
  }

  /*------------------------------
  Render Arrows
  ------------------------------*/
  const renderControls = () => {
    return (
      <div className={classes.controls}>
        <button
          onClick={() => {
            clearTimeout(timerNext.current)
            setActive(Math.max(0, active - 1))
          }}
          className={classNames({
            [classes.arrow]: true,
            carousel__prev: true,
          })}
        >
          <svg viewBox="0 0 11 21">
            <path fillRule="evenodd" d="M11.000,0.000 L11.000,21.000 L-0.000,10.000 L11.000,0.000 Z" />
          </svg>
        </button>
        <button
          onClick={() => {
            clearTimeout(timerNext.current)
            setActive(Math.min(active + 1, questionsNum.current - 1))
          }}
          className={classNames({
            [classes.arrow]: true,
            carousel__next: true,
          })}
        >
          <svg viewBox="0 0 11 21">
            <path fillRule="evenodd" d="M-0.000,0.000 L-0.000,21.000 L11.000,10.000 L-0.000,0.000 Z" />
          </svg>
        </button>
        {renderNext()}
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderCounter()}
          {renderQuestions()}
          {renderControls()}
        </div>
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageQuestions ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default QuestionsAfterEmail
