import noise from './noise'

export const vertexShader = /* glsl */`
  struct Data {
    vec2 pos;
    vec2 uv;
    vec2 uvMap;
    vec3 color;
    float scale;
  };
  varying vec3 vPos;
  uniform sampler2D uTexture;
  uniform Data data[10];
  uniform float uSwitchMap;
  uniform int uNoise;
  uniform float uTime;
  #define PI 3.14159265

  /*------------------------------
  Noise
  ------------------------------*/
  ${noise}


  /*------------------------------
  Rotate 3d
  ------------------------------*/
  vec3 rotateAxis(vec3 p, vec3 axis, float angle) {
    return mix(dot(axis, p)*axis, p, cos(angle)) + cross(axis,p)*sin(angle);
  }

  /*------------------------------
  Main
  ------------------------------*/
  void main() {
      vec3 pos = position;
      vPos = pos;
      
      float depth = clamp(pos.z, 0., 1.);
      vec2 uv = normalize(pos * depth).xy * 0.5 + 0.5;

      for (int i = 0; i <= 9; i++) {
        vec3 myPos = position;
        myPos = rotateAxis(myPos, vec3(1., 0., 0.), data[i].pos.x * PI);
        myPos = rotateAxis(myPos, vec3(0., 1., 0.), data[i].pos.y * PI);
        depth = clamp(myPos.z, 0., 1.);
        uv = normalize(myPos * depth).xy * 0.5 + 0.5;
        uv *= vec2(.2, .2);
        uv += data[i].uvMap;
        vec4 texture = texture2D(uTexture, uv);
        pos *= 1. + texture.rgb * depth * data[i].scale;
      }

      // Noise
      if (uNoise == 1) {
        pos.xyz = pos.xyz + pos.xyz * (0.5 + noise(vec3(pos.x, pos.y, pos.z + uTime * 0.3)) * 0.5) * .2;
      }

      // Output
      vec4 mvPosition = modelViewMatrix * vec4( pos, 1.0 );
      gl_Position = projectionMatrix * mvPosition;
      gl_PointSize = ${window.location.search.indexOf('size') !== -1 ? window.location.search.split('size=')[1] : 30.0}. / -mvPosition.z;
  } 
`
export const fragmentShader = /* glsl */`
  struct Data {
    vec2 pos;
    vec2 uv;
    vec2 uvMap;
    vec3 color;
    float scale;
  };
  varying vec3 vPos;
  uniform sampler2D uTexture;
  uniform Data data[10];
  uniform float uAlpha;
  #define PI 3.14159265

  /*------------------------------
  Rotate 3d
  ------------------------------*/
  vec3 rotateAxis(vec3 p, vec3 axis, float angle) {
    return mix(dot(axis, p)*axis, p, cos(angle)) + cross(axis,p)*sin(angle);
  }

  /*------------------------------
  Main
  ------------------------------*/
  void main() {
    vec2 uvPoint = gl_PointCoord - vec2(0.5);
    float d = length(uvPoint) * 2.;
    vec3 finalColor = vec3(0.);

    vec3 pos = vPos;
    float depth = clamp(pos.z, 0., 1.);
    vec2 uv = normalize(pos * depth).xy * 0.5 + 0.5;

    for (int i = 0; i <= 9; i++) {
      pos = vPos;
      pos = rotateAxis(pos, vec3(1., 0., 0.), data[i].pos.x * PI);
      pos = rotateAxis(pos, vec3(0., 1., 0.), data[i].pos.y * PI);
      depth = clamp(pos.z, 0., 1.);
      uv = normalize(pos * depth).xy * 0.5 + 0.5;
      uv *= vec2(.2, .2);
      uv += data[i].uv;
      vec4 texture = texture2D(uTexture, uv);
      finalColor += texture.rgb * depth * data[i].color;
    }

    gl_FragColor = vec4(finalColor, uAlpha);
    // gl_FragColor = vec4(finalColor * finalColor - finalColor * .1 + .13, 1.); // <- Saturated
  }    
`
