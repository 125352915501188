import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

function ArrowLeft(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : ''
  return (
    <button className="arrow" onClick={props.onClick}>
      <svg
        className={` arrow--left${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </button>
  )
}

function ArrowRight(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : ''
  return (
    <button className="arrow" onClick={props.onClick}>
      <svg
        className={` arrow--right${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </button>
  )
}

const KeenGallery = ({
  items,
  className,
}) => {
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [progress, setProgress] = useState(0)

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 8,
    mode: 'free-snap',
    spacing: 15,
    centered: true,
    rubberband: false,
    breakpoints: {
      '(max-width: 1920px)': {
        slidesPerView: 6,
      },
      '(max-width: 1680px)': {
        slidesPerView: 5,
      },
      '(max-width: 1440px)': {
        slidesPerView: 5,
      },
      '(max-width: 1280px)': {
        slidesPerView: 4,
      },
      '(max-width: 768px)': {
        slidesPerView: 5,
      },
      '(max-width: 575px)': {
        slidesPerView: 4,
      },
      '(max-width: 440px)': {
        slidesPerView: 3,
      },
    },
    move(s) {
      const details = s.details()
      setCurrentSlide(details.relativeSlide)
      setProgress(details.progressSlides)
    },
  })

  return (
    <div className={classNames({
      [classes.root]: true,
      className,
    })}
    >
      <div
        ref={sliderRef}
        className="keen-slider"
      >
        {items.map((item, i) => {
          return (
            <div
              key={i.toString()}
              className={classNames({
                [classes.slide]: true,
                'keen-slider__slide': true,
              })}
              style={{ '--color': item.color }}
            >
              <div className={classes.title}>
                {item.name}
              </div>
              <div className={classes.ball} />
            </div>
          )
        })}
      </div>

      <div className={classes.galleryControls}>
        {slider && (
        <div className={classes.arrowWrapper}>
          <ArrowLeft
            onClick={(e) => e.stopPropagation() || slider.prev()}
            disabled={currentSlide === 0}
          />
          <ArrowRight
            onClick={(e) => e.stopPropagation() || slider.next()}
            disabled={currentSlide === slider.details().size - 1}
          />
        </div>
        )}
      </div>
      {slider && (
        <div className={classes.scrollbar}>
          <div className={classes.scrollThumbPre} style={{ flex: `0 0 ${100 / items.length}%` }} />
          <div
            className={classes.scrollthumb}
            style={{ transform: `scaleX(${progress})` }}
          />
        </div>
      )}
    </div>
  )
}

export default KeenGallery
