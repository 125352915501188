import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { decodeEntities } from '@/utils/decodeEntities'

const Meta = ({
  title,
  meta,
  schema,
}) => {
  const strings = useSelector((state) => state.options.strings)
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (Object.keys(strings).length > 0) setLoad(true)
  }, [strings])

  return load && (
    <Helmet
      title={`${title ? `${decodeEntities(title)} - ` : ''}${strings['general.site_title']}`}
      meta={meta}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

Meta.defaultProps = {
  title: '',
  meta: [],
  schema: [],
}

export default Meta
