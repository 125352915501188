import { useContext } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Context } from '@/context'
import style from './style'

const useStyles = createUseStyles(style)

const Burger = ({
  headerMap,
  height,
  isActive,
  isHover,
  width,
}) => {
  const { headerInverse, headerExtraDark } = useContext(Context)
  const classes = useStyles({ width, height, headerInverse, headerExtraDark })

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.isActive]: isActive,
        [classes.isHover]: isHover,
        [classes.headerMap]: headerMap,
      })}
    >
      <div className={classes.box}>
        <div className={classes.inner} />
      </div>
    </div>
  )
}

Burger.defaultProps = {
  height: 9,
  width: 14,
}

export default Burger
