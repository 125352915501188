const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: '75%',
        height: '75%',
        '& path': {
          transition: `all 0.3s ${theme.easings['power4.out']}`,
          fill: 'none',
          stroke: theme.colors[3],
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        },
        '& path:nth-child(1)': {
          transform: 'translateX(3px)',
        },
        '& path:nth-child(2)': {
          opacity: 0,
        },
        '& path:nth-child(3)': {
          opacity: 0,
        },
      },
    },
    inverse: {
      '& svg': {
        '& path': {
          stroke: theme.colors[1],
        },
      },
    },
    extraDark: {
      '& svg': {
        '& path': {
          stroke: '#001E61',
        },
      },
    },
    active: {
      '& svg': {
        '& path:nth-child(1)': {
          transform: 'translateX(0px)',
        },
        '& path:nth-child(2)': {
          opacity: 1,
        },
        '& path:nth-child(3)': {
          opacity: 1,
        },
      },
    },
    isHover: {
      '@media (hover: hover)': {
        '& svg': {
          '& path': {
            stroke: theme.colors[1],
          },
        },
        '&$inverse': {
          '& svg': {
            '& path': {
              stroke: theme.colors[3],
            },
          },
        },
        '&$extraDark': {
          '& svg': {
            '& path': {
              stroke: 'rgb(232, 220, 74)',
            },
          },
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
