import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import SelectField from '@/components/SelectField'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import * as eventsActions from '@/actions/events'
import * as layerActions from '@/actions/layer'
import { convertRichText } from '@/utils/strings'
import style from './style'
import PrizeLoginModal from '../../../components/PrizeLoginModal'

const useStyles = createUseStyles(style)

const ChooseEvent = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight, setLogoWhite } = useContext(GeneralContext)
  const [isDataFetched, setDataFetched] = useState(false)
  const [fieldValue, setFieldValue] = useState(localStorage.getItem('lastSelectedEventName'))
  const [selectedEventImage, setSelectedEventImage] = useState()
  const [selectedEvent, setSelectedEvent] = useState()
  const [prize, setPrize] = useState(localStorage.getItem('checkPrizeBox') == 'true')
  const [featuredEventImage, setFeaturedEventImage] = useState('')
  const [isBrandedEvent, setIsBrandedEvent] = useState(false)
  const $root = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, events, brandedEvent, fakeLocation, strings, user } = useSelector((state) => ({
    page: state.content['event-page'] || {},
    events: state.events.dailyEvents,
    brandedEvent: state.events?.dailyEvents?.find((ev) => ev.fields.branded),
    eventLogo: state.events.eventLogo || {},
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    user: state.user,
  }), shallowEqual)

  const classes = useStyles({ headerHeight })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  // const moveForward = useCallback(() => dispatch(routerActions.changeLocation('steps')), [dispatch])
  const moveForward = useCallback(() => dispatch(routerActions.changeLocation('face-scan-camera-1')), [dispatch])
  const fetchEventImage = useCallback((id) => dispatch(eventsActions.fetchEventImage(id)), [dispatch])
  const fetchSelectedEventImage = useCallback((id) => dispatch(eventsActions.fetchSelectedEventImage(id)), [dispatch])
  const fetchEventLogo = useCallback((id) => dispatch(eventsActions.fetchEventLogo(id)), [dispatch])
  const setEvent = useCallback((id, name) => dispatch(experienceActions.setEvent(id, name)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'eventPage')), [dispatch])
  const setEventLocation = useCallback((coord) => dispatch(experienceActions.setEventLocation(coord)), [dispatch])
  const setLottery = useCallback((value) => dispatch(experienceActions.setLottery(value)), [dispatch])
  const setPrizeMailBody = useCallback((value) => dispatch(experienceActions.setPrizeMailBody(value)), [dispatch])
  const openModal = useCallback((modal, data = {}) => dispatch(layerActions.openLayer({ id: modal, ...data })), [dispatch])
  const fetchEventLocation = useCallback((coord, city) => {
    dispatch(experienceActions.fetchEventLocation(coord, city))
  }, [dispatch])

  //
  let eventBackgroundImage = page?.images?.find((img) => img.id === page?.fullBackground?.sys?.id)?.file?.url
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      eventBackgroundImage = page?.images?.find((img) => img.id === page?.mobileBackground?.sys?.id)?.file?.url
    }
  }
  resizeBg()

  window.addEventListener('resize', () => {
    resizeBg()
  })

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent('event-page')
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (
      events
      && Object.keys(events).length > 0
    ) setDataFetched(true)
    if (events) {
      setDataFetched(true)
    }
  }, [page, events])

  useEffect(()=>{
    if(events && events.length===0){
      moveForward();
    }
  },[events])
  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [
      // Object.keys(eventImage).length > 0 && { ...{ type: 'image', src: eventImage.url } },
    ],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Reset Logo White
  ------------------------------*/
  useEffect(() => {
    if (isBrandedEvent) setLogoWhite(true)
    return () => {
      setLogoWhite(false)
    }
  }, [isBrandedEvent])

  useEffect(() => {
    const selectedEvent = events?.filter((item) => item.fields?.title == fieldValue)[0]
    if (selectedEvent) {
      setSelectedEvent(selectedEvent)
      fetchImageForSelectedEvent(selectedEvent.sys?.id)
    }
    localStorage.removeItem('lastSelectedEventName')
    localStorage.removeItem('checkPrizeBox')
  }, [fieldValue, events])

  const handlePrizeCheckboxChange = () => {
    if (!user.isLoggedIn) {
      openModal('prize-login', { eventName: selectedEvent.fields.title })
      return
    }

    setPrize(!prize)
  }

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  const handleClickNext = () => {
    setLottery(prize)
    

    const event_name = fieldValue
    // const event_id = events.find((ev) => ev.fields.title === event_name)
    // setEvent(event_id, event_name)
    const selectedEvent = events.find((ev) => ev.fields.title === event_name)
    setEvent(selectedEvent.sys.id, event_name)
    if(prize){
      setPrizeMailBody(selectedEvent.fields.prizeMailBody);
    }
    setPrize(false)
    if (selectedEvent.fields.where) {
      fetchEventLocation(selectedEvent.fields.where, selectedEvent.fields.city)
    }
    moveForward()
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderChoose = () => {
    return (
      <div className={classes.page} style={{ background: `url(${eventBackgroundImage}) center center no-repeat` }}>
        <div
          className={classNames({
            [classes.hero]: true,
            [classes.heroCover]: isBrandedEvent,
          })}
          style={{ backgroundImage: featuredEventImage ? `url(${featuredEventImage})` : '' }}
        >
          <div className={classes.wrapper}>
            <div className={classes.box}>
              {selectedEventImage ? <img src={selectedEventImage} className={classes.eventImage} /> : null }
              <RevealText
                tag="h1"
                type="lines"
                value={page.heading}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
              <RevealFadeIn
                delay={0.5}
                inview={pageAnimationReady}
              >
                <SelectField
                  label={page.dropDown}
                  name="chooseEvent"
                  value={fieldValue}
                  options={events.map((evt) => ({ id: evt.sys?.id, name: evt.fields.title }))}
                  setFieldValue={(label, id) => {
                    setFieldValue(label)
                    fetchImageForSelectedEvent(id)
                    fetchLogoForSelectedEvent(id)
                    setPrize(false)
                    const selectedEvent = events.filter((item) => item.sys?.id == id)[0]
                    setSelectedEvent(selectedEvent || null)
                  }}
                />
              </RevealFadeIn>
            </div>
            {selectedEvent != undefined && selectedEvent.fields.prize
              ? (
                <div className={classes.prizeCheckbox}>
                  <span>{page.prizeDrawCopy.replace('[Event_Name]', selectedEvent.fields.title || '')}</span>
                  <label>
                    <input type="checkbox" checked={prize} onChange={() => { handlePrizeCheckboxChange() }} />
                    <div className="square">
                      <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 8.92308L8.95652 16.1923L23.5652 2" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </label>
                </div>
              )
              : null}
            <div className={classes.buttons}>
              <RevealFadeIn
                delay={2}
                inview={pageAnimationReady}
              >
                <button
                  onClick={moveForward}
                  className={classNames({
                    [classes.btn]: true,
                    white: true,
                  })}
                >
                  <span>{page.skipButton || strings['general.skip']}</span>
                </button>
                <button
                  onClick={handleClickNext}
                  className={classNames({
                    [classes.btn]: true,
                    disabled: !fieldValue,
                  })}
                >
                  <span>{page.continueButton || strings['general.continue']}</span>
                </button>
              </RevealFadeIn>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const fetchImageForSelectedEvent = async (id) => {
    const selectedEvent = events.filter((event) => {
      return event.sys?.id == id
    })

    if (selectedEvent[0].fields.cover) {
      const imageId = selectedEvent[0].fields.cover.sys?.id
      const imageFile = await fetchSelectedEventImage(imageId)
      setIsBrandedEvent(true)
      setFeaturedEventImage(imageFile.url)
    } else {
      setIsBrandedEvent(false)
      setFeaturedEventImage('')
    }
  }
  const fetchLogoForSelectedEvent  = async (id) => {
    const selectedEvent = events.filter((event) => {
      return event.sys?.id == id
    })

    if (selectedEvent[0].fields.eventLogo) {
      const imageId = selectedEvent[0].fields.eventLogo.sys?.id
      const imageFile = await fetchEventLogo(imageId)
      setSelectedEventImage(imageFile.url)
    } else {
      setSelectedEventImage('')
    }
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderChoose()}
          </div>
          {events.length >0 && <PrizeLoginModal selectedEvent={selectedEvent} />}
        </>
      )
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageChooseEvent ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default ChooseEvent
