const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      backgroundSize: 'cover!important',
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },

    // Hero
    hero: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 1,
      fontSize: 18,
      display: 'flex',
      minHeight: 'var(--vh)',
      flexDirection: 'column',
      justifyContent:'center',
      padding: [100, 0, 0, 0],
      backgroundSize: 'cover',
    },
    forgot:{
      fontSize:21,
      marginTop:40,
      marginBottom:30,
      textDecorationLine:'underline',
      color: '#485BC7',
      cursor:'pointer'
    },
    title: {
      extend: theme.typography.headings,
      fontSize: 60,
      marginBottom: 30,
    },
    hint: {
      maxWidth: 620,
      margin: [0, 'auto', 30],
      color: theme.colors[3],
      fontSize: 21,
    },
    email:{
      maxWidth:488,
    },
    text: {
      maxWidth: 360,
      margin: [0, 'auto', 40],
      color: theme.colors[3],
    },
    box: {
      maxWidth: 677,
      margin: [0, 'auto'],
    },
    buttons: {
      paddingBottom: 40,
      '& $btn': {
        margin: [0, 10],
      },
    },
    prizeCheckbox: {
      maxWidth: '100%',
      width: 600,
      margin: [40, 'auto'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& input[type=checkbox]': {
        display: 'none',
      },

      '& span': {
        color: '#485BC7',
        fontSize: 21,
      },

      '& .square': {
        width: 40,
        height: 40,
        background: '#fff',
        border: '2px solid #485BC7',
        borderRadius: 6,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& input[type=checkbox]:checked + .square': {
        background: '#485BC7',
      },
    },
    // Cover
    heroCover: {
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, .7)',
        pointerEvents: 'none',
      },
      '& $wrapper': {
        zIndex: 2,
        color: theme.colors[1],
      },
      '& $title, & $text': {
        color: theme.colors[1],
      },
    },

    eventImage: {
      width: '40%',
      marginBottom: 20,
    }
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      fontSize: 24,
      marginBottom: 35,
    },
    text: {
      fontSize: 14,
      margin: [0, 'auto', 40],
      padding: [0, 20],
    },
    hint: {
      padding: [0, 20],
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    buttons: {
      marginTop: 40,
      '& $btn': {
        margin: 10,
      },
    },
  }

  return output
}

export default style
