import { useEffect, useRef, Suspense } from 'react'
import gsap from 'gsap'
import { Color } from 'three'
import { JEELIZFACEFILTER, NN_4EXPR } from 'facefilter'
import useStore from '@/base/zustand'
import FaceModel from './FaceModel'
import React from 'react'
const DETECTION_THRESHOLD = 0.8
const TIMER_START_FACE_SCAN = 2

const FaceDetect = ({ faceFilterRef,isFirstFaceStep }) => {
  const $face = useRef()
  const { isFaceDetected, setFaceDetected, setStartFaceScan, isFaceScanFinish, setCameraEnabled, setFaceInCamera, isFaceInCamera, isStartFaceScan, initFaceScan,isModalClosed,setModalClosed } = useStore()
  const started = useRef(false)

  useEffect(() => {
    $face.current.children[0].material.opacity = 0
    $face.current.children[0].material.color = new Color(0x485cc7)

    if (initFaceScan) {
      JEELIZFACEFILTER.init({
        NNC: NN_4EXPR, // instead of NNCPath
        canvas: faceFilterRef.current,
        followZRot: true,
        maxFacesDetected: 1,
        callbackReady: (errCode) => {
          if (errCode) {
            setCameraEnabled('not_enabled')
          } else {
            setCameraEnabled('enabled')
            // gsap.to($face.current.children[0].material, {
            //   startAt: {
            //     opacity: 0,
            //   },
            //   opacity: 1,
            //   duration:0,
            // })
            setTimeout(() => {
              setStartFaceScan(true)
              started.current = true
            }, TIMER_START_FACE_SCAN * 500)
          }
        },
        callbackTrack: (data) => {
          JEELIZFACEFILTER.render_video()

          setFaceDetected(true)
          if (data.detected < DETECTION_THRESHOLD) {
            setFaceInCamera(false)
          } else if (data.detected > DETECTION_THRESHOLD) {
            setFaceInCamera(true)
            if (started.current) {
              gsap.to($face.current.rotation, {
                x: data.rx,
                y: data.ry * -1,
                duration: 0.7,
                ease: 'power3.out',
              })
            }
          }
        },
      })
    }
  }, [initFaceScan,isModalClosed])

  /*------------------------------
  Set Opacity to material model when is getting error on detection
  ------------------------------*/
  useEffect(() => {
    if (isFaceInCamera && isStartFaceScan) gsap.to($face.current.children[0].material, { opacity: 1 })
    if (!isFaceInCamera && isStartFaceScan) gsap.to($face.current.children[0].material, { opacity: 0.6 })
  }, [isFaceInCamera, isStartFaceScan])

  /*------------------------------
  Face In Camera
  ------------------------------*/
  useEffect(() => {
    if (!isFaceInCamera && isFaceDetected && started.current) {
      gsap.killTweensOf($face.current.rotation)
      gsap.to($face.current.rotation, {
        y: 0.1,
        x: -0.07,
        duration: 1,
        ease: 'power3.out',
        onComplete: () => {
          gsap.fromTo($face.current.rotation, {
            y: 0.1,
          }, {
            y: -0.1,
            repeat: -1,
            yoyo: true,
            duration: 2,
            ease: 'power2.inOut',
          })
          gsap.fromTo($face.current.rotation, {
            x: -0.07,
          }, {
            x: 0.07,
            repeat: -1,
            yoyo: true,
            duration: 1.5,
            ease: 'power2.inOut',
          })
        },
      })
    }
  }, [isFaceInCamera, isFaceDetected])

  // Finish detect
  useEffect(() => {
    if (isFaceScanFinish) {
      JEELIZFACEFILTER.destroy()
      $face.current.children[0].material.color = new Color(0x485cc7)
      gsap.to($face.current.position, {
        z: -0.5,
        ease: 'power3.out',
        duration: 3,
      })
      gsap.killTweensOf($face.current.rotation)
      gsap.to($face.current.rotation, {
        x: 0,
        y: 0,
        ease: 'power3.inOut',
        duration: 3,
        onComplete: () => {
          if ($face.current) gsap.killTweensOf($face.current.rotation)
        },
      })
    }
  }, [isFaceScanFinish])

  useEffect(() => {
    if (isFaceScanFinish) return

    if ($face.current && isFaceDetected) {
      gsap.killTweensOf($face.current.position)
      gsap.set($face.current.position, {
        z: 0.5,
        ease: 'power3.out',
        duration: 1,
      })
    }
  }, [isFaceDetected, isFaceScanFinish])

  return (
    <>
      <ambientLight />
      <Suspense fallback={null}>
        <FaceModel ref={$face} />
      </Suspense>
    </>
  )
}

export default React.memo(FaceDetect)
