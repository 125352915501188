import { GET_PROGRAM_DURATION, SET_PROGRAM_DURATION } from '@/actions/types'

const initialState = {
  duration: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRAM_DURATION:
        state = {
            ...state,
            duration: state.duration,
        }
        break
    case SET_PROGRAM_DURATION:
        state = {
            ...state,
            duration: action.payload,
        }
        break
    default:
      return { ...state }
  }
  return { ...state }
}
